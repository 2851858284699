import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const colorBlueBerry = colorPalette.interactions.blueBerry.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const RestoreDeletedTrashcanIcon = ({ title = "", fillPath = colorBlueBerry }: IconProps) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        d="M8.99957 19.0004C8.99957 20.1004 9.89957 21.0004 10.9996 21.0004H18.9996C20.0996 21.0004 20.9996 20.1004 20.9996 19.0004V13.0004C20.9996 11.9004 20.0996 11.0004 18.9996 11.0004H10.9996C9.89957 11.0004 8.99957 11.9004 8.99957 13.0004V19.0004Z"
        fill={fillPath}
      />
      <path
        d="M19.354 6.36846L21.617 7.43105C22.1148 7.66481 22.3309 8.26341 22.0971 8.76126C21.8634 9.2591 21.2648 9.47517 20.7669 9.2414L9.90478 4.14098C9.40694 3.90721 9.19087 3.30862 9.42464 2.81077C9.65841 2.31292 10.257 2.09686 10.7549 2.33063L13.0178 3.39322L13.9622 3.05231C14.2017 2.96589 14.4838 2.97683 14.7191 3.08734L18.5028 4.86398C18.7381 4.97449 18.9267 5.18457 19.0131 5.42401L19.354 6.36846Z"
        fill={fillPath}
      />
      <path
        d="M6.46951 9.14233L7.28919 11.1732L2.6765 9.38999C2.40691 9.28313 2.2834 8.97711 2.40328 8.71305L4.49329 4.24611L5.29052 6.22129C9.09988 5.26608 11.5328 7.1526 13.0514 9.41893C11.0626 8.20511 9.04744 8.10183 6.46951 9.14233Z"
        fill={fillPath}
      />
    </svg>
  );
};

/** @deprecated use DeleteTrashcanIcon icon instead */
export const TrashcanIcon = ({
  fillPath = colorIron,
  fillSvg = "none",
  height = "18",
  title = "",
  viewBox = "0 0 14 18",
  width = "14",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V7C13 5.9 12.1 5 11 5H3C1.9 5 1 5.9 1 7V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const DeleteTrashcanIcon = ({ fillPath = colorIron, title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V10C18 8.9 17.1 8 16 8H8C6.9 8 6 8.9 6 10V19ZM18 4H15.5L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4Z"
        fill={fillPath}
      />
    </svg>
  );
};
