// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WRXgM_c7FZfGrTU_6YZa{align-items:center;background-color:var(--color-neutrals-quartz);display:flex;justify-content:space-between;min-height:72px;padding:0 24px;position:relative;z-index:1105}.WRXgM_c7FZfGrTU_6YZa .oA7mZFk8duR903wGbslI{align-items:center;display:flex}.WRXgM_c7FZfGrTU_6YZa .oA7mZFk8duR903wGbslI .ypAf82mpc7ZrvJS3fANA{background-color:var(--color-neutrals-silver);height:72px;margin-right:24px;width:1px}.WRXgM_c7FZfGrTU_6YZa .oA7mZFk8duR903wGbslI .MYOydJCczkZ3MitfKL0E{left:0;position:relative}.WRXgM_c7FZfGrTU_6YZa .oA7mZFk8duR903wGbslI .OJSduFAR8UUwsqz8WTeY{margin:0}`, "",{"version":3,"sources":["webpack://./src/sections/branding-header-v2/branding-header-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,6CAAA,CACA,YAAA,CACA,6BAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CACA,YAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CAEA,kEACE,6CAAA,CACA,WAAA,CACA,iBAAA,CACA,SAAA,CAGF,kEACE,MAAA,CACA,iBAAA,CAGF,kEACE,QAAA","sourcesContent":[".brandingHeaderRoot {\n  align-items: center;\n  background-color: var(--color-neutrals-quartz);\n  display: flex;\n  justify-content: space-between;\n  min-height: 72px;\n  padding: 0 24px;\n  position: relative;\n  z-index: 1105;\n\n  & .brandingHeaderContent {\n    align-items: center;\n    display: flex;\n\n    & .brandingHeaderDivider {\n      background-color: var(--color-neutrals-silver);\n      height: 72px;\n      margin-right: 24px;\n      width: 1px;\n    }\n\n    & .brandingHeaderLoader {\n      left: 0;\n      position: relative;\n    }\n\n    & .brandingHeadingInitName {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brandingHeaderRoot": `WRXgM_c7FZfGrTU_6YZa`,
	"brandingHeaderContent": `oA7mZFk8duR903wGbslI`,
	"brandingHeaderDivider": `ypAf82mpc7ZrvJS3fANA`,
	"brandingHeaderLoader": `MYOydJCczkZ3MitfKL0E`,
	"brandingHeadingInitName": `OJSduFAR8UUwsqz8WTeY`
};
export default ___CSS_LOADER_EXPORT___;
