import isValidNumber from "./isValidNumber";

/**
 * @param {*} value var to be checked for being a positive number
 * @returns true if number is greater than 0
 */
const isPositiveNumber = (value) => {
  return isValidNumber(value) && value > 0;
};

export default isPositiveNumber;
