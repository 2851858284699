/**
 * publish custom event
 * @param {*} eventName name of event to be published
 * @param {*} data payload to be passed to event
 */
function publishEvent(eventName, data) {
  const event = new CustomEvent(eventName, { detail: data });
  window.dispatchEvent(event);
}

/**
 * subscribe to custom event
 * @param {*} eventName name of event to subscribe to
 * @param {*} listener callback function to be called when event is published
 */
function subscribeToEvent(eventName, listener) {
  window.addEventListener(eventName, listener);
}

/**
 * unsubscribe from custom event
 * @param eventName name of event to unsubscribe from
 * @param listener callback function to be removed from event
 */
function unsubscribeFromEvent(eventName, listener) {
  window.removeEventListener(eventName, listener);
}

export { publishEvent, subscribeToEvent, unsubscribeFromEvent };
