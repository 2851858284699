import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const blueberryColor = colorPalette.interactions.blueBerry.hex;
const ironColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const GroupAddIcon = ({
  fillPath = blueberryColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M7 10H5V8C5 7.45 4.55 7 4 7C3.45 7 3 7.45 3 8V10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12H3V14C3 14.55 3.45 15 4 15C4.55 15 5 14.55 5 14V12H7C7.55 12 8 11.55 8 11C8 10.45 7.55 10 7 10ZM18 11C19.66 11 20.99 9.66 20.99 8C20.99 6.34 19.66 5 18 5C17.68 5 17.37 5.05 17.09 5.14C17.66 5.95 17.99 6.93 17.99 8C17.99 9.07 17.65 10.04 17.09 10.86C17.37 10.95 17.68 11 18 11ZM13 11C14.66 11 15.99 9.66 15.99 8C15.99 6.34 14.66 5 13 5C11.34 5 10 6.34 10 8C10 9.66 11.34 11 13 11ZM13 13C11 13 7 14 7 16V17C7 17.55 7.45 18 8 18H18C18.55 18 19 17.55 19 17V16C19 14 15 13 13 13ZM19.62 13.16C20.45 13.89 21 14.82 21 16V17.5C21 17.67 20.98 17.84 20.95 18H23.5C23.78 18 24 17.78 24 17.5V16C24 14.46 21.63 13.51 19.62 13.16Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const AddAllMembersIcon = ({
  fillPath = ironColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <circle cx="12" cy="12" r="12" fill="#DDDDDD" />
      <path
        d="M14.8 16.1V17H5V16.1C5 16.1 5 13.3 9.9 13.3C14.8 13.3 14.8 16.1 14.8 16.1ZM12.35 9.45004C12.35 8.96547 12.2063 8.49179 11.9371 8.08889C11.6679 7.68599 11.2853 7.37197 10.8376 7.18653C10.3899 7.0011 9.89728 6.95258 9.42203 7.04711C8.94678 7.14165 8.51023 7.37499 8.16759 7.71763C7.82495 8.06026 7.59161 8.49681 7.49708 8.97207C7.40254 9.44732 7.45106 9.93993 7.6365 10.3876C7.82193 10.8353 8.13595 11.2179 8.53885 11.4871C8.94175 11.7563 9.41544 11.9 9.9 11.9C10.5498 11.9 11.1729 11.6419 11.6324 11.1824C12.0919 10.723 12.35 10.0998 12.35 9.45004ZM14.758 13.3C15.1883 13.6331 15.5404 14.0563 15.7896 14.5401C16.0387 15.0238 16.1788 15.5563 16.2 16.1V17H19V16.1C19 16.1 19 13.559 14.758 13.3ZM14.1 7.00004C13.6182 6.99735 13.147 7.14141 12.749 7.41304C13.1742 8.00715 13.4028 8.71944 13.4028 9.45004C13.4028 10.1806 13.1742 10.8929 12.749 11.487C13.147 11.7587 13.6182 11.9027 14.1 11.9C14.7498 11.9 15.3729 11.6419 15.8324 11.1824C16.2919 10.723 16.55 10.0998 16.55 9.45004C16.55 8.80026 16.2919 8.17709 15.8324 7.71763C15.3729 7.25816 14.7498 7.00004 14.1 7.00004Z"
        fill={fillPath}
      />
    </svg>
  );
};
