import { colorPalette, weightPalette } from "gx-npm-common-styles";

const tooltipStyles = {
  tooltip: {
    borderRadius: "4px",
    color: colorPalette.neutrals.coal.hex,
    fontSize: "12px",
    fontWeight: weightPalette.medium.amount,
    fontVariationSettings: weightPalette.medium.variation,
    padding: "12px 16px",
    border: `2px solid ${colorPalette.neutrals.silver.hex}`,
    boxShadow: "0px 4px 12px 0px rgba(20, 19, 18, 0.16)",
    letterSpacing: "0.35px",
    lineHeight: "18px",
    margin: "0px",
  },
  newFont: {
    fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
  },
  tooltipArrow: {
    backgroundColor: colorPalette.basic.white.hex,
  },

  arrow: {
    color: colorPalette.basic.white.hex,
    "&::before": {
      borderRadius: "2px",
      borderTop: `2px solid ${colorPalette.neutrals.silver.hex}`,
      marginTop: "0px",
      borderLeft: `2px solid ${colorPalette.neutrals.silver.hex}`,
      borderBottom: `2px solid ${colorPalette.neutrals.silver.hex}`,
      borderRight: `2px solid ${colorPalette.neutrals.silver.hex}`,
    },
  },
};

export default tooltipStyles;
