import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const xmlnsUrl = "http://www.w3.org/2000/svg";

export const RemoveCircleIcon = ({
  fillPath = colorPalette.neutrals.iron.hex,
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      <path
        d="M12 4C8.136 4 5 7.136 5 11C5 14.864 8.136 18 12 18C15.864 18 19 14.864 19 11C19 7.136 15.864 4 12 4ZM14.8 11.7H9.2C8.815 11.7 8.5 11.385 8.5 11C8.5 10.615 8.815 10.3 9.2 10.3H14.8C15.185 10.3 15.5 10.615 15.5 11C15.5 11.385 15.185 11.7 14.8 11.7Z"
        fill={fillPath}
      />
    </svg>
  );
};
