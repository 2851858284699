import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const colorWhite = colorPalette.basic.white.hex;

// accent colors
const colorGrape = colorPalette.accent.grape.hex;
const colorHuckleberry = colorPalette.accent.huckleberry.hex;
const colorKiwi = colorPalette.accent.kiwi.hex;
const colorSourGrape = colorPalette.accent.sourGrape.hex;
const colorSourHuckleberry = colorPalette.accent.sourHuckleberry.hex;
const colorSourKiwi = colorPalette.accent.sourKiwi.hex;
const colorSweetGrape = colorPalette.accent.sweetGrape.hex;
const colorSweetHuckleberry = colorPalette.accent.sweetHuckleberry.hex;
const colorSweetKiwi = colorPalette.accent.sweetKiwi.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorPewter = colorPalette.neutrals.pewter.hex;
const colorSilver = colorPalette.neutrals.silver.hex;

const userDisplayStyles = {
  userWrapper: {
    alignItems: "center",
    color: colorCoal,
    display: "flex",
    fontSize: "14px",
    fontVariationSettings: weightPalette.medium.variation,
    fontWeight: weightPalette.medium.amount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    paddingTop: "20px",
    "& .gx-ava-owner": {
      marginLeft: "12px",
      display: "flex",
    },
    "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
      backgroundColor: colorWhite,
      border: `1px solid ${colorCoal}`,
      boxSizing: "content-box",
      color: colorCoal,
      fontSize: "18px",
      lineHeight: "25.2px",
    },
    "&.zero": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorGrape,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorGrape}`,
        color: colorGrape,
      },
    },
    "&.one": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorHuckleberry,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorHuckleberry}`,
        color: colorHuckleberry,
      },
    },
    "&.two": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorKiwi,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorKiwi}`,
        color: colorKiwi,
      },
    },
    "&.three": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSourGrape,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSourGrape}`,
        color: colorSourGrape,
      },
    },
    "&.four": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSourHuckleberry,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSourHuckleberry}`,
        color: colorSourHuckleberry,
      },
    },
    "&.five": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSourKiwi,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSourKiwi}`,
        color: colorSourKiwi,
      },
    },
    "&.six": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSweetGrape,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSweetGrape}`,
        color: colorSweetGrape,
      },
    },
    "&.seven": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSweetHuckleberry,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSweetHuckleberry}`,
        color: colorSweetHuckleberry,
      },
    },
    "&.eight": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorWhite,
          border: `1px solid ${colorWhite}`,
          backgroundColor: colorSweetKiwi,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorSweetKiwi}`,
        color: colorSweetKiwi,
      },
    },
    "&.grey": {
      "&.invert, &.hovered": {
        "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
          color: colorCoal,
          border: `1px solid ${colorSilver}`,
          backgroundColor: colorSilver,
        },
      },
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        border: `1px solid ${colorCoal}`,
        color: colorCoal,
      },
    },
    "& .gx-userInfo": {
      display: "flex",
      flexDirection: "column",
      "& .gx-displayName": {
        alignSelf: "auto",
        color: colorCarbon,
        display: "flex",
        fontSize: "14px",
        lineHeight: "21px",
        letterSpacing: "0.25px",
        marginLeft: "12px",
      },
      "& .gx-displayName-GCOM-3606": {
        marginLeft: "12px",
      },
      "& .gx-email": {
        alignSelf: "auto",
        color: colorIron,
        marginLeft: "12px",
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "0.35px",
      },
      "& .gx-email-GCOM-3606": {
        marginLeft: "12px",
      },
    },
  },
  unknownWrapper: {
    "&$userWrapper": {
      "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
        color: colorPewter,
        border: `1px dashed ${colorPewter}`,
        backgroundColor: colorWhite,
        "& svg, path": {
          fill: colorPewter,
        },
      },
      "& .gx-userInfo": {
        " & .gx-displayName, .gx-email": {
          color: colorIron,
        },
      },
      "& .gx-ava-owner": {
        color: colorIron,
      },
    },
  },
  xs: {
    "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
      width: 24,
      height: 24,
      fontSize: 12,
      lineHeight: "normal",
    },
  },
  newFonts: {
    "& .MuiAvatar-root, .UsernameDisplay-UI-MuiAvatar-root": {
      fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
    },
  },
};

export { userDisplayStyles };
