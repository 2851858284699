import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const CloudIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "10",
  title = "",
  viewBox = "0 0 16 10",
  width = "16",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M12.9 3.775C12.4467 1.61875 10.4267 0 8 0C6.07333 0 4.4 1.025 3.56667 2.525C1.56 2.725 0 4.31875 0 6.25C0 8.31875 1.79333 10 4 10H12.6667C14.5067 10 16 8.6 16 6.875C16 5.225 14.6333 3.8875 12.9 3.775Z"
        fill={fillPath}
      />
    </svg>
  );
};
