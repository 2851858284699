import { colorPalette } from "gx-npm-common-styles";

const buttonLoaderClasses = {
  root: {
    alignItems: "center",
    display: "flex",
    "& $loaderWrapper": {
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "4px",
      height: "100%",
      left: 0,
      opacity: 1,
      position: "absolute",
      top: 0,
      width: "100%",
      "&.btn-primary": {
        backgroundColor: colorPalette.interactions.defaultCta.hex,
      },
      "&.primary-destructive-btn": {
        backgroundColor: colorPalette.status.lightCherry.hex,
      },
      "&.btn-secondary": {
        border: `2px solid ${colorPalette.interactions.defaultCta.hex}`,
        "&:hover": {
          border: `2px solid ${colorPalette.interactions.hoverCta.hex}`,
        },
      },
      "&.secondary-destructive-btn": {
        border: `2px solid ${colorPalette.status.lightCherry.hex}`,
      },
    },
  },
  wrapper: {
    position: "relative",
  },
  visible: {
    visibility: "visible",
  },
  loading: {
    visibility: "hidden",
  },
  loaderWrapper: {},
};

export default buttonLoaderClasses;
