import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  AddCircledDarkIcon,
  DeleteTrashcanIcon,
  RemoveCircleIcon,
  RevertIcon,
  SelectedVendorIcon,
  SelectionRibbonIcon,
} from "gx-npm-icons";
import { InitProdState, InitStatus, InitUserRole } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  PopoverMenu,
  TooltipV2,
  useFeatureFlag,
} from "gx-npm-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useWorkspaceHeaderContext } from "../../../../context/workspace-header.context";
import { AvailableActions } from "../../../../app.constants";
import { MenuItemsType } from "../../../../app.types";
import styles from "./product-state-actions-popover-menu.styles";
import { GCOM_3755__vendorSelections } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const ProductStateActionsPopoverMenu = ({ onClickMenu }: { onClickMenu?: (type: string) => void }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const { initStatus, productState, userRole } = useWorkspaceHeaderContext();

  const isGCOM3755FFOn = useFeatureFlag(GCOM_3755__vendorSelections);
  let menuItems_GCOM_3755: MenuItemsType[] = [];

  let noCurrentSelectionText = "";
  let menuItems: Array<{ index: number; name: string; type?: AvailableActions }> = [];
  const isViewerOrContributor = userRole === InitUserRole.VIEWER || userRole === InitUserRole.CONTRIBUTOR;

  if (productState === InitProdState.AWARDED) {
    noCurrentSelectionText = isGCOM3755FFOn ? t("Selected") : t("Selected vendor");
    menuItems = [
      {
        index: 0,
        name: t("Revert vendor selection"),
        type: AvailableActions.REVERT_AWARD,
      },
    ];
    menuItems_GCOM_3755 = [
      {
        index: 0,
        name: t("Revert vendor selection"),
        type: AvailableActions.REVERT_AWARD,
        menuListIcon: <RevertIcon />,
        rootClassName: "revertVendorOption",
      },
      {
        index: 1,
        name: t("Remove from evaluation"),
        type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
        menuListIcon: <DeleteTrashcanIcon />,
        rootClassName: "removeVendorOption",
      },
    ];
  }

  if (productState === InitProdState.IN_EVALUATION) {
    noCurrentSelectionText = t("In evaluation");
    menuItems = [
      {
        index: 0,
        name: t("Screen out vendor"),
        type: AvailableActions.SCREEN_OUT_PRODUCT,
      },
    ];
    if (initStatus !== InitStatus.PLANNING) {
      menuItems.push({
        index: 1,
        name: t("Choose this vendor"),
        type: AvailableActions.CHOOSE_PRODUCT,
      });
    }
    menuItems_GCOM_3755 = [
      {
        index: 0,
        name: t("Screen out vendor"),
        type: AvailableActions.SCREEN_OUT_PRODUCT,
        menuListIcon: <RemoveCircleIcon />,
        rootClassName: "screenOutOption",
      },
      {
        index: 1,
        name: t("Select this vendor"),
        type: AvailableActions.CHOOSE_PRODUCT,
        menuListIcon: <SelectionRibbonIcon />,
        rootClassName: "selectVendorOption",
      },
      {
        index: 2,
        name: t("Remove from evaluation"),
        type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
        menuListIcon: <DeleteTrashcanIcon />,
        rootClassName: "removeVendorOption",
      },
    ];
  }

  if (productState === InitProdState.SCREENED_OUT) {
    noCurrentSelectionText = t("Screened out");
    menuItems = [
      {
        index: 0,
        name: t("Add back to eval"),
        type: AvailableActions.ADD_PRODUCT_TO_EVAL,
      },
    ];
    menuItems_GCOM_3755 = [
      {
        index: 0,
        name: t("Add back to evaluation"),
        type: AvailableActions.ADD_PRODUCT_TO_EVAL,
        menuListIcon: <AddCircledDarkIcon width="24" height="24" viewBox="-5 -5 30 30" />,
        rootClassName: "addBackToEvalOption",
      },
      {
        index: 1,
        name: t("Remove from evaluation"),
        type: AvailableActions.REMOVE_PRODUCT_FROM_EVAL,
        menuListIcon: <DeleteTrashcanIcon />,
        rootClassName: "removeVendorOption",
      },
    ];
  }

  const handleMenuClick = (menuIndex: number) => {
    const type = isGCOM3755FFOn
      ? menuItems_GCOM_3755.find(({ index }) => index === menuIndex)?.type
      : menuItems.find(({ index }) => index === menuIndex)?.type;
    if (type && onClickMenu) {
      onClickMenu(type);
    }
  };

  const handleOpen = (isMenuOpen: boolean) => {
    setIsOpen(isMenuOpen);
  };

  const getTooltipContent = () => {
    if (isViewerOrContributor) {
      return t("This action is only available to evaluation owners.");
    }
    if (!isOpen && productState === InitProdState.AWARDED) {
      return t("You may unselect this vendor and/or choose another vendor at any time.");
    }
    return "";
  };
  return (
    <div className={classNames(classes.root, classes.fontUpdates, isGCOM3755FFOn && classes.rootGCOM3755)}>
      {productState === InitProdState.AWARDED && (
        <span className={classes.selectedVendorIcon}>
          <SelectedVendorIcon title={"selected vendor icon"} />
        </span>
      )}
      <TooltipV2
        title={getTooltipContent()}
        rootClassName={"gx-workspace-header-awarded-tool-tip"}
        placement="bottom"
        PopperProps={{ modifiers: { offset: { offset: "0px, 0px" } } }}
      >
        <div>
          <FeatureFlagBooleanContainer flagName={GCOM_3755__vendorSelections}>
            <FeatureFlagBooleanOn>
              <TooltipV2
                title={t("Vendor evaluation status.")}
                PopperProps={{ modifiers: { offset: { offset: "0, 10" } } }}
                placement={"top"}
              >
                <span>
                  <PopoverMenu
                    alignmentType="rightBottom"
                    disabled={isViewerOrContributor}
                    iconType="kebab"
                    handleOpenStatus={handleOpen}
                    menuItems={menuItems_GCOM_3755}
                    noCurrentSelectionText={noCurrentSelectionText}
                    onClick={handleMenuClick}
                    listItemClassName={"listItem"}
                    rootClassName={classNames(
                      classes.popoverMenu,
                      productState === InitProdState.AWARDED && "awarded",
                      productState === InitProdState.IN_EVALUATION && "ineval",
                      productState === InitProdState.SCREENED_OUT && "screened-out",
                      isViewerOrContributor && "disabled",
                      "popoverMenu"
                    )}
                    variant="select"
                  />
                </span>
              </TooltipV2>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <PopoverMenu
                alignmentType="rightBottom"
                disabled={isViewerOrContributor}
                iconType="arrowDynamic"
                handleOpenStatus={handleOpen}
                menuItems={menuItems}
                noCurrentSelectionText={noCurrentSelectionText}
                onClick={handleMenuClick}
                rootClassName={classNames(
                  classes.popoverMenu,
                  productState === InitProdState.AWARDED && "awarded",
                  productState === InitProdState.IN_EVALUATION && "ineval",
                  productState === InitProdState.SCREENED_OUT && "screened-out",
                  "popoverMenu"
                )}
                variant="select"
              />
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </TooltipV2>
    </div>
  );
};

export default ProductStateActionsPopoverMenu;
