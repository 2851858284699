import { colorPalette } from "gx-npm-common-styles";

const errorOverlayStyles = {
  root: {
    zIndex: "1600 !important",
  },
  header: {
    width: "100%",
    padding: "32px 36px 56px 0",
    margin: "auto",
    display: "flex",
    justifyContent: "flex-end",
    maxWidth: "1332px",
    minWidth: "1024px",
  },
  logoWrapper: {
    cursor: "pointer",
  },
  container: {
    height: "100%",
    width: "100%",
    padding: "0 100px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "1262px",
    minWidth: "1024px",
    transition: "padding 0.5s",
    "@media (min-width: 1440px)": {
      padding: "0 120px",
    },
  },
  errorCode: {
    color: colorPalette.neutrals.coal.hex,
    paddingBottom: "24px",
  },
  title: {
    color: colorPalette.neutrals.coal.hex,
    paddingBottom: "16px",
    whiteSpace: "pre-wrap",
  },
  message: {
    color: colorPalette.neutrals.coal.hex,
    whiteSpace: "pre-wrap",
    paddingBottom: "32px",
  },
  ctaWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    paddingBottom: "45px",
  },
  separator: {
    width: "1px",
    height: "24px",
    background: colorPalette.neutrals.silver.hex,
  },
  imageWrapper: {
    paddingBottom: "60px",
    textAlign: "center",
    "& svg": {
      transition: "width 0.5s",
      maxWidth: "95%",
      "@media (min-width: 1240px)": {
        maxWidth: "90%",
      },
      minWidth: "807px",
    },
  },
};
export { errorOverlayStyles };
