// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rBzFIkH61feMnVE8X10F{align-items:center;border-left:1px solid var(--color-neutrals-silver);display:inline-flex;margin-left:24px;padding-left:24px}.rBzFIkH61feMnVE8X10F .gx-user{padding-top:0}.rBzFIkH61feMnVE8X10F .gx-user .UsernameDisplay-UI-MuiAvatar-circle{border:1px solid var(--color-brand-gartner-blue);height:30px;width:30px}.rBzFIkH61feMnVE8X10F .WNPr0tPPafqSREejiYvp{align-items:center;background-color:var(--color-neutrals-silver);border-left:1px solid var(--color-white);border-radius:50%;display:flex;height:32px;justify-content:center;width:32px;z-index:1}`, "",{"version":3,"sources":["webpack://./src/sections/branding-header-v2/active-viewer-avatars/active-viewer-avatars.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,kDAAA,CACA,mBAAA,CACA,gBAAA,CACA,iBAAA,CAEA,+BACE,aAAA,CAEA,oEACE,gDAAA,CACA,WAAA,CACA,UAAA,CAIJ,4CACE,kBAAA,CACA,6CAAA,CACA,wCAAA,CACA,iBAAA,CACA,YAAA,CACA,WAAA,CACA,sBAAA,CACA,UAAA,CACA,SAAA","sourcesContent":[".activeViewRoot {\n  align-items: center;\n  border-left: 1px solid var(--color-neutrals-silver);\n  display: inline-flex;\n  margin-left: 24px;\n  padding-left: 24px;\n\n  & :global(.gx-user) {\n    padding-top: 0;\n\n    & :global(.UsernameDisplay-UI-MuiAvatar-circle) {\n      border: 1px solid var(--color-brand-gartner-blue);\n      height: 30px;\n      width: 30px;\n    }\n  }\n\n  & .activeViewerAdditionalTooltipText {\n    align-items: center;\n    background-color: var(--color-neutrals-silver);\n    border-left: 1px solid var(--color-white);\n    border-radius: 50%;\n    display: flex;\n    height: 32px;\n    justify-content: center;\n    width: 32px;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activeViewRoot": `rBzFIkH61feMnVE8X10F`,
	"activeViewerAdditionalTooltipText": `WNPr0tPPafqSREejiYvp`
};
export default ___CSS_LOADER_EXPORT___;
