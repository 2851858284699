import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const colorWhite = colorPalette.basic.white.hex;
const colorCherry = colorPalette.status.cherry.hex;
const colorPoisonCherry = colorPalette.status.poisonCherry.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;

const textFieldDropdownStyles = {
  container: {
    alignItems: "flex-start",
    display: "flex",
    gap: "24px",
    width: "532px",
    "&.autoWidth": {
      width: "100%",
    },
    "& .gx-btn-loader svg": {
      color: colorWhite,
    },
  },
  topRoot: {
    alignItems: "center",
    border: `solid 1px ${colorSilver}`,
    "&.text-field-rounded": {
      borderRadius: "4px",
    },
    "&.error, &.highlighted": {
      padding: "6px 11px",
      "& $labelText": {
        transform: "translate(-14px, -25px) scale(0.75)",
      },
    },
    "&.error": {
      border: `solid 2px ${colorCherry}`,
    },
    display: "flex",
    padding: "7px 12px",
    position: "relative",
    width: "426px",
    "&.autoWidth": {
      width: "100%",
      paddingRight: "0px",
    },
    "&.highlighted": {
      border: `solid 2px ${defaultCta}`,
      "& $labelText": {
        color: defaultCta,
      },
      "&.error": {
        border: `solid 2px ${colorCherry}`,
      },
    },
    "&.filled": {
      "& $labelText": {
        transition: "none",
      },
    },
    "& .gx-popover-menu-root": {
      height: "32px",
      "& .gx-popover-menu-list": {
        left: "2px",
        top: "43px",
      },
      "& button": {
        height: "24px",
        width: "24px",
        marginRight: "10px",
      },
      "& .gx-popover-menu-selected": {
        "& .gx-popover-menu-current-selection": {
          color: colorPalette.neutrals.carbon.hex,
          fontVariationSettings: weightPalette.regular.variation,
          fontWeight: weightPalette.regular.amount,
        },
      },
    },
  },
  fontUpdate: {
    fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
    "& input": {
      fontFamily: `"Graphik", "Roboto", "Helvetica Neue" !important`,
    },
  },
  input: {
    flex: 1,
    "& input": {
      color: colorPalette.neutrals.carbon.hex,
      fontSize: "16px",
      fontVariationSettings: weightPalette.regular.variation,
      fontWeight: weightPalette.regular.amount,
      letterSpacing: "0.25px",
      lineHeight: "24px",
      paddingLeft: "12px",
    },
    "& ::placeholder": {
      color: "transparent",
    },
  },
  divider: {
    color: colorSilver,
    height: "32px",
    marginLeft: "4px",
    marginRight: "0px",
  },
  labelText: {
    color: colorIron,
    backgroundColor: colorWhite,
    fontSize: "16px",
    left: "8px",
    lineHeight: "12px",
    position: "absolute",
    paddingLeft: "2px",
    paddingRight: "2px",
    top: "18px",
    transition: "0.3s ease-in-out",
    "&.lifted": {
      transform: "translate(-13px, -24px) scale(0.75)",
      "&.error": {
        color: colorPoisonCherry,
      },
    },
    "&.error": {
      color: colorPoisonCherry,
    },
  },
  infoText: {
    paddingBottom: "12px",
    paddingRight: "4px",
    paddingTop: "4px",
    width: "425px",
    "&.p4": {
      color: colorIron,
    },
    "&.error": {
      color: colorPoisonCherry,
    },
  },
};

export { textFieldDropdownStyles };
