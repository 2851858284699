import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const BoxedBallIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "17",
  title = "",
  viewBox = "0 0 17 17",
  width = "17",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M0 4.58333V1.83333C0 0.825 0.825 0 1.83333 0H4.58333C5.0875 0 5.5 0.4125 5.5 0.916667C5.5 1.42083 5.0875 1.83333 4.58333 1.83333H2.75C2.24583 1.83333 1.83333 2.24583 1.83333 2.75V4.58333C1.83333 5.0875 1.42083 5.5 0.916667 5.5C0.4125 5.5 0 5.0875 0 4.58333Z"
        fill={fillPath}
      />
      <path
        d="M0 11.9167C0 11.4125 0.4125 11 0.916667 11C1.42083 11 1.83333 11.4125 1.83333 11.9167V13.75C1.83333 14.2542 2.24583 14.6667 2.75 14.6667H4.58333C5.0875 14.6667 5.5 15.0792 5.5 15.5833C5.5 16.0875 5.0875 16.5 4.58333 16.5H1.83333C0.825 16.5 0 15.675 0 14.6667V11.9167Z"
        fill={fillPath}
      />
      <path
        d="M13.75 14.6667C14.2542 14.6667 14.6667 14.2542 14.6667 13.75V11.9167C14.6667 11.4125 15.0792 11 15.5833 11C16.0875 11 16.5 11.4125 16.5 11.9167V14.6667C16.5 15.675 15.675 16.5 14.6667 16.5H11.9167C11.4125 16.5 11 16.0875 11 15.5833C11 15.0792 11.4125 14.6667 11.9167 14.6667H13.75Z"
        fill={fillPath}
      />
      <path
        d="M11.9167 0H14.6667C15.675 0 16.5 0.825 16.5 1.83333V4.58333C16.5 5.0875 16.0875 5.5 15.5833 5.5C15.0792 5.5 14.6667 5.0875 14.6667 4.58333V2.75C14.6667 2.24583 14.2542 1.83333 13.75 1.83333H11.9167C11.4125 1.83333 11 1.42083 11 0.916667C11 0.4125 11.4125 0 11.9167 0Z"
        fill={fillPath}
      />
      <path
        d="M8.25 11.25C9.90685 11.25 11.25 9.90685 11.25 8.25C11.25 6.59315 9.90685 5.25 8.25 5.25C6.59315 5.25 5.25 6.59315 5.25 8.25C5.25 9.90685 6.59315 11.25 8.25 11.25Z"
        fill={fillPath}
      />
    </svg>
  );
};
