import { useTranslation } from "react-i18next";
import { SaveIndicator } from "gx-npm-ui";

type SpinningSaveIndicatorProps = {
  isError?: boolean;
  isSaving?: boolean;
  rootClassName?: string;
};

const SpinningSaveIndicator = ({
  isError = false,
  isSaving = false,
  rootClassName = "",
}: SpinningSaveIndicatorProps) => {
  const { t } = useTranslation();
  return (
    <SaveIndicator
      isError={isError}
      isSaving={isSaving}
      labelSaved={t("Changes saved")}
      labelSaving={t("Saving...")}
      rootClassName={rootClassName}
    />
  );
};
export default SpinningSaveIndicator;
