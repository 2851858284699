import { colorPalette } from "gx-npm-common-styles";
import { Fragment, ReactElement, RefObject, useEffect } from "react";
import {
  STICKY_HEADER_CHANGE_EVENT_NAME,
  STICKY_HEADER_IGNORE_CLASS,
  STICKY_HEADER_WRAPPER_CLASS,
} from "./notify-when-sticky-detach.constants";

const handleEntry = (entry: IntersectionObserverEntry) => {
  if (!entry) {
    return;
  }
  const entryBoundingBottom = entry.boundingClientRect?.bottom;
  const rootBoundsInfo = entry.rootBounds;
  const stickyHeaderTarget = entry.target?.parentElement?.querySelector(`.${STICKY_HEADER_WRAPPER_CLASS}`);
  if (!stickyHeaderTarget || !rootBoundsInfo) {
    return;
  }
  if (entryBoundingBottom < rootBoundsInfo.top) {
    const isStuck = !stickyHeaderTarget.classList.contains(STICKY_HEADER_IGNORE_CLASS);
    const detail = { header: stickyHeaderTarget, isStuck };
    document.dispatchEvent(new CustomEvent(STICKY_HEADER_CHANGE_EVENT_NAME, { detail }));
  }
  if (entryBoundingBottom >= rootBoundsInfo.top && entryBoundingBottom < rootBoundsInfo.bottom) {
    const detail = { header: stickyHeaderTarget, isStuck: false };
    const evt = new CustomEvent(STICKY_HEADER_CHANGE_EVENT_NAME, { detail });
    document.dispatchEvent(evt);
  }
};

export const handleObserver = (entries: IntersectionObserverEntry[]) => {
  for (const entry of entries) {
    handleEntry(entry);
  }
};

const createAttachedSentinels = (container: HTMLDivElement, topOffsetInPx: number) => {
  return Array.from(container.querySelectorAll(`.${STICKY_HEADER_WRAPPER_CLASS}`)).map((el) => {
    if (!el?.parentElement) {
      return;
    }
    const sentinel = document.createElement("div");
    sentinel.style.height = "0";
    sentinel.style.position = "absolute";
    sentinel.style.left = "0";
    sentinel.style.right = "0";
    sentinel.style.top = topOffsetInPx !== 0 ? `-${topOffsetInPx}px` : "0";
    sentinel.style.visibility = "hidden";
    return el.parentElement.appendChild(sentinel);
  });
};

type NotifyWhenStickyDetachProps = {
  children: ReactElement | ReactElement[];
  dependencyData: object;
  reference: RefObject<HTMLDivElement>;
  /** non-negative number set to be the height on the page where the box shadow starts appearing */
  topOffsetInPx?: number;
};
/** Development Reference Link: https://developers.google.com/web/updates/2017/09/sticky-headers */
export const NotifyWhenStickyDetachV2Component = ({
  children,
  dependencyData,
  reference,
  topOffsetInPx = 0,
}: NotifyWhenStickyDetachProps) => {
  useEffect(() => {
    const handleStickyChange = (event: CustomEvent) => {
      const { header, isStuck } = event.detail;
      if (!header?.style) {
        return;
      }
      if (isStuck) {
        header.style.borderBottom = `1px solid ${colorPalette.neutrals.silver.hex}`;
        header.style.boxShadow = `0 8px 6px -4px  ${colorPalette.neutrals.silver.hex}`;
      } else {
        header.style.borderBottom = "none";
        header.style.boxShadow = "none";
      }
    };
    document.addEventListener(STICKY_HEADER_CHANGE_EVENT_NAME, handleStickyChange);
    return () => {
      document.removeEventListener(STICKY_HEADER_CHANGE_EVENT_NAME, handleStickyChange);
    };
  }, []);

  useEffect(() => {
    if (!reference?.current) {
      return;
    }
    try {
      const observer = new IntersectionObserver(handleObserver, { root: null, threshold: 0 });
      const sentinels = createAttachedSentinels(reference.current, topOffsetInPx);
      sentinels.forEach((el) => {
        if (el) {
          observer.observe(el);
        }
      });
    } catch (_ignored) {
      // do nothing
    }
  }, [dependencyData, reference, topOffsetInPx]);

  return <Fragment>{children}</Fragment>;
};
