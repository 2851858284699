import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ServerStackIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 14 16",
  width = "14",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M1 0C0.447715 0 0 0.447715 0 1V3C0 3.55228 0.447715 4 1 4H13C13.5523 4 14 3.55228 14 3V1C14 0.447715 13.5523 0 13 0H1ZM12 3C12.5523 3 13 2.55228 13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2C11 2.55228 11.4477 3 12 3Z"
        fill={fillPath}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1 6C0.447715 6 0 6.44772 0 7V9C0 9.55228 0.447715 10 1 10H13C13.5523 10 14 9.55228 14 9V7C14 6.44772 13.5523 6 13 6H1ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
        fill={fillPath}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 13C0 12.4477 0.447715 12 1 12H13C13.5523 12 14 12.4477 14 13V15C14 15.5523 13.5523 16 13 16H1C0.447715 16 0 15.5523 0 15V13ZM13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
