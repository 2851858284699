import { colorPalette } from "gx-npm-common-styles";

const restoreBannerStyles = {
  restoreBannerRoot: {
    alignItems: "center",
    backgroundColor: colorPalette.interactions.lightBerry.hex,
    display: "flex",
    height: "48px",
    justifyContent: "flex-end",
    width: "100%",
  },
  restoreBannerWrapper: {
    display: "flex",
    marginLeft: "auto",
    order: 2,
  },
  bannerDate: {
    alignItems: "center",
    display: "flex",
    margin: "0 24px 0 auto",
    order: 2,
  },
  bannerButtonContainer: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    margin: "0 24px 0 auto",
    order: 2,
  },
  pipeSvg: {
    marginRight: "24px",
  },
  textButton: {
    alignItems: "center",
    color: colorPalette.interactions.defaultCta.hex,
    display: "flex",
    "& svg": {
      marginRight: "8px",
    },
  },
  textDate: {
    color: colorPalette.neutrals.coal.hex,
  },
};

export { restoreBannerStyles };
