import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.brand.gartnerBlue.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const LightningBolt = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path d="M11 15H6L13 1V9H18L11 23V15Z" fill={fillPath} />
    </svg>
  );
};

export const LightningBoltExpanded = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "32",
  title = "",
  viewBox = "0 0 32 32",
  width = "32",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path d="M14.6664 20.0007H7.99978L17.3331 1.33398V12.0007H23.9998L14.6664 30.6673V20.0007Z" fill={fillPath} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.32651 6.99591C3.06616 7.25626 3.06616 7.67837 3.32651 7.93872L5.36572 9.97794C5.62607 10.2383 6.04818 10.2383 6.30853 9.97794C6.56888 9.71759 6.56888 9.29548 6.30853 9.03513L4.26931 6.99591C4.00897 6.73556 3.58685 6.73556 3.32651 6.99591Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30868 22.2902C6.56903 22.5505 6.56903 22.9726 6.30868 23.233L4.26947 25.2722C4.00912 25.5326 3.58701 25.5326 3.32666 25.2722C3.06631 25.0119 3.06631 24.5898 3.32666 24.3294L5.36587 22.2902C5.62622 22.0298 6.04833 22.0298 6.30868 22.2902Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26758 16.0473C1.26758 16.4155 1.56605 16.7139 1.93424 16.7139H4.81813C5.18632 16.7139 5.4848 16.4155 5.4848 16.0473C5.4848 15.6791 5.18632 15.3806 4.81813 15.3806H1.93424C1.56605 15.3806 1.26758 15.6791 1.26758 16.0473Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.1772 6.99591C29.4375 7.25626 29.4375 7.67837 29.1772 7.93872L27.138 9.97794C26.8776 10.2383 26.4555 10.2383 26.1952 9.97794C25.9348 9.71759 25.9348 9.29548 26.1952 9.03513L28.2344 6.99591C28.4947 6.73556 28.9169 6.73556 29.1772 6.99591Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1949 22.2902C25.9345 22.5505 25.9345 22.9726 26.1949 23.233L28.2341 25.2722C28.4945 25.5326 28.9166 25.5326 29.1769 25.2722C29.4373 25.0119 29.4373 24.5898 29.1769 24.3294L27.1377 22.2902C26.8773 22.0298 26.4552 22.0298 26.1949 22.2902Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.236 16.0473C31.236 16.4155 30.9375 16.7139 30.5693 16.7139H27.6854C27.3173 16.7139 27.0188 16.4155 27.0188 16.0473C27.0188 15.6791 27.3173 15.3806 27.6854 15.3806H30.5693C30.9375 15.3806 31.236 15.6791 31.236 16.0473Z"
        fill={fillPath}
      />
    </svg>
  );
};
