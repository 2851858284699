const dialogWithTextboxStyles = {
  body: {
    marginBottom: "24px",
    "& p": {
      marginBottom: "16px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  confirmBtnWrapper: {
    marginLeft: "24px",
  },
};

export { dialogWithTextboxStyles };
