import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const RequirementLvl1Icon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M20.0526 13H3.94737C3.42632 13 3 13.45 3 14V20C3 20.55 3.42632 21 3.94737 21H20.0526C20.5737 21 21 20.55 21 20V14C21 13.45 20.5737 13 20.0526 13ZM20.0526 3H3.94737C3.42632 3 3 3.45 3 4V10C3 10.55 3.42632 11 3.94737 11H20.0526C20.5737 11 21 10.55 21 10V4C21 3.45 20.5737 3 20.0526 3Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const RequirementLvl2Icon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M5 15H19C19.55 15 20 14.55 20 14C20 13.45 19.55 13 19 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15ZM5 19H19C19.55 19 20 18.55 20 18C20 17.45 19.55 17 19 17H5C4.45 17 4 17.45 4 18C4 18.55 4.45 19 5 19ZM5 11H19C19.55 11 20 10.55 20 10C20 9.45 19.55 9 19 9H5C4.45 9 4 9.45 4 10C4 10.55 4.45 11 5 11ZM4 6C4 6.55 4.45 7 5 7H19C19.55 7 20 6.55 20 6C20 5.45 19.55 5 19 5H5C4.45 5 4 5.45 4 6Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const RequirementLvl3Icon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M10 15H19C19.55 15 20 14.55 20 14C20 13.45 19.55 13 19 13H10C9.45 13 9 13.45 9 14C9 14.55 9.45 15 10 15ZM10 19H19C19.55 19 20 18.55 20 18C20 17.45 19.55 17 19 17H10C9.45 17 9 17.45 9 18C9 18.55 9.45 19 10 19ZM10 11H19C19.55 11 20 10.55 20 10C20 9.45 19.55 9 19 9H10C9.45 9 9 9.45 9 10C9 10.55 9.45 11 10 11ZM4 6C4 6.55 4.45 7 5 7H19C19.55 7 20 6.55 20 6C20 5.45 19.55 5 19 5H5C4.45 5 4 5.45 4 6Z"
        fill={fillPath}
      />
      <circle cx="7" cy="10" r="1" fill={fillPath} />
      <circle cx="7" cy="14" r="1" fill={fillPath} />
      <circle cx="7" cy="18" r="1" fill={fillPath} />
    </svg>
  );
};
