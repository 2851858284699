import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RestoreAlertBanner } from "gx-npm-ui";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import { useSnackbarContext } from "../../context/snack-bar-banner/snack-bar.context";
import { restoreInitiative } from "../../context/product/workspace-header-product-state-restore.lib";
import { InitState } from "gx-npm-lib";

export const isStateRestoreBannerShown = (initState: InitState) => {
  const showBannerStates = [InitState.ARCHIVED, InitState.DELETED];
  return showBannerStates.indexOf(initState) > -1;
};

const StateRestoreBanner = () => {
  const { t } = useTranslation();

  const { initId, initState, initStateUpdateDate, setInitState } = useWorkspaceHeaderContext();
  const { setShowSnackBar, setSnackbarIsError, setSnackBarMessage } = useSnackbarContext();

  const handleSuccess = (data: { state: InitState }) => {
    setInitState(data.state);
    setSnackBarMessage(t("Initiative has been restored.") as string);
    setShowSnackBar(true);
  };

  const handleError = () => {
    setSnackbarIsError(true);
    setShowSnackBar(true);
  };

  const handleClick = () => {
    restoreInitiative(initId, handleSuccess, handleError);
  };

  return (
    <Fragment>
      {isStateRestoreBannerShown(initState) && (
        <RestoreAlertBanner
          actionDate={initStateUpdateDate}
          initState={initState}
          isRestorable={initState !== InitState.ACTIVE}
          onClickAction={handleClick}
        />
      )}
    </Fragment>
  );
};

export default StateRestoreBanner;
