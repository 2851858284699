import React from "react";
import PropTypes from "prop-types";
import { createGenerateClassName, createMuiTheme, StylesProvider } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { Banner, bannerTypes } from "./banner";
import { Snackbar } from "./snackbar";

const defaultErrorMessage = "Oops... something went wrong. Please try again.";
const generateClassName = createGenerateClassName({
  seed: "SnackbarBanner-UI",
});
const theme = createMuiTheme();

const propTypes = {
  anchorVertical: PropTypes.string,
  anchorHorizontal: PropTypes.string,
  autoHideDuration: PropTypes.number,
  buttonText: PropTypes.string,
  handleButtonClick: PropTypes.func,
  isDefaultErrorMessage: PropTypes.bool,
  isOpen: PropTypes.bool,
  isTimeoutAllowed: PropTypes.bool,
  keyName: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  pointerActionCursor: PropTypes.bool,
  setIsOpen: PropTypes.func,
  transitionDuration: PropTypes.shape({
    enter: PropTypes.number,
    exit: PropTypes.number,
  }),
  type: PropTypes.string,
};
/**
 * create a snackbar with banner to provide a message to the user
 * @param {{
 *   anchorVertical?: string,
 *   anchorHorizontal?: string,
 *   autoHideDuration?: number,
 *   buttonText?: number,
 *   handleButtonClick?: Function,
 *   isDefaultErrorMessage?: boolean,
 *   isOpen?: boolean,
 *   isTimeoutAllowed?: boolean,
 *   keyName?: string,
 *   message?: Node | string | null,
 *   pointerActionCursor?: boolean,
 *   setIsOpen?: Function,
 *   type?: string,
 *   transitionDuration?: {{ enter: number, exit: number }},
 * }} props
 * @var anchorVertical - sets vertical position of snackbar. choose between top or bottom, defaults to top
 * @var anchorHorizontal - sets horizontal position of snackbar. choose between left, center, or right, defaults to center
 * @var autoHideDuration - sets milliseconds until bar auto closes. defaults to 5000
 * @var buttonText -button text to be displayed
 * @var handleButtonClick - callback function for when close button is clicked
 * @var isDefaultErrorMessage - default error message for banner provided by product
 * @var isOpen - REQUIRED getter hook for flag for showing snackbar
 * @var isTimeoutAllowed - flag for turning timeout dismissal of snackbar on/off. defaults to true
 * @var keyName - REQUIRED unique key for tracking ref when multiple can appear
 * @var message - text to display in banner
 * @var pointerActionCursor - show a pointer on the action button for cursor
 * @var setIsOpen - REQUIRED setter hook for flag for showing snackbar
 * @var transitionDuration - sets the enter and exit duration for snackbar
 * @var type - specific type of banner to show, defaults to error
 * @returns MUI wrapper snackbar with design team banner
 * @unsupported - adding a button will currently break component
 * @unsupported - varying width sizes - set to be 720px, which will be min width
 */
const SnackbarBanner = ({
  anchorVertical = "top",
  anchorHorizontal = "center",
  autoHideDuration = 5000,
  buttonText = "",
  handleButtonClick = null,
  isDefaultErrorMessage = false,
  isOpen = false,
  isTimeoutAllowed = true,
  keyName = null,
  message = "",
  pointerActionCursor = false,
  setIsOpen = null,
  transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  },
  type = bannerTypes.error,
}) => {
  if (type === bannerTypes.success || type === bannerTypes.miniSuccess) {
    anchorVertical = "bottom";
    anchorHorizontal = "left";
  }

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Snackbar
        anchorVertical={anchorVertical}
        anchorHorizontal={anchorHorizontal}
        autoHideDuration={autoHideDuration}
        isOpen={isOpen}
        isTimeoutAllowed={isTimeoutAllowed}
        uniquKey={keyName}
        setIsOpen={(e) => handleEvent(setIsOpen, e)}
        transitionDuration={transitionDuration}
        type={type}
      >
        <Banner
          buttonText={buttonText}
          handleButtonClick={handleButtonClick}
          handleCloseClick={() => handleEvent(setIsOpen, false)}
          message={isDefaultErrorMessage ? defaultErrorMessage : message}
          pointerActionCursor={pointerActionCursor}
          type={type}
        />
      </Snackbar>
    </StylesProvider>
  );
};

SnackbarBanner.propTypes = propTypes;
export default SnackbarBanner;
