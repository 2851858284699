import { WorkspaceHeaderConfig, WorkspaceHeaderPayload } from "../../app.types";
import { getAsyncRequest, isValidResponse } from "gx-npm-lib";

/**
 * load workspace header data from API
 * @param workspaceHeaderConfig
 * @param onSuccessResponse will always be invoked with default values if API response lacks
 * @param onErrorResponse invoked when API response is invalid
 */
const loadWorkspaceHeaderData = async (
  workspaceHeaderConfig: WorkspaceHeaderConfig,
  onSuccessResponse: (data: object) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, initProdId } = workspaceHeaderConfig;

  const url = `/api/v2/initiatives/${initId}/workspace/${initProdId}/workspace-header`;
  const response = await getAsyncRequest(url);
  if (isValidResponse(response) && typeof response.data.data === "object") {
    const { data } = response.data;
    onSuccessResponse({ ...(<WorkspaceHeaderPayload>data) });
  } else {
    onErrorResponse(response);
  }
};

export { loadWorkspaceHeaderData };
