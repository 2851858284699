import isValidDate from "../isUtils/isValidDate";

/**
 * determines if two dates are equal by attempting caste dates - two invalid dates are considered equal
 * @param {*} dateOne first date being compared, will be checked for truthiness and cast into a date
 * @param {*} dateTwo second date being compared, will be checked for truthiness and cast into a date
 * @returns true if both dates are invalid/dont exist, or if they are valid date objects and have equal
 * returns on the date, month, and year getter calls
 */
const datesAreEqual = (dateOne, dateTwo) => {
  let isEqual = false;
  if (dateOne && dateTwo) {
    // comparing two truthy values
    const isOneValid = isValidDate(dateOne);
    const isTwoValid = isValidDate(dateTwo);
    if (isOneValid && isTwoValid) {
      // compare valid dates
      const castDateOne = new Date(dateOne);
      const castDateTwo = new Date(dateTwo);
      const daysEqual = castDateOne.getDate() === castDateTwo.getDate();
      const monthsEqual = castDateOne.getMonth() === castDateTwo.getMonth();
      const yearsEqual = castDateOne.getYear() === castDateTwo.getYear();
      isEqual = daysEqual && monthsEqual && yearsEqual;
    } else if (!isOneValid && !isTwoValid) {
      // two invalid dates are equal - one invalid/one valid defaults to false
      isEqual = true;
    }
  } else if (!dateOne && !dateTwo) {
    // when both dates passed are falsy
    isEqual = true;
  } else {
    // one date truthy, cast the truthy date and check if it is invalid date
    isEqual = !(dateOne ? isValidDate(dateOne) : isValidDate(dateTwo));
  }
  return isEqual;
};

export default datesAreEqual;
