import { Children, Fragment, ReactElement, ReactNode, useContext } from "react";
import { FeatureFlagContext } from "./feature-flag.context";

type FeatureFlagBooleanContainerProps = {
  children?: ReactNode | ReactNode[];
  flagName?: string;
  strictMode?: boolean;
};
type FeatureFlagBooleanOffProps = {
  children?: ReactNode;
};
type FeatureFlagBooleanOnProps = {
  children?: ReactNode;
};

/**
 * feature flag content wrapper for displaying content based on a boolean feature flag
 * @param {{
 *   children: Element,
 *   flagName: string,
 *   strictMode: boolean,
 * }}
 * @var children - content to display within context
 * @var flagName - name of feature flag to be checked against, displays nothing when no flag passed
 * @var strictMode - only show content if the flag value is strictly true or false
 * @returns children props when feature flag provided is passed and indicates should be displayed
 */
const FeatureFlagBooleanContainer = ({
  children,
  flagName = "",
  strictMode = false,
}: FeatureFlagBooleanContainerProps) => {
  const { flags } = useContext(FeatureFlagContext);
  if (!(flagName && children)) {
    return null;
  }
  if (strictMode && Object.keys(flags).length <= 0) {
    return null;
  }
  return (
    <Fragment>
      {Children.map(children, (child: ReactElement) => {
        let display = null;
        if (child) {
          if (
            (!flags?.[flagName] && child.type === FeatureFlagBooleanOff) ||
            (flags?.[flagName] && child.type === FeatureFlagBooleanOn)
          ) {
            display = child;
          }
        }
        return display;
      })}
    </Fragment>
  );
};

/**
 * @param {*} children content to render when flag passed into context is OFF
 */
const FeatureFlagBooleanOff = ({ children }: FeatureFlagBooleanOffProps) => {
  return <Fragment>{children}</Fragment>;
};

/**
 * @param {*} children content to render when flag passed into context is ON
 */
const FeatureFlagBooleanOn = ({ children }: FeatureFlagBooleanOnProps) => {
  return <Fragment>{children}</Fragment>;
};

export { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn };
