import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const BoxDottedIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "14",
  title = "",
  viewBox = "0 0 14 14",
  width = "14",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M0 1.75C0 2.7165 0.783502 3.5 1.75 3.5C2.7165 3.5 3.5 2.7165 3.5 1.75C3.5 0.783502 2.7165 0 1.75 0C0.783502 0 0 0.783502 0 1.75ZM5.25 12.25C5.25 13.2165 6.0335 14 7 14C7.9665 14 8.75 13.2165 8.75 12.25C8.75 11.2835 7.9665 10.5 7 10.5C6.0335 10.5 5.25 11.2835 5.25 12.25ZM0 12.25C0 13.2165 0.783502 14 1.75 14C2.7165 14 3.5 13.2165 3.5 12.25C3.5 11.2835 2.7165 10.5 1.75 10.5C0.783502 10.5 0 11.2835 0 12.25ZM0 7C0 7.9665 0.783502 8.75 1.75 8.75C2.7165 8.75 3.5 7.9665 3.5 7C3.5 6.0335 2.7165 5.25 1.75 5.25C0.783502 5.25 0 6.0335 0 7ZM5.25 7C5.25 7.9665 6.0335 8.75 7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7ZM12.25 0C11.2835 0 10.5 0.783502 10.5 1.75C10.5 2.7165 11.2835 3.5 12.25 3.5C13.2165 3.5 14 2.7165 14 1.75C14 0.783502 13.2165 0 12.25 0ZM5.25 1.75C5.25 2.7165 6.0335 3.5 7 3.5C7.9665 3.5 8.75 2.7165 8.75 1.75C8.75 0.783502 7.9665 0 7 0C6.0335 0 5.25 0.783502 5.25 1.75ZM10.5 7C10.5 7.9665 11.2835 8.75 12.25 8.75C13.2165 8.75 14 7.9665 14 7C14 6.0335 13.2165 5.25 12.25 5.25C11.2835 5.25 10.5 6.0335 10.5 7ZM10.5 12.25C10.5 13.2165 11.2835 14 12.25 14C13.2165 14 14 13.2165 14 12.25C14 11.2835 13.2165 10.5 12.25 10.5C11.2835 10.5 10.5 11.2835 10.5 12.25Z"
        fill={fillPath}
      />
    </svg>
  );
};
