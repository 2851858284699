import { isPositiveNumber } from "gx-npm-lib";

/**
 * determine the summation of a string from
 * @param {*} value string to get char code summation from
 * @returns number value of string, returns -1 if no value (or empty string) is passed
 */
const genUniqueNumber = (value) => {
  const length = value?.length || 0;
  let output = length > 0 ? 0 : -1;
  for (let i = 0; i < length; i++) {
    output += value[i].charCodeAt(0);
  }
  return output;
};

const colorClasses = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
};
const getUserIdColorClass = (id = "", defaultMod = -1) => {
  const idMod = isPositiveNumber(id) ? id % 9 : -1;
  const mod = isPositiveNumber(defaultMod) ? defaultMod % 9 : idMod;
  return colorClasses[mod] || "";
};

export { genUniqueNumber, getUserIdColorClass };
