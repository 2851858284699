export enum ClientEvent {
  INITIATIVE_PRODUCT_WORKSPACE_DOCUMENTS_PAGE_VIEWED = "INITIATIVE_PRODUCT_WORKSPACE_DOCUMENTS_PAGE_VIEWED",
  INITIATIVE_PRODUCT_WORKSPACE_PROFILE_PAGE_VIEWED = "INITIATIVE_PRODUCT_WORKSPACE_PROFILE_PAGE_VIEWED",
  INITIATIVE_PRODUCT_WORKSPACE_RESPONSES_PAGE_VIEWED = "INITIATIVE_PRODUCT_WORKSPACE_RESPONSES_PAGE_VIEWED",
  INITIATIVE_PRODUCT_WORKSPACE_SCORESHEET_PAGE_VIEWED = "INITIATIVE_PRODUCT_WORKSPACE_SCORESHEET_PAGE_VIEWED",
  INITIATIVE_PRODUCT_WORKSPACE_SELECT_VENDOR_CLICKED = "INITIATIVE_PRODUCT_WORKSPACE_SELECT_VENDOR_CLICKED",
  INITIATIVE_PRODUCT_WORKSPACE_SCREENOUT_VENDOR_CLICKED = "INITIATIVE_PRODUCT_WORKSPACE_SCREENOUT_VENDOR_CLICKED",
  INITIATIVE_PRODUCT_WORKSPACE_REMOVE_VENDOR_CLICKED = "INITIATIVE_PRODUCT_WORKSPACE_REMOVE_VENDOR_CLICKED",
}

export enum LegacyTabPathname {
  PROFILE = "/s/product-profile",
  SCORECARD = "/s/product-scoresheet",
  RESPONSES = "/s/product-responses",
  DOCUMENTS = "/s/product-documents",
}

export enum TabRoute {
  PROFILE = "profile",
  SCORECARD = "scoresheet",
  RESPONSES = "responses",
  FILES = "files",
  DOCUMENTS = "documents",
}

export enum TabIndex {
  PROFILE = 0,
  SCORECARD = 1,
  RESPONSES = 2,
  FILES = 3,
}

export enum TabNames {
  NONE = "",
  PROFILE = "Profile",
  MY_SCORESHEET = "My Scoresheet",
  RESPONSES = "Responses",
  DOCUMENTS = "Documents",
}

export enum NewAppPaths {
  PRODUCT_PROFILE_TAB = "/profile",
  PRODUCT_SCORESHEET_TAB = "/scoresheet",
  PRODUCT_RESPONSES_TAB = "/responses",
  PRODUCT_DOCUMENTS_TAB = "/documents",
}

/**
 * @deprecated
 */
export enum AppPaths {
  PRODUCT_PROFILE_TAB = "/s/product-profile",
  PRODUCT_SCORESHEET_TAB = "/s/product-scoresheet",
  PRODUCT_RESPONSES_TAB = "/s/product-responses",
  PRODUCT_DOCUMENTS_TAB = "/s/product-documents",
}

export enum AvailableActions {
  ADD_PRODUCT_TO_EVAL = "ADD_PRODUCT_TO_EVAL",
  CHOOSE_PRODUCT = "CHOOSE_PRODUCT",
  REMOVE_PRODUCT_FROM_EVAL = "REMOVE_PRODUCT_FROM_EVAL",
  SCREEN_OUT_PRODUCT = "SCREEN_OUT_PRODUCT",
  REVERT_AWARD = "REVERT_AWARD",
}

export enum CustomEvents {
  SCORESHEET_SAVING_COUNT = "SCORESHEET_SAVING_COUNT",
  WORKSPACE_HEADER_ACTIONS = "WORKSPACE_HEADER_ACTIONS",
}

export enum WebSocketMessageEvents {
  INITIATIVE_ACCESS_REVOKED = "INITIATIVE_ACCESS_REVOKED",
  INITIATIVE_DELETED = "INITIATIVE_DELETED",
  NONE = "NONE",
  PRODUCT_REMOVED_FROM_EVAL = "PRODUCT_REMOVED_FROM_EVAL",
  UPDATE_INITIATIVE_STATE = "UPDATE_INITIATIVE_STATE",
  UPDATE_PRODUCT_STATE = "UPDATE_PRODUCT_STATE",
  VIEWING_INITIATIVE = "VIEWING_INITIATIVE",
}

export const ProductStateActions = Object.freeze({
  [AvailableActions.CHOOSE_PRODUCT]: "award",
  [AvailableActions.REMOVE_PRODUCT_FROM_EVAL]: "remove-from-eval",
  [AvailableActions.ADD_PRODUCT_TO_EVAL]: "add-to-eval",
  [AvailableActions.SCREEN_OUT_PRODUCT]: "screen-out",
  [AvailableActions.REVERT_AWARD]: "revert-award",
});
