import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Button } from "../button";
import { Loader } from "../loader";
import styles from "./button-loader.styles";

const propTypes = {
  ariaLabel: PropTypes.string,
  btnClass: PropTypes.oneOf([
    "btn-primary",
    "btn-secondary",
    "btn-tertiary",
    "primary-destructive-btn",
    "secondary-destructive-btn",
    "tertiary-destructive-btn",
    "secondary-dark-theme-btn",
  ]),
  btnRootClassName: PropTypes.string,
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  disabled: PropTypes.bool,
  handleButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  rootClassName: PropTypes.string,
};
const useStyles = makeStyles(() => styles);

/**
 * @param ariaLabel aria label for button
 * @param btnClass button type to be used
 * @param btnRootClassName class to inject in root of button
 * @param children child element being wrapped by button
 * @param disabled flag to disable button
 * @param handleButtonClick - DEPRECATED use onClick instead
 * @param isLoading flag to show loader
 * @param onClick callback when button is clicked
 * @param rootClassName class to inject of root of component wrapper
 */
const ButtonLoader = ({
  ariaLabel = "",
  btnClass = "btn-tertiary",
  btnRootClassName = "",
  children,
  disabled = false,
  handleButtonClick = (_event) => {},
  isLoading = false,
  onClick = (_event) => {},
  rootClassName = "",
}) => {
  const classes = useStyles();
  const handleClick = (e) => {
    handleButtonClick(e);
    onClick(e);
  };
  return (
    <div className={classNames("gx-btn-loader", rootClassName, classes.root)}>
      <div className={classes.wrapper}>
        <Button
          ariaLabel={ariaLabel || null}
          disabled={disabled}
          onClick={handleClick}
          rootClassName={classNames(btnClass, btnRootClassName, isLoading ? classes.loading : classes.visible)}
        >
          {children}
        </Button>
        {isLoading && (
          <Fragment>
            <div className={classNames(btnClass, classes.loaderWrapper)} />
            <Loader btnClass={btnClass} source="button" />
          </Fragment>
        )}
      </div>
    </div>
  );
};

ButtonLoader.propTypes = propTypes;
export { ButtonLoader };
