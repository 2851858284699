import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { handleEvent, isValidDate } from "gx-npm-lib";
import { datePickerClasses, muiTheme } from "./styles";

/**
 * TODO GCOM-1156 - Move DatePicker / DateRangePicker Into component - invalidDateText was causing issues of double
 * labels. Removed and replaced with emptyLabel for GCOM-911 / GCOM-1082 to display the disabled state.
 * Will need to confirm if invalidDateText is needed as part of GCOM-1156.
 */

const propTypes = {
  autoClose: PropTypes.bool,
  disabled: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  invalidDateText: PropTypes.string,
  isLabelAlwaysShow: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  setValue: PropTypes.func,
  showToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
};

/**
 * @param {*} props.disabled - flag to disable picker, defaults to false
 * @param {*} props.handleAutoSave - callback for fired to automatically save on value change
 * @param {*} props.invalidDateText - placeholder text to show when no value or invaid value is in picker
 * @param {*} props.isLabelAlwaysShow - flag to determine if label should always be shown, or only when a value is set
 * @param {*} props.onChange - NOT REQUIRED: additional parent callback for onchange events outside of default behavior
 * @param {*} props.onClose - NOT REQUIRED: additional parent callback for onclose events outside of default behavior
 * @param {*} props.onOpen - NOT REQUIRED: additional parent callback for onopen events outside of default behavior
 * @param {*} props.setValue - REQUIRED: setter hook used by parent to track value, required in order to keep value up to date
 * @param {*} props.value - REQUIRED: getter hook used by parent to track value, required in order to keep value up to date
 * @returns MUI wrapped date picker with tooltip
 * @deprecated Use xDatePicker instead
 */
const DatePicker = ({
  autoClose = true,
  disabled = false,
  handleAutoSave = null,
  invalidDateText = "",
  label = "",
  onChange = null,
  onClose = null,
  onOpen = null,
  setValue = null,
  value = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    handleEvent(setValue, e);
    handleEvent(onChange, e);
    handleEvent(handleAutoSave, e);
  };

  const handleClose = (e) => {
    setIsOpen(false);
    handleEvent(onClose, e);
  };

  const handleOpen = (e) => {
    setIsOpen(true);
    handleEvent(onOpen, e);
  };

  const materialTheme = createMuiTheme(muiTheme);
  const createStyles = makeStyles(() => datePickerClasses);
  const classes = createStyles();
  const containerClass = isOpen ? "calendarOpen" : "calendarClose";
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={materialTheme}>
        <div className={classNames(classes.dateContainer, containerClass, disabled && "disabled")}>
          <div className={`${classes.innerContainer}`}>
            {!!value && <span className={`${classes.dateLabel}`}>{label}</span>}

            <KeyboardDatePicker
              className={classNames(classes.datePicker, !!value && "has-date")}
              disabled={disabled}
              disableToolbar={true}
              emptyLabel={invalidDateText}
              format="MMMM dd, yyyy"
              id="date-picker-inline"
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              invalidDateMessage={invalidDateText}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={""}
              margin="normal"
              onChange={handleChange}
              onClose={handleClose}
              onOpen={handleOpen}
              autoOk={autoClose}
              value={
                isValidDate(value)
                  ? new Date(value).toLocaleDateString("en-us", {
                      timeZone: "UTC",
                    })
                  : value
              }
              variant="inline"
            />
          </div>
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = propTypes;
export default DatePicker;
