import { useEffect, useState } from "react";
import { getAsyncRequest } from "../apiRequests";
import { useWebSocket } from "../useWebSocket";
import { UUID } from "../types";
import {
  DEFAULT_USER_INIT_ACCESS,
  SESSION_STORAGE_KEY_PREFIX,
  SESSION_STORAGE_LOCK_KEY_PREFIX,
  STATE_SUBJECT,
} from "./use-user-init-access.constants";
import { UserInitAccess, WebSocketMessageEvents, WsInMsg } from "./use-user-init-access.types";

export function useUserInitAccess(initId: UUID | ""): UserInitAccess {
  const [userInitAccess, setUserInitAccess] = useState<UserInitAccess>(DEFAULT_USER_INIT_ACCESS);
  const [wsMessage, _publish] = useWebSocket<WsInMsg, never>();

  useEffect(() => {
    if (!wsMessage || wsMessage.initiativeId !== initId) {
      return;
    }
    if (
      [WebSocketMessageEvents.INITIATIVE_ACCESS_CHANGED, WebSocketMessageEvents.INITIATIVE_ACCESS_REVOKED].includes(
        wsMessage.event
      )
    ) {
      sessionStorage.removeItem(`${SESSION_STORAGE_KEY_PREFIX}.${initId}`);
      sessionStorage.removeItem(`${SESSION_STORAGE_LOCK_KEY_PREFIX}.${initId}`);
    }
  }, [initId, wsMessage]);

  useEffect(() => {
    if (!initId || userInitAccess.hasLoadedAccess) {
      return;
    }

    const sessionStorageObjStr = sessionStorage.getItem(`${SESSION_STORAGE_KEY_PREFIX}.${initId}`);
    if (!userInitAccess.hasLoadedAccess && sessionStorageObjStr) {
      setUserInitAccess(JSON.parse(sessionStorageObjStr));
      return;
    }

    if (sessionStorage.getItem(`${SESSION_STORAGE_LOCK_KEY_PREFIX}.${initId}`)) {
      const listener: EventListener = (event: CustomEvent<UserInitAccess>) => {
        window.removeEventListener(STATE_SUBJECT, listener);
        setUserInitAccess(event.detail);
      };
      window.addEventListener(STATE_SUBJECT, listener);
    } else {
      sessionStorage.setItem(`${SESSION_STORAGE_LOCK_KEY_PREFIX}.${initId}`, "true");
      window.addEventListener("beforeunload", () => {
        sessionStorage.removeItem(`${SESSION_STORAGE_KEY_PREFIX}.${initId}`);
        sessionStorage.removeItem(`${SESSION_STORAGE_LOCK_KEY_PREFIX}.${initId}`);
      });
      (async () => {
        const response = await getAsyncRequest(`/api/v3/initiatives/${initId}/access`);
        if (response && response.status === 200 && response.data.data.role) {
          const updatedAccess = { ...DEFAULT_USER_INIT_ACCESS, ...response.data.data, hasLoadedAccess: true };
          sessionStorage.setItem(`${SESSION_STORAGE_KEY_PREFIX}.${initId}`, JSON.stringify(updatedAccess));
          setUserInitAccess(updatedAccess);
          window.dispatchEvent(new CustomEvent(STATE_SUBJECT, { detail: updatedAccess }));
        }
        sessionStorage.removeItem(`${SESSION_STORAGE_LOCK_KEY_PREFIX}.${initId}`);
      })();
    }
  }, [initId, userInitAccess]);

  return userInitAccess;
}
