import isValidDate from "../isUtils/isValidDate";
/**
 * take a date and a format and convert to string formatted date, invalid date objects and upsupported
 * formats return empty string by default
 * @param {*} date - date object to be formatted
 * @param {*} format - format style to set date to: defaults to "MM-DD-YYYY" (only supported format)
 * @returns formatted string per API agreement
 */
const formatDate = (date, format = "YYYY-MM-DD") => {
  let formattedDate = "";
  if (isValidDate(date)) {
    const values = getDateValues(date);
    if (format === "MM-DD-YYYY") {
      formattedDate =
        pad(values?.month) + "-" + pad(values?.day) + "-" + values?.year;
    } else if (format === "YYYY-MM-DD") {
      formattedDate =
        values?.year + "-" + pad(values?.month) + "-" + pad(values?.day);
    }
  }
  return formattedDate;
};

const pad = (val) => {
  return val?.toString().padStart(2, "0");
};

const getDateValues = (date) => {
  let obj = {};
  if (isValidDate(date)) {
    const castedDate = new Date(date);
    const month = castedDate.getUTCMonth() + 1;
    const day = castedDate.getUTCDate();
    const year = castedDate.getUTCFullYear();
    obj = { day, month, year };
  }
  return obj;
};

export default formatDate;
