import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles, InputAdornment } from "@material-ui/core";
import { TextField } from "../textField";
import { handleEvent, isValidNumber } from "gx-npm-lib";
import { numberFieldStyles } from "./styles";

const defaultConfig = { decimal: 1, defaultToZero: true, max: 99999, min: 0 };
const getConfigKey = (config, key) => {
  let value = config?.[key];
  if (value === undefined) {
    value = defaultConfig[key];
  }
  return value;
};

const convertToFloat = (value, config = {}) => {
  let float = getConfigKey(config, "defaultToZero") ? 0 : NaN;
  const parsedValue = Number.parseFloat(value);
  if (isValidNumber(parsedValue)) {
    float = parsedValue;
    float = Number.parseFloat(float.toString());
    // set within range
    const max = getConfigKey(config, "max");
    const min = getConfigKey(config, "min");
    if (isValidNumber(min) && float < min) {
      float = min;
    } else if (isValidNumber(max) && float > max) {
      float = max;
    }
    // set precision
    const decimal = getConfigKey(config, "decimal");
    if (float !== Number.parseInt(float) && isValidNumber(decimal)) {
      float = Number.parseFloat(float.toFixed(decimal));
    }
  }
  return float;
};

const useStyles = makeStyles(() => numberFieldStyles);

const propTypes = {
  ariaLabel: PropTypes.string,
  decimal: PropTypes.number,
  defaultToZero: PropTypes.bool,
  handleAutoSave: PropTypes.func,
  isDisabled: PropTypes.bool,
  isPreffixHiddenOnFocus: PropTypes.bool,
  isSuffixHiddenOnFocus: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  prefix: PropTypes.string,
  rootClassName: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const NumberTextField = ({
  ariaLabel = "",
  decimal = 1,
  defaultToZero = true,
  handleAutoSave = null,
  isDisabled = false,
  isPreffixHiddenOnFocus = true,
  isSuffixHiddenOnFocus = true,
  max = 99999,
  min = 0,
  name = "",
  onBlur = null,
  onChange = null,
  onFocus = null,
  prefix = "",
  rootClassName = "",
  suffix = "",
  value = "",
}) => {
  let timer = null;
  const [display, setDisplay] = useState(() => {
    return convertToFloat(value, { decimal, defaultToZero, max, min });
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [initialValue, setInitialValue] = useState("");

  useEffect(() => {
    setDisplay(convertToFloat(value, { decimal, defaultToZero, max, min }));
  }, [decimal, defaultToZero, max, min, value]);

  const handleBlur = (event) => {
    setIsFocused(false);
    const val = convertToFloat(event?.target?.value, {
      decimal,
      defaultToZero,
      max,
      min,
    });
    if (val !== initialValue) {
      handleEvent(handleAutoSave, name, val);
    }
    setDisplay(val);
    setInitialValue("");
    handleEvent(onBlur, val);
  };

  const handleChange = (event) => {
    const eventVal = event?.target?.value;

    if (isNaN(event?.target?.value)) {
      setDisplay(display);
    } else if (!eventVal || !eventVal.endsWith(".")) {
      displayEvent(eventVal);
    } else {
      setDisplay(eventVal);
    }
  };

  const displayEvent = (eventVal) => {
    const val = convertToFloat(eventVal, {
      decimal,
      defaultToZero,
      max,
      min,
    });
    setDisplay(val);
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleEvent(onChange, val);
    });
  };

  const handleFocus = (event) => {
    setIsFocused(true);
    setInitialValue(display);
    handleEvent(onFocus, event);
  };

  const handleHover = (hovered) => {
    setIsHovered(hovered);
  };

  const classes = useStyles();
  return (
    <div
      className={classNames(
        rootClassName,
        classes.container,
        isDisabled && "disabled",
        isFocused && "focused",
        isHovered && "hovered"
      )}
    >
      <TextField
        disabled={isDisabled}
        InputProps={{
          "aria-label": ariaLabel || null,
          pattern: "([0-9]*[.])?[0-9]+",
          endAdornment: suffix && (!isSuffixHiddenOnFocus || !isFocused) && (
            <InputAdornment className="gx-end-adornment">{suffix}</InputAdornment>
          ),
          startAdornment: prefix && (!isPreffixHiddenOnFocus || !isFocused) && (
            <InputAdornment className="gx-start-adornment">{prefix}</InputAdornment>
          ),
        }}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
        rootClassName={classNames("gx-number-input")}
        size="small"
        type="text"
        value={display?.toString()}
      />
    </div>
  );
};

NumberTextField.propTypes = propTypes;
export { NumberTextField };
