import { colorPalette, weightPalette } from "gx-npm-common-styles";

const cherry = colorPalette.status.cherry.hex;
const iron = colorPalette.neutrals.iron.hex;
const lightBerry = colorPalette.interactions.lightBerry.hex;
const lightCherry = colorPalette.status.lightCherry.hex;
const poisonCherry = colorPalette.status.poisonCherry.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;

const miniButtonStyles = {
  fontGCOM3606: {
    fontFamily: "Graphik, Roboto, Helvetica Neue !important",
  },
  root: {
    "&.mini-btn": {
      backgroundColor: "transparent",
      borderRadius: "0px",
      color: defaultCta,
      fontSize: "14px",
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      height: "32px",
      letterSpacing: "0.25px",
      lineHeight: "21px",
      padding: "5.5px 10px 6px 8px !important",
      textTransform: "none",
      zIndex: "1",
      "&$svgEffect svg": {
        marginRight: "6px",
      },
      "& .MuiTouchRipple-rippleVisible": { opacity: 0.2 },
      "& .MuiTouchRipple-childPulsate": { all: "unset" },
      "& .MuiTouchRipple-child": {
        backgroundColor: defaultCta,
      },
      "&$svgEffect svg path": {
        fill: defaultCta,
      },
      "&:active,&:focus": {
        backgroundColor: lightBerry,
        "& .MuiButton-label": {
          color: selectedCta,
        },
        "&$svgEffect svg path": {
          fill: selectedCta,
        },
      },

      "&:hover": {
        backgroundColor: lightBerry,
        "& .MuiButton-label": {
          color: hoverCta,
        },
        "&$svgEffect svg path": {
          fill: hoverCta,
        },
      },

      "&:disabled": {
        backgroundColor: "transparent !important",
        color: `${iron} !important`,
        "&$svgEffect svg path": {
          fill: iron,
        },
      },
      "&.destructive-mini-btn": {
        color: poisonCherry,
        "&$svgEffect svg path": {
          fill: poisonCherry,
        },
        "& .MuiTouchRipple-child": {
          backgroundColor: cherry,
        },
        "&:hover, &:focus": {
          backgroundColor: lightCherry,
          "& .MuiButton-label": {
            color: poisonCherry,
          },
          "&$svgEffect svg path": {
            fill: poisonCherry,
          },
        },
      },
      "&.dark-theme-mini-btn": {
        color: colorPalette.basic.white.hex,
        "&$svgEffect svg path": {
          fill: colorPalette.basic.white.hex,
        },
        "&:hover, &:focus": {
          backgroundColor: `rgba(${colorPalette.basic.white.rgba}, 0.2)`,
          "& .MuiButton-label": {
            color: colorPalette.basic.white.hex,
          },
          "&$svgEffect svg path": {
            fill: colorPalette.basic.white.hex,
          },
        },
        "&:disabled": {
          color: colorPalette.neutrals.stone.hex,
        },
        "& .MuiTouchRipple-child": {
          backgroundColor: colorPalette.basic.white.hex,
        },
      },
      "&.dark-destructive-mini-btn": {
        color: colorPalette.status.mediumCherry.hex,
        "&$svgEffect svg path": {
          fill: colorPalette.status.mediumCherry.hex,
        },
        "&:hover, &:focus": {
          backgroundColor: "rgba(255, 222, 221, 0.3)",
        },
        "&:disabled": {
          color: colorPalette.neutrals.stone.hex,
        },
        "& .MuiTouchRipple-child": {
          backgroundColor: colorPalette.status.cherry.hex,
        },
      },
    },
    "&.mini-btn-rounded": {
      borderRadius: "4px",
    },
  },
  svgEffect: {},
  rootGCOM3606: {
    "&.mini-btn": {
      fontVariationSettings: weightPalette.medium.variation,
      fontWeight: weightPalette.medium.amount,
      letterSpacing: "0.05px",
      lineHeight: "22.4px",
    },
  },
};

export { miniButtonStyles };
