import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const SavedCloudIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM10.71 16.29C10.32 16.68 9.69 16.68 9.3 16.29L7.2 14.2C6.81 13.81 6.81 13.18 7.2 12.79C7.59 12.4 8.22 12.4 8.61 12.79L10 14.18L14.48 9.7C14.87 9.31 15.5 9.31 15.89 9.7C16.28 10.09 16.28 10.72 15.89 11.11L10.71 16.29Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const SavingSpinnerIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "22",
  title = "saving",
  viewBox = "0 0 16 22",
  width = "16",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M8 5V6.79C8 7.24 8.54 7.46 8.85 7.14L11.64 4.35C11.84 4.15 11.84 3.84 11.64 3.64L8.85 0.850002C8.54 0.540002 8 0.760002 8 1.21V3C3.58 3 0 6.58 0 11C0 12.04 0.2 13.04 0.57 13.95C0.84 14.62 1.7 14.8 2.21 14.29C2.48 14.02 2.59 13.61 2.44 13.25C2.15 12.56 2 11.79 2 11C2 7.69 4.69 5 8 5ZM13.79 7.71C13.52 7.98 13.41 8.4 13.56 8.75C13.84 9.45 14 10.21 14 11C14 14.31 11.31 17 8 17V15.21C8 14.76 7.46 14.54 7.15 14.86L4.36 17.65C4.16 17.85 4.16 18.16 4.36 18.36L7.15 21.15C7.46 21.46 8 21.24 8 20.8V19C12.42 19 16 15.42 16 11C16 9.96 15.8 8.96 15.43 8.05C15.16 7.38 14.3 7.2 13.79 7.71Z"
        fill={fillPath}
      />
    </svg>
  );
};
