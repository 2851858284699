import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.status.poisonCherry.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const RemoveUserIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M16 7C16 8.92788 14.6378 10.5361 12.823 10.9153L8.14148 5.94124C8.60534 4.24546 10.1566 3 12 3C14.21 3 16 4.79 16 7Z"
        fill={fillPath}
      />
      <path
        d="M10.2669 13.1417L4.26721 6.68046C3.89141 6.27574 3.91484 5.64301 4.31955 5.26721C4.72426 4.89141 5.35699 4.91484 5.7328 5.31955L18.7328 19.3196C19.1086 19.7243 19.0852 20.357 18.6805 20.7328C18.2757 21.1086 17.643 21.0852 17.2672 20.6805L15.7068 19H5C4.45 19 4 18.55 4 18V17C4 14.8396 7.5158 13.5499 10.2669 13.1417Z"
        fill={fillPath}
      />
      <path
        d="M19.9 18.4346C19.964 18.303 20 18.1555 20 18V17C20 15.2084 17.5822 14.0157 15.1876 13.4278L19.9 18.4346Z"
        fill={fillPath}
      />
    </svg>
  );
};
