import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.interactions.blueBerry.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const BaseTemplateIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "13",
  title = "",
  viewBox = "0 0 13 16",
  width = "16",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}

      <path
        d="M0 9.62V12C0 12.5523 0.447715 13 1 13H3.29333L12.48 3.59895C12.7467 3.32526 12.7467 2.90105 12.48 2.62737L10.12 0.205263C9.85333 -0.0684211 9.44 -0.0684211 9.17334 0.205263L0 9.62Z"
        fill={fillPath}
      />
      <path
        d="M6 13H14.6667C15.4 13 16 12.3842 16 11.6316C16 10.8789 15.4 10.2632 14.6667 10.2632H8.66667L6 13Z"
        fill={fillPath}
      />
    </svg>
  );
};
