import React from "react";
import Proptypes from "prop-types";

const propTypes = {
  width: Proptypes.string,
  height: Proptypes.string,
  viewBox: Proptypes.string,
};
const FlightSvg = ({
  width = "1099",
  height = "319",
  viewBox = "0 0 1099 319",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#n)">
        <g clipPath="url(#m)">
          <ellipse
            cx="600.54"
            cy="271.43"
            rx="491.52"
            ry="8.5695"
            fill="#eee"
          />
          <ellipse cx="348" cy="305.5" rx="36" ry="2.5" fill="#eee" />
          <path
            d="m460.28 118.35-165.8-18.165c-2.901-0.3183-5.71-1.2072-8.266-2.6157s-4.808-3.3087-6.627-5.5912c-15.467-19.422-57.876-71.851-69.919-78.228 0 0-4.354-3.053-9.367-3.7064-4.225-0.56329-74.245 3.9993-96.281 5.4526-0.736 0.0483-1.449 0.2746-2.078 0.6595-0.63 0.3849-1.156 0.9169-1.534 1.5501-0.379 0.6333-0.5979 1.3488-0.6387 2.0853-0.0408 0.7366 0.0978 1.4721 0.4037 2.1433l56.336 125.06 159.07-9.903 146.88-12.206-2.18-6.54z"
            fill="#A3B7CD"
          />
          <path d="m974.5 221.63h3.424v34.343h-3.424v-34.343z" fill="#BBB9B8" />
          <path
            d="m974.5 246.3-10.555 8.55c-0.233 0.189-0.424 0.423-0.562 0.689-0.137 0.265-0.218 0.557-0.237 0.855-0.019 0.299 0.024 0.598 0.127 0.879 0.102 0.281 0.262 0.538 0.469 0.754l1.447 1.521 9.311-8.032v-5.216z"
            fill="#BBB9B8"
          />
          <path
            d="m978.34 247.43h3.171v-1.487h2.095v1.487h3.943c0.241 0 0.481 0.048 0.704 0.141 0.223 0.092 0.426 0.228 0.596 0.399 0.171 0.171 0.306 0.374 0.398 0.598 0.092 0.223 0.139 0.463 0.138 0.704 0 0.488-0.193 0.955-0.537 1.301-0.344 0.345-0.812 0.54-1.299 0.541h-9.621v-3.785l0.412 0.101z"
            fill="#BBB9B8"
          />
          <path
            d="m987.3 222.55-9.429 9.277v4.855l15.164-14.132h-5.735z"
            fill="#BBB9B8"
          />
          <path
            d="m973.09 222.55-8.207 21.314c-0.088 0.228-0.223 0.434-0.396 0.607-0.174 0.172-0.381 0.306-0.609 0.394-0.228 0.087-0.472 0.126-0.716 0.114s-0.483-0.076-0.701-0.186c-0.383-0.195-0.68-0.525-0.835-0.926-0.155-0.402-0.157-0.846-4e-3 -1.248l7.576-20.069h3.892z"
            fill="#BBB9B8"
          />
          <path
            d="m978.34 272.24c-6.097 0-11.04-4.943-11.04-11.04s4.943-11.04 11.04-11.04 11.04 4.943 11.04 11.04-4.943 11.04-11.04 11.04z"
            fill="#4A4644"
          />
          <path
            d="m978.34 266.14c-2.728 0-4.94-2.212-4.94-4.94 0-2.729 2.212-4.94 4.94-4.94s4.94 2.211 4.94 4.94c0 2.728-2.212 4.94-4.94 4.94z"
            fill="#676565"
          />
          <path
            d="m978.34 264.29c-1.708 0-3.092-1.385-3.092-3.092 0-1.708 1.384-3.093 3.092-3.093s3.093 1.385 3.093 3.093c0 1.707-1.385 3.092-3.093 3.092z"
            fill="#4A4644"
          />
          <path
            d="m978.34 263.34c0.566 0 1.109-0.225 1.509-0.625 0.401-0.4 0.626-0.943 0.626-1.51 0-0.566-0.225-1.109-0.626-1.509-0.4-0.4-0.943-0.625-1.509-0.625-0.567 0-1.109 0.225-1.51 0.625-0.4 0.4-0.625 0.943-0.625 1.509 0 0.567 0.225 1.11 0.625 1.51 0.401 0.4 0.943 0.625 1.51 0.625zm0-3.706c0.416 0 0.816 0.165 1.111 0.46s0.46 0.695 0.46 1.111c0 0.417-0.165 0.817-0.46 1.112-0.295 0.294-0.695 0.46-1.111 0.46-0.417 0-0.817-0.166-1.111-0.46-0.295-0.295-0.461-0.695-0.461-1.112 0-0.416 0.166-0.816 0.461-1.111 0.294-0.295 0.694-0.46 1.111-0.46z"
            fill="#676565"
          />
          <path
            d="m336.19 106.57 2.253 5.678h116.2l365.18 8.561s112.69 1.949 130.03 1.949c17.338 0 61.638 32.58 61.638 32.58 27.28 6.793 64.1 30.586 78.35 40.274 3.05 2.079 3.06 6.788-0.19 8.556-40.41 22.069-113.61 19.309-113.61 19.309h-160.24c-15.245-1e-3 -30.463 1.292-45.49 3.864l-7.35 1.262c-23.586 4.043-47.472 6.074-71.401 6.072h-55.928c-43.139 1e-3 -86.023-6.602-127.16-19.579-15.688-4.951-31.743-8.656-48.014-11.08l-305.36-45.484c-0.836-0.215-1.577-0.702-2.107-1.384-0.529-0.682-0.816-1.521-0.817-2.384v-4.405c0-0.794 0.241-1.569 0.693-2.221 0.452-0.653 1.093-1.151 1.836-1.429l6.523-2.428 174.98-37.711z"
            fill="#ddd"
          />
          <path
            d="m336.19 107 2.253 5.678h116.2l365.18 8.562s112.69 1.948 130.03 1.948c17.338 0 61.638 32.58 61.638 32.58 27.28 6.793 64.1 30.586 78.35 40.274 3.05 2.079 3.06 6.788-0.19 8.557-40.41 22.069-113.61 19.309-113.61 19.309h-160.24c-15.245-2e-3 -30.463 1.291-45.49 3.864l-7.35 1.261c-23.586 4.043-47.472 6.074-71.401 6.072h-55.928c-43.139 1e-3 -86.023-6.602-127.16-19.579-15.688-4.951-31.743-8.655-48.014-11.08l-305.36-45.484c-0.836-0.215-1.577-0.702-2.107-1.384-0.529-0.682-0.816-1.521-0.817-2.384v-4.405c0-0.794 0.241-1.569 0.693-2.221 0.452-0.653 1.093-1.151 1.836-1.429l6.523-2.428 174.98-37.711z"
            fill="#ddd"
          />
          <path
            d="m819.82 120.81s112.69 1.949 130.03 1.949c17.337 0 61.637 32.58 61.637 32.58 27.28 6.793 64.1 30.586 78.35 40.274 0.58 0.396 1.08 0.907 1.45 1.501 0.38 0.595 0.63 1.261 0.74 1.956 0.11 0.696 0.08 1.407-0.1 2.089-0.17 0.682-0.48 1.322-0.92 1.878-67.84-11.417-244.87-43.079-388.05-84.976l116.87 2.749z"
            fill="#002856"
          />
          <path
            d="m611.44 236.54-14.498 4.264c-0.424 0.124-0.795 0.382-1.059 0.736-0.264 0.353-0.407 0.783-0.406 1.224v2.704c0 0.256 0.06 0.51 0.175 0.739 0.115 0.23 0.282 0.429 0.488 0.583 0.205 0.155 0.443 0.259 0.696 0.305s0.512 0.033 0.759-0.039l13.845-4.073v-6.443z"
            fill="#BBB9B8"
          />
          <path
            d="m632 234.1-3.667 14.538h-16.892v-14.809h20.559"
            fill="#002856"
          />
          <path
            d="m608.37 272.24c-8.079 0-14.628-6.549-14.628-14.628s6.549-14.629 14.628-14.629 14.628 6.55 14.628 14.629-6.549 14.628-14.628 14.628z"
            fill="#4A4644"
          />
          <path
            d="m608.37 264.16c-3.615 0-6.545-2.93-6.545-6.545s2.93-6.545 6.545-6.545 6.545 2.93 6.545 6.545-2.93 6.545-6.545 6.545z"
            fill="#676565"
          />
          <path
            d="m608.37 261.7c-2.261 0-4.095-1.834-4.095-4.095 0-2.262 1.834-4.095 4.095-4.095 2.262 0 4.095 1.833 4.095 4.095 0 2.261-1.833 4.095-4.095 4.095z"
            fill="#4A4644"
          />
          <path
            d="m608.37 260.34c0.725-2e-3 1.42-0.291 1.932-0.804s0.8-1.209 0.8-1.934-0.288-1.42-0.8-1.934c-0.512-0.513-1.207-0.802-1.932-0.803-0.725 1e-3 -1.421 0.29-1.934 0.803s-0.802 1.209-0.803 1.934c1e-3 0.726 0.29 1.421 0.803 1.934s1.209 0.802 1.934 0.804zm0-4.912c0.576 1e-3 1.127 0.231 1.534 0.639 0.406 0.407 0.635 0.959 0.635 1.535s-0.229 1.128-0.635 1.536c-0.407 0.407-0.958 0.637-1.534 0.638-0.576 0-1.13-0.229-1.537-0.636-0.408-0.408-0.637-0.961-0.637-1.538 3e-3 -0.575 0.233-1.125 0.641-1.53 0.407-0.405 0.958-0.633 1.533-0.633v-0.011z"
            fill="#676565"
          />
          <path
            d="m104.02 15.497c22.035-1.4532 92.056-5.9989 96.281-5.4526 5.007 0.6534 9.367 3.7064 9.367 3.7064 9.063 4.7991 35.311 35.661 54.339 58.924-30.265-3.8922-111.56-14.375-149.5-19.264l-14.335-31.476c-0.3063-0.6711-0.4448-1.4067-0.404-2.1432 0.0408-0.7366 0.26-1.4521 0.638-2.0853 0.379-0.6333 0.905-1.1653 1.534-1.5501 0.63-0.3849 1.343-0.6112 2.079-0.6596z"
            fill="#002856"
          />
          <path
            d="m150.59 83.078h5.525l6.047 12.823h0.052l1.798-12.823h4.066l-2.58 18.453h-5.317l-6.229-13.136h-0.052l-1.825 13.136h-4.066l2.581-18.453z"
            fill="#CC4308"
          />
          <path
            d="m179.55 86.675h-9.122l0.495-3.5968h13.501l-0.495 3.4404-9.461 15.013h-4.561l9.643-14.856z"
            fill="#CC4308"
          />
          <path
            d="m201.57 100.23c-1.303 0.625-2.623 1.077-3.961 1.355s-2.685 0.417-4.04 0.417c-1.442 0-2.745-0.226-3.91-0.678-1.146-0.451-2.102-1.094-2.866-1.9283-0.765-0.8514-1.312-1.8766-1.642-3.0755-0.331-1.1989-0.391-2.5368-0.183-4.0138 0.209-1.4769 0.643-2.8148 1.303-4.0137 0.678-1.1989 1.512-2.2154 2.502-3.0494 1.008-0.8514 2.155-1.503 3.441-1.9548 1.285-0.4517 2.649-0.6776 4.092-0.6776 1.477 0 2.806 0.1824 3.987 0.5473 1.199 0.3475 2.207 0.9557 3.024 1.8244l-3.31 3.1276c-0.47-0.5733-1.034-1.0077-1.695-1.3031-0.66-0.2954-1.511-0.4431-2.554-0.4431-0.868 0-1.676 0.1477-2.424 0.4431-0.747 0.2954-1.407 0.7124-1.98 1.251-0.574 0.5213-1.052 1.1468-1.434 1.8766-0.365 0.7298-0.608 1.5203-0.73 2.3717-0.121 0.8688-0.095 1.6681 0.079 2.3979 0.173 0.7124 0.469 1.3379 0.886 1.8765 0.434 0.5213 0.981 0.9296 1.642 1.225 0.66 0.2954 1.424 0.4431 2.293 0.4431 0.886 0 1.668-0.0956 2.346-0.2867 0.678-0.2085 1.216-0.417 1.616-0.6255l0.417-2.9973h-3.31l0.547-3.7531h7.22l-1.356 9.6437z"
            fill="#CC4308"
          />
          <path
            d="m207.7 83.078h12.38l-0.522 3.7531h-8.314l-0.521 3.7532h7.689l-0.548 3.7531h-7.688l-0.991 7.1936h-4.066l2.581-18.453z"
            fill="#CC4308"
          />
          <path
            d="m116.88 58.644 103.44 14.031-100.25-7.0183-3.199-7.0128z"
            fill="#002856"
          />
          <path
            d="m952.91 132.15h22.368c17.743 9.62 36.18 23.184 36.18 23.184 2.51 0.625 5.1 1.397 7.74 2.281l-66.288 0.169c-0.896 0-1.756-0.356-2.389-0.99-0.634-0.633-0.99-1.493-0.99-2.389v-18.926c0.013-0.888 0.375-1.735 1.007-2.357 0.633-0.623 1.485-0.972 2.372-0.972z"
            fill="#ddd"
          />
          <path
            d="m954.57 135.03h6.534c1.826-1e-3 3.58 0.71 4.889 1.983l16.037 15.563c0.225 0.219 0.38 0.5 0.445 0.807 0.064 0.307 0.036 0.627-0.082 0.918s-0.32 0.54-0.58 0.715c-0.261 0.176-0.568 0.269-0.882 0.269h-26.361c-0.327 0-0.65-0.064-0.952-0.189s-0.576-0.309-0.807-0.54c-0.23-0.231-0.413-0.506-0.538-0.808-0.124-0.302-0.188-0.626-0.187-0.952v-15.248c-5e-3 -0.329 0.056-0.656 0.179-0.961s0.305-0.583 0.536-0.818c0.231-0.234 0.507-0.42 0.811-0.547 0.303-0.127 0.629-0.192 0.958-0.192z"
            fill="#4A4644"
          />
          <path
            d="m972.55 137.28 17.056 16.634c0.904 0.88 2.117 1.371 3.379 1.368h19.061s-26.13-17.534-31.284-20.278h-7.283c-0.267-7e-3 -0.53 0.066-0.754 0.212s-0.399 0.357-0.5 0.604-0.124 0.52-0.066 0.781 0.194 0.498 0.391 0.679z"
            fill="#4A4644"
          />
          <path
            d="m881.44 144h21.139c1.895 0 3.43 1.723 3.43 3.848v51.304c0 2.125-1.535 3.848-3.43 3.848h-21.139c-1.895 0-3.431-1.723-3.431-3.848v-51.304c0-2.125 1.536-3.848 3.431-3.848z"
            fill="#141312"
            stroke="#BBB9B8"
            strokeMiterlimit="10"
            strokeWidth="1.6898"
          />
          <g filter="url(#d)" opacity=".4">
            <path
              d="m432.89 111.51s-28.749-6.723-64.093-5.318c-35.359 1.411-114.79 21.416-114.79 21.416v37.782s79.428 20.01 114.79 21.416c35.365 1.405 64.093-5.318 64.093-5.318l0.145-0.376 14.969-5.613v-57.895l-13.975-3.149-1.139-2.945z"
              clipRule="evenodd"
              fill="#BBB9B8"
              fillRule="evenodd"
            />
          </g>
          <path
            d="m560.25 204.81c6.132-6.383 13.777-11.115 22.226-13.755 4.509-1.408 9.187-2.203 13.908-2.366 27.887-0.935 129.19-3.971 155.91 0.254 31.081 4.906 34.591 35.221 34.591 35.221v0.862h-0.102c-5.516 0.597-11.014 1.363-16.492 2.298l-7.351 1.262c-0.676 0.118-1.352 0.225-2.034 0.338-6.139 0.912-12.127 1.797-16.374 2.399-5.633 0.71-11.288 1.309-16.966 1.797l-0.439 0.04c-11.829 0.999-23.691 1.498-35.588 1.498h-55.922c-12.576 0-25.12-0.563-37.632-1.69-15.366-1.464-26.553-3.346-26.553-3.346l-29.639-5.52h-0.068l18.521-19.292z"
            fill="#8FA8C5"
          />
          <path
            d="m372.24 96.868c38.517-1.3801 69.846 5.2219 69.846 5.2219l2.304 5.368v57.972l-2.304 5.368s-31.307 6.601-69.846 5.221-125.1-21.027-125.1-21.027v-37.097s86.564-19.641 125.1-21.027z"
            fill="#002856"
          />
          <path
            d="m449.89 105.77v61.002l-7.824 4.056v-68.736l7.824 3.678z"
            fill="#F4F4F4"
          />
          <path
            d="m747.53 210.77s1.538 6.704-4.883 8.658c-6.422 1.955-196.62 5.441-232.63 4.608-32.912-0.766-165.95-9.576-212.07-11.091-4.447-0.145-8.705-1.834-12.043-4.777-3.943-3.492-10.043-9.012-17.878-16.532-12.606-12.127-35.375-33.96-44.714-43.609-0.205-0.215-0.343-0.484-0.399-0.775s-0.027-0.592 0.083-0.867 0.298-0.512 0.54-0.684c0.242-0.171 0.528-0.269 0.824-0.282l12.814-0.535c0.334-0.012 0.662 0.085 0.935 0.276l90.384 56.942c5.28 3.645 11.525 5.638 17.941 5.723l104.93 3.081 125.68 0.281s9.215-6.703 23.46-6.984c11.547-0.226 89.139-1.003 118.13-1.29 7.246-0.07 14.481 0.587 21.596 1.96 1.61 0.309 3.119 1.008 4.396 2.037 1.277 1.028 2.282 2.354 2.927 3.86h-0.023z"
            fill="#A3B7CD"
          />
          <path
            d="m750.08 175.98c-8.602 0-15.575-5.934-15.575-13.254s6.973-13.254 15.575-13.254c8.601 0 15.574 5.934 15.574 13.254s-6.973 13.254-15.574 13.254z"
            fill="#fff"
          />
          <path
            d="m749.98 173.61c-7.117 0-12.887-4.907-12.887-10.961s5.77-10.961 12.887-10.961c7.118 0 12.888 4.907 12.888 10.961s-5.77 10.961-12.888 10.961z"
            fill="#4A4644"
          />
          <path
            d="m698.72 175.98c-8.601 0-15.574-5.934-15.574-13.254s6.973-13.254 15.574-13.254c8.602 0 15.575 5.934 15.575 13.254s-6.973 13.254-15.575 13.254z"
            fill="#fff"
          />
          <path
            d="m698.63 173.61c-7.117 0-12.887-4.907-12.887-10.961s5.77-10.961 12.887-10.961c7.118 0 12.888 4.907 12.888 10.961s-5.77 10.961-12.888 10.961z"
            fill="#4A4644"
          />
          <path
            d="m647.37 175.98c-8.602 0-15.575-5.934-15.575-13.254s6.973-13.254 15.575-13.254c8.601 0 15.574 5.934 15.574 13.254s-6.973 13.254-15.574 13.254z"
            fill="#fff"
          />
          <path
            d="m647.27 173.61c-7.118 0-12.888-4.907-12.888-10.961s5.77-10.961 12.888-10.961 12.888 4.907 12.888 10.961-5.77 10.961-12.888 10.961z"
            fill="#4A4644"
          />
          <path
            d="m596.02 175.98c-8.602 0-15.575-5.934-15.575-13.254s6.973-13.254 15.575-13.254c8.601 0 15.574 5.934 15.574 13.254s-6.973 13.254-15.574 13.254z"
            fill="#fff"
          />
          <path
            d="m595.92 173.61c-7.118 0-12.888-4.907-12.888-10.961s5.77-10.961 12.888-10.961c7.117 0 12.887 4.907 12.887 10.961s-5.77 10.961-12.887 10.961z"
            fill="#4A4644"
          />
          <path
            d="m544.66 175.98c-8.601 0-15.574-5.934-15.574-13.254s6.973-13.254 15.574-13.254c8.602 0 15.575 5.934 15.575 13.254s-6.973 13.254-15.575 13.254z"
            fill="#fff"
          />
          <path
            d="m544.56 173.61c-7.118 0-12.888-4.907-12.888-10.961s5.77-10.961 12.888-10.961c7.117 0 12.887 4.907 12.887 10.961s-5.77 10.961-12.887 10.961z"
            fill="#4A4644"
          />
          <g filter="url(#c)">
            <path
              d="M200 44.2571L198.541 41.3429L110 36L114.865 47.1714L197.081 53L200 44.2571Z"
              fill="#001125"
            />
          </g>
          <path
            d="m198.31 32.965 0.377 9.3503c0.028 0.7376-0.1 1.4729-0.376 2.1576-0.275 0.6847-0.693 1.3032-1.225 1.815-0.532 0.5117-1.166 0.9052-1.861 1.1544-0.695 0.2493-1.435 0.3487-2.171 0.2917-33.869-2.4897-141.38-16.65-165.32-18.25-0.2007-0.0142-0.3886-0.104-0.5256-0.2515-0.137-0.1474-0.2128-0.3414-0.2123-0.5426v-3.3797c1e-4 -0.5996 0.1218-1.193 0.3578-1.7442 0.236-0.5513 0.5815-1.0488 1.0154-1.4627s0.9473-0.7354 1.509-0.9451c0.5618-0.2098 1.1602-0.3036 1.7592-0.2754l158.28 4.174c2.118 0.0824 4.134 0.9288 5.676 2.3826s2.505 3.4167 2.712 5.5258z"
            fill="#A3B7CD"
          />
        </g>
        <g clipPath="url(#l)">
          <ellipse
            cx="43.927"
            cy="288.71"
            rx="32.927"
            ry="2.2866"
            fill="#eee"
          />
          <ellipse
            cx="109.32"
            cy="288.71"
            rx="29.726"
            ry="2.2866"
            fill="#eee"
          />
          <g clipPath="url(#k)">
            <path
              d="m119.66 263.22h3.568l1.801 6.704-4.941 1.694-1.473-1.988 1.502-0.372-0.029-0.521 3.01-0.171-0.911-3.111-1.425 0.059-1.102-2.294z"
              fill="#404243"
            />
            <path
              d="m121.86 258.16c-0.243 0.03-3.087 0.136-3.087 0.136v2.058h3.838l-0.751-2.194z"
              fill="#404243"
            />
            <path
              d="m114.84 235.19 0.645 0.97 9.29 34.476 2.04-0.302-9.716-35.659-2.259 0.515z"
              fill="#010105"
            />
            <path
              d="m130.23 267.42-2.123 0.449v-0.544h-2.78l0.083 2.6h2.697l-0.083-1.529 2.206-0.444 0.053-0.544"
              fill="#414545"
            />
            <path
              d="m116.25 252.84 0.272 0.964-0.521 0.148 3.614 13.11 1.531-0.426-3.572-13.087-0.337 0.083-0.266-0.934-0.721 0.142z"
              fill="#404243"
            />
            <path
              d="m83.904 263.92 1.8864-24.95c0.1039-1.332 0.7054-2.577 1.6849-3.486 0.9795-0.91 2.2653-1.417 3.6019-1.422h28.16l-2.413 2.525-1.342-0.432h-23.927c-0.8585 1e-3 -1.6859 0.322-2.3203 0.901-0.6343 0.578-1.03 1.373-1.1095 2.228l-2.2886 24.908-1.9337-0.272z"
              fill="#010105"
            />
            <path
              d="m122 230.01h3.034v3.217h-0.645l0.645 6.34h0.804l3.01 25.079h1.39v1.183h-2.466v-0.668h-0.727v-0.994l-3.247-24.488h0.591l-0.727-6.44h-1.396l-0.266-3.229z"
              fill="#414545"
            />
            <path
              d="m109.2 275.44 0.638-1.573v-10.538h-24.263v10.526l23.625 1.585z"
              fill="#BA7826"
            />
            <path
              d="m81.864 263.33v20.834h1.0171s-0.3193-6.28 5.9609-6.28c0 0 5.3222-0.757 5.9609 5.582v4.081h20.893s-0.142-12.839 8.427-15.506c0.929-0.272 1.894-0.403 2.862-0.39h5.322l-0.319-1.934h-5.322c-0.675 1e-3 -1.348 0.064-2.011 0.189-1.138 0.187-2.248 0.521-3.3 0.994l-0.976-1.283c-0.11-0.146-0.261-0.257-0.433-0.32-0.171-0.063-0.358-0.075-0.536-0.035l-0.255 0.053c-0.235 0.051-0.442 0.189-0.58 0.387-0.138 0.197-0.197 0.439-0.165 0.677l0.089 0.669c0.031 0.239-0.027 0.481-0.163 0.68-0.137 0.198-0.342 0.34-0.576 0.396l-7.522 1.774-22.105-2.425c-0.5228-0.058-1.0059-0.306-1.3571-0.698-0.3512-0.391-0.546-0.899-0.5471-1.425v-6.031l-4.3642 0.011z"
              fill="#EA9933"
            />
            <path
              d="m124.88 227.35 3.708-0.378 6.978 56.764h-2.685l-1.927-8.297-6.074-48.089z"
              fill="#010105"
            />
            <path
              d="m132.42 236.05-1.933 2.017 2.82 22.099s0.645 3.193 3.756 2.815c2.826-0.337 20.283-3.631 23.536-4.246 0.064-0.013 0.125-0.038 0.179-0.075s0.1-0.085 0.135-0.14c0.035-0.056 0.058-0.118 0.068-0.182 0.01-0.065 7e-3 -0.131-0.01-0.195-0.031-0.111-0.102-0.206-0.199-0.27-0.097-0.063-0.213-0.089-0.327-0.073l-22.336 3.034s-2.738 0.668-3.004-1.881l-2.685-22.903z"
              fill="#010105"
            />
            <path
              d="m75.903 279.93c0.1892 0 6.0969-0.728 6.0969-0.728v1.372l-6.2033 0.509 0.1064-1.153z"
              fill="#595B5C"
            />
            <path
              d="m120.82 268.9-2.856-1.703-0.083 0.775 2.282 1.443 0.657-0.515z"
              fill="#404243"
            />
            <path
              d="m119.12 262.94-1.75 6.951h-0.857l1.534-6.415 1.073-0.536z"
              fill="#404243"
            />
            <path
              d="m112.82 252.11 6.676-1.851 0.184 0.573-6.671 1.851-0.189-0.573z"
              fill="#404243"
            />
            <path
              d="m113.24 252.46 3.217 0.751 0.284-0.526-2.472-0.591-1.029 0.366z"
              fill="#404243"
            />
            <path
              d="m119.01 250.4-1.898 3.034-0.426-0.402 1.343-2.147 0.981-0.485z"
              fill="#404243"
            />
            <path
              d="m115.8 251.66c0.053 0 0.592 1.301 0.592 1.301l0.833-0.284-0.591-1.183-0.834 0.166z"
              fill="#404243"
            />
            <path
              d="m111.79 252.69 0.325-1.591s1.597-0.307 1.774 0c0.048 0.118 0.067 0.245 0.056 0.372-0.011 0.126-0.053 0.248-0.121 0.355l-0.958 0.45s0 0.438 0.243 0.378l0.443-0.124s0 0.515-0.467 0.592c-0.365 0.08-0.724 0.182-1.076 0.307l-0.219-0.739z"
              fill="#FCCCA6"
            />
            <path
              d="m97.854 245.23-3.0218 2.052c-0.4091 0.483-0.6927 1.06-0.8258 1.679-0.133 0.618-0.1115 1.261 0.0629 1.869 0.6209 1.993 3.2998 8.87 3.2998 8.87l6.7654-2.418 0.224-1.65h0.923l-0.343-3.282s1.354 2.537 3.247 2.56c1.892 0.024 3.642-0.242 4.068-0.969 0.099-0.267 0.121-0.556 0.065-0.834-0.012-0.048-0.034-0.092-0.065-0.13-0.133-0.155-0.236-0.333-0.302-0.527 0 0-1.916 0.255-2.678-0.224-0.763-0.479-2.478-3.661-2.88-3.921s-1.213-1.916-2.502-2.235c-1.17-0.244-2.356-0.412-3.548-0.503l-2.4895-0.337z"
              fill="#007BAC"
            />
            <path
              d="m96.743 243s0.4671 3.141 1.3128 3.442c0.8456 0.302 0.5914 0.367 1.6085 0.384 1.017 0.018 0.988-2.365 0.988-2.365s0.768-0.645 0.36-1.088c-0.227-0.238-0.404-0.52-0.52-0.828l-3.7493 0.455z"
              fill="#FEC99B"
            />
            <path
              d="m96.24 243.22s-0.544-3.181 1.715-3.365c2.2589-0.183 2.8089 1.183 3.1759 2.579 0 0-2.5905 1.389-4.8909 0.786z"
              fill="#007BAC"
            />
            <path
              d="m93.408 250.4 1.7209 13.01h12.051l1.236-3.087h-5.209c-1.62-0.813-3.4236-1.192-5.2339-1.1-0.1994-0.144-0.3651-0.329-0.4856-0.544-0.1205-0.214-0.1929-0.452-0.2123-0.697-0.1123-0.882-1.9337-6.979-1.9337-6.979s-0.3489-1.123-1.9338-0.603z"
              fill="#404243"
            />
            <path
              d="m106.97 256.19 4.37 0.177s3.679 3.548 3.495 6.966c-0.047 0.858-0.071 1.289-0.082 1.461 0 0.514 0.082-1.354 0.082-1.354l-0.733 1.549-1.431-0.402c-1.029-0.254-1.971-0.781-2.725-1.526-0.753-0.746-1.291-1.682-1.556-2.708l-5.914 1.029-3.6073-1.508-1.0289-0.887 1.7149-1.39c0.4025-0.245 0.8193-0.466 1.2473-0.662 0.107 0 6.168-0.745 6.168-0.745z"
              fill="#007BAC"
            />
            <path
              d="m104.22 255.48s2.956-0.077 3.66 1.136c0.038 0.151 0.026 0.31-0.032 0.454-0.058 0.145-0.161 0.267-0.293 0.35h-0.556s-0.148-0.745-0.384-0.769c-0.237-0.024-0.397 0.278-0.397 0.278l0.066 0.378-0.414 0.042s-0.373-0.509-1.816-0.645l0.166-1.224z"
              fill="#FCCCA6"
            />
            <path
              d="m112.67 264.59c0.336 0.298 0.587 0.68 0.727 1.106 0.101 0.544 0.544 2.543 1.124 2.572 0.579 0.03 3.388-1.224 3.891-2.046s0.361-1.573 0-1.632c-0.74 0.123-1.465 0.321-2.164 0.592l-1.414-1.709s-0.562 1.62-2.164 1.117z"
              fill="#010105"
            />
            <path
              d="m75.3 281.69c0.6075 0 1.1-0.492 1.1-1.1 0-0.607-0.4925-1.1-1.1-1.1-0.6074 0-1.0999 0.493-1.0999 1.1 0 0.608 0.4925 1.1 1.0999 1.1z"
              fill="#595B5C"
            />
            <path
              d="m123.53 288.84c4.806 0 8.702-3.897 8.702-8.703s-3.896-8.702-8.702-8.702-8.702 3.896-8.702 8.702 3.896 8.703 8.702 8.703z"
              fill="#010004"
            />
            <path
              d="m123.53 284.89c2.628 0 4.759-2.131 4.759-4.759s-2.131-4.759-4.759-4.759-4.759 2.131-4.759 4.759 2.131 4.759 4.759 4.759z"
              fill="#fff"
            />
            <path
              d="m123.53 284.17c2.23 0 4.038-1.809 4.038-4.039s-1.808-4.038-4.038-4.038-4.038 1.808-4.038 4.038 1.808 4.039 4.038 4.039z"
              fill="#010004"
            />
            <path
              d="m74.614 280.33h-0.8871v-2.229h-58.828v4.512h58.858v-1.715h1.2064l-0.2129-0.538-0.136-0.03z"
              fill="#595B5C"
            />
            <path
              d="m21.368 288.88c2.5932 0 4.6954-2.102 4.6954-4.695 0-2.594-2.1022-4.696-4.6954-4.696s-4.6953 2.102-4.6953 4.696c0 2.593 2.1021 4.695 4.6953 4.695z"
              fill="#686868"
            />
            <path
              d="m21.368 287.98c2.0968 0 3.7965-1.7 3.7965-3.796 0-2.097-1.6997-3.797-3.7965-3.797s-3.7965 1.7-3.7965 3.797c0 2.096 1.6997 3.796 3.7965 3.796z"
              fill="#AA333B"
            />
            <path
              d="m21.368 285.6c0.7838 0 1.4192-0.635 1.4192-1.419s-0.6354-1.42-1.4192-1.42-1.4193 0.636-1.4193 1.42 0.6355 1.419 1.4193 1.419z"
              fill="#5C0F0E"
            />
            <path
              d="m67.589 288.88c2.5932 0 4.6954-2.102 4.6954-4.695 0-2.594-2.1022-4.696-4.6954-4.696s-4.6953 2.102-4.6953 4.696c0 2.593 2.1021 4.695 4.6953 4.695z"
              fill="#686868"
            />
            <path
              d="m67.589 287.98c2.0968 0 3.7965-1.7 3.7965-3.796 0-2.097-1.6997-3.797-3.7965-3.797s-3.7965 1.7-3.7965 3.797c0 2.096 1.6997 3.796 3.7965 3.796z"
              fill="#AA333B"
            />
            <path
              d="m67.589 285.6c0.7838 0 1.4193-0.635 1.4193-1.419s-0.6355-1.42-1.4193-1.42-1.4193 0.636-1.4193 1.42 0.6355 1.419 1.4193 1.419z"
              fill="#5C0F0E"
            />
            <path
              d="m12.829 257.48s19.29-5.843 29.763-5.358c10.473 0.485 19.775 0.722 25.576 3.14v16.233c-4.9827 1.274-10.124 1.816-15.263 1.609-4.9487 2.591-10.634 3.415-16.114 2.335-9.0183-1.851-10.189-3.944-10.189-3.944v-6.931l-2.0107 0.728s-9.2666-1.248-11.762-7.812z"
              fill="#A3B7CD"
            />
            <path
              d="m64.668 272.26c2.1584-0.39 3.5008-0.769 3.5008-0.769v-16.209c-1.1371-0.46-2.3074-0.834-3.5008-1.118v18.096z"
              fill="#ddd"
            />
            <path
              d="m30.268 270.69h-4.0272v7.409h4.0272v-7.409z"
              fill="#595B5C"
            />
            <path
              d="m46.418 270.69h-4.0272v7.409h4.0272v-7.409z"
              fill="#595B5C"
            />
            <path
              d="m20.121 278.7 4.8018-17.386c0.0703-0.246 0.2183-0.462 0.422-0.617 0.2036-0.154 0.4519-0.239 0.7075-0.241h35.742c0.2564 3e-3 0.5052 0.088 0.709 0.244 0.2037 0.155 0.3513 0.373 0.4205 0.62l4.6599 17.38h-3.2406l-4.1395-14.134c-0.0321-0.109-0.0992-0.206-0.1909-0.274-0.0918-0.068-0.2034-0.105-0.3177-0.104h-31.786c-0.1156 1e-3 -0.2278 0.04-0.3196 0.11s-0.1581 0.169-0.1889 0.28l-4.0449 14.903-3.2347-0.781z"
              fill="#EA9933"
            />
            <path
              d="m62.568 270.69h-4.0272v7.409h4.0272v-7.409z"
              fill="#595B5C"
            />
            <path
              d="m88.47 288.41c3.1157 0 5.6415-2.526 5.6415-5.642s-2.5258-5.641-5.6415-5.641c-3.1158 0-5.6416 2.525-5.6416 5.641s2.5258 5.642 5.6416 5.642z"
              fill="#010004"
            />
            <path
              d="m88.47 285.86c1.7049 0 3.0869-1.382 3.0869-3.087s-1.382-3.087-3.0869-3.087c-1.7048 0-3.0869 1.382-3.0869 3.087s1.3821 3.087 3.0869 3.087z"
              fill="#fff"
            />
            <path
              d="m88.47 285.39c1.4468 0 2.6197-1.173 2.6197-2.62 0-1.446-1.1729-2.619-2.6197-2.619s-2.6197 1.173-2.6197 2.619c0 1.447 1.1729 2.62 2.6197 2.62z"
              fill="#010004"
            />
          </g>
        </g>
        <g clipPath="url(#j)">
          <ellipse cx="407.5" cy="310.5" rx="10.5" ry="1.5" fill="#eee" />
          <path
            d="m339.66 229.61-1.015-0.456-21.633 48.136 1.015 0.456 21.633-48.136z"
            fill="#676565"
          />
          <path
            d="m320.22 298.5-1.107 0.107-2.115-21.332 1.107-0.106 2.115 21.331z"
            fill="#676565"
          />
          <path
            d="m327.31 280.76-0.454 1.017-9.379-4.146 0.456-1.014 9.377 4.143z"
            fill="#676565"
          />
          <path
            d="m337.65 257.22-0.456 1.014-9.465-4.125 0.456-1.014 9.465 4.125z"
            fill="#676565"
          />
          <path d="m373.1 250.54h-34.462v3.182h34.462v-3.182z" fill="#676565" />
          <path
            d="m344.28 254.36-0.855 1.967h-5.138l0.722-1.648-0.73-0.319-20.087 45.96c-0.108 0.244-0.154 0.512-0.131 0.778 0.022 0.267 0.111 0.523 0.258 0.746 0.145 0.224 0.344 0.408 0.579 0.535s0.497 0.193 0.764 0.192h4.563l20.785-47.892-0.73-0.319zm-1.765 4.064-2.479 5.712h-5.163l2.498-5.712h5.144zm-18.179 29.828 2.575-5.893h5.218l-2.559 5.893h-5.234zm4.324 2.096-2.673 6.161h-5.26l2.691-6.161h5.242zm-0.833-10.085 2.717-6.219h5.205l-2.699 6.219h-5.223zm3.636-8.316 2.495-5.712h5.17l-2.482 5.712h-5.183zm-7.761 29.828h-4.04c-0.134 1e-3 -0.267-0.032-0.385-0.096-0.119-0.064-0.219-0.157-0.292-0.271-0.074-0.112-0.119-0.241-0.13-0.375s0.012-0.269 0.067-0.392l0.889-2.035h5.266l-1.375 3.169z"
            fill="#9D9CA9"
          />
          <path
            d="m371.13 254.15h-1.354v44.205h1.354v-44.205z"
            fill="#676565"
          />
          <path d="m374.14 253.72h-36.615v1.59h36.615v-1.59z" fill="#676565" />
          <path d="m339.76 229.13h-1.112v22.68h1.112v-22.68z" fill="#676565" />
          <path
            d="m371.13 301.52v-1.354h-46.687v1.354h46.687z"
            fill="#676565"
          />
          <path d="m373.1 230.24v-1.112h-34.462v1.112h34.462z" fill="#676565" />
          <path d="m356.43 229.13h-1.112v22.68h1.112v-22.68z" fill="#676565" />
          <path d="m373.1 229.13h-1.112v22.68h1.112v-22.68z" fill="#676565" />
          <path
            d="m341.92 254.15h-1.353v46.687h1.353v-46.687z"
            fill="#676565"
          />
          <path
            d="m370.94 256.37-0.875-0.578-29.214 44.172 0.874 0.578 29.215-44.172z"
            fill="#676565"
          />
          <path
            d="m370.32 305.83c2.757 0 4.992-2.235 4.992-4.993 0-2.757-2.235-4.992-4.992-4.992s-4.993 2.235-4.993 4.992c0 2.758 2.236 4.993 4.993 4.993z"
            fill="#4A4644"
          />
          <path
            d="m370.32 304.88c2.229 0 4.037-1.807 4.037-4.037s-1.808-4.037-4.037-4.037c-2.23 0-4.037 1.807-4.037 4.037s1.807 4.037 4.037 4.037z"
            fill="#AA333B"
          />
          <path
            d="m370.32 302.35c0.833 0 1.508-0.675 1.508-1.508 0-0.832-0.675-1.507-1.508-1.507-0.832 0-1.507 0.675-1.507 1.507 0 0.833 0.675 1.508 1.507 1.508z"
            fill="#5C0F0E"
          />
          <path
            d="m326.96 305.83c2.758 0 4.993-2.235 4.993-4.993 0-2.757-2.235-4.992-4.993-4.992-2.757 0-4.992 2.235-4.992 4.992 0 2.758 2.235 4.993 4.992 4.993z"
            fill="#4A4644"
          />
          <path
            d="m326.96 304.88c2.229 0 4.037-1.807 4.037-4.037s-1.808-4.037-4.037-4.037c-2.23 0-4.037 1.807-4.037 4.037s1.807 4.037 4.037 4.037z"
            fill="#AA333B"
          />
          <path
            d="m326.96 302.35c0.832 0 1.507-0.675 1.507-1.508 0-0.832-0.675-1.507-1.507-1.507-0.833 0-1.508 0.675-1.508 1.507 0 0.833 0.675 1.508 1.508 1.508z"
            fill="#5C0F0E"
          />
          <path
            d="m361.97 212.62s1.842 1.203 2.352 2.676c0.062 0.171 0.078 0.356 0.048 0.536s-0.106 0.349-0.221 0.491c-0.111 0.127-0.257 0.219-0.419 0.265 0 0-0.773-0.023-0.749 0.651v0.65h-1.927s-0.361-1.76-1.133-2.049l-0.916-1.229 2.193-2.001 0.772 0.01z"
            fill="#FEC99B"
          />
          <path
            d="m363.35 212.07c-0.876 1.272-3.626 3.217-4.345 3.716-0.017 0.012-0.036 0.019-0.057 0.02-0.02 1e-3 -0.041-3e-3 -0.059-0.013-0.018-9e-3 -0.033-0.024-0.044-0.042-0.01-0.017-0.015-0.037-0.015-0.058v-0.531s-1.362 0.494-1.12-1.29c0.241-1.783 2.736-2.712 2.736-2.712s1.096-0.396 1.675 0.085c0.114 0.098 0.197 0.227 0.239 0.371 0.021 0.068 0.067 0.126 0.128 0.161 0.062 0.036 0.135 0.046 0.204 0.03 0 0 0.618-0.284 0.777-0.079 0.107 0.122-0.119 0.342-0.119 0.342z"
            fill="#007BAC"
          />
          <path
            d="m361.82 217.18s-2.625 0.168-2.577 2.554 0.769 10.919 0.769 10.919l6.968-0.143s-0.701-6.58-1.832-9.088c0 0 2.339 0.146 2.819-0.266 0.48-0.411 2.219-2.107 2.434-4.374l0.122-0.735-1.181-0.435-1.919 2.681c-0.161 0.233-0.389 0.412-0.654 0.513s-0.554 0.12-0.83 0.055c-0.048-0.011-0.095-0.026-0.14-0.043-0.773-0.265-1.906-1.3-1.906-1.3l-2.073-0.338z"
            fill="#007BAC"
          />
          <path
            d="m369.89 212.38c0.057 0.399 0.087 0.8 0.09 1.203-2e-3 0.075-0.022 0.15-0.061 0.215-0.038 0.065-0.092 0.12-0.157 0.159s-0.139 0.06-0.214 0.063c-0.076 2e-3 -0.151-0.015-0.218-0.05-0.064-0.04-0.115-0.097-0.146-0.166-0.031-0.068-0.042-0.144-0.03-0.219v-0.435l-0.398-0.119s-0.265 1.191 0.122 1.651c0.223 0.28 0.402 0.592 0.531 0.926l1.11 0.398c0.279-0.187 0.503-0.446 0.649-0.748 0.146-0.303 0.209-0.64 0.181-0.975-0.034-0.67-0.13-1.335-0.289-1.988 0 0-0.977-0.687-1.17 0.085z"
            fill="#FEC99B"
          />
          <path
            d="m360.88 228.69-0.868 1.964c0.064 0.896 0.209 1.785 0.435 2.655 0.361 1.327 0.433 17.22 0.433 17.22h3.498l-0.244-14.654 1.881 14.654h3.753l-2.818-20.291-3.451-2.824-2.619 1.276z"
            fill="#007BAC"
          />
        </g>
        <g clipPath="url(#i)">
          <path
            d="m369.5 213.5c1 12.5 0.7 37.4-8.5 37-11.5-0.5-16 11.5-16 34.5s4.5 23 12 23 30.5-1.5 36.5 1c2.5 0.5 3.6-0.5 2-2.5s0.667-2.833 2-3"
            stroke="#4A4644"
          />
          <rect x="397" y="302" width="2" height="3" fill="#C4C4C4" />
          <rect x="399" y="301" width="1" height="5" fill="#C4C4C4" />
          <rect x="400" y="297" width="14" height="13" rx="1" fill="#C4C4C4" />
          <circle cx="402.5" cy="299.5" r=".5" fill="#993206" />
          <rect x="404" y="299" width="1" height="3" fill="#8D8A89" />
          <rect x="404" y="305" width="1" height="3" fill="#8D8A89" />
          <rect x="406" y="299" width="1" height="3" fill="#8D8A89" />
          <rect x="408" y="299" width="1" height="3" fill="#8D8A89" />
          <rect x="410" y="299" width="1" height="3" fill="#8D8A89" />
          <rect x="404" y="303" width="7" height="1" fill="#8D8A89" />
          <rect x="406" y="305" width="1" height="3" fill="#8D8A89" />
          <rect x="408" y="305" width="1" height="3" fill="#8D8A89" />
          <rect x="410" y="305" width="1" height="3" fill="#8D8A89" />
        </g>
        <g clipPath="url(#h)">
          <path
            d="m986.22 243.08s0.777 0.962 0.853 1.192c0.077 0.231 0.369-0.069 0.369-0.069s0.754 0.725 1.356 0.604c0.603-0.121 1.622-2.392 1.622-2.392l-0.498-0.917-1.199-1.177-1.846 0.481-0.657 2.278z"
            fill="#FEC99B"
          />
          <path
            d="m988.95 240.09s1.018 0.787 0.905 1.581c0 0-0.03 0.337-0.256 0.321-0.225-0.015-0.551-0.802-1.206-0.504-0.656 0.297 0 1.229 0 1.229s0 0.268-0.166 0.275c-0.112-3e-3 -0.221-0.034-0.317-0.091 0 0-0.22-0.535-1.093-0.49-0.873 0.046-0.438-0.74-0.438-0.74l2.51-1.581"
            fill="#302A25"
          />
          <path
            d="m1000.9 270.29v0.97s-0.88 0.263-1.265 0.214c-0.381-0.049-0.7-0.274-0.816-0.299-0.222-0.049-0.45-0.064-0.676-0.044-0.166 0.037-1.179 0.106-1.346-0.481-0.168-0.587 0.131-0.682 0.131-0.682l1.139-0.284 2.833 0.606z"
            fill="#4A4644"
          />
          <path
            d="m989.24 240.18-3.34 3.376s-0.579 0.488-0.843 0.466-0.166-0.214-0.091-0.313c0.074-0.099 0.64-1.063 0.664-1.135 0.023-0.073-0.445-1.035-0.407-1.592s1.66-1.978 2.389-1.978c0.728 0 1.462 0.789 1.628 1.176z"
            fill="#007BAC"
          />
          <path
            d="m992.34 270.13s0.121 1.405-0.075 1.817c0 0-1.379 0.222-2.382-0.214-0.379-0.1-0.771-0.141-1.162-0.121-0.498 0.052-2.05 0.121-2.012-0.757 0 0 0.06-0.466 0.784-0.466 0.723 0 1.636-0.373 1.826-0.755 0.189-0.382 3.021 0.496 3.021 0.496z"
            fill="#4A4644"
          />
          <path
            d="m980.62 259.68-0.022 0.126-1.482 1.682s-0.588 0.429-0.988-0.126c-0.342-0.473 0.056-0.871 0.254-0.997l1.826-1.178 0.412 0.493z"
            fill="#B4B2B1"
          />
          <path
            d="m978.68 261.44c-0.263 0-0.475-0.215-0.475-0.481 0-0.265 0.212-0.481 0.475-0.481 0.262 0 0.474 0.216 0.474 0.481 0 0.266-0.212 0.481-0.474 0.481z"
            fill="#272625"
          />
          <path
            d="m981.53 258.88-0.086 0.217-0.842 0.712-0.463-0.579 0.938-0.643 0.453 0.293z"
            fill="#272625"
          />
          <path
            d="m985.4 255.62-3.946 3.448-0.374-0.481 4.421-3.792-0.101 0.825z"
            fill="#B4B2B1"
          />
          <path
            d="m986.32 253.57-0.95 0.505-1.205 1.527c0.033 0.049 0.077 0.089 0.128 0.119 0.051 0.029 0.107 0.046 0.166 0.049 0.166-0.015 0.7-0.572 0.7-0.572l-0.074 1.023s0.127 0.214 0.317 0.153c0.189-0.06 0.232-0.718 0.232-0.718 0.112 0.075 0.238 0.125 0.37 0.146 0.165-5e-3 0.329-0.026 0.49-0.062 0.038-0.039 0.73-0.58 0.724-1.206-7e-3 -0.626-0.898-0.964-0.898-0.964z"
            fill="#FEC99B"
          />
          <path
            d="m991.43 242.21-0.483-0.535s-1.582 0.641-1.642 1.161-0.409 1.605-0.543 1.711-0.709 0.642-0.709 1.054c0 0.413-0.468 1.94-0.422 2.154 0.047 0.213-0.021 1.695 0 1.803 0.022 0.356 0.022 0.714 0 1.07-0.024 0.397-0.166 1.329-0.521 1.527-0.355 0.199-1.085 1.206-1.085 1.206s-0.226 1.514 1.055 1.437c0 0 1.312-0.412 1.297-0.673s0.573-0.824 0.692-0.917c0.12-0.092 0.438-0.336 0.438-0.336s0.648-0.518 0.648-1.178l0.24-0.106 0.122 0.214 0.015 2.749 0.287 0.247s-0.483 1.724-0.453 2.396c0.029 0.671-0.377 4.205-0.392 4.262s0.438 5.76 0.257 6.056-1.042 1.788-1.034 1.941c-0.012 0.186-0.039 0.37-0.081 0.551 0 0 1.175 0.824 1.763 0.962 0.432 0.09 0.87 0.146 1.311 0.168l0.86-2.994 0.647-4.233 1.358-5.183 0.754 3.131 1.642 4.934s0.483 1.208 0.393 1.406c-0.09 0.199-0.121 1.62-0.121 1.62l0.131 0.581s0.97 0.366 1.451 0.243c0.481-0.122 1.708-0.259 1.708-0.259s-0.15-3.988-0.31-4.446c-0.15-0.457-2.485-9.945-2.407-10.45 0.078-0.504-0.06-3.329-0.196-3.869s-1.19-3.637-1.448-4.629c0 0 1.765-0.978 1.718-2.093-0.046-1.115-3.044-1.818-3.165-1.97-0.122-0.151-0.452-0.459-0.754-0.489-0.302-0.031-1.914-0.259-2.158-0.259s-0.863 0.035-0.863 0.035z"
            fill="#007BAC"
          />
        </g>
        <ellipse
          cx="892"
          cy="270.5"
          rx="19"
          ry="2.5"
          fill="#BBB9B8"
          opacity=".5"
        />
        <g clipPath="url(#g)">
          <path d="m877 201.82v68.185h30v-69h-30v0.815z" fill="#BBB9B8" />
          <path d="m879 201.4v68.597h26v-69h-26v0.403z" fill="#8D8A89" />
          <path d="m904.47 208.72h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 210.52h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m907 200.68h-30v1.233h30v-1.233z" fill="#8D8A89" />
          <path d="m904.47 201.91h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 260.38h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 262.19h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 251.78h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 253.57h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 243.16h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 244.96h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 234.56h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 236.35h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 225.94h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 227.74h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="m904.47 217.33h-24.61v1.801h24.61v-1.801z" fill="#BBB9B8" />
          <path d="m904.47 219.13h-24.61v6.814h24.61v-6.814z" fill="#ddd" />
          <path d="M880.156 179H879V200.675H880.156V179Z" fill="#8D8A89" />
          <path d="M905.156 179H904V200.675H905.156V179Z" fill="#8D8A89" />
        </g>
        <g clipPath="url(#f)">
          <path
            d="m888.25 160.12-0.424-0.597s-0.656-5.006-0.671-5.4v-0.27s0.526-1.153 0.504-1.387c-0.022-0.233-0.051-1.006-0.051-1.006l-0.504-0.818s-0.19-0.051-0.233 0.117c-0.044 0.168 0.036 0.577 0.16 0.693l-8e-3 0.351s0 0.204-0.102 0.152c-0.102-0.051-0.495-0.86-0.495-0.86l-0.351-1.263s-0.204-0.759-0.423-0.715c-0.219 0.045-0.058 0.832-0.058 0.832l0.299 1.167 7e-3 0.066s-0.066 0.124-0.132 0.015-0.474-1.321-0.474-1.321-0.168-0.635-0.503-0.605c0 0-0.219 0.014 0 0.766 0.135 0.459 0.298 0.91 0.489 1.349 0 0 7e-3 0.102-0.066 0.124s-0.138-0.182-0.138-0.182l-0.563-1.286s-0.095-0.27-0.314-0.226c-0.218 0.045 0.168 0.891 0.168 0.891l0.445 1.108s0.051 0.124-7e-3 0.154-0.205-0.205-0.205-0.205l-0.241-0.517s-0.153-0.409-0.307-0.38c-0.153 0.029 0.08 0.773 0.08 0.773l0.336 0.766s0.54 1.467 0.723 1.664c0 0 0.183 0.693 0.154 1.102-0.029 0.408 0.058 4.901 0.058 4.901s0.278 1.233 0.357 1.306c0.08 0.072 1.051 0.555 1.19 0.518s1.3-1.777 1.3-1.777z"
            fill="#CC4308"
          />
          <path
            d="m905.64 166.06 0.394 2.495s-2.257 3.021-4.281 3.24l-0.666-2.411 2.254-1.751 0.219-2.102 2.08 0.529z"
            fill="#CC4308"
          />
          <path
            d="m898.97 156.04c0 0.241-0.541 1.319-0.541 1.319l-1.258 0.586-0.734 0.117-0.788-0.922-0.504-0.7s0.161-1.073 0.088-1.577c-0.074-0.503-0.438-0.964-0.438-0.964s-0.515-0.35-0.503-0.777c0.021-0.3 0.099-0.594 0.229-0.865 0 0-0.634-3.491 2.364-3.616 2.999-0.124 2.879 3.634 2.879 3.634s0.081 1.083-0.149 1.313-0.563 0.909-0.563 0.909l-0.021 0.635s-0.066 0.585-0.061 0.908z"
            fill="#CC4308"
          />
          <path
            d="m898.77 149.11c0.677 0.314-0.161 1.399-3.167 1.377h-0.548s0.643 1.302-0.699 1.871c0 0-0.23-1.576 0-2.255 0.229-0.678 0.985-2.046 3.107-1.839 0-1e-3 1.147 0.222 1.307 0.846z"
            fill="#4A4644"
          />
          <path
            d="m899.84 152.13s-0.482-0.153-0.602 0.23c0 0-0.477-1.66-0.161-2.668-1e-3 0 1.047 0.84 0.763 2.438z"
            fill="#4A4644"
          />
          <path
            d="m898.35 151.99h-1.062c-0.08 0.048-0.142 0.122-0.174 0.21s-0.032 0.184-1e-3 0.272c0.099 0.339 0.471 1.063 0.942 1.073s0.95-0.35 0.913-0.777c-9e-3 -0.103-0.017-0.189-0.028-0.264-0.019-0.142-0.09-0.273-0.199-0.367-0.108-0.095-0.247-0.147-0.391-0.147z"
            fill="#331D22"
          />
          <path
            d="m901.82 198.97s0.03 1.431 0.263 1.607c0.233 0.177 1.489 1.051 1.546 1.929 0 0 0 0.613-0.379 0.7-0.379 0.088-2.364-0.204-2.86-0.495-0.497-0.292-0.7-0.701-0.846-0.73-0.147-0.029-0.905-0.087-0.905-0.087l-0.204-2.453 3.385-0.471z"
            fill="#2C2C30"
          />
          <path
            d="m894.14 200.34v2.394s-0.585 0.469-1.022 0.482c-0.438 0.013-1.607-0.016-2.189-0.308s0.12-2.457 0.12-2.457l1.365-0.958 1.726 0.847z"
            fill="#2C2C30"
          />
          <path
            d="m895.5 151.99h1.062c0.08 0.048 0.142 0.122 0.174 0.21s0.032 0.184 1e-3 0.272c-0.099 0.339-0.471 1.063-0.942 1.073s-0.95-0.35-0.913-0.777c9e-3 -0.103 0.017-0.189 0.028-0.264 0.019-0.142 0.09-0.273 0.198-0.367 0.109-0.095 0.248-0.147 0.392-0.147z"
            fill="#331D22"
          />
          <path
            d="m902.1 172.28 0.657 3.021v14.388l-0.328 7.909-0.197 2.277-3.065 0.584h-0.321l-0.758-0.788-0.76-1.022 0.672-1.576-0.293-6.976-0.817-11.557s-0.35-0.147-0.408 0.67c-0.058 0.818-0.554 10.07-0.554 10.07l-0.847 7.676-0.555 2.277 0.351 0.642-0.613 1.051-1.489-0.437-1.225 0.175-0.38 0.058-0.73-1.722 0.876-1.198-0.351-12.228-0.175-9.427 0.438-2.219 0.65-2.093 10.091 0.445"
            fill="#676565"
          />
          <path
            d="m894.83 156.34-2.582 1.357h-0.701l-0.569 0.657-1.97 0.701-1.358-0.219-0.875 3.615 2.014 0.329h3.064l0.438 1.532 0.088 3.065-0.876 3.677 0.608 1.277 4.295 0.387 5.692-0.438 0.92-1.576-0.876-2.539 0.175-0.919 0.128-1.184 0.897 1.576c2.17-0.044 3.656-1.532 3.656-1.532l-2.973-7.18-2.977-1.971-1.357-0.218-2.802 0.964-2.059-1.361z"
            fill="#fff"
          />
          <path
            d="m899.06 155.96 0.556 1.042-1.028 2.14-1.569-1.376 2.041-1.806z"
            fill="#eee"
          />
          <path
            d="m894.98 155.96-0.556 1.042 1.028 2.14 1.57-1.376-2.042-1.806z"
            fill="#eee"
          />
          <path
            d="m903.96 158.48-0.36-0.244-2.263-1.497-0.569 0.716 2.623 1.783 0.569-0.758z"
            fill="#002856"
          />
          <path
            d="m903.37 158.08-0.553 0.762-0.372-0.252 0.556-0.754 0.369 0.244z"
            fill="#FEC10D"
          />
          <path
            d="m902.78 157.69-0.548 0.755-0.372-0.252 0.551-0.746 0.369 0.243z"
            fill="#FEC10D"
          />
          <path
            d="m902.21 157.32-0.543 0.747-0.371-0.252 0.545-0.739 0.369 0.244z"
            fill="#FEC10D"
          />
          <path
            d="m893.03 156.79-0.38 0.213-2.353 1.35 0.384 0.829 2.772-1.543-0.423-0.849z"
            fill="#002856"
          />
          <path
            d="m892.41 157.14 0.432 0.837-0.392 0.219-0.424-0.836 0.384-0.22z"
            fill="#FEC10D"
          />
          <path
            d="m891.8 157.49 0.428 0.828-0.392 0.219-0.419-0.827 0.383-0.22z"
            fill="#FEC10D"
          />
          <path
            d="m891.21 157.83 0.424 0.821-0.392 0.218-0.416-0.819 0.384-0.22z"
            fill="#FEC10D"
          />
        </g>
        <g clipPath="url(#e)">
          <g opacity=".2">
            <path d="M1115 -1H5V323H1115V-1Z" fill="url(#a)" />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="d"
          x="252"
          y="104"
          width="198"
          height="85"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_40_1655"
            stdDeviation="1"
          />
        </filter>
        <filter
          id="c"
          x="106"
          y="32"
          width="98"
          height="25"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_40_1655"
            stdDeviation="2"
          />
        </filter>
        <pattern
          id="a"
          width="0.0847614"
          height="0.290386"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.00038528 .0013199)" xlinkHref="#b" />
        </pattern>
        <clipPath id="n">
          <rect width="1099" height="319" fill="#fff" />
        </clipPath>
        <clipPath id="m">
          <rect
            transform="translate(27.024 10)"
            width="1065.1"
            height="298"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="l">
          <rect
            transform="translate(11 226.98)"
            width="150"
            height="64.024"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="k">
          <rect
            transform="translate(12.829 226.98)"
            width="148.17"
            height="61.903"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="j">
          <rect
            transform="translate(317 211.01)"
            width="101"
            height="100.99"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="i">
          <rect
            transform="translate(345 213.5)"
            width="69"
            height="96.5"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="h">
          <rect
            transform="matrix(-1 0 0 1 1001 239)"
            width="23"
            height="33"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="g">
          <rect
            transform="translate(877 179)"
            width="30"
            height="91"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="f">
          <rect
            transform="matrix(-1 0 0 1 907 148.24)"
            width="23"
            height="54.976"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="e">
          <rect
            transform="translate(5 -1)"
            width="1110"
            height="319"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
FlightSvg.propTypes = propTypes;

export default FlightSvg;
