/**
 * search for a set of class names in an event path
 * @param {*} event event being search
 * @param {*} classes list of valid icon names
 */
const findClassesEventPath = (event, classes) => {
  let isFound = false;
  const path = getEventPath(event);
  const outerLength = path?.length || 0;
  for (let i = 0; i < outerLength; i++) {
    const classList = path?.[i]?.classList;
    isFound = searchClassListForClasses(classList, classes);
    if (isFound) {
      break;
    }
  }
  return isFound;
};

const getEventPath = (event) => {
  let path = event?.path;
  if (typeof event?.composedPath === "function") {
    path = event.composedPath();
  }
  return path;
};

const searchClassListForClasses = (classList, classes) => {
  let isFound = false;
  const innerLength = Array.isArray(classes) ? classes.length : 0;
  if (typeof classList?.contains === "function") {
    for (let j = 0; j < innerLength; j++) {
      const className = classes?.[j] || null;
      if (className && classList.contains(className)) {
        isFound = true;
        break;
      }
    }
  }
  return isFound;
};

export default findClassesEventPath;
