import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const xmlns = "http://www.w3.org/2000/svg";

const BoldIcon = ({ fillPath = colorIron, title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M15.6 10.79C16.57 10.12 17.25 9.02 17.25 8C17.25 5.74 15.5 4 13.25 4H8C7.45 4 7 4.45 7 5V17C7 17.55 7.45 18 8 18H13.78C15.85 18 17.74 16.31 17.75 14.23C17.76 12.7 16.9 11.39 15.6 10.79V10.79ZM10 6.5H13C13.83 6.5 14.5 7.17 14.5 8C14.5 8.83 13.83 9.5 13 9.5H10V6.5ZM13.5 15.5H10V12.5H13.5C14.33 12.5 15 13.17 15 14C15 14.83 14.33 15.5 13.5 15.5Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

const ClearFormatIcon = ({ fillPath = colorIron, title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M18.0005 14C18.0005 10.91 14.4005 6.12001 12.7705 4.13001C12.3705 3.64001 11.6205 3.64001 11.2205 4.13001C10.7605 4.70001 10.1405 5.49001 9.49047 6.40001L17.9305 14.84C17.9705 14.56 18.0005 14.28 18.0005 14ZM19.2905 19.01L6.12047 5.84001C5.73047 5.45001 5.10047 5.45001 4.71047 5.84001C4.32047 6.23001 4.32047 6.86001 4.71047 7.25001L7.32047 9.86001C6.55047 11.33 6.00047 12.79 6.00047 14C6.00047 17.31 8.69047 20 12.0005 20C13.5205 20 14.9005 19.43 15.9505 18.5L17.8705 20.42C18.2605 20.81 18.8905 20.81 19.2805 20.42C19.6805 20.04 19.6805 19.4 19.2905 19.01Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

const ItalicsIcon = ({ fillPath = colorIron, title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M10 5.5C10 6.33 10.67 7 11.5 7H12.21L8.79 15H7.5C6.67 15 6 15.67 6 16.5C6 17.33 6.67 18 7.5 18H12.5C13.33 18 14 17.33 14 16.5C14 15.67 13.33 15 12.5 15H11.79L15.21 7H16.5C17.33 7 18 6.33 18 5.5C18 4.67 17.33 4 16.5 4H11.5C10.67 4 10 4.67 10 5.5Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

const UnderlineIcon = ({ fillPath = colorIron, title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M12.79 16.95C15.82 16.56 18 13.84 18 10.79V4.25C18 3.56 17.44 3 16.75 3C16.06 3 15.5 3.56 15.5 4.25V10.9C15.5 12.57 14.37 14.09 12.73 14.42C10.48 14.89 8.5 13.17 8.5 11V4.25C8.5 3.56 7.94 3 7.25 3C6.56 3 6 3.56 6 4.25V11C6 14.57 9.13 17.42 12.79 16.95ZM5 20C5 20.55 5.45 21 6 21H18C18.55 21 19 20.55 19 20C19 19.45 18.55 19 18 19H6C5.45 19 5 19.45 5 20Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export { BoldIcon, ClearFormatIcon, ItalicsIcon, UnderlineIcon };
