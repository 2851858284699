import * as queryString from "query-string";
import { Fragment, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { LegacyTabPathname, TabRoute } from "./app.constants";
import { WorkspaceHeaderLocationParams } from "./app.types";

const DEFAULT_REDIRECT = "/s/evaluations";
const HandleRedirectsV2Component = () => {
  const { initId = "", initProdId = "" } = useParams<WorkspaceHeaderLocationParams>();
  const { search, pathname } = useLocation();

  useEffect(() => {
    const { id, pid } = queryString.parse(search);
    if (!pathname) {
      singleSpa.navigateToUrl(DEFAULT_REDIRECT);
    } else if (pathname.includes(`/s/evaluation/${initId}/product/${initProdId}/documents`)) {
      singleSpa.navigateToUrl(`/s/evaluation/${initId}/product/${initProdId}/${TabRoute.FILES}`);
    } else if (!id || !pid) {
      singleSpa.navigateToUrl(DEFAULT_REDIRECT);
    } else if (pathname.includes(LegacyTabPathname.PROFILE)) {
      singleSpa.navigateToUrl(`/s/evaluation/${id}/product/${pid}/${TabRoute.PROFILE}`);
    } else if (pathname.includes(LegacyTabPathname.SCORECARD)) {
      singleSpa.navigateToUrl(`/s/evaluation/${id}/product/${pid}/${TabRoute.SCORECARD}`);
    } else if (pathname.includes(LegacyTabPathname.RESPONSES)) {
      singleSpa.navigateToUrl(`/s/evaluation/${id}/product/${pid}/${TabRoute.RESPONSES}`);
    } else if (pathname.includes(LegacyTabPathname.DOCUMENTS)) {
      singleSpa.navigateToUrl(`/s/evaluation/${id}/product/${pid}/${TabRoute.FILES}`);
    } else {
      singleSpa.navigateToUrl(DEFAULT_REDIRECT);
    }
  }, [initId, initProdId, pathname, search]);

  return <Fragment />;
};

export default HandleRedirectsV2Component;
