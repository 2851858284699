import { createContext, ReactNode, useContext, useState } from "react";
import { SnackbarContextValue } from "../../app.types";

const SnackbarContext = createContext<SnackbarContextValue>({} as SnackbarContextValue);

const useSnackbarContext = () => {
  return useContext(SnackbarContext);
};

const SnackbarContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [snackbarIsError, setSnackbarIsError] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  const contextValue = {
    snackbarIsError,
    setSnackbarIsError,
    setSnackBarMessage,
    setShowSnackBar,
    showSnackBar,
    snackBarMessage,
  };

  return <SnackbarContext.Provider value={contextValue}>{props.children}</SnackbarContext.Provider>;
};

export { SnackbarContext, SnackbarContextProvider, useSnackbarContext };
