import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const colorGartnerBlue = colorPalette.brand.gartnerBlue.hex;
const colorIron = colorPalette.neutrals.iron.hex;
const colorStone = colorPalette.neutrals.stone.hex;
const xmlns = "http://www.w3.org/2000/svg";

/** @deprecated use StarSmallIcon */
export const StarIcon = ({
  fillPath = colorGartnerBlue,
  fillSvg = "none",
  height = "10",
  opacityPath = "0.6",
  title = "",
  viewBox = "0 0 11 10",
  width = "11",
}: IconProps & { opacityPath?: string }) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M5.5 8.3747L8.16742 9.90932C8.65591 10.1906 9.25367 9.77481 9.12512 9.24901L8.41809 6.36318L10.777 4.41892C11.2076 4.0643 10.9762 3.39176 10.4106 3.34896L7.30613 3.09829L6.09133 0.371428C5.8728 -0.123809 5.1272 -0.123809 4.90867 0.371428L3.69387 3.09217L0.589376 3.34285C0.0237545 3.38565 -0.207636 4.05819 0.223007 4.4128L2.58191 6.35707L1.87488 9.24289C1.74633 9.7687 2.34409 10.1845 2.83258 9.90321L5.5 8.3747Z"
        fill={fillPath}
        opacity={opacityPath}
      />
    </svg>
  );
};

/** @deprecated use StarV2Icon with fillPath */
export const EmptyStarIcon = ({
  fill = "none",
  height = "18",
  width = "20",
  pathFill = colorStone,
  viewBox = "0 0 20 18",
}: IconProps & { pathFill?: string }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M9.80113 14.8451L14.3689 17.5654C15.2054 18.064 16.229 17.327 16.0089 16.3949L14.7981 11.2795L18.8376 7.83305C19.575 7.20445 19.1788 6.01229 18.2102 5.93643L12.894 5.49207L10.8137 0.658399C10.4395 -0.219466 9.16275 -0.219466 8.78852 0.658399L6.70827 5.48124L1.39207 5.92559C0.42349 6.00145 0.0272517 7.19361 0.764695 7.82221L4.80413 11.2686L3.5934 16.3841C3.37326 17.3162 4.39688 18.0531 5.23338 17.5546L9.80113 14.8451Z"
        fill={pathFill}
      />
    </svg>
  );
};

/** @deprecated use StarV2Icon with fillPath */
export const FullStarIcon = ({
  fill = "none",
  height = "18",
  width = "20",
  pathFill = colorGartnerBlue,
  viewBox = "0 0 20 18",
}: IconProps & { pathFill?: string }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M9.83043 14.8451L14.3982 17.5654C15.2347 18.064 16.2583 17.327 16.0382 16.3949L14.8274 11.2795L18.8669 7.83305C19.6043 7.20445 19.2081 6.01229 18.2395 5.93643L12.9233 5.49207L10.843 0.658399C10.4688 -0.219466 9.19204 -0.219466 8.81782 0.658399L6.73757 5.48124L1.42137 5.92559C0.452787 6.00145 0.0565486 7.19361 0.793992 7.82221L4.83342 11.2686L3.62269 16.3841C3.40256 17.3162 4.42618 18.0531 5.26268 17.5546L9.83043 14.8451Z"
        fill={pathFill}
      />
    </svg>
  );
};

export const HalfStarIcon = ({
  fill = "none",
  height = "18",
  width = "20",
  pathFill = colorGartnerBlue,
  viewBox = "0 0 20 18",
}: IconProps & { pathFill?: string }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M10.3021 14.8451L14.8699 17.5654C15.7064 18.064 16.73 17.327 16.5098 16.3949L15.2991 11.2795L19.3385 7.83305C20.076 7.20445 19.6797 6.01229 18.7112 5.93643L13.395 5.49207L11.3147 0.658399C10.9405 -0.219466 9.66372 -0.219466 9.2895 0.658399L7.20925 5.48124L1.89305 5.92559C0.924467 6.00145 0.528228 7.19361 1.26567 7.82221L5.3051 11.2686L4.09437 16.3841C3.87424 17.3162 4.89786 18.0531 5.73436 17.5546L10.3021 14.8451Z"
        fill={colorStone}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8715 0.151697V15.1842L10.3021 14.8451L5.73436 17.5546C4.89786 18.0531 3.87424 17.3162 4.09437 16.3841L5.3051 11.2686L1.26567 7.82221C0.528228 7.19361 0.924467 6.00145 1.89305 5.92559L7.20925 5.48124L9.2895 0.658399C9.56643 0.00877729 10.3376 -0.160123 10.8715 0.151697Z"
        fill={pathFill}
      />
    </svg>
  );
};

export const HalfStarV2Icon = ({
  fill = "none",
  height = "18",
  width = "20",
  fillPath = colorGartnerBlue,
  viewBox = "0 0 20 18",
}: IconProps & { fillPath?: string }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M10.3021 14.8451L14.8699 17.5654C15.7064 18.064 16.73 17.327 16.5098 16.3949L15.2991 11.2795L19.3385 7.83305C20.076 7.20445 19.6797 6.01229 18.7112 5.93643L13.395 5.49207L11.3147 0.658399C10.9405 -0.219466 9.66372 -0.219466 9.2895 0.658399L7.20925 5.48124L1.89305 5.92559C0.924467 6.00145 0.528228 7.19361 1.26567 7.82221L5.3051 11.2686L4.09437 16.3841C3.87424 17.3162 4.89786 18.0531 5.73436 17.5546L10.3021 14.8451Z"
        fill={colorStone}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8715 0.151697V15.1842L10.3021 14.8451L5.73436 17.5546C4.89786 18.0531 3.87424 17.3162 4.09437 16.3841L5.3051 11.2686L1.26567 7.82221C0.528228 7.19361 0.924467 6.00145 1.89305 5.92559L7.20925 5.48124L9.2895 0.658399C9.56643 0.00877729 10.3376 -0.160123 10.8715 0.151697Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const StarSmallIcon = ({
  fillPath = colorGartnerBlue,
  opacity = "0.6",
  title = "",
}: IconProps & { opacity?: string }) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M7.5 11.3747L10.1674 12.9093C10.6559 13.1906 11.2537 12.7748 11.1251 12.249L10.4181 9.36318L12.777 7.41892C13.2076 7.0643 12.9762 6.39176 12.4106 6.34896L9.30613 6.09829L8.09133 3.37143C7.8728 2.87619 7.1272 2.87619 6.90867 3.37143L5.69387 6.09217L2.58938 6.34285C2.02375 6.38565 1.79236 7.05819 2.22301 7.4128L4.58191 9.35707L3.87488 12.2429C3.74633 12.7687 4.34409 13.1845 4.83258 12.9032L7.5 11.3747Z"
        fill={fillPath}
        opacity={opacity}
      />
    </svg>
  );
};

export const StarV2Icon = ({
  fillPath = colorIron,
  title = "",
  rootClassName = "",
}: IconProps & { rootClassName?: string }) => {
  return (
    <svg className={rootClassName} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M11.9998 17.27L16.1498 19.78C16.9098 20.24 17.8398 19.56 17.6398 18.7L16.5398 13.98L20.2098 10.8C20.8798 10.22 20.5198 9.12001 19.6398 9.05001L14.8098 8.64001L12.9198 4.18001C12.5798 3.37001 11.4198 3.37001 11.0798 4.18001L9.18983 8.63001L4.35983 9.04001C3.47983 9.11001 3.11983 10.21 3.78983 10.79L7.45983 13.97L6.35983 18.69C6.15983 19.55 7.08983 20.23 7.84983 19.77L11.9998 17.27Z"
        fill={fillPath}
      />
    </svg>
  );
};
