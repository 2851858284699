import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const white = colorPalette.basic.white.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const LightBulbOnIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "22",
  title = "",
  viewBox = "0 0 23 22",
  width = "23",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M19.24 3.21C18.85 2.82 18.22 2.82 17.83 3.21L17.44 3.6C17.05 3.99 17.06 4.62 17.44 5L17.45 5.01C17.84 5.4 18.47 5.4 18.86 5.01L19.25 4.62C19.63 4.22 19.63 3.6 19.24 3.21ZM11.5 0H11.49C10.94 0 10.5 0.44 10.5 0.99V1.95C10.5 2.5 10.94 2.94 11.49 2.94H11.5C12.05 2.94 12.49 2.5 12.49 1.95V0.99C12.49 0.44 12.05 0 11.5 0ZM20.48 11.95H21.5C22.05 11.95 22.49 11.51 22.49 10.96V10.95C22.49 10.4 22.05 9.96 21.5 9.96H20.49C19.94 9.96 19.5 10.4 19.5 10.95V10.96C19.49 11.51 19.93 11.95 20.48 11.95ZM3.49 10.96V10.95C3.49 10.4 3.05 9.96 2.5 9.96H1.49C0.94 9.96 0.5 10.4 0.5 10.95V10.96C0.5 11.51 0.94 11.95 1.49 11.95H2.5C3.05 11.95 3.49 11.51 3.49 10.96ZM5.55 3.59L5.16 3.2C4.77 2.81 4.14 2.81 3.75 3.2C3.36 3.59 3.36 4.22 3.75 4.61L4.14 5C4.53 5.39 5.16 5.38 5.54 5C5.94 4.6 5.94 3.98 5.55 3.59ZM8.49016 17.95V16.14C6.70016 15.1 5.49016 13.17 5.49016 10.94C5.49016 7.39 8.60016 4.55 12.2402 4.99C14.8602 5.31 17.0202 7.4 17.4202 10.01C17.8102 12.62 16.5302 14.96 14.4902 16.14V17.95H8.49016ZM14.49 20H8.49V21C8.49 21.5523 8.93772 22 9.49 22H13.49C14.0423 22 14.49 21.5523 14.49 21V20Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const LightBulbOffIcon = ({
  fillPath = white,
  fillColor = defaultCta,
  fill = "none",
  viewBox = "0 0 48 48",
  width = "48",
  height = "48",
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlnsUrl}>
      <circle cx="24" cy="24" r="12" fill={fillColor} />
      <path
        d="M28 21.9763C28 22.8613 27.7102 23.6807 27.215 24.343C27.2111 24.3481 27.2122 24.3472 27.2137 24.3452C27.1439 24.4324 26.1443 25.6965 25.8188 27.1387C25.7057 27.6387 25.271 28 24.7546 28H23.2479C22.7343 28 22.297 27.6387 22.1837 27.1387C21.8439 25.644 20.9114 24.5078 20.9114 24.5078C20.9009 24.4922 20.8877 24.4739 20.8772 24.4607C20.8628 24.4438 20.849 24.4265 20.8356 24.4089L20.7955 24.356C20.7876 24.3403 20.7797 24.3325 20.7797 24.3325C20.2898 23.6702 20 22.856 20 21.9763C20 19.78 21.7886 18 23.9987 18C26.2087 18 28 19.78 28 21.9763Z"
        fill={fillPath}
      />
      <path
        d="M22.5 30C22.2239 30 22 30.2239 22 30.5C22 30.7761 22.2239 31 22.5 31H25.5C25.7761 31 26 30.7761 26 30.5C26 30.2239 25.7761 30 25.5 30H22.5Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const RibbonBulbIcon = ({
  fillPath = white,
  fill = "none",
  viewBox = "0 0 24 24",
  width = "24",
  height = "24",
}) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlnsUrl}>
      <path
        d="M19 8.95877C19 10.5071 18.493 11.9412 17.6263 13.1001C17.6124 13.1184 17.6309 13.0956 17.6309 13.0956C17.6309 13.0956 15.7731 15.3769 15.1831 17.9929C14.9848 18.8679 14.2242 19.5 13.3207 19.5H10.6839C9.78494 19.5 9.01974 18.8679 8.8215 17.9929C8.22688 15.3769 6.59502 13.3887 6.59502 13.3887C6.57654 13.3613 6.55347 13.3293 6.5351 13.3062C6.49974 13.2648 6.46663 13.221 6.43427 13.178L6.39218 13.1231C6.3783 13.0956 6.36452 13.0819 6.36452 13.0819C5.50703 11.9228 5 10.4981 5 8.95877C5 5.11526 8.13011 2 11.9977 2C15.8653 2 19 5.11526 19 8.95877Z"
        fill={fillPath}
      />
      <path
        d="M9.375 21.25C8.89178 21.25 8.5 21.6418 8.5 22.125C8.5 22.6082 8.89178 23 9.375 23H14.625C15.1082 23 15.5 22.6082 15.5 22.125C15.5 21.6418 15.1082 21.25 14.625 21.25H9.375Z"
        fill={fillPath}
      />
    </svg>
  );
};
