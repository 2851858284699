import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const KebabDoubleIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 25 24",
  width = "25",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M9.5 8C10.6 8 11.5 7.1 11.5 6C11.5 4.9 10.6 4 9.5 4C8.4 4 7.5 4.9 7.5 6C7.5 7.1 8.4 8 9.5 8ZM9.5 10C8.4 10 7.5 10.9 7.5 12C7.5 13.1 8.4 14 9.5 14C10.6 14 11.5 13.1 11.5 12C11.5 10.9 10.6 10 9.5 10ZM9.5 16C8.4 16 7.5 16.9 7.5 18C7.5 19.1 8.4 20 9.5 20C10.6 20 11.5 19.1 11.5 18C11.5 16.9 10.6 16 9.5 16Z"
        fill={fillPath}
      />
      <path
        d="M15.5 8C16.6 8 17.5 7.1 17.5 6C17.5 4.9 16.6 4 15.5 4C14.4 4 13.5 4.9 13.5 6C13.5 7.1 14.4 8 15.5 8ZM15.5 10C14.4 10 13.5 10.9 13.5 12C13.5 13.1 14.4 14 15.5 14C16.6 14 17.5 13.1 17.5 12C17.5 10.9 16.6 10 15.5 10ZM15.5 16C14.4 16 13.5 16.9 13.5 18C13.5 19.1 14.4 20 15.5 20C16.6 20 17.5 19.1 17.5 18C17.5 16.9 16.6 16 15.5 16Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const KebabSingleIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 4 16",
  width = "4",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
        fill={fillPath}
      />
    </svg>
  );
};
