import axios from "axios";
import { DEFAULT_USER_INIT_ACCESS } from "./use-user-init-access.constants";
import { UserInitAccess } from "./use-user-init-access.types";

export const createUserInitAccessMock = () => {
  beforeEach(() => {
    window.sessionStorage.clear();
  });
  afterEach(() => {
    window.sessionStorage.clear();
  });
  return (userInitAccess: Partial<UserInitAccess>) => {
    (axios.get as jest.Mock).mockImplementationOnce(() =>
      Promise.resolve({
        data: {
          data: {
            ...DEFAULT_USER_INIT_ACCESS,
            ...userInitAccess,
          },
        },
        status: 200,
      })
    );
  };
};
