import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultAdditionColor = colorPalette.interactions.blueBerry.hex;
const defaultPlusColor = colorPalette.interactions.blueBerry.hex;
const ironColor = colorPalette.neutrals.iron.hex;
const defaultPercentageColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

/**
 * @deprecated - use PlusIcon with proper sizing instead
 */
export const AdditionIcon = ({
  fillPath = defaultAdditionColor,
  fillSvg = "none",
  height = "14",
  title = "",
  viewBox = "0 0 14 14",
  width = "14",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const AdditionCircledIcon = ({
  fillPath = defaultAdditionColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M12 7C11.45 7 11 7.45 11 8V11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H13V8C13 7.45 12.55 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const AddCircledDarkIcon = ({
  fillPath = ironColor,
  fillSvg = "none",
  height = "20",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM14 11H11V14C11 14.55 10.55 15 10 15C9.45 15 9 14.55 9 14V11H6C5.45 11 5 10.55 5 10C5 9.45 5.45 9 6 9H9V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const SubtractionCircledIcon = ({
  fillPath = defaultAdditionColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <g clipPath="url(#clip0_11612_3739)">
        <path
          d="M7 12C7 12.55 7.45 13 8 13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H8C7.45 11 7 11.45 7 12ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill="#0052D6"
        />
      </g>
      <defs>
        <clipPath id="clip0_11612_3739">
          <rect width="24" height="24" fill={fillPath} />
        </clipPath>
      </defs>
    </svg>
  );
};

/**
 * multiple purpose - used as close icon
 */
export const MultiplyIcon = ({
  fillPath = ironColor,
  fillSvg = "none",
  height = "14",
  title = "",
  viewBox = "0 0 14 14",
  width = "14",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M13.2997 0.710001C12.9097 0.320001 12.2797 0.320001 11.8897 0.710001L6.99973 5.59L2.10973 0.700001C1.71973 0.310001 1.08973 0.310001 0.699727 0.700001C0.309727 1.09 0.309727 1.72 0.699727 2.11L5.58973 7L0.699727 11.89C0.309727 12.28 0.309727 12.91 0.699727 13.3C1.08973 13.69 1.71973 13.69 2.10973 13.3L6.99973 8.41L11.8897 13.3C12.2797 13.69 12.9097 13.69 13.2997 13.3C13.6897 12.91 13.6897 12.28 13.2997 11.89L8.40973 7L13.2997 2.11C13.6797 1.73 13.6797 1.09 13.2997 0.710001Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const PlusIcon = ({ title = "", fillPath = defaultPlusColor }: IconProps) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <g clipPath="url(#clip0_2542_17592)">
        <path
          d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_2542_17592">
          <rect fill={colorPalette.basic.white.hex} height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PercentageIcon = ({
  fillPath = defaultPercentageColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M16.4231 4.09381C16.9236 4.32736 17.14 4.92241 16.9064 5.42288L9.90642 20.4229C9.67287 20.9234 9.07782 21.1397 8.57735 20.9062C8.07688 20.6726 7.8605 20.0776 8.09406 19.5771L15.0941 4.57711C15.3276 4.07664 15.9227 3.86026 16.4231 4.09381Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 21C19.433 21 21 19.433 21 17.5C21 15.567 19.433 14 17.5 14C15.567 14 14 15.567 14 17.5C14 19.433 15.567 21 17.5 21ZM17.5 19.25C18.4665 19.25 19.25 18.4665 19.25 17.5C19.25 16.5335 18.4665 15.75 17.5 15.75C16.5335 15.75 15.75 16.5335 15.75 17.5C15.75 18.4665 16.5335 19.25 17.5 19.25Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 7.5C11 9.433 9.433 11 7.5 11C5.567 11 4 9.433 4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5ZM9.25 7.5C9.25 8.4665 8.4665 9.25 7.5 9.25C6.5335 9.25 5.75 8.4665 5.75 7.5C5.75 6.5335 6.5335 5.75 7.5 5.75C8.4665 5.75 9.25 6.5335 9.25 7.5Z"
        fill={fillPath}
      />
    </svg>
  );
};
