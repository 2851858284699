import { makeStyles, Tooltip as MuiTooltip } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./tooltip-v2.styles";
import { useFeatureFlag } from "../featureFlags";
import { GCOM_3606__fontUpdate } from "../feature-flags";

const propTypes = {
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  deactivate: PropTypes.bool,
  interactive: PropTypes.bool,
  rootClassName: PropTypes.string,
  title: PropTypes.any, // ReactNode | string
};
const useStyles = makeStyles(() => styles);

const TooltipV2 = ({ children, deactivate = false, interactive = true, rootClassName = "", title, ...props }) => {
  const classes = useStyles();
  const isGCOM3606FF = useFeatureFlag(GCOM_3606__fontUpdate);

  return (
    <MuiTooltip
      arrow={true}
      classes={{
        popper: classNames(rootClassName),
        tooltip: classNames(classes.tooltip, isGCOM3606FF && classes.newFont),
        tooltipArrow: classes.tooltipArrow,
        arrow: classes.arrow,
        arrowBorder: classes.arrowBorder,
      }}
      disableFocusListener={deactivate}
      disableHoverListener={deactivate}
      disableTouchListener={deactivate}
      interactive={interactive}
      title={title}
      {...props}
      enterDelay={600}
      enterNextDelay={600}
      TransitionProps={{ timeout: { appear: 300, enter: 300, exit: 300 } }}
    >
      {children}
    </MuiTooltip>
  );
};

TooltipV2.propTypes = propTypes;
export default TooltipV2;
