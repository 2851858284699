const defaultTo = (value, def = null) => {
  return value || def;
};

const defaultToZero = (value) => {
  const val = typeof value === "number" ? value : null;
  return defaultTo(val, 0);
};

const defaultToEmptyString = (value) => {
  const val = typeof value === "string" ? value : null;
  return defaultTo(val, "");
};

const defaultToEmptyArray = (value) => {
  const val = Array.isArray(value) ? value : null;
  return defaultTo(val, []);
};

const defaultToEmptyObject = (value) => {
  const val = typeof value === "object" ? value : null;
  return defaultTo(val, {});
};

const defaultDateToNull = (dateStr = "", defaultDate = "") => {
  let output;

  if (dateStr === defaultDate) {
    output = null;
  } else {
    output = dateStr;
  }

  return output;
};

const defaultDateRangesToNull = (
  dateRangeObj = { start: "", end: "" },
  defaultDate = ""
) => {
  let startDate, endDate;

  if (dateRangeObj.start === defaultDate) {
    startDate = null;
  } else {
    startDate = dateRangeObj.start;
  }

  if (dateRangeObj.end === defaultDate) {
    endDate = null;
  } else {
    endDate = dateRangeObj.end;
  }

  return {
    start: startDate,
    end: endDate,
  };
};

export {
  defaultTo,
  defaultToEmptyArray,
  defaultToEmptyObject,
  defaultToEmptyString,
  defaultToZero,
  defaultDateRangesToNull,
  defaultDateToNull,
};
