import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const PipeSvg = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 1 24"
      width="1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.04907e-06 24L0 0L0.999999 -4.37113e-08L1 24L1.04907e-06 24Z"
        fill={colorPalette.neutrals.stone.hex}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default PipeSvg;
