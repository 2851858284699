import classNames from "classnames";
import { formatDate, getAsyncRequest, InitProdState, useCaptureEventsV2, useUserState, useWebSocket } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  FreeTrialBannerComponent,
} from "gx-npm-ui";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { WorkspaceHeaderContext } from "./context/workspace-header.context";
import { useWorkspaceHeaderLoad } from "./context/load/use-load-workspace-header.hook";
import { SnackbarContext } from "./context/snack-bar-banner/snack-bar.context";
import useScrolling from "./hooks/scrolling.hook";
import { GCOM_3787__fileHub } from "./lib/feature-flags";
import BrandingHeaderV2Component from "./sections/branding-header-v2/branding-header-v2.component";
import LegalInlineBannerV2Component from "./sections/legal-inline-banner-v2/legal-inline-banner-v2.component";
import NavigationDialogV2Component from "./sections/navigation-dialog-v2/navigation-dialog-v2.component";
import ProductHeader from "./sections/product-header/product-header.component";
import SelectedProductBanner from "./sections/selected-product-banner/selected-product-banner.component";
import Spacer from "./sections/spacer/spacer.component";
import StateRestoreBanner from "./sections/state-restore-banner/state-restore-banner.component";
import TabMenu from "./sections/tab-menu/tab-menu.component";
import TabMenuV2Component from "./sections/tab-menu-v2/tab-menu-v2.component";
import Snackbar from "./sections/snack-bar/snack-bar.component";
import { ClientEvent, TabRoute, WebSocketMessageEvents } from "./app.constants";
import {
  WorkspaceHeaderLocationParams,
  WorkspaceHeaderPayload,
  WsInMsgUpdateState,
  WsOutMsgViewingInitiative,
} from "./app.types";
import styles from "./app-v2.styles.module.scss";

const AppV2Component = () => {
  const { initId = "", initProdId = "", tab = "" } = useParams<WorkspaceHeaderLocationParams>();
  const { freeTrialDaysRemaining, freeTrialRole } = useUserState();
  const [wsMessage, wsPublish] = useWebSocket<WsInMsgUpdateState, WsOutMsgViewingInitiative>();
  const { setShowSnackBar, setSnackbarIsError } = useContext(SnackbarContext);
  const {
    hasRequestedDocuments,
    isSurveySubmitted,
    productState,
    setAwardedProductImageLoc,
    setAwardedProductName,
    setHasRequestedDocuments,
    setInitId,
    setInitName,
    setInitProdId,
    setInitState,
    setInitStateUpdateDate,
    setInitStatus,
    setIsSurveyLegalDismissed,
    setIsSurveySubmitted,
    setProductImageLoc,
    setProductName,
    setProductState,
    setSurveyId,
  } = useContext(WorkspaceHeaderContext);
  const { hasError, isLoading } = useWorkspaceHeaderLoad();
  const isScrolling = useScrolling();
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (!initId) {
      return;
    }
    const message = { event: WebSocketMessageEvents.VIEWING_INITIATIVE, initiativeId: initId };
    wsPublish({ action: "message", data: { ...message, isViewing: true } });
    return () => {
      wsPublish({ action: "message", data: { ...message, isViewing: false } });
    };
  }, [initId, wsPublish]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    // setting these will invoke the load hook, tab-menu-v1 component, and product-header component
    setInitId(initId || "");
    setInitProdId(initProdId || "");
  }, [initId, initProdId, setInitId, setInitProdId]);

  useEffect(() => {
    if (!wsMessage || !initId || wsMessage.initiativeId !== initId) {
      return;
    } else if (wsMessage.event !== WebSocketMessageEvents.UPDATE_INITIATIVE_STATE) {
      return;
    }
    const date = formatDate(new Date().toISOString(), "YYYY-MM-DD");
    setInitState(wsMessage.state);
    setInitStateUpdateDate(date);
  }, [initId, wsMessage, setInitState, setInitStateUpdateDate]);

  useEffect(() => {
    if (!wsMessage || !initId || wsMessage.initiativeId !== initId) {
      return;
    } else if (wsMessage.event !== WebSocketMessageEvents.UPDATE_PRODUCT_STATE) {
      return;
    }
    (async () => {
      try {
        const url = `/api/v2/initiatives/${initId}/workspace/${initProdId}/workspace-header`;
        const response: { data: { data: WorkspaceHeaderPayload }; status: number } = await getAsyncRequest(url);
        if (response.status !== 200 || !response.data?.data) {
          return;
        }
        setAwardedProductImageLoc(response.data.data.awardedProduct?.imageLoc || "");
        setAwardedProductName(response.data.data.awardedProduct?.name || "");
        setHasRequestedDocuments(response.data.data.hasRequestedDocuments);
        setInitName(response.data.data.initName || "");
        setInitState(response.data.data.initState);
        setInitStateUpdateDate(response.data.data.initStateUpdateDate || "");
        setInitStatus(response.data.data.initStatus || "");
        setIsSurveyLegalDismissed(response.data.data.isSurveyLegalDismissed);
        setIsSurveySubmitted(response.data.data.isSurveySubmitted);
        setProductImageLoc(response.data.data.productImageLoc || "");
        setProductName(response.data.data.productName || "");
        setProductState(response.data.data.productState || "");
        setSurveyId(response.data.data.surveyId || "");
      } catch (err) {
        // silently ignore
      }
    })();
  }, [
    initId,
    initProdId,
    wsMessage,
    setAwardedProductImageLoc,
    setAwardedProductName,
    setHasRequestedDocuments,
    setInitName,
    setInitState,
    setInitStateUpdateDate,
    setInitStatus,
    setIsSurveyLegalDismissed,
    setIsSurveySubmitted,
    setProductName,
    setProductImageLoc,
    setProductState,
    setSurveyId,
  ]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    if (tab === TabRoute.SCORECARD) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_SCORESHEET_PAGE_VIEWED;
      captureEvents([{ eventType, metaData: { initiativeId: initId, initProductId: initProdId } }]);
    } else if (tab === TabRoute.PROFILE) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_PROFILE_PAGE_VIEWED;
      captureEvents([{ eventType, metaData: { initiativeId: initId, initProductId: initProdId } }]);
    }
  }, [captureEvents, initId, initProdId, tab]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    if (tab === TabRoute.RESPONSES && isSurveySubmitted) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_RESPONSES_PAGE_VIEWED;
      captureEvents([{ eventType, metaData: { initiativeId: initId, initProductId: initProdId } }]);
    }
  }, [captureEvents, hasRequestedDocuments, initId, initProdId, isSurveySubmitted, tab]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    if (tab === TabRoute.DOCUMENTS && isSurveySubmitted && hasRequestedDocuments) {
      const eventType = ClientEvent.INITIATIVE_PRODUCT_WORKSPACE_DOCUMENTS_PAGE_VIEWED;
      captureEvents([{ eventType, metaData: { initiativeId: initId, initProductId: initProdId } }]);
    }
  }, [captureEvents, hasRequestedDocuments, initId, initProdId, isSurveySubmitted, tab]);

  useEffect(() => {
    if (hasError) {
      setSnackbarIsError(true);
      setShowSnackBar(true);
    }
  }, [hasError, setShowSnackBar, setSnackbarIsError]);

  return (
    <div className={styles.appRoot}>
      <FreeTrialBannerComponent daysRemaining={freeTrialDaysRemaining} freeTrialRole={freeTrialRole} />
      <div className={classNames(styles.appContent, isScrolling && styles.contentScrolling)}>
        <StateRestoreBanner />
        <BrandingHeaderV2Component isLoading={isLoading} />
        <SelectedProductBanner />
        <div className={styles.tabsHeaderOutsideContainer}>
          <div className={styles.tabsHeaderInsiderContainer}>
            <ProductHeader
              hasError={hasError}
              isLoading={isLoading}
              isScreenedOut={productState === InitProdState.SCREENED_OUT}
            />
            <FeatureFlagBooleanContainer flagName={GCOM_3787__fileHub} strictMode={true}>
              <FeatureFlagBooleanOff>
                <TabMenu />
              </FeatureFlagBooleanOff>
              <FeatureFlagBooleanOn>
                {!isScrolling && productState && productState !== InitProdState.LISTED && <TabMenuV2Component />}
              </FeatureFlagBooleanOn>
            </FeatureFlagBooleanContainer>
          </div>
        </div>
      </div>
      <Spacer />
      <LegalInlineBannerV2Component />
      <Snackbar />
      <NavigationDialogV2Component />
    </div>
  );
};

export default AppV2Component;
