import deepmerge from "deepmerge";
import { isValidObject } from "../isUtils";

const defaultConfig = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const defaultResponse = {
  data: {},
  status: 500,
};

const mergeConfigs = (additionalConfig = {}) => {
  if (window.apiConnection) {
    additionalConfig = { ...additionalConfig, baseURL: window.apiConnection };
  }
  const additions = isValidObject(additionalConfig) ? additionalConfig : {};
  return deepmerge(defaultConfig, additions);
};

export { defaultResponse, mergeConfigs };
