import { Collapse, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { InitStatus } from "gx-npm-lib";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { useWorkspaceHeaderContext } from "../../context/workspace-header.context";
import useScrolling from "../../hooks/scrolling.hook";
import { selectedProductRootDefaultStyles as styles } from "./selected-product-banner.styles";

export const isSelectedProductBannerShown = (
  awardedProductName: string,
  isScrolling: boolean,
  productName: string,
  initStatus: InitStatus
) => {
  return !isScrolling && initStatus === InitStatus.SELECTED && awardedProductName !== productName;
};

const useStyles = makeStyles(() => styles);
const SelectedProductBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { initStatus, awardedProductImageLoc, awardedProductName, productName } = useWorkspaceHeaderContext();
  const isScrolling = useScrolling();

  return (
    <Collapse
      className={classNames(classes.collapseRoot)}
      in={isSelectedProductBannerShown(awardedProductName, isScrolling, productName, initStatus)}
      timeout={{ enter: 400, exit: 500 }}
    >
      {awardedProductName && (
        <div className={classes.container}>
          <TypographyComponent rootClassName={classes.productLogo} styling={"p3"} color={"coal"} boldness={"medium"}>
            {`${t("Currently selected vendor")}:`}
          </TypographyComponent>
          <TypographyComponent element={"span"} rootClassName={classes.productLogo}>
            <ProductLogo imageHeight="24px" imageWidth="24px" logo={awardedProductImageLoc} />
          </TypographyComponent>
          <TypographyComponent rootClassName={classes.productLogo} styling={"p2"} color={"coal"} boldness={"medium"}>
            {awardedProductName}
          </TypographyComponent>
        </div>
      )}
    </Collapse>
  );
};

export default SelectedProductBanner;
