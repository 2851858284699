import classNames from "classnames";
import { FreeTrialRole } from "gx-npm-lib";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../button";
import { Dialog } from "../../dialog";
import { TypographyComponent } from "../../typography/typography.component";
import styles from "./free-trial-info-modal.styles.module.scss";

export type FreeTrialInfoModalProps = {
  daysRemaining?: number;
  freeTrialRole?: FreeTrialRole | "";
  isOpen?: boolean;
  onClickClose: () => void;
};
export const FreeTrialInfoModalComponent = ({
  daysRemaining = 0,
  freeTrialRole = "",
  isOpen = false,
  onClickClose = () => {},
}: FreeTrialInfoModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      body={
        <Fragment>
          <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
            {t("We hope you have enjoyed exploring BuySmart.")}
          </TypographyComponent>
          {freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT && (
            <div>
              <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
                <TypographyComponent color="carbon" boldness="semi" element="span" styling="p2">
                  {t("Your free trial will end in")} {`${daysRemaining}`} {t("days")}.
                </TypographyComponent>
                {` `}
                {t(
                  "When your trial expires, any data in BuySmart will no longer be accessible to you or team members you have invited to an evaluation."
                )}
              </TypographyComponent>
              <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
                {t(
                  "To extend your trial or to inquire about purchasing a Gartner license, please contact your Gartner Account Executive."
                )}
              </TypographyComponent>
            </div>
          )}
          {freeTrialRole === FreeTrialRole.FREE_TRIAL_GUEST && (
            <div>
              <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
                <TypographyComponent boldness="semi" color="carbon" element="span" styling="p2">
                  {t("The free trial associated with this evaluation will end in")} {`${daysRemaining}`} {t("days")}.
                </TypographyComponent>
                {` `}
                {t("When the trial expires, this evaluation will no longer be accessible.")}
              </TypographyComponent>
              <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
                {t(
                  "The team member who created this evaluation can extend their trial or inquire about purchasing a Gartner license by contacting their Gartner Account Executive."
                )}
              </TypographyComponent>
            </div>
          )}
          <div className={styles.listContainer}>
            <TypographyComponent color="carbon" rootClassName={styles.sentenceBottomBreak} styling="p2">
              {t("Full access to BuySmart includes")}:
            </TypographyComponent>
            <ul className={styles.list}>
              <li>
                <TypographyComponent color="carbon" element="span" styling="p2">
                  {t("Creation of up to 100 evaluations")}
                </TypographyComponent>
              </li>
              <li>
                <TypographyComponent color="carbon" element="span" styling="p2">
                  {t("Export of requirements, questionnaires, scorecards, and more")}
                </TypographyComponent>
              </li>
              <li>
                <TypographyComponent color="carbon" element="span" styling="p2">
                  {t("Questionnaire functionality")}
                </TypographyComponent>
              </li>
            </ul>
          </div>
        </Fragment>
      }
      closeIcon={true}
      footer={
        <Button
          ariaLabel={t("close button")}
          onClick={onClickClose}
          rootClassName={classNames(styles.footerButton, "btn-primary")}
        >
          {t("GOT IT")}
        </Button>
      }
      onClose={onClickClose}
      open={isOpen}
      title={t("Gartner BuySmart free trial")}
    />
  );
};
