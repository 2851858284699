import { colorPalette } from "gx-npm-common-styles";

const inlineAlertStyles = {
  container: {
    borderRadius: 8,
    display: "flex",
    paddingRight: "16px",
    height: "48px",
    marginBottom: "12px",
    marginTop: "12px",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    "&.shadowed": {
      boxShadow: "5px 4px 15px rgba(0, 0, 0, 0.2)",
    },
    "&.warning": {
      color: colorPalette.status.darkLemon.hex,
      backgroundColor: colorPalette.status.lightLemon.hex,
    },
    "&.error": {
      color: colorPalette.status.poisonCherry.hex,
      backgroundColor: colorPalette.status.lightCherry.hex,
    },
    "&.success": {
      color: colorPalette.status.darkPear.hex,
      backgroundColor: colorPalette.status.lightPear.hex,
    },
    "&.info": {
      color: colorPalette.neutrals.coal.hex,
      backgroundColor: colorPalette.interactions.lightBerry.hex,
    },
    "& .mini-btn:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    padding: "0 10px 0 15px",
    marginTop: "3px",
  },
  flipIcon: {
    transform: "rotate(180deg)",
    marginBottom: "8px",
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
};

export { inlineAlertStyles };
