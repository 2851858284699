import { colorPalette } from "gx-npm-common-styles";

const gartnerFooterStyles = {
  footer: {
    bottom: 0,
    height: "128px",
    background: colorPalette.neutrals.quartz.hex,
    "@media (min-width:1024px) and (max-width:1239px)": {
      paddingLeft: 102,
      paddingRight: 25,
    },
    "@media (min-width:1240px) and (max-width:1588px)": {
      paddingLeft: 132,
      paddingRight: 52,
    },
    "@media (min-width:1589px)": {
      paddingLeft: "calc(132px + (100vw - 1589px) / 2)",
      paddingRight: "calc(52px + (100vw - 1589px) / 2)",
    },
  },
  footerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  linkGroup: {
    display: "flex",
    marginTop: "38px",
  },
  link: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: colorPalette.neutrals.slate.hex,
    textDecoration: "none",
    marginRight: "26px",
  },
  copyright: {
    marginTop: "20px",
    fontSize: "12px",
    color: colorPalette.neutrals.slate.hex,
  },
  copyrightText: {
    fontSize: "12px",
    color: colorPalette.neutrals.slate.hex,
  },
  appSection: {
    marginTop: "28px",
    height: "70px",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column" as const,
  },
  appTitle: {
    marginRight: "146px",
    color: colorPalette.brand.gartnerBlue.hex,
  },
  badgeContainer: {
    display: "flex",
    gap: "8px",
    paddingTop: "7px",
  },
};

export default gartnerFooterStyles;
