import { FreeTrialRole } from "../constants/enums";

export type UserState = {
  hasError: boolean;
  hasUserState: boolean;
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  isEntitled: boolean;
  isEntitledPR: boolean;
  hashedUserId: string;
  acquisitionSource: string;
  userHMAC: string;
  profileComplete: boolean;
  hasInitiatives: boolean;
  hasTasks: boolean;
  isGartnerAssociate: boolean;
  /** @deprecated - stale data issues, use APIs */
  profile: {
    companySize: string;
    industry: string;
    role: string;
    title: string;
    browsingReason: BrowsingReason;
  };
  freeTrialDaysRemaining: number;
  freeTrialRole: FreeTrialRole | "";
  isOnboarded: boolean;
};

export enum BrowsingReason {
  EMPTY = "",
  JUST_BROWSING = "JUST_BROWSING",
  READY_TO_START = "READY_TO_START",
}
