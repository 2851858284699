const actionDialogStyles = {
  root: {
    "& $body": {
      "& $bodyFirst": {
        marginBottom: 20,
      },
    },
    "& $footer": {
      display: "flex" as string as "flex",
      float: "right" as string as "right",
      "& $confirm": {
        marginLeft: 24,
      },
    },
  },
  body: {},
  bodyFirst: {},
  confirm: {},
  footer: {},
};

export default actionDialogStyles;
