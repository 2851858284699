import { BrowsingReason, UserState } from "./use-user-state.types";

export const STATE_SUBJECT = "USER_STATE";
export const DEFAULT_USER_STATE: UserState = Object.freeze({
  hasError: false,
  hasUserState: false,
  userId: -1,
  email: "",
  firstName: "",
  lastName: "",
  isEntitled: false,
  hashedUserId: "",
  acquisitionSource: "",
  userHMAC: "",
  profileComplete: false,
  hasInitiatives: false,
  hasTasks: false,
  isEntitledPR: false,
  isGartnerAssociate: false,
  profile: {
    companySize: "",
    industry: "",
    role: "",
    title: "",
    browsingReason: BrowsingReason.EMPTY,
  },
  freeTrialDaysRemaining: 0,
  freeTrialRole: "",
  isOnboarded: true,
});
