import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ToggleIconButton } from "./index";

const propTypes = {
  data: PropTypes.array,
  selected: PropTypes.number,
  onItemClicked: PropTypes.func,
};

const defaultStyles = {
  iconGroup: {
    display: "flex",
    "&& button": {
      borderLeft: `1px solid ${colorPalette.neutrals.silver.hex}`,
      borderTop: `1px solid ${colorPalette.neutrals.silver.hex}`,
      borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
      padding: 0,
    },
    "& button:last-child": {
      borderRight: `1px solid ${colorPalette.neutrals.silver.hex}`,
    },
  },
};

const useStyles = makeStyles(() => defaultStyles);

const ToggleIconButtonGroup = ({ selected, data, onItemClicked }) => {
  const [activeItem, setActiveItem] = useState(0);
  const rootClasses = useStyles();

  useEffect(() => {
    if (selected) {
      setActiveItem(selected);
    }
  }, [selected]);

  const handleClick = (e, index) => {
    setActiveItem(index);
    onItemClicked(index);
  };

  return (
    <Box className={rootClasses.iconGroup}>
      {data.map((buttondata, index) => {
        return (
          <ToggleIconButton
            onClick={(event) => handleClick(event, index)}
            key={buttondata.ariaLabel}
            ariaLabel={buttondata.ariaLabel}
            isToggledOn={index === activeItem}
            isPartOfGroup={true}
            backgroundColor={buttondata.backgroundColor}
            disabled={buttondata.disabled}
            fillColor={buttondata.fillColor}
            hoverBackgroundColor={buttondata.hoverBackgroundColor}
            hoverFillColor={buttondata.hoverFillColor}
            rippleBackgroundColor={buttondata.rippleBackgroundColor}
            className={buttondata.className}
            density={buttondata.density}
          >
            {buttondata.icon}
          </ToggleIconButton>
        );
      })}
    </Box>
  );
};

ToggleIconButtonGroup.propTypes = propTypes;
export default ToggleIconButtonGroup;
