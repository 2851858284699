import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ClockIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "18",
  title = "",
  viewBox = "0 0 18 18",
  width = "18",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02942 13.9706 0 9 0C4.02942 0 0 4.02942 0 9C0 13.9706 4.02942 18 9 18ZM8 4C8 3.44775 8.44769 3 9 3C9.55231 3 10 3.44775 10 4V8H13C13.5523 8 14 8.44775 14 9C14 9.55225 13.5523 10 13 10H9C8.44769 10 8 9.55225 8 9V4Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
