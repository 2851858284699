import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const CustomerServiceIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M19.1098 4H12.8785C12.3889 4 11.9883 4.40059 11.9883 4.89019V12.9019L14.6589 10.2313H19.1098C19.5994 10.2313 20 9.83075 20 9.34115V4.89019C20 4.40059 19.5994 4 19.1098 4ZM18.4244 14.9137L16.1633 14.6556C15.6203 14.5933 15.0861 14.7802 14.7034 15.163L13.0654 16.8009C10.5462 15.5191 8.48093 13.4627 7.19906 10.9346L8.84591 9.28773C9.22869 8.90495 9.41563 8.37084 9.35332 7.82782L9.09516 5.58454C8.98834 4.68545 8.23168 4.0089 7.32368 4.0089H5.78365C4.77774 4.0089 3.94096 4.84568 4.00327 5.8516C4.47507 13.4538 10.5551 19.5249 18.1484 19.9967C19.1543 20.059 19.9911 19.2223 19.9911 18.2163V16.6763C20 15.7772 19.3235 15.0206 18.4244 14.9137Z"
        fill={fillPath}
      />
    </svg>
  );
};
