/**
 * safely call a function with an event passed in as args
 * @param {*} handler callback handle being checked to make sure it is a function
 * @param {*} event event that needs to be handled
 * @param {*} args array of additional arguments to be handled by callback
 * @deprecated - see gx-npm-lib README.md for more information
 */
const handleEvent = (handler = null, event = null, ...args) => {
  if (typeof handler === "function") {
    if (args) {
      handler(event, ...args);
    } else {
      handler(event);
    }
  }
};

export default handleEvent;
