import { InitProdState, InitState, InitStatus, InitUserRole, UUID } from "gx-npm-lib";
import { createContext, ReactElement, useContext, useState } from "react";
import { TabNames } from "../app.constants";
import { WorkspaceHeaderContextValue } from "../app.types";

const WorkspaceHeaderContext = createContext<WorkspaceHeaderContextValue>({} as WorkspaceHeaderContextValue);

const useWorkspaceHeaderContext = () => {
  return useContext(WorkspaceHeaderContext);
};

const WorkspaceHeaderContextProvider = (props: { children: ReactElement | Array<ReactElement> }) => {
  const [awardedProductImageLoc, setAwardedProductImageLoc] = useState<string>("");
  const [awardedProductName, setAwardedProductName] = useState<string>("");
  const [initId, setInitId] = useState<UUID>("");
  const [initName, setInitName] = useState<string>("");
  const [initProdId, setInitProdId] = useState<UUID>("");
  const [initState, setInitState] = useState<InitState>(InitState.ACTIVE);
  const [initStatus, setInitStatus] = useState<InitStatus>(InitStatus.PLANNING);
  const [initStateUpdateDate, setInitStateUpdateDate] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productImageLoc, setProductImageLoc] = useState<string>("");
  const [productState, setProductState] = useState<InitProdState>(InitProdState.LISTED);
  const [savingCount, setSavingCount] = useState<number>(0);
  const [surveyId, setSurveyId] = useState<UUID>("");
  const [userRole, setUserRole] = useState<InitUserRole | "">("");
  const [isSurveySubmitted, setIsSurveySubmitted] = useState<boolean>(false);
  const [hasRequestedDocuments, setHasRequestedDocuments] = useState<boolean>(false);
  const [isSurveyLegalDismissed, setIsSurveyLegalDismissed] = useState<boolean>(false);
  /* todo 'selectedTab' this does not need to be in context (only ever used in the component it is set) */
  const [selectedTab, setSelectedTab] = useState<TabNames | "">("");

  const contextValue = {
    selectedTab,
    awardedProductImageLoc,
    awardedProductName,
    initId,
    initName,
    initState,
    initStateUpdateDate,
    initStatus,
    initProdId,
    productName,
    productImageLoc,
    productState,
    savingCount,
    surveyId,
    userRole,
    isSurveySubmitted,
    hasRequestedDocuments,
    isSurveyLegalDismissed,
    setSelectedTab,
    setAwardedProductImageLoc,
    setAwardedProductName,
    setInitId,
    setInitName,
    setInitProdId,
    setInitState,
    setInitStateUpdateDate,
    setInitStatus,
    setProductName,
    setProductImageLoc,
    setProductState,
    setSavingCount,
    setSurveyId,
    setUserRole,
    setIsSurveySubmitted,
    setHasRequestedDocuments,
    setIsSurveyLegalDismissed,
  };

  return <WorkspaceHeaderContext.Provider value={contextValue}>{props.children}</WorkspaceHeaderContext.Provider>;
};

export { WorkspaceHeaderContext, WorkspaceHeaderContextProvider, useWorkspaceHeaderContext };
