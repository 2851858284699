import { useWebSocket } from "../useWebSocket";
import { CaptureEventDto } from "./use-capture-events-V2.types";
import { useCallback } from "react";

export function useCaptureEventsV2(): (events: CaptureEventDto[]) => void {
  const [_message, publish] = useWebSocket();
  return useCallback(
    (events: CaptureEventDto[]) => {
      publish({
        action: "message",
        data: { event: "CLIENT_EVENTS", events },
      });
    },
    [publish]
  );
}
