import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const PartnerIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "12",
  title = "",
  viewBox = "0 0 18 12",
  width = "18",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M12.2727 5.14286C13.6309 5.14286 14.7191 3.99429 14.7191 2.57143C14.7191 1.14857 13.6309 0 12.2727 0C10.9145 0 9.81818 1.14857 9.81818 2.57143C9.81818 3.99429 10.9145 5.14286 12.2727 5.14286ZM5.72727 5.14286C7.08545 5.14286 8.17364 3.99429 8.17364 2.57143C8.17364 1.14857 7.08545 0 5.72727 0C4.36909 0 3.27273 1.14857 3.27273 2.57143C3.27273 3.99429 4.36909 5.14286 5.72727 5.14286ZM5.72727 6.85714C3.82091 6.85714 0 7.86 0 9.85714V11.1429C0 11.6143 0.368182 12 0.818182 12H10.6364C11.0864 12 11.4545 11.6143 11.4545 11.1429V9.85714C11.4545 7.86 7.63364 6.85714 5.72727 6.85714ZM12.2727 6.85714C12.0355 6.85714 11.7655 6.87429 11.4791 6.9C11.4955 6.90857 11.5036 6.92571 11.5118 6.93429C12.4445 7.64571 13.0909 8.59714 13.0909 9.85714V11.1429C13.0909 11.4429 13.0336 11.7343 12.9436 12H17.1818C17.6318 12 18 11.6143 18 11.1429V9.85714C18 7.86 14.1791 6.85714 12.2727 6.85714Z"
        fill={fillPath}
      />
    </svg>
  );
};
