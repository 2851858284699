// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xArVnsURCwXYYh2FkXu3.JWLBopuW3Fhba1QlgUh3 .BhaicJv2HSVRJnsYJPkD{height:0}.xArVnsURCwXYYh2FkXu3 .fUKr5ca5AIz_JvstNdcD{background:var(--color-white)}.xArVnsURCwXYYh2FkXu3 .BhaicJv2HSVRJnsYJPkD{height:48px;min-height:initial;transition:all .5s ease-in-out}.xArVnsURCwXYYh2FkXu3 .kF1CoONWKdH89_AbYL4C{background-color:var(--color-interactions-default-cta);height:4px}.xArVnsURCwXYYh2FkXu3 .O426B8HctcmQ890zE_s0{display:block;flex-basis:initial;flex-grow:initial;min-width:auto;padding:11px 34px}.xArVnsURCwXYYh2FkXu3 .O426B8HctcmQ890zE_s0:hover{background:var(--color-interactions-light-berry);border-radius:8px 8px 0 0}`, "",{"version":3,"sources":["webpack://./src/sections/tab-menu-v2/tab-menu-v2.styles.module.scss"],"names":[],"mappings":"AACE,iEACE,QAAA,CAGF,4CACE,6BAAA,CAGF,4CACE,WAAA,CACA,kBAAA,CACA,8BAAA,CAGF,4CACE,sDAAA,CACA,UAAA,CAGF,4CACE,aAAA,CACA,kBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CAEA,kDACE,gDAAA,CACA,yBAAA","sourcesContent":[".tabMenuSectionRoot {\n  &.scrolling .muiTabsRootClass {\n    height: 0;\n  }\n\n  & .muiAppBarRootClass {\n    background: var(--color-white);\n  }\n\n  & .muiTabsRootClass {\n    height: 48px;\n    min-height: initial;\n    transition: all 0.5s ease-in-out;\n  }\n\n  & .muiTabsIndicatorClass {\n    background-color: var(--color-interactions-default-cta);\n    height: 4px;\n  }\n\n  & .muiTabRootClass {\n    display: block;\n    flex-basis: initial;\n    flex-grow: initial;\n    min-width: auto;\n    padding: 11px 34px;\n\n    &:hover {\n      background: var(--color-interactions-light-berry);\n      border-radius: 8px 8px 0 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabMenuSectionRoot": `xArVnsURCwXYYh2FkXu3`,
	"scrolling": `JWLBopuW3Fhba1QlgUh3`,
	"muiTabsRootClass": `BhaicJv2HSVRJnsYJPkD`,
	"muiAppBarRootClass": `fUKr5ca5AIz_JvstNdcD`,
	"muiTabsIndicatorClass": `kF1CoONWKdH89_AbYL4C`,
	"muiTabRootClass": `O426B8HctcmQ890zE_s0`
};
export default ___CSS_LOADER_EXPORT___;
