import React, { useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { findClassesEventPath, handleEvent } from "gx-npm-lib";
import MenuItem from "./menu-item.component";
import { optionListStyles as styles } from "./styles";
import { alignmentTypes, variantTypes } from "./types";

const useOutsideAlerter = (ref, onClick, containerClass = "") => {
  const handleClickOutside = useCallback(
    (event) => {
      if (ref?.current?.contains && !ref.current.contains(event?.target)) {
        if (!containerClass || !findClassesEventPath(event, [containerClass])) {
          handleEvent(onClick, event);
        }
      }
    },
    [containerClass, onClick, ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleClickOutside]);
};

const propTypes = {
  containerClass: PropTypes.string,
  isBehindAppBar: PropTypes.bool,
  listFooter: PropTypes.node,
  listHeader: PropTypes.node,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      index: PropTypes.number,
      link: PropTypes.string,
      menuListIcon: PropTypes.node,
      name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      rootClassName: PropTypes.string,
      showTooltip: PropTypes.bool,
      tooltipOptions: PropTypes.object,
    })
  ),
  onNonOptionsClick: PropTypes.func,
  onOptionsClick: PropTypes.func,
  setRef: PropTypes.func,
  alignmentType: PropTypes.oneOf(Object.values(alignmentTypes)),
  variant: PropTypes.oneOf(Object.values(variantTypes)),
  listItemClassName: PropTypes.string,
  popoverMenuClass: PropTypes.string,
  isDynamicWidth: PropTypes.bool,
};
const useStyles = makeStyles(() => styles);
const MenuOptionList = ({
  containerClass = "",
  isBehindAppBar = false,
  listFooter = null,
  listHeader = null,
  menuItems = [],
  onNonOptionsClick = null,
  onOptionsClick = null,
  setRef,
  alignmentType = alignmentTypes.default,
  variant = null,
  listItemClassName = "",
  popoverMenuClass = "",
  isDynamicWidth = true,
}) => {
  const classes = useStyles();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onNonOptionsClick, containerClass);
  return (
    <div className={"gx-popover-menu"} ref={setRef}>
      <div
        className={classNames(
          popoverMenuClass,
          "gx-popover-menu-list",
          classes.optionsList,

          isDynamicWidth && "gx-menu-list-auto-width",
          isBehindAppBar && classes.shallowOptionsList,
          alignmentType === alignmentTypes.leftBottom && "gx-menu-list-aligned-left-bottom",
          alignmentType === alignmentTypes.rightBottom && "gx-menu-list-aligned-right-bottom",
          alignmentType === alignmentTypes.left && "gx-menu-list-aligned-left",
          variant === variantTypes.select && "gx-menu-list-select",
          "menu-box-rounded"
        )}
        data-testid={"popover-menu-list"}
        ref={wrapperRef}
      >
        {listHeader}
        <ul>
          {Array.isArray(menuItems) &&
            menuItems.map((item, index) => {
              return (
                <MenuItem
                  disabled={item?.disabled}
                  index={item?.index}
                  key={index}
                  link={item?.link}
                  menuListIcon={item?.menuListIcon}
                  name={item?.name}
                  onClick={onOptionsClick}
                  rootClassName={item?.rootClassName}
                  showTooltip={item?.showTooltip}
                  tooltipOptions={item?.tooltipOptions}
                  listItemClassName={listItemClassName}
                  isDynamicWidth={isDynamicWidth}
                />
              );
            })}
        </ul>
        {listFooter}
      </div>
    </div>
  );
};

MenuOptionList.propTypes = propTypes;
export { MenuOptionList };
