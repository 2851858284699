import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkCircledIcon, ExclamationCircledIcon, ExclamationTriangledIcon, MultiplyIcon } from "gx-npm-icons";
import { bannerClasses } from "./styles";
import { types } from "./types";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../../featureFlags";
import { GCOM_3606__fontUpdate } from "../../feature-flags";
import { TypographyComponent } from "../../typography/typography.component";

const getIcon = (type = types.error) => {
  let icon = <ExclamationCircledIcon />;
  if (type === types.error) {
    icon = <ExclamationCircledIcon title="exclamation" />;
  } else if (type === types.warning) {
    icon = <ExclamationTriangledIcon title="warning" />;
  } else if (type === types.success) {
    icon = <CheckmarkCircledIcon title="success" />;
  } else if (type === types.miniSuccess) {
    icon = <CheckmarkCircledIcon fillPath={colorPalette.status.darkPear.hex} title="success" />;
  }
  return icon;
};

const propTypes = {
  buttonText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  handleButtonClick: PropTypes.func,
  handleCloseClick: PropTypes.func,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  pointerActionCursor: PropTypes.bool,
  type: PropTypes.string,
};
const useStyles = makeStyles(() => bannerClasses);

/**
 * banner styled per design to for snackbar messages
 * @unsupported - adding a button will currently break component
 * @unsupported - varying width sizes - set to be 720px, which will be min width
 * @param {*} props.buttonText -button text to be displayed
 * @param {*} props.handleButtonClick - action to happen when button is present and clicked
 * @param {*} props.handleCloseClick - action to happen when close icon is clicked
 * @param {*} props.message - text to display in banner
 * @param {*} props.pointerActionCursor - show a pointer on the action button for cursor
 * @param {*} props.type - specific type of banner to show
 * @returns styled banner based on type and texted passed in by props
 */
const Banner = ({
  buttonText = "",
  handleButtonClick = null,
  handleCloseClick = null,
  message = "",
  pointerActionCursor = false,
  type = types.error,
}) => {
  const hasButton = !!buttonText && !!handleButtonClick;
  const classes = useStyles();

  const getClassName = (bannerType) => {
    let className = classes.errorContainer;

    if (bannerType === types.error) {
      className = classes.errorContainer;
    } else if (bannerType === types.miniSuccess) {
      className = classNames(classes.successContainer, "mini");
    } else if (bannerType === types.success) {
      className = classes.successContainer;
    } else if (bannerType === types.warning) {
      className = classes.warningContainer;
    }

    return className;
  };

  return (
    <div className={classNames(classes.container, hasButton && classes.containerWithBtn, getClassName(type))}>
      <div className={classes.iconContainer}>{getIcon(type)}</div>
      <div className={classes.messageContainer}>
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent styling={"p2"} boldness={"medium"} color={"inherit"}>
              {message}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>{message}</FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      </div>
      {hasButton && (
        <div
          className={classNames(classes.actionButton, pointerActionCursor && classes.actionCursor)}
          onClick={(e) => handleButtonClick(e)}
          role="button"
          tabIndex={0}
          onKeyUp={(e) => handleButtonClick(e)}
        >
          {buttonText}
        </div>
      )}
      {type !== types.success && type !== types.miniSuccess && (
        <div
          className={classes.closeButton}
          onClick={(e) => handleCloseClick(e)}
          role="button"
          tabIndex={0}
          onKeyUp={(e) => handleCloseClick(e)}
          aria-label={"close"}
        >
          <MultiplyIcon />
        </div>
      )}
    </div>
  );
};

Banner.propTypes = propTypes;
export default Banner;
