// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QZqYzqy8IQfNJ5dtnfHg{display:flex;justify-content:flex-end}.QZqYzqy8IQfNJ5dtnfHg .t4yb4Xw8eRe1ZD4ZwFI8{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/weighted-priority-drawer/confirm-cancel-dialog/confirm-cancel-dialog.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".buttonWrapper {\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `QZqYzqy8IQfNJ5dtnfHg`,
	"cancelButton": `t4yb4Xw8eRe1ZD4ZwFI8`
};
export default ___CSS_LOADER_EXPORT___;
