import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  CheckmarkCircledIcon,
  CloseIcon,
  ExclamationCircledIcon,
  ExclamationTriangledIcon,
  RevertIcon,
} from "gx-npm-icons";
import { MiniButton } from "../miniButton";
import { inlineAlertStyles as styles } from "./styles";
import { IconButton } from "../iconButton";
import { Collapse } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { handleEvent } from "gx-npm-lib";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { TypographyComponent } from "../typography/typography.component";

const alertButtonTypes = {
  CLOSE: "close",
  DISMISS: "dismiss",
  REVERT: "revert",
  NONE: "",
};

const alertLevels = {
  WARNING: "warning",
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
};

const propTypes = {
  buttonType: PropTypes.oneOf(Object.values(alertButtonTypes)),
  alertType: PropTypes.oneOf(Object.values(alertLevels)),
  isShadowed: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  onButtonClick: PropTypes.func,
  textMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const useStyles = makeStyles(() => styles);
const InlineAlert = ({
  buttonType = alertButtonTypes.NONE,
  alertType = alertLevels.INFO,
  isShadowed = true,
  isViewOnly = false,
  onButtonClick = () => {},
  textMessage = "",
}) => {
  const classes = useStyles();
  const [collapseMessage, setCollapseMessage] = React.useState(false);

  function handleClose() {
    setCollapseMessage(true);
    handleEvent(onButtonClick);
  }

  function handleClick() {
    handleEvent(onButtonClick);
  }

  const renderButton = () => {
    switch (buttonType) {
      case alertButtonTypes.CLOSE:
        return (
          <IconButton
            ariaLabel="close"
            fillColor={colorPalette.neutrals.iron.hex}
            hoverBackgroundColor="transparent"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        );
      case alertButtonTypes.DISMISS:
        return (
          <MiniButton ariaLabel="dismiss" onClick={handleClose}>
            Dismiss
          </MiniButton>
        );
      case alertButtonTypes.REVERT:
        return (
          <MiniButton ariaLabel="revert" onClick={handleClick} disabled={isViewOnly}>
            <RevertIcon />
            Revert all changes
          </MiniButton>
        );
      default:
        return null;
    }
  };

  const renderIcon = () => {
    switch (alertType) {
      case alertLevels.WARNING:
        return <ExclamationTriangledIcon title={alertLevels.WARNING} fillPath={colorPalette.status.darkLemon.hex} />;
      case alertLevels.ERROR:
        return <ExclamationCircledIcon title={alertLevels.ERROR} fillPath={colorPalette.status.poisonCherry.hex} />;
      case alertLevels.SUCCESS:
        return <CheckmarkCircledIcon title={alertLevels.SUCCESS} fillPath={colorPalette.status.darkPear.hex} />;
      case alertLevels.INFO:
        return (
          <div className={classes.flipIcon}>
            <ExclamationCircledIcon title={alertLevels.INFO} fillPath={colorPalette.neutrals.coal.hex} />
          </div>
        );
    }
  };

  return (
    <Collapse in={!collapseMessage}>
      <div className={classNames("inline-alert", alertType, classes.container, isShadowed && "shadowed")}>
        <div className={classes.message}>
          <div className={classes.icon}>{renderIcon()}</div>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent boldness={"medium"} element={"p"}>
                {textMessage}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p>{textMessage}</p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
        <div>{renderButton()}</div>
      </div>
    </Collapse>
  );
};

InlineAlert.propTypes = propTypes;
export { InlineAlert, alertButtonTypes, alertLevels };
