export enum DrawerCloseAction {
  SAVE_CHANGES = "SAVE_CHANGES",
  DISCARD_CHANGES = "DISCARD_CHANGES",
}

export enum ClientEvent {
  MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED = "MARKET_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED",
  INITIATIVE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED = "INITIATIVE_POWERED_BY_CRITICAL_CAPABILITIES_SLIDE_IN_LINK_CLICKED",
}

export enum WeightedDrawerLocation {
  INITIATIVE_VENDOR_TAB = "INITIATIVE_VENDOR_TAB",
  MARKET_PROFILE = "MARKET_PROFILE",
}
