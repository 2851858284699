import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const IdCardIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "13",
  title = "",
  viewBox = "0 0 18 13",
  width = "18",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M1.8 0H16.2C17.19 0 18 0.73125 18 1.625V11.375C18 12.2688 17.19 13 16.2 13H1.8C0.81 13 0 12.2688 0 11.375V1.625C0 0.73125 0.81 0 1.8 0ZM7 4.5C7 3.67333 6.32667 3 5.5 3C4.67333 3 4 3.67333 4 4.5C4 5.32667 4.67333 6 5.5 6C6.32667 6 7 5.32667 7 4.5ZM9 9V8.66667C9 7.55333 6.669 7 5.5 7C4.331 7 2 7.55333 2 8.66667V9H9ZM10.5 4C10.2239 4 10 4.22386 10 4.5C10 4.77614 10.2239 5 10.5 5H15.5C15.7761 5 16 4.77614 16 4.5C16 4.22386 15.7761 4 15.5 4H10.5ZM10 6.5C10 6.22386 10.2239 6 10.5 6H13.5C13.7761 6 14 6.22386 14 6.5C14 6.77614 13.7761 7 13.5 7H10.5C10.2239 7 10 6.77614 10 6.5Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
