import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { forwardRef, ReactNode } from "react";
import { BoldTypes, ParagraphTypes } from "../types";
import styles from "./paragraph.styles";

type ParagraphProps = {
  ariaLabel?: string;
  boldness?: BoldTypes;
  children: Array<ReactNode> | ReactNode | number | string;
  rootClassName?: string;
  type?: ParagraphTypes;
};

const useStyles = makeStyles(() => styles);
/** @deprecated use TypographyComponent instead (change "type" prop to be "styling") */
export const Paragraph = forwardRef<HTMLParagraphElement, ParagraphProps>(
  ({ ariaLabel = "", boldness = "regular", children = null, rootClassName = "", type = "p3" }: ParagraphProps, ref) => {
    const classes = useStyles();
    return (
      <p
        aria-label={ariaLabel || undefined}
        className={classNames("gx-p", classes.root, classes[boldness], classes[type], rootClassName)}
        ref={ref}
      >
        {children}
      </p>
    );
  }
);

Paragraph.displayName = "Paragraph";
