import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const hoverCta = colorPalette.interactions.hoverCta.hex;
const lightBerry = colorPalette.interactions.lightBerry.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const InfoIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlns}>
      <g clipPath="url(#clip0_4147_163636)">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_4147_163636">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const InfoLightIcon = ({
  fillPath = defaultColor,
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.5C12.4971 7.5 12.9 7.90294 12.9 8.4C12.9 8.89706 12.4971 9.3 12 9.3C11.5029 9.3 11.1 8.89706 11.1 8.4C11.1 7.90294 11.5029 7.5 12 7.5Z"
        fill={fillPath}
      />
      <path
        d="M12 16.5C11.505 16.5 11.1 16.095 11.1 15.6V12C11.1 11.505 11.505 11.1 12 11.1C12.495 11.1 12.9 11.505 12.9 12V15.6C12.9 16.095 12.495 16.5 12 16.5Z"
        fill={fillPath}
      />
      <path
        d="M12 3C7.032 3 3 7.032 3 12C3 16.968 7.032 21 12 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 12 3ZM4.8 12C4.8 8.02611 8.02611 4.8 12 4.8C15.9739 4.8 19.2 8.02611 19.2 12C19.2 15.9739 15.9739 19.2 12 19.2C8.02611 19.2 4.8 15.9739 4.8 12Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const InfoHoverIcon = ({
  width = "32",
  height = "32",
  viewBox = "0 0 32 32",
  fill = "none",
  fillPath = hoverCta,
  fillColor = lightBerry,
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns}>
      <circle cx="16" cy="16" r="16" fill={fillColor} />
      <path
        d="M16 11.5C16.4971 11.5 16.9 11.9029 16.9 12.4C16.9 12.8971 16.4971 13.3 16 13.3C15.5029 13.3 15.1 12.8971 15.1 12.4C15.1 11.9029 15.5029 11.5 16 11.5Z"
        fill={fillPath}
      />
      <path
        d="M16 20.5C15.505 20.5 15.1 20.095 15.1 19.6V16C15.1 15.505 15.505 15.1 16 15.1C16.495 15.1 16.9 15.505 16.9 16V19.6C16.9 20.095 16.495 20.5 16 20.5Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7C11.032 7 7 11.032 7 16C7 20.968 11.032 25 16 25C20.968 25 25 20.968 25 16C25 11.032 20.968 7 16 7ZM8.8 16C8.8 12.0261 12.0261 8.8 16 8.8C19.9739 8.8 23.2 12.0261 23.2 16C23.2 19.9739 19.9739 23.2 16 23.2C12.0261 23.2 8.8 19.9739 8.8 16Z"
        fill={fillPath}
      />
    </svg>
  );
};
