import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { Divider, InputBase, makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { ButtonLoader } from "../button-loader/button-loader.component";
import { PopoverMenu } from "../popoverMenu/popover-menu.component";
import { iconTypes } from "../popoverMenu/types";
import { textFieldDropdownStyles as styles } from "./styles";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "../featureFlags";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { TypographyComponent } from "../typography/typography.component";

const supportedIcons = (({ [iconTypes.kebab]: _, ...o }) => o)(iconTypes);

const propTypes = {
  ariaLabel: PropType.string,
  autoFocus: PropType.bool,
  autoWidth: PropType.bool,
  disabledBtn: PropType.bool,
  iconType: PropType.oneOf(Object.values(supportedIcons)),
  isError: PropType.bool,
  isLoading: PropType.bool,
  onClickButton: PropType.func,
  onSelectDropdown: PropType.func,
  onTextBlur: PropType.func,
  onTextUpdate: PropType.func,
  selectItems: PropType.arrayOf(PropType.object),
  textButton: PropType.oneOfType([PropType.node, PropType.string]),
  textLowerHelper: PropType.oneOfType([PropType.node, PropType.string]),
  textPlaceholder: PropType.oneOfType([PropType.node, PropType.string]),
  valueSelect: PropType.number,
  valueText: PropType.string,
};
const useStyles = makeStyles(() => styles);

/**
 * @param {{
 *   ariaLabel: string,
 *   autoFocus: bool,
 *   disabledBtn: bool,
 *   iconType: bool,
 *   isError: bool,
 *   isLoading: bool,
 *   onClickButton: Function,
 *   onSelectDropdown: Function,
 *   onTextBlur: Function,
 *   onTextUpdate: Function,
 *   selectItems: Array,
 *   textButton: node | string,
 *   textLowerHelper: node | string,
 *   textPlaceholder: node | string,
 *   valueSelect: number,
 *   valueText: string,
 * }} props
 * @var ariaLabel a11y label for text field
 * @var autoFocus focus bool
 * @var disabledBtn flag to disable button
 * @var iconType icon to use for drop down menu
 * @var isError flag to indicate error state
 * @var isLoading flag to indicate loading
 * @var onClickButton callback when button clicked
 * @var onSelectDropdown callback when dropdown is selected
 * @var onTextBlur callback when text is unfocused
 * @var onTextUpdate callback when text is updated
 * @var selectItems array of dropdown items
 * @var textButton display for button text
 * @var textLowerHelper display to show under text input field
 * @var textPlaceholder placeholder input field text
 * @var valueSelect index of dropdown item
 * @var valueText input field value
 * @returns input field with a combo box and confirm button
 */
const TextFieldWithSelectCombo = ({
  ariaLabel = "input field",
  autoWidth = false,
  autoFocus = false,
  disabledBtn = true,
  iconType = iconTypes.arrow,
  isError = false,
  isLoading = false,
  onClickButton = null,
  onSelectDropdown = null,
  onTextBlur = null,
  onTextUpdate = null,
  selectItems = null,
  textButton = "",
  textLowerHelper = "",
  textPlaceholder = "",
  valueSelect = -1,
  valueText = "",
}) => {
  const classes = useStyles();
  const [isTextFocused, setIsTextFocused] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [select, setSelect] = useState(-1);
  const [text, setText] = useState("");
  const isFontUpdateFFOn = useFeatureFlag(GCOM_3606__fontUpdate);

  useEffect(() => {
    setSelect(valueSelect);
  }, [valueSelect]);

  useEffect(() => {
    setText(valueText);
  }, [valueText]);

  const handleClickBtn = () => {
    handleEvent(onClickButton);
  };

  const handleSelectDropdown = (val) => {
    setSelect(val);
    handleEvent(onSelectDropdown, val);
  };

  const handleSelectOpen = (isOpen) => {
    setIsPopoverOpen(isOpen);
  };

  const handleTyping = (event) => {
    setText(event.target.value);
    handleEvent(onTextUpdate, event.target.value);
  };

  const handleTypingFinish = (event) => {
    setText(event.target.value);
    handleEvent(onTextUpdate, event.target.value);
    handleEvent(onTextBlur, event.target.value);
    setIsTextFocused(false);
  };

  const handleTypingStart = (_event) => {
    setIsTextFocused(true);
  };

  const isHighlighted = () => {
    return isTextFocused || isPopoverOpen;
  };
  return (
    <Fragment>
      <div className={classNames(classes.container, autoWidth && "autoWidth")}>
        <div
          className={classNames(
            classes.topRoot,
            isHighlighted() && "highlighted",
            isError && "error",
            text && "filled",
            autoWidth && "autoWidth",
            "text-field-rounded"
          )}
        >
          {textPlaceholder && (
            <label
              className={classNames(
                classes.labelText,
                (isHighlighted() || text) && "lifted",
                isError && "error",
                isFontUpdateFFOn && classes.fontUpdate
              )}
            >
              {textPlaceholder}
            </label>
          )}
          <InputBase
            autoFocus={autoFocus}
            className={classNames(classes.input, isFontUpdateFFOn && classes.fontUpdate)}
            inputProps={{ "aria-label": ariaLabel }}
            onBlur={handleTypingFinish}
            onChange={handleTyping}
            onFocus={handleTypingStart}
            value={text}
          />
          <Divider className={classNames(classes.divider)} orientation="vertical" />
          <PopoverMenu
            handleOpenStatus={handleSelectOpen}
            iconType={iconType}
            menuItems={selectItems}
            onClick={handleSelectDropdown}
            selectedIndex={select}
            useIconButton={true}
            iconButtonBorderRadius={25}
          />
        </div>
        <ButtonLoader
          btnClass="btn-primary"
          disabled={disabledBtn}
          handleButtonClick={handleClickBtn}
          isLoading={isLoading}
        >
          {textButton}
        </ButtonLoader>
      </div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent
            rootClassName={classNames(classes.infoText, "info-text", isError && "error")}
            styling={"p4"}
            color={"coal"}
          >
            {textLowerHelper}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <p className={classNames("p4", classes.infoText, "info-text", isError && "error")}>{textLowerHelper}</p>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>
    </Fragment>
  );
};

TextFieldWithSelectCombo.propTypes = propTypes;
export default TextFieldWithSelectCombo;
