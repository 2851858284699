import { SnackbarBanner } from "gx-npm-ui";
import { useSnackbarContext } from "../../context/snack-bar-banner/snack-bar.context";

const Snackbar = () => {
  const { snackbarIsError, setShowSnackBar, showSnackBar, snackBarMessage } = useSnackbarContext();

  const errorProps = {
    isDefaultErrorMessage: true,
  };

  const successProps = {
    autoHideDuration: 3000,
    type: "SUCCESS",
  };

  return (
    <SnackbarBanner
      isOpen={showSnackBar}
      message={snackBarMessage}
      setIsOpen={() => setShowSnackBar(false)}
      {...(snackbarIsError ? errorProps : successProps)}
    />
  );
};

export default Snackbar;
