import { InitUserRole, useUserInitAccess } from "gx-npm-lib";
import { useEffect, useState } from "react";
import { WorkspaceHeaderContextValue, WorkspaceHeaderPayload } from "../../app.types";
import { useWorkspaceHeaderContext } from "../workspace-header.context";
import { loadWorkspaceHeaderData } from "./workspace-header-load.lib";

export const handleLoadWorkspaceHeaderData = (
  payload: WorkspaceHeaderPayload,
  workspaceHeaderContext: WorkspaceHeaderContextValue
) => {
  const {
    setAwardedProductImageLoc,
    setAwardedProductName,
    setInitName,
    setInitState,
    setInitStateUpdateDate,
    setInitStatus,
    setProductName,
    setProductImageLoc,
    setProductState,
    setSurveyId,
    setIsSurveySubmitted,
    setHasRequestedDocuments,
    setIsSurveyLegalDismissed,
  } = workspaceHeaderContext;

  if (payload.awardedProduct) {
    setAwardedProductImageLoc(payload.awardedProduct.imageLoc);
    setAwardedProductName(payload.awardedProduct.name);
  }
  setInitName(payload.initName);
  setInitState(payload.initState);
  setInitStateUpdateDate(payload.initStateUpdateDate);
  setInitStatus(payload.initStatus);
  setProductName(payload.productName);
  setProductImageLoc(payload.productImageLoc);
  setProductState(payload.productState);
  setSurveyId(payload.surveyId);
  setIsSurveySubmitted(payload.isSurveySubmitted);
  setHasRequestedDocuments(payload.hasRequestedDocuments);
  setIsSurveyLegalDismissed(payload.isSurveyLegalDismissed);
};

const useWorkspaceHeaderLoad = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRole, setIsLoadingRole] = useState(true);
  const [isLoadingHeader, setIsLoadingHeader] = useState(true);
  const workspaceHeaderContext = useWorkspaceHeaderContext();
  const { initId, initProdId, setUserRole } = workspaceHeaderContext;
  const { hasLoadedAccess, role } = useUserInitAccess(initId);

  useEffect(() => {
    if (!initId) {
      return;
    }
    const loadData = async () => {
      await loadWorkspaceHeaderData(
        { initId, initProdId },
        (data: WorkspaceHeaderPayload) => {
          handleLoadWorkspaceHeaderData(data, workspaceHeaderContext);
        },
        () => setHasError(true)
      );
    };
    setIsLoadingHeader(true);
    loadData().finally(() => setIsLoadingHeader(false));
    // the way this is written, by including workspaceHeaderContext in the dep array,
    // multiple calls end up firing on first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initId, initProdId]);

  useEffect(() => {
    if (!hasLoadedAccess) {
      setIsLoadingRole(true);
      return;
    }
    setIsLoadingRole(false);
    setUserRole(role as InitUserRole);
  }, [hasLoadedAccess, role, setUserRole]);

  useEffect(() => {
    setIsLoading(isLoadingHeader || isLoadingRole);
  }, [isLoadingHeader, isLoadingRole]);

  return { hasError, isLoading };
};

export { useWorkspaceHeaderLoad };
