import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const EyeIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  width = "22",
  height = "16",
  title = "",
  viewBox = "0 0 22 16",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
        fill={fillPath}
      />
    </svg>
  );
};
export const EyeSlashIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  width = "22",
  height = "19",
  title = "",
  viewBox = "0 0 22 19",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M11 3.99999C13.76 3.99999 16 6.23999 16 8.99999C16 9.50999 15.9 9.99999 15.76 10.46L18.82 13.52C20.21 12.29 21.31 10.75 22 8.98999C20.27 4.60999 16 1.49999 11 1.49999C9.73 1.49999 8.51 1.69999 7.36 2.06999L9.53 4.23999C10 4.09999 10.49 3.99999 11 3.99999ZM1.71 0.659993C1.32 1.04999 1.32 1.67999 1.71 2.06999L3.68 4.03999C2.06 5.32999 0.77 7.02999 0 8.99999C1.73 13.39 6 16.5 11 16.5C12.52 16.5 13.97 16.2 15.31 15.68L18.03 18.4C18.42 18.79 19.05 18.79 19.44 18.4C19.83 18.01 19.83 17.38 19.44 16.99L3.13 0.659993C2.74 0.269993 2.1 0.269993 1.71 0.659993ZM11 14C8.24 14 6 11.76 6 8.99999C6 8.22999 6.18 7.49999 6.49 6.85999L8.06 8.42999C8.03 8.60999 8 8.79999 8 8.99999C8 10.66 9.34 12 11 12C11.2 12 11.38 11.97 11.57 11.93L13.14 13.5C12.49 13.82 11.77 14 11 14ZM13.97 8.66999C13.82 7.26999 12.72 6.17999 11.33 6.02999L13.97 8.66999Z"
        fill={fillPath}
      />
    </svg>
  );
};
