import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ChartLineIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "10",
  title = "",
  viewBox = "0 0 19 10",
  width = "19",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M18.3333 1.66667C18.3333 2.58333 17.5833 3.33333 16.6667 3.33333C16.5167 3.33333 16.375 3.31667 16.2417 3.275L13.275 6.23333C13.3167 6.36667 13.3333 6.51667 13.3333 6.66667C13.3333 7.58333 12.5833 8.33333 11.6667 8.33333C10.75 8.33333 10 7.58333 10 6.66667C10 6.51667 10.0167 6.36667 10.0583 6.23333L7.93333 4.10833C7.8 4.15 7.65 4.16667 7.5 4.16667C7.35 4.16667 7.2 4.15 7.06667 4.10833L3.275 7.90833C3.31667 8.04167 3.33333 8.18333 3.33333 8.33333C3.33333 9.25 2.58333 10 1.66667 10C0.75 10 0 9.25 0 8.33333C0 7.41667 0.75 6.66667 1.66667 6.66667C1.81667 6.66667 1.95833 6.68333 2.09167 6.725L5.89167 2.93333C5.85 2.8 5.83333 2.65 5.83333 2.5C5.83333 1.58333 6.58333 0.833333 7.5 0.833333C8.41667 0.833333 9.16667 1.58333 9.16667 2.5C9.16667 2.65 9.15 2.8 9.10833 2.93333L11.2333 5.05833C11.3667 5.01667 11.5167 5 11.6667 5C11.8167 5 11.9667 5.01667 12.1 5.05833L15.0583 2.09167C15.0167 1.95833 15 1.81667 15 1.66667C15 0.75 15.75 0 16.6667 0C17.5833 0 18.3333 0.75 18.3333 1.66667ZM18.3333 1.66667C18.3333 2.58333 17.5833 3.33333 16.6667 3.33333C16.5167 3.33333 16.375 3.31667 16.2417 3.275L13.275 6.23333C13.3167 6.36667 13.3333 6.51667 13.3333 6.66667C13.3333 7.58333 12.5833 8.33333 11.6667 8.33333C10.75 8.33333 10 7.58333 10 6.66667C10 6.51667 10.0167 6.36667 10.0583 6.23333L7.93333 4.10833C7.8 4.15 7.65 4.16667 7.5 4.16667C7.35 4.16667 7.2 4.15 7.06667 4.10833L3.275 7.90833C3.31667 8.04167 3.33333 8.18333 3.33333 8.33333C3.33333 9.25 2.58333 10 1.66667 10C0.75 10 0 9.25 0 8.33333C0 7.41667 0.75 6.66667 1.66667 6.66667C1.81667 6.66667 1.95833 6.68333 2.09167 6.725L5.89167 2.93333C5.85 2.8 5.83333 2.65 5.83333 2.5C5.83333 1.58333 6.58333 0.833333 7.5 0.833333C8.41667 0.833333 9.16667 1.58333 9.16667 2.5C9.16667 2.65 9.15 2.8 9.10833 2.93333L11.2333 5.05833C11.3667 5.01667 11.5167 5 11.6667 5C11.8167 5 11.9667 5.01667 12.1 5.05833L15.0583 2.09167C15.0167 1.95833 15 1.81667 15 1.66667C15 0.75 15.75 0 16.6667 0C17.5833 0 18.3333 0.75 18.3333 1.66667Z"
        fill={fillPath}
      />
    </svg>
  );
};
