import { createStateSubscription } from "gx-npm-messenger-util";
import { defaultTo } from "../defaultTo";
import { handleEvent } from "../eventUtils";

/**
 * feature flag loader for getting flags from messeneger utility - pass state name
 * where flags are expected to be communicated through. calling function with create
 * a function for flag provider to invoke and set flag states within provider context
 * @param {*} stateName name of state to listen to, defaults to FEATURE_FLAG_KEYS
 * @return setter function for feature flag context provider
 */
const messengerFlagLoader = (stateName = "") => {
  const name = defaultTo(stateName, "FEATURE_FLAG_KEYS");
  return (setFlags = null) => {
    createStateSubscription(name, (newState = null) => {
      if (newState) {
        handleEvent(setFlags, newState);
      }
    });
  };
};

export default messengerFlagLoader;
