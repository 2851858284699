import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Checkbox as MuiCheckbox, FormControlLabel, makeStyles } from "@material-ui/core";
import { checkboxStyles } from "./styles";
import { useFeatureFlag } from "../featureFlags";
import { GCOM_3606__fontUpdate } from "../feature-flags";

const variantCheckBoxType = {
  darkTheme: "darkTheme",
  default: "",
};
const propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  rootClassName: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.any,
};
/*
checked - flag for if checkbox is checked
indeterminate - flag for if checkbox is marked with a -
disabled - flag for if checkbox is clickable
inputProps - props to pass to the input element of checkbox
label - string or object to pass to mui form control label if used
name - name of input
onChange - function to invoke when checked or unchecked
rootClassName - css class to determine style
variant - applies styles based on variant type, options are darkTheme
 */

const useStyles = makeStyles(() => checkboxStyles);
const Checkbox = (props) => {
  const {
    indeterminate,
    checked,
    disabled = false,
    inputProps = {},
    label = "",
    name = "",
    onChange,
    rootClassName = "",
    value = "",
    variant = "",
    ...others
  } = props;

  const classes = useStyles();
  const isFontUpdateFFOn = useFeatureFlag(GCOM_3606__fontUpdate);

  const renderCheckbox = () => {
    return (
      <MuiCheckbox
        {...others}
        aria-label="checkbox"
        checked={checked}
        indeterminate={indeterminate}
        className={classnames(
          "gx-checkbox",
          classes.checkbox,
          variant === variantCheckBoxType.darkTheme && "dark-theme-check-box"
        )}
        disabled={disabled}
        inputProps={inputProps}
        name={name}
        onChange={onChange}
        value={value}
      />
    );
  };

  return (
    <div className={classnames("gx-checkbox-container", rootClassName && rootClassName)}>
      {label ? (
        <FormControlLabel
          classes={{
            label: classnames(
              "gx-checkbox-label",
              classes.label,
              variant === variantCheckBoxType.darkTheme && "dark-theme-check-box",
              isFontUpdateFFOn && classes.newFonts
            ),
          }}
          className={classes.label}
          control={renderCheckbox()}
          label={label}
        />
      ) : (
        renderCheckbox()
      )}
    </div>
  );
};

Checkbox.propTypes = propTypes;
export { Checkbox, variantCheckBoxType };
