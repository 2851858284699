import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowIcon, KebabSingleIcon } from "gx-npm-icons";
import { IconButton } from "../iconButton";
import { TooltipV2 } from "../tooltip-v2";
import { popoverContentStyles as styles } from "./styles";
import { iconTypes, variantTypes } from "./types";

const disabledColor = colorPalette.neutrals.stone.hex;
const propTypes = {
  disabled: PropTypes.bool,
  iconHoverColor: PropTypes.string,
  iconHoverFill: PropTypes.string,
  iconSelectedColor: PropTypes.string,
  iconType: PropTypes.oneOf(Object.values(iconTypes)),
  isHovering: PropTypes.bool,
  isOpen: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipOptions: PropTypes.object,
  useIconButton: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(variantTypes)),
  iconButtonBorderRadius: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const PopoverMenuContent = ({
  disabled = false,
  iconHoverColor = "",
  iconHoverFill = colorPalette.neutrals.coal.hex,
  iconSelectedColor = "",
  iconType = iconTypes.arrow,
  isHovering = false,
  isOpen = false,
  showTooltip = false,
  tooltipOptions = {},
  useIconButton = false,
  variant = null,
  iconButtonBorderRadius = 4,
}) => {
  const classes = useStyles();
  const optionalFillPathProp = disabled ? { fillPath: disabledColor } : {};
  let renderedIcon = <ArrowIcon {...optionalFillPathProp} />;

  if (iconType === iconTypes.kebab) {
    renderedIcon = <KebabSingleIcon {...optionalFillPathProp} />;
  } else if (iconType === iconTypes.arrowDynamic) {
    renderedIcon = (
      <span aria-label={isOpen ? "close menu" : "open menu"} className={isOpen ? "arrow-up" : "arrow-down"}>
        <ArrowIcon {...optionalFillPathProp} />
      </span>
    );
  }

  const renderedContent = (
    <React.Fragment>
      <span className={classes.dropDownIcon}>
        {useIconButton || variant === variantTypes.dropdown ? (
          <IconButton
            disabled={disabled}
            hover={isHovering}
            hoverBackgroundColor={iconHoverColor}
            hoverFill={iconHoverFill}
            rippleBackgroundColor={iconSelectedColor}
            borderRadius={iconButtonBorderRadius}
          >
            {renderedIcon}
          </IconButton>
        ) : (
          renderedIcon
        )}
      </span>
    </React.Fragment>
  );
  return showTooltip ? (
    <TooltipV2 {...tooltipOptions}>
      <div className={classes.tooltipWrapper}>{renderedContent}</div>
    </TooltipV2>
  ) : (
    renderedContent
  );
};

PopoverMenuContent.propTypes = propTypes;
export default PopoverMenuContent;
