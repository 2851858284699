import { InitState, isValidResponse, postAsyncRequest, UUID } from "gx-npm-lib";

/**
 * request to API to restore initiative state
 * @param {string} initId initiativeId
 * @param {function} onSuccessResponse callback on success
 * @param {function} onErrorResponse callback on error
 */

const restoreInitiative = async (
  initId: UUID,
  onSuccessResponse: (success: { state: InitState }) => void,
  onErrorResponse: (error: string | object) => void
) => {
  if (!initId) {
    onErrorResponse("missing initId");
    return;
  }

  const url = `/api/v2/initiatives/${initId}/action/active`;

  const response = await postAsyncRequest(url, {});

  if (isValidResponse(response)) {
    onSuccessResponse({ state: InitState.ACTIVE });
  } else {
    onErrorResponse(response);
  }
};

export { restoreInitiative };
