import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const MessageEmptyIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "20",
  title = "",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M3.17157 14H18V2H2V15.1716L3.17157 14ZM0.853553 19.1464C0.538571 19.4614 0 19.2383 0 18.7929V2C0 0.9 0.9 0 2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H4L0.853553 19.1464Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const MessageFilledIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "20",
  title = "",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H4L0.853553 19.1464C0.538571 19.4614 0 19.2383 0 18.7929V2C0 0.9 0.9 0 2 0ZM5 6C5 5.44772 5.44772 5 6 5H14C14.5523 5 15 5.44772 15 6C15 6.55229 14.5523 7 14 7H6C5.44772 7 5 6.55229 5 6ZM5 10C5 9.44771 5.44772 9 6 9H14C14.5523 9 15 9.44771 15 10C15 10.5523 14.5523 11 14 11H6C5.44772 11 5 10.5523 5 10Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
