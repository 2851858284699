import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ShoppingCartIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 16 16",
  width = "16",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M4.79822 12.8C3.91854 12.8 3.20681 13.52 3.20681 14.4C3.20681 15.28 3.91854 16 4.79822 16C5.67789 16 6.39762 15.28 6.39762 14.4C6.39762 13.52 5.67789 12.8 4.79822 12.8ZM0 0.8C0 1.24 0.359866 1.6 0.799703 1.6H1.59941L4.47834 7.672L3.39874 9.624C2.81495 10.696 3.58267 12 4.79822 12H13.595C14.0348 12 14.3947 11.64 14.3947 11.2C14.3947 10.76 14.0348 10.4 13.595 10.4H4.79822L5.67789 8.8H11.6357C12.2355 8.8 12.7633 8.472 13.0352 7.976L15.8981 2.784C16.194 2.256 15.8101 1.6 15.2024 1.6H3.36675L2.83095 0.456C2.703 0.176 2.4151 0 2.11122 0H0.799703C0.359866 0 0 0.36 0 0.8ZM12.7952 12.8C11.9156 12.8 11.2038 13.52 11.2038 14.4C11.2038 15.28 11.9156 16 12.7952 16C13.6749 16 14.3947 15.28 14.3947 14.4C14.3947 13.52 13.6749 12.8 12.7952 12.8Z"
        fill={fillPath}
      />
    </svg>
  );
};
