import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IconButton as MaterialIconButton, makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import classNames from "classnames";

const propTypes = {
  ariaLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fillColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  hoverFillColor: PropTypes.string,
  onClick: PropTypes.func,
  rippleBackgroundColor: PropTypes.string,
  density: PropTypes.string,
  isToggledOn: PropTypes.bool,
  isPartOfGroup: PropTypes.bool,
};

const ToggleIconButton = ({
  ariaLabel,
  backgroundColor,
  children,
  disabled,
  className,
  fillColor,
  hoverBackgroundColor,
  hoverFillColor,
  onClick,
  rippleBackgroundColor,
  density,
  isToggledOn = false,
  isPartOfGroup = false,
}) => {
  let size;

  const apricotColor = "var(--color-brand-apricot) !important";
  const tangerineColor = "var(--color-brand-tangerine)";
  const buttonHoverState = hoverBackgroundColor && !isSelected ? hoverBackgroundColor : apricotColor;
  const hvrFillClr = hoverFillColor && !isSelected ? hoverFillColor : "transparent";
  const rplBgClr = rippleBackgroundColor ? rippleBackgroundColor : tangerineColor;

  const [isSelected, setIsSelected] = useState();

  const defaultStyles = {
    icon: {
      padding: 0,
      borderRadius: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {},
      "& svg": {
        zIndex: 1000,
      },
      "&:disabled": {
        backgroundColor: "transparent !important",
        "& svg": {
          "& path": {
            fill: "var(--color-neutrals-stone)",
          },
        },
      },
      "& .MuiTouchRipple-child": {
        backgroundColor: rplBgClr,
      },
    },
    roundedButtons: {
      borderRadius: "4px",
    },
  };

  // Use selected hoverBackgroundColor as toggle on state
  const getBackgroundColor = () => {
    if (isSelected && hoverBackgroundColor) {
      return hoverBackgroundColor;
    } else if (isSelected && !hoverBackgroundColor) {
      return apricotColor;
    } else if (!isSelected && backgroundColor) {
      return backgroundColor;
    } else {
      return "transparent";
    }
  };

  // Use selected hoverFillColor as toggle on state
  const getFillColor = () => {
    if (isSelected && hoverFillColor) {
      return hoverFillColor;
    } else if (!isSelected && fillColor) {
      return fillColor;
    } else {
      return "transparent";
    }
  };

  defaultStyles.icon.backgroundColor = getBackgroundColor();

  if (fillColor) {
    defaultStyles.icon["& svg"] = {
      "& path": {
        fill: getFillColor(),
      },
    };
  }

  if (hoverFillColor) {
    defaultStyles.icon["&:hover svg"] = {
      "& path": {
        fill: hvrFillClr,
      },
    };
  }

  // Density spacing for icon buttons is not defined in the style guide.
  switch (density) {
    case "comfortable":
      size = 47;
      break;
    case "compact":
      size = 32;
      break;
    case "spacious":
      size = 62;
      break;
    default:
      size = 47;
  }
  defaultStyles.icon.width = size;
  defaultStyles.icon.height = size;

  defaultStyles.icon["&:hover"] = {
    backgroundColor: buttonHoverState,
  };

  const useStyles = makeStyles(() => defaultStyles);
  const rootClasses = useStyles();

  const toggleSelected = () => {
    if (!isPartOfGroup) {
      setIsSelected(!isSelected);
    }
  };

  useEffect(() => {
    if (isToggledOn && isPartOfGroup) {
      setIsSelected(true);
    } else if (!isToggledOn && isPartOfGroup) {
      setIsSelected(false);
    }
  }, [isToggledOn, isPartOfGroup]);

  return (
    <Fragment>
      <MaterialIconButton
        aria-label={ariaLabel || null}
        aria-pressed={isSelected}
        className={classNames(rootClasses.icon, className, rootClasses.roundedButtons)}
        disabled={disabled}
        onClick={(e) => {
          handleEvent(onClick, e);
          toggleSelected();
        }}
      >
        {children}
      </MaterialIconButton>
    </Fragment>
  );
};

ToggleIconButton.propTypes = propTypes;
export default ToggleIconButton;
