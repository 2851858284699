import classNames from "classnames";
import PropTypes from "prop-types";
import { IconButton as MaterialIconButton, makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { colorPalette } from "gx-npm-common-styles";

const propTypes = {
  ariaLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  className: PropTypes.string,
  clickable: PropTypes.bool,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  fillColor: PropTypes.string,
  focusBackgroundColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hover: PropTypes.bool,
  hoverBackgroundColor: PropTypes.string,
  hoverFill: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onMouseUp: PropTypes.func,
  rippleBackgroundColor: PropTypes.string,
  rootClassName: PropTypes.string,
  style: PropTypes.object,
  tabIndex: PropTypes.number,
  transparentBackground: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const IconButton = ({
  ariaLabel = "",
  backgroundColor,
  borderRadius = "4px",
  children,
  className,
  clickable = true,
  cursor,
  disabled = false,
  fillColor,
  focusBackgroundColor,
  height = 48,
  hover = false,
  hoverBackgroundColor,
  hoverFill,
  onClick,
  onKeyPress,
  onMouseUp,
  rippleBackgroundColor,
  rootClassName = "",
  style,
  tabIndex = 0,
  transparentBackground,
  width = 48,
}) => {
  const isNotClickable = transparentBackground || !clickable;
  const colorMildBerry60Opacity = `${colorPalette.interactions.mildBerry.hex}99`;
  const colorBerry40Opacity = `${colorPalette.interactions.berry.hex}66`;
  const colorHoverBackground = isNotClickable ? "transparent" : hoverBackgroundColor || colorMildBerry60Opacity;
  const buttonHoverState = `${colorHoverBackground} !important`;
  const touchRippleBackgroundColor = isNotClickable
    ? "var(--color-neutrals-quartz)"
    : rippleBackgroundColor || colorBerry40Opacity;

  const defaultStyles = {
    icon: {
      cursor: clickable ? "pointer" : "default",
      width: width,
      height: height,
      display: "flex",
      "-webkit-align-items": "center",
      alignItems: "center",
      "-webkit-justify-content": "center",
      justifyContent: "center",
      "&:hover, &:focus": {},
      "&:focus": {},
      "& svg": {
        zIndex: 1000,
      },
      "&:disabled": {
        backgroundColor: "transparent !important",
        "& svg": {
          "& path": {
            fill: "var(--color-neutrals-stone)",
          },
        },
      },
      "& .MuiTouchRipple-child": {
        backgroundColor: touchRippleBackgroundColor,
      },
    },
  };

  if (!clickable) {
    defaultStyles.icon["& svg"] = {
      "& path": {
        fill: "var(--color-neutrals-iron) !important",
      },
    };
  }

  if (cursor) {
    defaultStyles.icon.cursor = cursor;
  }

  if (backgroundColor) {
    defaultStyles.icon.backgroundColor = backgroundColor;
  }

  const hoverFillColor =
    hoverFill && hoverFill !== colorPalette.neutrals.iron.hex ? hoverFill : colorPalette.neutrals.coal.hex;

  if (fillColor) {
    defaultStyles.icon["& svg"] = {
      "& path": {
        fill: fillColor,
      },
    };
  }

  defaultStyles.icon["&:hover, &:focus"]["& svg"] = {
    "& path": {
      fill: hoverFillColor,
    },
  };

  if (hover) {
    defaultStyles.icon.backgroundColor = buttonHoverState;
    defaultStyles.icon["& svg"] = {
      "& path": {
        fill: hoverFillColor,
      },
    };
  }

  defaultStyles.icon["&:focus"] = {
    backgroundColor: focusBackgroundColor || buttonHoverState,
  };

  defaultStyles.icon["&:hover"] = {
    backgroundColor: buttonHoverState,
  };

  const useStyles = makeStyles(() => defaultStyles);
  const rootClasses = useStyles();
  return (
    <MaterialIconButton
      aria-label={ariaLabel || null}
      className={classNames(rootClasses.icon, className, rootClassName)}
      onClick={(e) => handleEvent(onClick, e)}
      onKeyPress={(e) => handleEvent(onKeyPress, e)}
      onMouseUp={(e) => handleEvent(onMouseUp, e)}
      style={{ ...style, borderRadius, padding: 0 }}
      tabIndex={tabIndex}
      {...(disabled ? { disabled: true } : {})}
      {...(!clickable ? { disableRipple: true } : {})}
    >
      {children}
    </MaterialIconButton>
  );
};

IconButton.propTypes = propTypes;
export { IconButton };
