import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const xmlnsUrl = "http://www.w3.org/2000/svg";
const defaultFillSvg = "none";
const defaultColor = colorPalette.interactions.blueBerry.hex;

export const SelectedVendorIcon = ({
  height = "64",
  title = "Selected vendor",
  viewBox = "0 0 40 64",
  width = "40",
}: IconProps) => {
  return (
    <svg fill={defaultFillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}

      <filter id="noiseFilter">
        <feTurbulence type="fractalNoise" baseFrequency="0.80" numOctaves="3" stitchTiles="stitch" />
      </filter>
      <g clipPath="url(#clip0_6_10469)">
        <mask
          id="mask0_6_10469"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
        >
          <path
            d="M40 0L3.8147e-06 -3.49691e-06L-1.63988e-06 62.3931C-1.70463e-06 63.1338 0.77716 63.6174 1.44168 63.2902L19.5583 54.3713C19.8368 54.2342 20.1632 54.2342 20.4417 54.3713L38.5583 63.2902C39.2228 63.6174 40 63.1338 40 62.3931L40 0Z"
            fill="#FEC10D"
          />
        </mask>
        <g mask="url(#mask0_6_10469)">
          <path
            d="M40 0L3.8147e-06 -3.49691e-06L-1.63988e-06 62.3931C-1.70463e-06 63.1338 0.77716 63.6174 1.44168 63.2902L19.5583 54.3713C19.8368 54.2342 20.1632 54.2342 20.4417 54.3713L38.5583 63.2902C39.2228 63.6174 40 63.1338 40 62.3931L40 0Z"
            fill="#FEC10D"
          />
          <path
            d="M19.1156 29.6763C19.4908 28.9652 20.5092 28.9652 20.8844 29.6763L22.5214 32.7789C22.6661 33.0532 22.9298 33.2448 23.2353 33.2976L26.6919 33.8957C27.4842 34.0328 27.7989 35.0014 27.2385 35.578L24.7936 38.0936C24.5775 38.316 24.4768 38.626 24.5209 38.9329L25.0203 42.4051C25.1347 43.201 24.3108 43.7996 23.5892 43.4448L20.4412 41.897C20.163 41.7601 19.837 41.7601 19.5588 41.897L16.4108 43.4448C15.6892 43.7996 14.8653 43.201 14.9797 42.4051L15.4791 38.9329C15.5232 38.626 15.4225 38.316 15.2064 38.0936L12.7615 35.578C12.2011 35.0014 12.5158 34.0328 13.3081 33.8957L16.7647 33.2976C17.0702 33.2448 17.3339 33.0532 17.4786 32.7789L19.1156 29.6763Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "color-burn" }} opacity="1" filter="url(#noiseFilter)">
            <path d="M394 -168H-154V158H394V-168Z" fill="url(#pattern0)" />
          </g>
        </g>
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.171688" height="0.288605">
          <use xlinkHref="#image0_6_10469" transform="scale(0.000780401 0.00131184)" />
        </pattern>
        <clipPath id="clip0_6_10469">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SelectedVendorIconMedium = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill={defaultFillSvg}
      xmlns={xmlnsUrl}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <filter id="noiseFilter">
        <feTurbulence type="fractalNoise" baseFrequency="0.80" numOctaves="3" stitchTiles="stitch" />
      </filter>
      <g clipPath="url(#clip0_7_5162)">
        <mask
          id="mask0_7_5162"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="-25"
          width="43"
          height="68"
        >
          <path
            d="M42.25 -24.1445L0.285711 -24.1445L0.285706 41.3125C0.285706 42.0895 1.10103 42.5969 1.79819 42.2537L20.8045 32.8968C21.0966 32.7529 21.4391 32.7529 21.7312 32.8968L40.7375 42.2537C41.4347 42.5969 42.25 42.0896 42.25 41.3125L42.25 -24.1445Z"
            fill="#FEC10D"
          />
        </mask>
        <g mask="url(#mask0_7_5162)">
          <path
            d="M41.7148 -7L0.286277 -7L0.286273 41.4913C0.286273 42.2224 1.01526 42.7293 1.7006 42.4748L20.6353 35.4433C20.871 35.3558 21.1302 35.3558 21.3658 35.4433L40.3005 42.4748C40.9858 42.7293 41.7148 42.2224 41.7148 41.4913L41.7148 -7Z"
            fill="#FEC10D"
          />
          <path
            d="M20.3383 11.993C20.7319 11.247 21.8004 11.247 22.1941 11.993L23.9114 15.248C24.0632 15.5357 24.3399 15.7367 24.6604 15.7922L28.2867 16.4196C29.1179 16.5634 29.4481 17.5796 28.8602 18.1845L26.2952 20.8236C26.0685 21.0569 25.9628 21.3822 26.0091 21.7042L26.533 25.3469C26.6531 26.1818 25.7886 26.8099 25.0316 26.4377L21.7291 24.8138C21.4372 24.6702 21.0952 24.6702 20.8033 24.8138L17.5007 26.4377C16.7437 26.8099 15.8793 26.1818 15.9994 25.3469L16.5232 21.7042C16.5695 21.3822 16.4639 21.0569 16.2371 20.8236L13.6722 18.1845C13.0843 17.5796 13.4145 16.5634 14.2457 16.4196L17.872 15.7922C18.1925 15.7367 18.4692 15.5357 18.621 15.248L20.3383 11.993Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "color-burn" }} opacity="1" filter="url(#noiseFilter)">
            <path
              d="M413.633 -200.391L-161.278 -200.391V141.618L413.633 141.618L413.633 -200.391Z"
              fill="url(#pattern0)"
            />
          </g>
        </g>
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.171688" height="0.288605">
          <use xlinkHref="#image0_7_5162" transform="scale(0.000780401 0.00131184)" />
        </pattern>
        <clipPath id="clip0_7_5162">
          <rect width="42" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SelectedVendorIconSmall = ({
  fillSvg = "none",
  height = "35",
  title = "Selected vendor",
  viewBox = "0 0 29 35",
  width = "29",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}

      <filter id="noiseFilter">
        <feTurbulence type="fractalNoise" baseFrequency="0.80" numOctaves="3" stitchTiles="stitch" />
      </filter>
      <g clipPath="url(#clip0_7_12601)">
        <mask
          id="mask0_7_12601"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="47"
        >
          <path
            d="M29.375 -12L2.80142e-06 -12L-1.20429e-06 33.8199C-1.25184e-06 34.3639 0.570727 34.719 1.05874 34.4788L14.3631 27.9289C14.5677 27.8282 14.8073 27.8282 15.0119 27.9289L28.3163 34.4788C28.8043 34.719 29.375 34.3639 29.375 33.8199L29.375 -12Z"
            fill="#FEC10D"
          />
        </mask>
        <g mask="url(#mask0_7_12601)">
          <path
            d="M40 0L3.8147e-06 -3.49691e-06L-1.63988e-06 62.3931C-1.70463e-06 63.1338 0.77716 63.6174 1.44168 63.2902L19.5583 54.3713C19.8368 54.2342 20.1632 54.2342 20.4417 54.3713L38.5583 63.2902C39.2228 63.6174 40 63.1338 40 62.3931L40 0Z"
            fill="#FEC10D"
          />
          <path
            d="M29 0L4.19617e-06 -2.53526e-06L1.2287e-06 33.9439C1.18396e-06 34.4557 0.510293 34.8105 0.99003 34.6324L14.2443 29.7103C14.4093 29.6491 14.5907 29.6491 14.7557 29.7103L28.01 34.6324C28.4897 34.8105 29 34.4557 29 33.9439L29 0Z"
            fill="#FEC10D"
          />
          <path
            d="M14.038 9.79356C14.3135 9.27131 15.0615 9.27131 15.337 9.79356L16.5391 12.072C16.6454 12.2734 16.8391 12.4141 17.0635 12.4529L19.6019 12.8922C20.1837 12.9928 20.4148 13.7042 20.0033 14.1276L18.2078 15.975C18.0491 16.1383 17.9751 16.366 18.0076 16.5913L18.3743 19.1412C18.4583 19.7257 17.8532 20.1653 17.3233 19.9048L15.0115 18.7681C14.8072 18.6676 14.5678 18.6676 14.3635 18.7681L12.0517 19.9048C11.5218 20.1653 10.9167 19.7257 11.0007 19.1412L11.3674 16.5913C11.3999 16.366 11.3259 16.1383 11.1672 15.975L9.37171 14.1276C8.96017 13.7042 9.1913 12.9928 9.77313 12.8922L12.3115 12.4529C12.5359 12.4141 12.7296 12.2734 12.8359 12.072L14.038 9.79356Z"
            fill="white"
          />
          <g style={{ mixBlendMode: "color-burn" }} opacity="1" filter="url(#noiseFilter)">
            <path d="M289.344 -135.375H-113.094V104.031H289.344V-135.375Z" fill="url(#pattern0)" />
          </g>
        </g>
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="0.171688" height="0.288605">
          <use xlinkHref="#image0_7_12601" transform="scale(0.000780401 0.00131184)" />
        </pattern>
        <clipPath id="clip0_7_12601">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SelectedVendorStar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={defaultFillSvg} xmlns={xmlnsUrl}>
      <path
        d="M12.0001 17.27L16.1501 19.78C16.9101 20.24 17.8401 19.56 17.6401 18.7L16.5401 13.98L20.2101 10.8C20.8801 10.22 20.5201 9.12001 19.6401 9.05001L14.8101 8.64001L12.9201 4.18001C12.5801 3.37001 11.4201 3.37001 11.0801 4.18001L9.19007 8.63001L4.36007 9.04001C3.48007 9.11001 3.12007 10.21 3.79007 10.79L7.46007 13.97L6.36007 18.69C6.16007 19.55 7.09007 20.23 7.85007 19.77L12.0001 17.27Z"
        fill="#FEC10D"
      />
    </svg>
  );
};

export const SelectionRibbonIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns={xmlnsUrl}>
      <path
        d="M18 4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44771 6 4L6 19.4371C6 20.1669 6.75644 20.6509 7.41906 20.345L11.5809 18.4242C11.8468 18.3015 12.1532 18.3015 12.4191 18.4242L16.5809 20.3451C17.2436 20.6509 18 20.1669 18 19.4371L18 4Z"
        fill={colorPalette.status.lemon.hex}
      />
      <circle cx="12" cy="13" r="2" fill="white" />
    </svg>
  );
};

export const VendorOptOutIcon = ({
  fillPath = defaultColor,
  height = "24",
  title = "Opt out vendor",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={defaultFillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <g clipPath="url(#clip0_2299_4520)">
        <path
          d="M2.4834 12.6406H9.4834V10.6406H2.4834M13.4834 2.64062H2.4834V4.64062H13.4834M13.4834 6.64062H2.4834V8.64062H13.4834V6.64062Z"
          fill={fillPath}
        />
        <path
          d="M16.655 14.3161L19.4834 11.4334L18.0692 9.99205L15.2408 12.8747L12.4123 9.99205L10.9981 11.4334L13.8265 14.3161L10.9981 17.1988L12.4123 18.6401L15.2408 15.7574L18.0692 18.6401L19.4834 17.1988"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_2299_4520">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
