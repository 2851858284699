/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Proptypes from "prop-types";
import { Dialog } from "@material-ui/core";
import { createGenerateClassName, makeStyles, StylesProvider } from "@material-ui/core/styles";
import { defaultToEmptyString } from "gx-npm-lib";
import { errorOverlayStyles as styles } from "./styles";
import labels from "./labels.json";

const generateClassName = createGenerateClassName({
  seed: "ErrorOverlay-UI",
});
const propTypes = { hasRefresh: Proptypes.bool };
const useStyles = makeStyles(() => styles);

/**
 * @param {{ hasRefresh: boolean }} props
 * @var props.hasRefresh - flag to allow refreshing the URL
 * @returns overlay for entire screen error page
 */
const ErrorOverlay = ({ hasRefresh = false }) => {
  const handleClickRefresh = () => {
    if (window?.location) {
      window.location.href = defaultToEmptyString(window.location.href);
    }
  };
  const classes = useStyles();
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Dialog classes={{ root: classes.root }} fullScreen={true} open={true}>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <div className={classes.imageWrapper}>
                <svg width="898" height="476" viewBox="0 0 898 476" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_11709_41769)">
                    <path
                      opacity="0.5"
                      d="M898.049 473.5L160.064 50.733L130.268 81.0677L276.75 473.501L898.049 473.5Z"
                      fill="url(#paint0_linear_11709_41769)"
                    />
                    <g clipPath="url(#clip1_11709_41769)">
                      <path
                        d="M127.041 48.3809L106.166 27.4843C102.654 23.9698 96.9597 23.9686 93.4466 27.4817C93.4457 27.4825 93.4449 27.4834 93.444 27.4843L37.8691 83.1085C34.4973 86.483 32.6032 91.059 32.6032 95.8302V464.251"
                        stroke="#CBCBCB"
                        strokeWidth="8"
                      />
                      <path
                        d="M3.37346 463.127H62.9593C64.822 463.127 66.332 464.638 66.332 466.501V467.626C66.332 469.489 64.822 471 62.9593 471H3.37346C1.51072 471 0.000671387 469.489 0.000671387 467.626V466.501C0.000671387 464.638 1.51072 463.127 3.37346 463.127Z"
                        fill="#AFB9C5"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M114.487 36.121C99.9979 50.6152 99.9979 74.1149 114.487 88.6091L136.746 110.877L189.214 58.3887L166.955 36.121C152.466 21.6268 128.975 21.6268 114.487 36.121Z"
                        fill="#C5CFD6"
                      />
                      <path
                        d="M167.259 89.1979C152.77 103.692 138.711 113.128 135.857 110.273C133.004 107.418 142.435 93.3535 156.924 78.8593C171.413 64.3651 185.472 54.9296 188.326 57.7845C191.179 60.6394 181.747 74.7037 167.259 89.1979Z"
                        fill="#F2F2F2"
                      />
                    </g>
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_11709_41769"
                      x1="133.114"
                      y1="76.413"
                      x2="456.947"
                      y2="392.643"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F4F5C5" />
                      <stop offset="1" stopColor="#F4F5C5" stopOpacity="0" />
                    </linearGradient>
                    <clipPath id="clip0_11709_41769">
                      <rect width="898" height="476" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_11709_41769">
                      <rect width="190" height="451" fill="white" transform="matrix(-1 0 0 1 190 20)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className={classes.textWrapper}>
                <h3>{defaultToEmptyString(labels?.messages?.en?.header)}</h3>
                <h5>{defaultToEmptyString(labels?.messages?.en?.subtitle)}</h5>
                {!!hasRefresh && (
                  <div className={classes.linkWrapper}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                    <a onClick={handleClickRefresh} role="button" tabIndex={0}>
                      Refresh
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </StylesProvider>
  );
};

ErrorOverlay.propTypes = propTypes;
export { ErrorOverlay };
