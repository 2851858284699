import * as queryString from "query-string";
import * as singleSpa from "single-spa";
import { tabIndexToData, tabIndexToDataOld } from "./sections/tab-menu/tab-menu.lib";
import { UUID } from "gx-npm-lib";

/**
 * @description :: This file will expect the URL to be of the form
 * s/product-profile?id=:initId&pid=:initProductId
 * s/product-responses?id=:initId&pid=:initProductId
 * s/product-documents?id=:initId&pid=:initProductId
 * s/product-scoresheet?id=:initId&pid=:initProductId
 */

const HandleRedirects = () => {
  const { search, pathname } = window.location;
  const defaultRoute = "/s/evaluations";
  const { id, pid } = queryString.parse(search);
  const tabData = tabIndexToDataOld.find((tab) => pathname.includes(tab.pathName));
  if (tabData && id && pid) {
    const newTabInfo = tabIndexToData.find((tab) => tab.name === tabData.name);
    singleSpa.navigateToUrl(newTabInfo ? newTabInfo.url(id as UUID, pid as UUID) + search : defaultRoute);
  } else {
    singleSpa.navigateToUrl(defaultRoute);
  }
  return null;
};

export default HandleRedirects;
