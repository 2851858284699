import { useContext } from "react";
import { FeatureFlagContext } from "./feature-flag.context";

/**
 * @param {string} flagName
 * @returns boolean if flag is true or false
 */
const useFeatureFlag = (flagName = "") => {
  const { flags } = useContext(FeatureFlagContext);
  if (flagName in flags) {
    return flags[flagName];
  }
  return false;
};

export { useFeatureFlag };
