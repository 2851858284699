import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import merge from "deepmerge";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import {
  add,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
  formatDuration,
} from "date-fns";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CalendarIcon } from "gx-npm-icons";
import { datesAreEqual, formatDate, handleEvent } from "gx-npm-lib";
import { IconButton } from "../iconButton";
import {
  datePickerClasses,
  dateRangePickerClasses,
  muiTheme,
  dateRangeMuiTheme,
} from "./styles";

const propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  value: PropTypes.shape({
    end: PropTypes.string,
    start: PropTypes.string,
  }),
};
const DateRangePicker = ({ disabled = false, onClose = null, value = {} }) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [hover, setHover] = useState(null);

  let startDatems = Date.parse(dateRange.start);
  if (isNaN(startDatems)) {
    startDatems = null;
  }
  let endDatems = Date.parse(dateRange.end);
  if (isNaN(endDatems)) {
    endDatems = null;
  }

  const min = Math.min(startDatems, endDatems || hover);
  const max = Math.max(startDatems, endDatems || hover);
  const dateToday = new Date();

  useEffect(() => {
    if (value) {
      if (!!value.start && !!value.end) {
        setDateRange({
          start: value.start + "T00:00:00",
          end: value.end + "T00:00:00",
        });
      } else {
        setDateRange(value);
      }
    }
  }, [value]);

  const formatDateRange = (date = {}) => {
    if (date.start === null && date.end === null) {
      return null;
    }

    const startDate = new Date(date.start);
    const endDate = new Date(date.end);

    const startDateMonth = startDate.toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
    });

    const startDateDay = startDate.toLocaleString("en-us", {
      timeZone: "UTC",
      day: "numeric",
    });

    const startDateYear = startDate.toLocaleString("en-us", {
      timeZone: "UTC",
      year: "numeric",
    });

    const endDateMonth = endDate.toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
    });

    const endDateDay = endDate.toLocaleString("en-us", {
      timeZone: "UTC",
      day: "numeric",
    });

    const endDateYear = endDate.toLocaleString("en-us", {
      timeZone: "UTC",
      year: "numeric",
    });

    const datePrefix = `${startDateMonth} ${startDateDay}`;
    const dateSuffix = `${endDateDay}, ${endDateYear}`;

    if (startDateMonth === endDateMonth && startDateYear === endDateYear) {
      return `${datePrefix} - ${dateSuffix}`;
    } else if (
      startDateMonth !== endDateMonth &&
      startDateYear === endDateYear
    ) {
      return `${datePrefix} - ${endDateMonth} ${dateSuffix}`;
    } else {
      return `${datePrefix}, ${startDateYear} - ${endDateMonth} ${dateSuffix}`;
    }
  };

  const getMonthDay = (date) => {
    if (!date) return null;

    return new Date(date).toLocaleString("en-us", {
      timeZone: "UTC",
      month: "short",
      day: "numeric",
    });
  };

  const getDateRangeDuration = (date = {}) => {
    if (date.start === null || date.end === null || date.end === "") {
      return null;
    }
    let duration = "";

    const startDate = new Date(date.start);
    const endDate = new Date(date.end);
    const includeEndDate = add(endDate, { days: 1 });

    const days = differenceInDays(includeEndDate, startDate);
    const weeks = differenceInWeeks(includeEndDate, startDate);
    const months = differenceInMonths(includeEndDate, startDate);
    const years = differenceInYears(includeEndDate, startDate);

    if (years >= 1) {
      duration = formatDuration({
        years,
        months: months - years * 12,
      });
    } else if (months >= 1) {
      duration = formatDuration({ months });
    } else {
      duration = formatDuration({
        weeks,
        days: days - weeks * 7,
      });
    }

    return " (" + duration + ")";
  };

  const handleClose = (range = {}) => {
    if (!!range?.start && !!range?.end) {
      handleEvent(onClose, range);
    } else {
      handleEvent(onClose, { end: null, start: null });
    }
    setOpen(false);
  };

  const renderDay = (day, _selectedDate, _dayInCurrentMonth, dayComponent) => {
    const style = {
      margin: 0,
      width: 40,
      borderRadius: 0,
    };

    const dayToday = "day-today";
    const dayEnds = "day-ends";
    const dayMiddle = "day-middle";

    let dayType = "";
    if (datesAreEqual(day, startDatems) || datesAreEqual(day, endDatems)) {
      dayType = dayEnds;
    } else if (datesAreEqual(day, dateToday)) {
      dayType = dayToday;
    } else if (day.valueOf() > min && day.valueOf() < max) {
      if (startDatems) {
        dayType = dayMiddle;
      }
    }
    const handleClickEvent = (e) => {
      const standardDate = formatDate(day, "YYYY-MM-DD").replace(/-/g, "/");
      if (!startDatems) {
        setDateRange({ ...dateRange, start: standardDate });
      } else if (!endDatems) {
        if (day >= startDatems) {
          const range = { ...dateRange, end: standardDate };
          setDateRange(range);
          handleClose(range);
        } else {
          e.stopPropagation();
        }
      } else {
        setDateRange({ start: standardDate, end: null });
      }
    };
    return React.cloneElement(dayComponent, {
      onClick: (e) => handleClickEvent(e),
      onMouseEnter: () => setHover(day),
      style,
      "data-daterange": dayType,
    });
  };

  const handleClickIcon = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const materialTheme = createMuiTheme(merge(muiTheme, dateRangeMuiTheme));
  const createStyles = makeStyles(() =>
    merge(datePickerClasses, dateRangePickerClasses)
  );
  const classes = createStyles();
  const anchor = useRef();
  return (
    <React.Fragment>
      <IconButton disabled={disabled} onClick={handleClickIcon}>
        <CalendarIcon />
      </IconButton>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <div
            className={classNames(
              classes.dateContainer,
              disabled && "disabled",
              startDatems && endDatems && "has-date"
            )}
            role="link"
            tabIndex={0}
            ref={anchor}
            onClick={() => {
              !open && !disabled && setOpen(true);
            }}
          >
            <KeyboardDatePicker
              variant="inline"
              disableToolbar={false}
              value={startDatems}
              renderDay={renderDay}
              onClick={() => {
                !disabled && setOpen(true);
              }}
              onClose={() => handleClose(dateRange)}
              onChange={() => {
                /* do nothing */
              }}
              label=""
              labelFunc={() =>
                dateRange.start && dateRange.end
                  ? formatDateRange(dateRange)
                  : "Add date range"
              }
              ToolbarComponent={() => (
                <div className={classes.toolBar}>
                  <div className={classes.toolBarTitle}>
                    <p className="p1">Select date range</p>
                  </div>
                  <div className={classes.toolBarRange}>{`${
                    dateRange.start ? getMonthDay(dateRange.start) : "Start"
                  } - ${
                    dateRange.end ? getMonthDay(dateRange.end) : "End"
                  }`}</div>
                </div>
              )}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: "none" },
              }}
              open={open}
              TextFieldComponent={(props) => props.value}
              PopoverProps={{
                anchorEl: anchor.current,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }}
            />
            <span data-testid="durationtext" className={classes.duration}>
              {getDateRangeDuration({
                start: dateRange.start,
                end: dateRange.end,
              })}
            </span>
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </React.Fragment>
  );
};

DateRangePicker.propTypes = propTypes;
export default DateRangePicker;
