// TODO use mui v5 and resolve any styling issues
import { AppBar, Tab, Tabs } from "@material-ui/core";
import classNames from "classnames";
import { TypographyComponent } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { TabIndex, TabRoute } from "../../app.constants";
import { WorkspaceHeaderLocationParams } from "../../app.types";
import useScrolling from "../../hooks/scrolling.hook";
import styles from "./tab-menu-v2.styles.module.scss";

const TabMenuV2Component = () => {
  const { t } = useTranslation();
  const { initId = "", initProdId = "", tab = "" } = useParams<WorkspaceHeaderLocationParams>();
  const [currentTabIndex, setCurrentTabIndex] = useState(TabIndex.PROFILE);
  const isScrolling = useScrolling();

  useEffect(() => {
    if (!tab) {
      return;
    } else if (tab.toLocaleLowerCase() === TabRoute.PROFILE) {
      setCurrentTabIndex(TabIndex.PROFILE);
    } else if (tab.toLocaleLowerCase() === TabRoute.SCORECARD) {
      setCurrentTabIndex(TabIndex.SCORECARD);
    } else if (tab.toLocaleLowerCase() === TabRoute.RESPONSES) {
      setCurrentTabIndex(TabIndex.RESPONSES);
    } else if (tab.toLocaleLowerCase() === TabRoute.FILES) {
      setCurrentTabIndex(TabIndex.FILES);
    }
  }, [tab]);

  const handleChange = (index: number) => {
    setCurrentTabIndex(index);
    if (index === TabIndex.PROFILE) {
      singleSpa.navigateToUrl(`/s/evaluation/${initId}/product/${initProdId}/${TabRoute.PROFILE}`);
    } else if (index === TabIndex.SCORECARD) {
      singleSpa.navigateToUrl(`/s/evaluation/${initId}/product/${initProdId}/${TabRoute.SCORECARD}`);
    } else if (index === TabIndex.RESPONSES) {
      singleSpa.navigateToUrl(`/s/evaluation/${initId}/product/${initProdId}/${TabRoute.RESPONSES}`);
    } else if (index === TabIndex.FILES) {
      singleSpa.navigateToUrl(`/s/evaluation/${initId}/product/${initProdId}/${TabRoute.FILES}`);
    }
  };

  return (
    <section className={classNames(styles.tabMenuSectionRoot, isScrolling && styles.scrolling)}>
      <AppBar classes={{ root: styles.muiAppBarRootClass }} color="default" elevation={0} position="relative">
        <Tabs
          classes={{ indicator: styles.muiTabsIndicatorClass, root: styles.muiTabsRootClass }}
          indicatorColor="primary"
          onChange={(_event, index) => handleChange(index)}
          scrollButtons="auto"
          textColor="primary"
          value={currentTabIndex}
          variant="scrollable"
        >
          {[t("PROFILE"), t("MY SCORECARD"), t("RESPONSES"), t("FILES")].map((name, index) => {
            return (
              <Tab
                classes={{ root: styles.muiTabRootClass }}
                key={name}
                label={
                  <TypographyComponent
                    boldness="semi"
                    color={index === currentTabIndex ? "defaultCta" : "iron"}
                    element="span"
                    styling="p3"
                  >
                    {name}
                  </TypographyComponent>
                }
              />
            );
          })}
        </Tabs>
      </AppBar>
    </section>
  );
};

export default TabMenuV2Component;
