import React, { Fragment } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { BoldTypes, HeaderTypes } from "../types";
import styles from "./header.styles";

type HeaderProps = {
  ariaLabel?: string;
  boldness?: BoldTypes;
  children: Array<React.ReactNode> | React.ReactNode | number | string;
  rootClassName?: string;
  type?: HeaderTypes;
};

const useStyles = makeStyles(() => styles);
/** @deprecated use TypographyComponent instead (change "type" prop to be "styling") */
export const Header = ({
  ariaLabel = "",
  boldness = "semi",
  children = null,
  rootClassName = "",
  type = "h3",
}: HeaderProps) => {
  const classes = useStyles();
  const className = classNames("gx-h", classes.root, classes[boldness], classes[type], rootClassName);
  const label = ariaLabel || undefined;
  return (
    <Fragment>
      {type === "h1" && (
        <h1 aria-label={label} className={className}>
          {children}
        </h1>
      )}
      {type === "h2" && (
        <h2 aria-label={label} className={className}>
          {children}
        </h2>
      )}
      {type === "h3" && (
        <h3 aria-label={label} className={className}>
          {children}
        </h3>
      )}
      {type === "h4" && (
        <h4 aria-label={label} className={className}>
          {children}
        </h4>
      )}
      {type === "h5" && (
        <h5 aria-label={label} className={className}>
          {children}
        </h5>
      )}
    </Fragment>
  );
};
