import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const colorGartnerBlue = colorPalette.brand.gartnerBlue.hex;
const colorPewter = colorPalette.neutrals.pewter.hex;
const colorSurf = colorPalette.brand.surf.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const MQIcon = ({ title = "" }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M18.6667 1.15151C18.6667 0.515547 19.1822 -1.81995e-06 19.8182 -1.76435e-06L38.8485 -1.00669e-07C39.4845 -4.50708e-08 40 0.515549 40 1.15152L40 20.1818C40 20.8178 39.4845 21.3333 38.8485 21.3333L19.8182 21.3333C19.1822 21.3333 18.6667 20.8178 18.6667 20.1818L18.6667 1.15151Z"
        fill={colorGartnerBlue}
      />
      <path
        d="M19.6626 1.15151C19.6626 1.0656 19.7323 0.995951 19.8182 0.995951L38.8485 0.995953C38.9344 0.995953 39.0041 1.0656 39.0041 1.15152L39.0041 20.1818C39.0041 20.2677 38.9344 20.3374 38.8485 20.3374L19.8182 20.3374C19.7323 20.3374 19.6626 20.2677 19.6626 20.1818L19.6626 1.15151ZM0.995954 27.1164C0.995954 27.0305 1.0656 26.9609 1.15152 26.9609L12.8836 26.9609C12.9695 26.9609 13.0391 27.0305 13.0391 27.1164L13.0391 38.8485C13.0391 38.9344 12.9695 39.004 12.8836 39.004L1.15152 39.004C1.0656 39.004 0.995953 38.9344 0.995953 38.8485L0.995954 27.1164ZM0.995955 7.81818C0.995955 7.73226 1.0656 7.66262 1.15152 7.66262L12.8836 7.66262C12.9695 7.66262 13.0391 7.73227 13.0391 7.81818L13.0391 19.5502C13.0391 19.6362 12.9695 19.7058 12.8836 19.7058L1.15152 19.7058C1.0656 19.7058 0.995954 19.6361 0.995954 19.5502L0.995955 7.81818ZM20.2942 27.1164C20.2942 27.0305 20.3638 26.9609 20.4498 26.9609L32.1818 26.9609C32.2677 26.9609 32.3374 27.0305 32.3374 27.1164L32.3374 38.8485C32.3374 38.9344 32.2677 39.004 32.1818 39.004L20.4498 39.004C20.3638 39.004 20.2942 38.9344 20.2942 38.8485L20.2942 27.1164Z"
        stroke={colorGartnerBlue}
        strokeWidth="1.9919"
      />
    </svg>
  );
};

export const MQChallengersIcon = ({ title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect fill={colorGartnerBlue} height="12" transform="matrix(-1 0 0 1 12 0)" width="12" />
      <path
        d="M22.5 15.5H15.5V22.5H22.5V15.5ZM22.5 4.5H15.5V11.5H22.5V4.5ZM11.5 15.5H4.5V22.5H11.5V15.5Z"
        stroke={colorPewter}
      />
    </svg>
  );
};

export const MQLeadersIcon = ({ title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect fill={colorGartnerBlue} height="12" x="12" width="12" />
      <path
        d="M1.5 15.5H8.5V22.5H1.5V15.5ZM1.5 4.5H8.5V11.5H1.5V4.5ZM12.5 15.5H19.5V22.5H12.5V15.5Z"
        stroke={colorPewter}
      />
    </svg>
  );
};

export const MQNichePlayersIcon = ({ title = "" }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect fill={colorGartnerBlue} height="12" transform="rotate(-180 12 24)" width="12" x="12" y="24" />
      <path
        d="M22.5 8.5H15.5V1.5H22.5V8.5ZM22.5 19.5H15.5V12.5H22.5V19.5ZM11.5 8.5H4.5V1.5H11.5V8.5Z"
        stroke={colorPewter}
      />
    </svg>
  );
};

export const MQVisionariesIcon = ({ title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect fill={colorGartnerBlue} height="12" transform="matrix(1 0 0 -1 12 24)" width="12" />
      <path
        d="M1.5 8.5H8.5V1.5H1.5V8.5ZM1.5 19.5H8.5V12.5H1.5V19.5ZM12.5 8.5H19.5V1.5H12.5V8.5Z"
        stroke={colorPewter}
      />
    </svg>
  );
};

export const MQTemplateResearchIcon = ({
  title = "",
  width = "24px",
  height = "24px ",
  fill = colorSurf,
  stroke = colorSurf,
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <rect x="13" y="21" width="10" height="10" transform="rotate(180 13 21)" fill={fill} />
      <path
        d="M21.5 8.23684H15.7632V2.5H21.5V8.23684ZM21.5 17.5H15.7632V11.7632H21.5V17.5ZM12.2368 8.23684H6.5V2.5H12.2368V8.23684Z"
        stroke={stroke}
      />
    </svg>
  );
};
