import { types } from "../banner/types";
const snackbarClasses = {
  root: {
    width: "100%",
    zIndex: "1400",
  },
  refSafeContainer: {
    width: "100%",
  },
};

const topClasses = {
  root: {
    top: "0 !important",
  },
  refSafeContainer: {
    textAlign: "center",
  },
};

const successClasses = {
  root: {
    bottom: 32,
    left: 32,
    width: "unset",
  },
};

const miniSuccessClasses = {
  root: {
    bottom: "inherit",
    position: "absolute",
    top: "inherit",
    width: "unset",
  },
};

const getTypeClasses = (type) => {
  let classes = topClasses;
  if (["ERROR", "WARNING"].indexOf(type) > -1) {
    classes = topClasses;
  } else if (type === types.success) {
    classes = successClasses;
  } else if (type === types.miniSuccess) {
    classes = miniSuccessClasses;
  }

  return classes;
};

export { getTypeClasses, snackbarClasses };
