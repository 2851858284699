import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorBlueBerryHex = colorPalette.interactions.blueBerry.hex;
const colorCarbonHex = colorPalette.neutrals.carbon.hex;
const colorCoalHex = colorPalette.neutrals.coal.hex;
const colorIronHex = colorPalette.neutrals.iron.hex;
const colorMildBerryHex = colorPalette.interactions.mildBerry.hex;
const colorSilverHex = colorPalette.neutrals.silver.hex;
const colorTransparent = "transparent";
const colorWhiteHex = colorPalette.basic.white.hex;
const weightRegularAmount = weightPalette.regular.amount;
const weightRegularVariation = weightPalette.regular.variation;
const weightSemiBold = weightPalette.semiBold.amount;
const variationSemiBold = weightPalette.semiBold.variation;
const xDatePickerDefaultWidth = 180; // This needs to be overridden from rootClasses

const datePickerClasses = {
  dateContainer: {
    color: colorCarbonHex,
    display: "inline-block",
    fontSize: "14px",
    fontVariationSettings: weightRegularVariation,
    fontWeight: weightRegularAmount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginLeft: "24px",
    marginRight: "20px",
    verticalAlign: "middle",
    "& .has-date": {
      "& input": {
        fontVariationSettings: variationSemiBold,
        fontWeight: weightSemiBold,
        color: colorCarbonHex,
        margin: "8px 12px 8px 12px",
      },
    },
    "& input": {
      color: colorIronHex,
      boxSizing: "border-box",
      fontSize: "14px",
      letterSpacing: "0.25px",
      lineHeight: "21px !important",
      margin: "8px 12px 8px 18px",
      padding: 0,
      paddingLeft: 11,
      width: 183,
      height: "100%",
    },
    "&.calendarOpen": {
      zIndex: 1300,
      "& button": {
        backgroundColor: colorTransparent,
        outlineColor: colorBlueBerryHex,
        outlineStyle: "solid",
        outlineWidth: 2,
      },
    },
    "&.disabled": {
      "& button": {
        backgroundColor: colorTransparent,
      },
    },
    "&:hover": {
      "& input": {
        color: colorIronHex,
      },
    },
  },
  dateLabel: {
    color: colorIronHex,
    paddingRight: 8,
    minWidth: "max-content",
  },
  xDatePicker: {
    width: xDatePickerDefaultWidth,
    borderRadius: 2,
    "& button": {
      "& svg": {
        display: "none",
      },
      position: "absolute",
      top: 2,
      left: 2,
      right: 2,
      bottom: 2,
      borderRadius: 2,
      width: xDatePickerDefaultWidth,
    },
    "& input": {
      color: colorCarbonHex,
      fontSize: "14px",
      fontVariationSettings: weightRegularVariation,
      fontWeight: weightRegularAmount,
    },
  },
  actionBar: {
    gridRow: 3,
    gridColumn: 2,
    borderTop: "1px solid" + colorPalette.neutrals.silver.hex,
    padding: 8,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& .mini-btn": {
      color: colorPalette.neutrals.iron.hex,
    },
    "& .mini-btn-enabled": {
      color: colorPalette.interactions.blackBerry.hex,
      "& path": {
        fill: colorPalette.interactions.blackBerry.hex,
      },
    },
  },
  datePicker: {
    "&.has-date": {
      "& button": {
        marginLeft: -204,
        width: 183,
        padding: 17,
      },
    },
    "& button": {
      borderRadius: 0,
      marginLeft: -201,
      width: 183,
      padding: 17,
      "&:hover": {
        backgroundColor: colorTransparent,
        outlineColor: colorPalette.neutrals.stone.hex,
        outlineStyle: "solid",
        outlineWidth: 1,
      },
    },
  },
  innerContainer: {
    alignItems: "center",
    display: "flex",
  },
};

const dateRangePickerClasses = {
  dateContainer: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 8,
    cursor: "pointer",
    color: colorIronHex,
    "&.disabled": {
      cursor: "default",
    },
    "&.has-date": {
      color: colorCarbonHex,
    },
  },
  duration: {
    color: colorIronHex,
  },
  toolBar: {
    padding: "16px 24px 16px 24px",
    backgroundColor: colorMildBerryHex,
    "& $toolBarTitle": {
      color: colorCoalHex,
      marginBottom: 6,
    },
    "& $toolBarRange": {
      fontSize: 28,
      fontVariationSettings: weightPalette.semiBold.variation,
      fontWeight: weightPalette.semiBold.amount,
      lineHeight: "36.4px",
      letterSpacing: "0.15px",
      color: colorCarbonHex,
    },
  },
  toolBarTitle: {},
  toolBarRange: {},
};

const xDatePickerStyles = {
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: "none",
          border: "2px solid " + colorPalette.neutrals.silver.hex,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colorPalette.neutrals.coal.hex,
          fontSize: 16,
          fontWeight: weightPalette.medium.amount,
          borderRadius: 4,
          "&:hover": {
            backgroundColor: colorPalette.neutrals.silver.hex,
          },
          "&:focus": {
            backgroundColor: colorPalette.basic.white.hex,
          },
          "&.Mui-selected": {
            backgroundColor: colorPalette.interactions.blueBerry.hex,
            border: 0,
          },
          "&.MuiPickersDay-today": {
            borderColor: colorPalette.neutrals.coal.hex,
          },
        },
      },
    },
  },
};

const muiTheme = {
  overrides: {
    MuiPickersBasePicker: {
      container: {
        backgroundColor: colorWhiteHex,
        borderRadius: "0px !important",
        marginLeft: -150,
        marginTop: 7,
      },
      pickerView: {
        border: `2px solid ${colorSilverHex}`,
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: colorTransparent,
        boxShadow: "none !important",
        overflow: "visible !important",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colorMildBerryHex,
      },
    },
    MuiPickersCalendar: {
      week: {
        "& p": {
          color: colorCarbonHex,
        },
      },
    },
    MuiPickersDay: {
      day: {
        "&:hover": {
          borderRadius: 0,
          backgroundColor: `${colorSilverHex} !important`,
          "& p": {
            color: `${colorCarbonHex} !important`,
          },
        },
      },
      daySelected: {
        backgroundColor: colorBlueBerryHex,
        borderRadius: "0px",
        "& p": {
          color: `${colorWhiteHex} !important`,
        },
      },
      dayDisabled: {
        "& p": {
          color: `${colorCoalHex} !important`,
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fill: colorIronHex,
      },
    },
    MuiFormHelperText: {
      root: {
        color: `${colorIronHex} !important`,
        fontSize: "14px !important",
        fontVariationSettings: weightRegularVariation,
        fontWeight: weightRegularAmount,
        letterSpacing: "0.25px !important",
        lineHeight: "21px !important",
        position: "absolute",
        top: 4,
        zIndex: -1,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
};

const dateRangeMuiTheme = {
  overrides: {
    MuiPickersBasePicker: {
      container: {
        marginLeft: 6,
        marginTop: 35,
      },
      pickerView: {
        borderTop: 0,
      },
    },
    MuiPickersDay: {
      day: {
        "&[data-daterange='day-today']": {
          border: `1px solid ${colorCoalHex}`,
          backgroundColor: colorTransparent,
          "& p": {
            color: `${colorCarbonHex} !important`,
          },
        },
        "&[data-daterange='day-ends']": {
          backgroundColor: colorBlueBerryHex,
          "& p": {
            color: colorWhiteHex,
          },
        },
        "&[data-daterange='day-middle']": {
          backgroundColor: colorPalette.interactions.lightBerry.hex,
          "& p": {},
        },
      },
    },
  },
};

export { datePickerClasses, dateRangePickerClasses, muiTheme, dateRangeMuiTheme, xDatePickerStyles };
