import React from "react";
import merge from "deepmerge";
import PropTypes from "prop-types";
import { Slide, Snackbar as MuiSnackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { getTypeClasses, snackbarClasses } from "./styles";
import { types } from "../banner/types";

const SlideTransitionDown = (props) => {
  return <Slide {...props} direction="down" />;
};

function slideTransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function slideTransitionRight(props) {
  return <Slide {...props} direction="right" />;
}
/**
 * create a snackback to provide a message to the user
 * @unsupported - adding a button will currently break component
 * @unsupported - transitionDirection not functioning
 * @param {*} props.anchorVertical - sets vertical position of snackbar. choose between top or bottom, defaults to top
 * @param {*} props.anchorHorizontal - sets horizontal position of snackbar. choose between left, center, or right,
 * defaults to center
 * @param {*} props.autoHideDuration - sets milliseconds until bar auto closes. defaults to 3000
 * @param {*} props.children - REQUIRED content to be displayed - set between tags
 * @param {*} props.isOpen - REQUIRED getter hook for flag for showing snackbar
 * @param {*} props.isTimeoutAllowed - flag for turning timeout dismissal of snackbar on/off. defaults to true
 * @param {*} props.setIsOpen - REQUIRED setter hook for flag for showing snackbar
 * @param {*} props.transitionDuration - sets the enter and exit duration for snackbar
 * @param {*} props.type - type of styling/animation for snackbar
 * @param {*} props.uniquKey - REQUIRED unique key for tracking ref when multiple can appear
 * @returns MUI wrapper snackbar
 */
const Snackbar = ({
  anchorVertical = "top",
  anchorHorizontal = "center",
  autoHideDuration = 5000,
  children = null,
  isOpen = false,
  isTimeoutAllowed = true,
  setIsOpen = null,
  transitionDuration = {},
  type = "ERROR",
  uniquKey = null,
}) => {
  const typeClasses = getTypeClasses(type);
  const createStyles = makeStyles(() => merge(snackbarClasses, typeClasses));
  const classes = createStyles();
  const { refSafeContainer, ...muiClasses } = classes;
  let slideDirection = "";

  if (type === types.success) {
    slideDirection = slideTransitionRight;
  } else if (type === types.miniSuccess) {
    slideDirection = slideTransitionLeft;
  } else {
    slideDirection = SlideTransitionDown;
  }

  const handleOnClose = (_event, reason) => {
    if (isTimeoutAllowed && reason === "timeout") {
      handleEvent(setIsOpen, false);
    }
  };

  return (
    <MuiSnackbar
      anchorOrigin={{
        horizontal: anchorHorizontal,
        vertical: anchorVertical,
      }}
      autoHideDuration={autoHideDuration}
      classes={muiClasses}
      key={uniquKey}
      open={isOpen}
      onClose={handleOnClose}
      resumeHideDuration={null}
      transitionDuration={transitionDuration}
      TransitionComponent={slideDirection}
    >
      <div className={refSafeContainer}>{children}</div>
    </MuiSnackbar>
  );
};

const propTypes = {
  anchorVertical: PropTypes.string,
  anchorHorizontal: PropTypes.string,
  autoHideDuration: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isOpen: PropTypes.bool,
  isTimeoutAllowed: PropTypes.bool,
  setIsOpen: PropTypes.func,
  transitionDuration: PropTypes.shape({
    enter: PropTypes.number,
    exit: PropTypes.number,
  }),
  type: PropTypes.string,
  uniquKey: PropTypes.string,
};

Snackbar.propTypes = propTypes;
export default Snackbar;
