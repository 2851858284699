import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const blueBerry = colorPalette.interactions.blueBerry.hex;
const darkPear = colorPalette.status.darkPear.hex;
const defaultColor = colorPalette.neutrals.iron.hex;
const lightCherry = colorPalette.status.lightCherry.hex;
const lightPear = colorPalette.status.lightPear.hex;
const poisonCherry = colorPalette.status.poisonCherry.hex;
const silver = colorPalette.neutrals.silver.hex;
const white = colorPalette.basic.white.hex;

const xmlns = "http://www.w3.org/2000/svg";

export const ArrowIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "8",
  title = "",
  viewBox = "0 0 12 8",
  width = "12",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M9.88047 1.29L6.00047 5.17L2.12047 1.29C1.73047 0.899998 1.10047 0.899998 0.710469 1.29C0.320469 1.68 0.320469 2.31 0.710469 2.7L5.30047 7.29C5.69047 7.68 6.32047 7.68 6.71047 7.29L11.3005 2.7C11.6905 2.31 11.6905 1.68 11.3005 1.29C10.9105 0.909998 10.2705 0.899998 9.88047 1.29Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ArrowCircledIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM6.69997 13.3L11.29 8.71001C11.68 8.32001 12.32 8.32001 12.7 8.71001L17.29 13.3C17.68 13.69 17.68 14.32 17.29 14.71C16.9 15.1 16.27 15.1 15.88 14.71L12 10.83L8.10997 14.71C7.71997 15.1 7.08997 15.1 6.69997 14.71C6.30997 14.32 6.30997 13.69 6.69997 13.3Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const ArrowBackIcon = ({
  fill = "none",
  height = "24",
  width = "24",
  fillPath = blueBerry,
  viewBox = "0 0 24 24",
}: IconProps) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M16.6201 2.99C16.1301 2.5 15.3401 2.5 14.8501 2.99L6.54006 11.3C6.15006 11.69 6.15006 12.32 6.54006 12.71L14.8501 21.02C15.3401 21.51 16.1301 21.51 16.6201 21.02C17.1101 20.53 17.1101 19.74 16.6201 19.25L9.38006 12L16.6301 4.75C17.1101 4.27 17.1101 3.47 16.6201 2.99Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ArrowFavorableIcon = ({
  fill = "none",
  height = "24",
  width = "25",
  fillPath = darkPear,
  viewBox = "0 0 25 24",
}: IconProps) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <circle cx="12.5" cy="12" r="12" fill={lightPear} />
      <path
        d="M11.634 7.50049C12.0189 6.83382 12.9811 6.83382 13.366 7.50049L16.8301 13.5005C17.215 14.1672 16.7339 15.0005 15.9641 15.0005L9.0359 15.0005C8.2661 15.0005 7.78497 14.1672 8.16987 13.5005L11.634 7.50049Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ArrowCriticalIcon = ({
  fill = "none",
  height = "24",
  width = "25",
  fillPath = poisonCherry,
  viewBox = "0 0 25 24",
}: IconProps) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <circle cx="12.5" cy="12" r="12" fill={lightCherry} />
      <path
        d="M13.366 16.5005C12.9811 17.1672 12.0189 17.1672 11.634 16.5005L8.16987 10.5005C7.78497 9.83382 8.2661 9.00049 9.0359 9.00049L15.9641 9.00049C16.7339 9.00049 17.215 9.83382 16.8301 10.5005L13.366 16.5005Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ArrowMoreIcon = ({
  fill = "none",
  height = "12",
  width = "8",
  fillPath = defaultColor,
  viewBox = "0 0 8 12",
}: IconProps) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      <path
        d="M1.28957 0.710022C0.89957 1.10002 0.89957 1.73002 1.28957 2.12002L5.16957 6.00002L1.28957 9.88002C0.89957 10.27 0.89957 10.9 1.28957 11.29C1.67957 11.68 2.30957 11.68 2.69957 11.29L7.28957 6.70002C7.67957 6.31002 7.67957 5.68002 7.28957 5.29002L2.69957 0.700022C2.31957 0.320022 1.67957 0.320022 1.28957 0.710022Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ArrowRight = ({
  fillPath = "#8D8A89",
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 43 16",
  width = "43",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM42.7071 8.70711C43.0976 8.31658 43.0976 7.68342 42.7071 7.29289L36.3431 0.928932C35.9526 0.538408 35.3195 0.538408 34.9289 0.928932C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM1 9H42V7H1V9Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const BullsEyeArrowIcon = ({ title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.84 21.79 9.69 21.39 8.61L19.79 10.21C19.93 10.8 20 11.4 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20C9.87827 20 7.84344 19.1571 6.34315 17.6569C4.84285 16.1566 4 14.1217 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C12.6 4 13.2 4.07 13.79 4.21L15.4 2.6C14.31 2.21 13.16 2 12 2ZM19 2L15 6V7.5L12.45 10.05C12.3 10 12.15 10 12 10C11.4696 10 10.9609 10.2107 10.5858 10.5858C10.2107 10.9609 10 11.4696 10 12C10 12.5304 10.2107 13.0391 10.5858 13.4142C10.9609 13.7893 11.4696 14 12 14C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12C14 11.85 14 11.7 13.95 11.55L16.5 9H18L22 5H19V2ZM12 6C10.4087 6 8.88258 6.63214 7.75736 7.75736C6.63214 8.88258 6 10.4087 6 12C6 13.5913 6.63214 15.1174 7.75736 16.2426C8.88258 17.3679 10.4087 18 12 18C13.5913 18 15.1174 17.3679 16.2426 16.2426C17.3679 15.1174 18 13.5913 18 12H16C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8V6Z"
        fill={colorPalette.accent.sweetGrape.hex}
      />
    </svg>
  );
};

export const ArrowForwardIcon = ({ title = "", fillColor = defaultColor }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <path
        d="M3.33335 9.66486H10.78L7.52669 12.9182C7.26669 13.1782 7.26669 13.6049 7.52669 13.8649C7.78669 14.1249 8.20669 14.1249 8.46669 13.8649L12.86 9.47152C13.12 9.21152 13.12 8.79152 12.86 8.53152L8.47335 4.13152C8.21335 3.87152 7.79335 3.87152 7.53335 4.13152C7.27335 4.39152 7.27335 4.81152 7.53335 5.07152L10.78 8.33152H3.33335C2.96669 8.33152 2.66669 8.63152 2.66669 8.99819C2.66669 9.36486 2.96669 9.66486 3.33335 9.66486Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const ArrowRoundedTip = ({
  width = "27",
  height = "15",
  viewBox = "0 0 27 15",
  fill = "none",
  fillColor = defaultColor,
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns}>
      <path
        d="M12.0134 1.65177L0 15H27L14.9866 1.65176C14.1921 0.769004 12.8079 0.769004 12.0134 1.65177Z"
        fill={fillColor}
      />
    </svg>
  );
};

export const ArrowRoundedTipWithBorder = ({
  width = "26",
  height = "13",
  viewBox = "0 0 26 13",
  fill = "none",
  stroke = silver,
  fillPath = white,
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlns}>
      <path
        d="M3 13.0001L11.5133 2.99999C12.2964 2.14714 13.7039 2.14714 14.487 2.99999L23 13.0001L3 13.0001Z"
        fill={fillPath}
      />
      <path
        d="M3.00009 13L11.5133 2.99999C12.2964 2.14714 13.7039 2.14714 14.487 2.99999L22.9999 13H26L15.6368 1.20187C14.2388 -0.400624 11.6912 -0.400622 10.2931 1.20187L0 13H3.00009Z"
        fill={stroke}
      />
    </svg>
  );
};
