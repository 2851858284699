import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const SpeakerHornIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "14",
  title = "",
  viewBox = "0 0 18 14",
  width = "18",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M16.5199 0.385658C16.5199 0.381055 16.5176 0.378754 16.5153 0.376452C16.513 0.374151 16.5107 0.371849 16.5107 0.367246C16.2046 -0.0378065 15.6295 -0.120658 15.2306 0.183132C15.0521 0.316836 14.8687 0.452968 14.6844 0.589764C14.3612 0.829713 14.0352 1.07171 13.728 1.30623C13.3198 1.61002 13.2456 2.18078 13.5517 2.57663C13.5517 2.58123 13.5541 2.58353 13.5564 2.58583C13.5587 2.58813 13.561 2.59043 13.561 2.59504C13.8671 3.00009 14.4329 3.08294 14.841 2.77915C15.1873 2.5263 15.5473 2.25518 15.9017 1.9883C16.0505 1.87626 16.1983 1.76493 16.3437 1.65605C16.7426 1.36147 16.8168 0.790711 16.5199 0.385658Z"
        fill={fillPath}
      />
      <path
        d="M13.5332 11.4418C13.2271 11.8468 13.3106 12.4084 13.7187 12.703C14.2103 13.062 14.7297 13.4486 15.2213 13.8169C15.6295 14.1207 16.2045 14.0378 16.5014 13.6328C16.5014 13.6282 16.5037 13.6259 16.506 13.6235C16.5083 13.6212 16.5106 13.6189 16.5106 13.6143C16.8167 13.2093 16.7333 12.6385 16.3251 12.3439C15.8343 11.9763 15.3156 11.5902 14.8339 11.2317L14.8318 11.2301C14.4236 10.9263 13.8485 11.0183 13.5424 11.4234C13.5424 11.4326 13.5332 11.4418 13.5332 11.4418Z"
        fill={fillPath}
      />
      <path
        d="M1.85512 4.24288H5.56537L10.2032 1.48116V12.5281L5.56537 9.76633H4.63781V12.5281C4.63781 13.0344 4.22041 13.4486 3.71025 13.4486C3.20009 13.4486 2.78269 13.0344 2.78269 12.5281V9.76633H1.85512C0.834806 9.76633 0 8.93781 0 7.92518V6.08403C0 5.0714 0.834806 4.24288 1.85512 4.24288Z"
        fill={fillPath}
      />
      <path
        d="M12.5221 7.00463C12.5221 5.78026 11.9841 4.67557 11.1307 3.9207V10.0793C11.9841 9.33368 12.5221 8.22899 12.5221 7.00463Z"
        fill={fillPath}
      />
      <path
        d="M17.9981 7.06986C17.9986 7.07307 17.999 7.07628 17.9962 7.07995C17.9874 7.57925 17.5819 7.98935 17.0847 7.98616C16.9026 7.98621 16.7171 7.98672 16.5303 7.98724C16.0854 7.98849 15.6334 7.98975 15.2036 7.9847C14.6934 7.9833 14.292 7.58018 14.2934 7.07535C14.2962 7.07168 14.2957 7.06847 14.2953 7.06525C14.2948 7.06204 14.2944 7.05883 14.2972 7.05516C14.293 6.55766 14.6986 6.14756 15.2087 6.14895C15.5963 6.14533 16.0034 6.14698 16.407 6.14861C16.6372 6.14954 16.8662 6.15047 17.0898 6.15041C17.5926 6.14628 18.0014 6.55493 18 7.05976C17.9972 7.06343 17.9977 7.06664 17.9981 7.06986Z"
        fill={fillPath}
      />
    </svg>
  );
};
