import { InitProdState, isValidResponse, postAsyncRequest, putAsyncRequest } from "gx-npm-lib";
import { AvailableActions, ProductStateActions } from "../../app.constants";
import { WorkspaceHeaderConfig } from "../../app.types";

/**
 * request to API to update product state
 */
const saveProductRequestV3 = async (
  workspaceHeaderConfig: WorkspaceHeaderConfig,
  actionType: AvailableActions,
  onSuccessResponse: (success: { productState: InitProdState }) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, initProdId } = workspaceHeaderConfig;

  if (!initId && !initProdId) {
    onErrorResponse("missing id and prod id");
    return;
  }

  const data = { productState: InitProdState.LISTED, actionType };

  const actionStr = ProductStateActions[actionType] || ProductStateActions[AvailableActions.ADD_PRODUCT_TO_EVAL];
  const url = `/api/v3/initiatives/${initId}/products/${initProdId}/action/${actionStr}`;

  let response;

  if (actionStr === ProductStateActions[AvailableActions.ADD_PRODUCT_TO_EVAL]) {
    response = await putAsyncRequest(url, {});
    data.productState = InitProdState.IN_EVALUATION;
  } else if (actionStr === ProductStateActions[AvailableActions.SCREEN_OUT_PRODUCT]) {
    response = await postAsyncRequest(url, {});
    data.productState = InitProdState.SCREENED_OUT;
  } else if (actionStr === ProductStateActions[AvailableActions.CHOOSE_PRODUCT]) {
    response = await postAsyncRequest(url, {});
    data.productState = InitProdState.AWARDED;
  } else if (actionStr === ProductStateActions[AvailableActions.REMOVE_PRODUCT_FROM_EVAL]) {
    response = await postAsyncRequest(url, {});
    data.productState = InitProdState.LISTED;
  } else if (actionStr === ProductStateActions[AvailableActions.REVERT_AWARD]) {
    response = await postAsyncRequest(url, {});
    data.productState = InitProdState.IN_EVALUATION;
  }

  if (isValidResponse(response)) {
    if (data.productState) {
      onSuccessResponse(data);
    }
  } else {
    onErrorResponse(response);
  }
};

export { saveProductRequestV3 };
