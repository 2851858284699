import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ClosedLockIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 12 16",
  width = "12",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M10.5 5.33333H9.75V3.80952C9.75 1.70667 8.07 0 6 0C3.93 0 2.25 1.70667 2.25 3.80952V5.33333H1.5C0.675 5.33333 0 6.01905 0 6.85714V14.4762C0 15.3143 0.675 16 1.5 16H10.5C11.325 16 12 15.3143 12 14.4762V6.85714C12 6.01905 11.325 5.33333 10.5 5.33333ZM6 12.1905C5.175 12.1905 4.5 11.5048 4.5 10.6667C4.5 9.82857 5.175 9.14286 6 9.14286C6.825 9.14286 7.5 9.82857 7.5 10.6667C7.5 11.5048 6.825 12.1905 6 12.1905ZM3.75 5.33333V3.80952C3.75 2.54476 4.755 1.52381 6 1.52381C7.245 1.52381 8.25 2.54476 8.25 3.80952V5.33333H3.75Z"
        fill={fillPath}
      />
    </svg>
  );
};
