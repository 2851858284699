import { AriaRole } from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const colorCoal = colorPalette.neutrals.coal.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const BuildingIcon = ({
  ariaLabel = undefined,
  fillPath = colorCoal,
  fillSvg = "none",
  height = "20",
  role = undefined,
  rootClassName = "",
  title = "",
  viewBox = "0 0 69 69",
  width = "20",
}: IconProps & { ariaLabel: string | undefined; role: AriaRole | undefined; rootClassName: string }) => {
  return (
    <svg
      aria-label={ariaLabel}
      className={rootClassName}
      fill={fillSvg}
      height={height}
      role={role}
      viewBox={viewBox}
      xmlns={xmlnsUrl}
      width={width}
    >
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.86738 1.31055C4.86738 0.758262 5.3151 0.310547 5.86738 0.310547H49.0268C49.5791 0.310547 50.0268 0.758262 50.0268 1.31055V67.6121C50.0268 68.1644 49.5791 68.6121 49.0268 68.6121H41.463C40.9108 68.6121 40.463 68.1644 40.463 67.6121V55.8016H30.8448V67.6121C30.8448 68.1644 30.3971 68.6121 29.8448 68.6121H5.86738C5.3151 68.6121 4.86738 68.1644 4.86738 67.6121V1.31055ZM6.86738 2.31055V66.6121H28.8448V54.8016C28.8448 54.2493 29.2926 53.8016 29.8448 53.8016H41.463C42.0153 53.8016 42.463 54.2493 42.463 54.8016V66.6121H48.0268V2.31055H6.86738ZM14.4148 9.46873C14.4148 8.91645 14.8625 8.46873 15.4148 8.46873H23.3055C23.8578 8.46873 24.3055 8.91645 24.3055 9.46873V17.4284C24.3055 17.9807 23.8578 18.4284 23.3055 18.4284H15.4148C14.8625 18.4284 14.4148 17.9807 14.4148 17.4284V9.46873ZM16.4148 10.4687V16.4284H22.3055V10.4687H16.4148ZM30.3707 9.46873C30.3707 8.91645 30.8184 8.46873 31.3707 8.46873H39.2396C39.7919 8.46873 40.2396 8.91645 40.2396 9.46873V17.4284C40.2396 17.9807 39.7919 18.4284 39.2396 18.4284H31.3707C30.8184 18.4284 30.3707 17.9807 30.3707 17.4284V9.46873ZM32.3707 10.4687V16.4284H38.2396V10.4687H32.3707ZM14.4148 27.4608C14.4148 26.9085 14.8625 26.4608 15.4148 26.4608H23.3055C23.8578 26.4608 24.3055 26.9085 24.3055 27.4608V35.4425C24.3055 35.9948 23.8578 36.4425 23.3055 36.4425H15.4148C14.8625 36.4425 14.4148 35.9948 14.4148 35.4425V27.4608ZM16.4148 28.4608V34.4425H22.3055V28.4608H16.4148ZM30.3707 27.4608C30.3707 26.9085 30.8184 26.4608 31.3707 26.4608H39.2396C39.7919 26.4608 40.2396 26.9085 40.2396 27.4608V35.4425C40.2396 35.9948 39.7919 36.4425 39.2396 36.4425H31.3707C30.8184 36.4425 30.3707 35.9948 30.3707 35.4425V27.4608ZM32.3707 28.4608V34.4425H38.2396V28.4608H32.3707Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3754 32.4424C48.3754 31.8901 48.8231 31.4424 49.3754 31.4424H64.1324C64.6847 31.4424 65.1324 31.8901 65.1324 32.4424V67.6547C65.1324 68.207 64.6847 68.6547 64.1324 68.6547H49.092C48.5397 68.6547 48.092 68.207 48.092 67.6547C48.092 67.1024 48.5397 66.6547 49.092 66.6547H63.1324V33.4424H49.3754C48.8231 33.4424 48.3754 32.9946 48.3754 32.4424Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.290039 67.8973C0.290039 67.345 0.737754 66.8973 1.29004 66.8973H68.7103C69.2626 66.8973 69.7103 67.345 69.7103 67.8973C69.7103 68.4496 69.2626 68.8973 68.7103 68.8973H1.29004C0.737754 68.8973 0.290039 68.4496 0.290039 67.8973Z"
        fill={fillPath}
      />
    </svg>
  );
};
