import { useState, useEffect } from "react";
import { publish, subscribe, unsubscribe } from "gx-npm-messenger-util";

// sending messages
function localPublish<U>(message: U) {
  publish("WS_OUT", message);
}

export function useWebSocket<T, U>(): [T, (message: U) => void] {
  const [messages, setMessages] = useState<T>({ event: "" } as T);

  // receiving messages
  useEffect(() => {
    const updateMessageStateFn = (item: T): void => {
      setMessages(item);
    };
    subscribe("WS_IN", updateMessageStateFn);
    return () => {
      unsubscribe("WS_IN", updateMessageStateFn);
    };
  }, []);

  return [messages, localPublish];
}
