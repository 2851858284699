import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";
import { SquareQuadrantIconProps } from "./shape.types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

/** @deprecated - use an export from figma with 24x24 px dimensions */
export const CircleOutlinedEmptyIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "20",
  title = "",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM2 10C2 14.42 5.58 18 10 18C14.42 18 18 14.42 18 10C18 5.58 14.42 2 10 2C5.58 2 2 5.58 2 10Z"
        fill={fillPath}
      />
    </svg>
  );
};

/** @deprecated - use an export from figma with 24x24 px dimensions */
export const CircleOutlinedPartialFilledIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "20",
  title = "",
  viewBox = "0 0 20 20",
  width = "20",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M14.24 5.76C13.07 4.59 11.54 4 10 4V10L5.76 14.24C8.1 16.58 11.9 16.58 14.25 14.24C16.59 11.9 16.59 8.1 14.24 5.76ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const SquareQuadrantIcon = ({
  fill = "none",
  height = "85",
  width = "85",
  viewBox = "0 0 85 85",
  text = "Challengers",
  textFill = defaultColor,
  fontSize = "12px",
  fontWeight = "500",
  letterSpacing = "0.35px",
}: SquareQuadrantIconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns={xmlnsUrl}>
      <g opacity="0.3">
        <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="85" height="85">
          <rect x="0.5" y="0.5" width="84" height="84" fill="#F4F4F4" stroke="#BBB9B8" />
        </mask>
        <g mask="url(#mask0)">
          <path d="M-107 87.5C-106.2 87.1 -13.1667 -6.16667 1.5 -21" stroke="#BBB9B8" />
          <path d="M-101 88C-100.2 87.6 -7.16667 -5.66667 7.5 -20.5" stroke="#BBB9B8" />
          <path d="M-95 88.5C-94.2 88.1 -1.16667 -5.16667 13.5 -20" stroke="#BBB9B8" />
          <path d="M-89 89C-88.2 88.6 4.83333 -4.66667 19.5 -19.5" stroke="#BBB9B8" />
          <path d="M-83 89.5C-82.2 89.1 10.8333 -4.16667 25.5 -19" stroke="#BBB9B8" />
          <path d="M-77 90C-76.2 89.6 16.8333 -3.66667 31.5 -18.5" stroke="#BBB9B8" />
          <path d="M-71 90.5C-70.2 90.1 22.8333 -3.16667 37.5 -18" stroke="#BBB9B8" />
          <path d="M-65 91C-64.2 90.6 28.8333 -2.66667 43.5 -17.5" stroke="#BBB9B8" />
          <path d="M-59 91.5C-58.2 91.1 34.8333 -2.16667 49.5 -17" stroke="#BBB9B8" />
          <path d="M-53 92C-52.2 91.6 40.8333 -1.66667 55.5 -16.5" stroke="#BBB9B8" />
          <path d="M-47 92.5C-46.2 92.1 46.8333 -1.16667 61.5 -16" stroke="#BBB9B8" />
          <path d="M-41 93C-40.2 92.6 52.8333 -0.666667 67.5 -15.5" stroke="#BBB9B8" />
          <path d="M-35 93.5C-34.2 93.1 58.8333 -0.166667 73.5 -15" stroke="#BBB9B8" />
          <path d="M-29 94C-28.2 93.6 64.8333 0.333333 79.5 -14.5" stroke="#BBB9B8" />
          <path d="M-23 94.5C-22.2 94.1 70.8333 0.833333 85.5 -14" stroke="#BBB9B8" />
          <path d="M-17 95C-16.2 94.6 76.8333 1.33333 91.5 -13.5" stroke="#BBB9B8" />
          <path d="M-11 95.5C-10.2 95.1 82.8333 1.83333 97.5 -13" stroke="#BBB9B8" />
          <path d="M-5 96C-4.2 95.6 88.8333 2.33333 103.5 -12.5" stroke="#BBB9B8" />
          <path d="M1 96.5C1.8 96.1 94.8333 2.83333 109.5 -12" stroke="#BBB9B8" />
          <path d="M7 97C7.8 96.6 100.833 3.33333 115.5 -11.5" stroke="#BBB9B8" />
          <path d="M13 97.5C13.8 97.1 106.833 3.83333 121.5 -11" stroke="#BBB9B8" />
          <path d="M19 98C19.8 97.6 112.833 4.33333 127.5 -10.5" stroke="#BBB9B8" />
          <path d="M25 98.5C25.8 98.1 118.833 4.83333 133.5 -10" stroke="#BBB9B8" />
          <path d="M31 99C31.8 98.6 124.833 5.33333 139.5 -9.5" stroke="#BBB9B8" />
          <path d="M37 99.5C37.8 99.1 130.833 5.83333 145.5 -9" stroke="#BBB9B8" />
          <path d="M43 100C43.8 99.6 136.833 6.33333 151.5 -8.5" stroke="#BBB9B8" />
          <path d="M49 100.5C49.8 100.1 142.833 6.83333 157.5 -8" stroke="#BBB9B8" />
          <path d="M55 101C55.8 100.6 148.833 7.33333 163.5 -7.5" stroke="#BBB9B8" />
          <path d="M61 101.5C61.8 101.1 154.833 7.83333 169.5 -7" stroke="#BBB9B8" />
          <path d="M67 102C67.8 101.6 160.833 8.33333 175.5 -6.5" stroke="#BBB9B8" />
          <path d="M73 102.5C73.8 102.1 166.833 8.83333 181.5 -6" stroke="#BBB9B8" />
        </g>
      </g>
      <text
        x="50%"
        y="50%"
        fill={textFill}
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={fontSize}
        fontWeight={fontWeight}
        letterSpacing={letterSpacing}
      >
        {text}
      </text>
    </svg>
  );
};
