import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "../iconButton";
import { TooltipV2 } from "../tooltip-v2";

const propTypes = {
  ariaLabel: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.any, // ReactNode | ReactNode[] | string
  className: PropTypes.string,
  clickable: PropTypes.bool,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  fillColor: PropTypes.string,
  focusBackgroundColor: PropTypes.string,
  hover: PropTypes.bool,
  hoverBackgroundColor: PropTypes.string,
  hoverFill: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onMouseUp: PropTypes.func,
  rippleBackgroundColor: PropTypes.string,
  showTooltip: PropTypes.bool,
  style: PropTypes.object,
  tooltipOptions: PropTypes.object,
  tooltipText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  transparentBackground: PropTypes.string,
};
const ToolTipIconButton = ({
  ariaLabel = "",
  backgroundColor,
  children,
  className,
  clickable = true,
  cursor,
  disabled = false,
  fillColor,
  focusBackgroundColor,
  hover = false,
  hoverBackgroundColor,
  hoverFill,
  onClick,
  onKeyPress,
  onMouseUp,
  rippleBackgroundColor,
  style,
  tooltipOptions = {},
  tooltipText = "",
  transparentBackground,
}) => {
  return (
    <TooltipV2 title={tooltipText} placement="top" {...tooltipOptions}>
      <div>
        <IconButton
          ariaLabel={ariaLabel}
          backgroundColor={backgroundColor}
          className={className}
          clickable={clickable}
          cursor={cursor}
          disabled={disabled}
          fillColor={fillColor}
          focusBackgroundColor={focusBackgroundColor}
          hover={hover}
          hoverBackgroundColor={hoverBackgroundColor}
          hoverFill={hoverFill}
          onClick={onClick}
          onKeyPress={onKeyPress}
          onMouseUp={onMouseUp}
          rippleBackgroundColor={rippleBackgroundColor}
          style={style}
          transparentBackground={transparentBackground}
        >
          {children}
        </IconButton>
      </div>
    </TooltipV2>
  );
};

ToolTipIconButton.propTypes = propTypes;
export default ToolTipIconButton;
