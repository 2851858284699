import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { LogoProps } from "./logo.types";
import { logoColorVariants } from "./logo.constants";

const defaultColor = colorPalette.basic.white.hex;
const defaultBetaColor = colorPalette.neutrals.silver.hex;
const defaultGartnerBlueColor = colorPalette.brand.gartnerBlue.hex;

const validTypes: Record<string, string> = {
  blue: defaultGartnerBlueColor,
  white: defaultColor,
};

const xmlnsUrl = "http://www.w3.org/2000/svg";

export const GartnerXBetaLogo = ({
  fillPath = defaultColor,
  betaTextFillPath = defaultBetaColor,
  fillSvg = "none",
  height = "22",
  title = "",
  viewBox = "0 0 180 22",
  width = "180",
}: LogoProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M125.146 17.0752L117.065 8.99414C117.38 9.66608 117.904 10.2429 117.904 11.004C117.904 11.777 117.6 12.4965 117.059 13.0436C117.059 13.0436 117.059 13.0436 117.053 13.0495C117.053 13.0495 117.053 13.0495 117.047 13.0555L115.008 15.0951L121.067 21.1544C122.191 22.2783 124.022 22.2783 125.146 21.1544C125.693 20.6073 125.991 19.8878 125.991 19.1148C125.991 18.3477 125.693 17.6223 125.146 17.0752Z"
        fill="#CC4308"
      />
      <path d="M115.347 7.2692L114.842 6.75781L115.347 7.2692Z" fill="#993206" />
      <path
        d="M119.718 11.6462L117.066 8.99414C117.37 9.51147 117.905 10.2429 117.905 11.01C117.905 11.783 117.601 12.5025 117.06 13.0495C117.06 13.0495 117.06 13.0495 117.054 13.0555C117.054 13.0555 117.054 13.0555 117.048 13.0614L115.003 15.107L116.525 16.6292C116.549 16.653 116.597 16.653 116.62 16.6292L118.636 14.6134C119.403 13.8345 119.802 12.7463 119.718 11.6462Z"
        fill="#993206"
      />
      <path
        d="M125.147 0.918599C124.6 0.371536 123.88 0.0742188 123.107 0.0742188C122.334 0.0742188 121.615 0.377482 121.068 0.918599L117.464 4.52208C117.44 4.54587 117.44 4.59344 117.464 4.61722L121.448 8.60127C121.472 8.62506 121.52 8.62506 121.543 8.60127L125.147 4.99779C125.694 4.45073 125.991 3.73122 125.991 2.95819C125.991 2.18517 125.688 1.45972 125.147 0.918599Z"
        fill="#FF540A"
      />
      <path
        d="M109.003 0.924459C108.438 0.359556 107.7 0.0800781 106.963 0.0800781C106.226 0.0800781 105.488 0.359556 104.923 0.924459C104.376 1.47152 104.079 2.19103 104.079 2.96405C104.079 3.73708 104.382 4.45658 104.923 5.00365L110.929 11.0095L104.9 17.039C103.776 18.1629 103.776 19.9944 104.9 21.1182C106.023 22.2421 107.855 22.2421 108.979 21.1182L117.042 13.055C117.042 13.055 117.042 13.055 117.048 13.049C117.048 13.049 117.048 13.049 117.054 13.0431C117.601 12.496 117.898 11.7765 117.898 11.0035C117.898 10.2305 117.595 9.52287 117.048 8.9758"
        fill="#FF540A"
      />
      <path
        d="M95.9736 10.4844L96.2531 7.14258H94.9449C93.2859 7.14258 91.942 7.70153 91.1749 8.93243L91.0917 7.14258H87.4287V21.4614H91.2938V13.9095C91.2938 12.2326 92.4296 10.4844 94.6059 10.4844H95.9736Z"
        fill={fillPath}
      />
      <path
        d="M64.3335 6.79297C62.2226 6.79297 60.7895 7.75033 60.0224 8.97528L59.9392 7.1438H56.3535V21.4626H60.2186V13.6669C60.2186 11.8652 61.0749 10.3132 63.2215 10.3132C65.4514 10.3132 65.5882 12.3112 65.5882 13.8929V21.4626H69.4593V12.4717C69.4593 9.57586 68.2284 6.79297 64.3335 6.79297Z"
        fill={fillPath}
      />
      <path
        d="M50.8173 16.3955V10.4908H54.397L54.6646 7.14898H50.8173V3.10547H46.9521V16.6869C46.9521 20.0466 48.0106 21.7175 51.6557 21.7175C52.6547 21.7175 53.4753 21.6283 54.4029 21.3786L54.6705 18.12C53.838 18.3995 53.172 18.4708 52.5714 18.4708C51.1859 18.4708 50.8173 17.7394 50.8173 16.3955Z"
        fill={fillPath}
      />
      <path
        d="M40.9404 8.93243L40.8631 7.14258H37.2002V21.4614H41.0653V13.9095C41.0653 12.2326 42.1476 10.4844 44.3239 10.4844H45.3764L45.6737 7.14258H44.7104C43.0573 7.14258 41.7135 7.70748 40.9404 8.93243Z"
        fill={fillPath}
      />
      <path
        d="M11.3337 13.0958H15.6627V17.5377C14.4496 18.0848 13.0463 18.3464 11.084 18.2988C6.92154 18.1918 4.25758 15.1948 4.3111 10.854C4.36461 6.84619 6.77883 3.93248 10.7926 3.7422C13.2544 3.62922 15.3118 4.32494 16.7092 5.52015L19.4326 2.767C17.173 0.774973 14.2831 -0.045622 11.0483 0.00194877C4.67382 0.0970902 0 4.37845 0 11.08C0 17.8112 4.62625 21.7953 11.0483 21.9915C14.5091 22.0926 17.1314 21.2839 19.7537 19.8806V9.45663H11.6251L11.3337 13.0958Z"
        fill={fillPath}
      />
      <path
        d="M78.5864 6.79297C74.4181 6.79297 71.207 9.81371 71.207 14.3032C71.207 18.7986 74.4359 21.8134 78.747 21.8134C81.1969 21.8134 83.3495 21.0107 85.1453 18.7986L82.624 16.872C81.3515 18.1624 80.3763 18.6857 78.634 18.6381C76.8442 18.5905 75.4051 17.4904 75.1019 15.659H85.7042V14.5113C85.6983 9.57586 83.177 6.79297 78.5864 6.79297ZM75.0959 12.668C75.3576 11.0803 76.6063 9.86128 78.4854 9.78992C80.3525 9.71857 81.7083 10.8603 81.8272 12.668H75.0959Z"
        fill={fillPath}
      />
      <path
        d="M28.3763 6.79297C26.1286 6.79297 24.0236 7.40544 21.9959 9.04663L24.2258 11.3122C25.2545 10.3489 26.64 9.78398 28.0433 9.78398C29.9997 9.78398 31.0403 10.6343 31.0403 12.2041V12.3944H30.3565C26.9492 12.3944 21.5202 12.7334 21.5975 17.4845C21.651 20.5112 24.1366 21.8075 26.7708 21.8075C28.519 21.8075 30.2197 21.3437 31.2662 20.0474L31.3495 21.4566H34.7567V12.0852C34.7686 8.84446 32.8123 6.79297 28.3763 6.79297ZM31.0462 15.8136C31.0462 19.0662 25.4924 19.9225 25.4745 17.2288C25.4626 15.3141 28.6796 15.2249 30.5111 15.2189H31.0462V15.8136Z"
        fill={fillPath}
      />
      <path
        d="M95.6889 19.3217C95.6889 18.8817 95.4272 18.6914 94.9039 18.6914H94.0596V20.9153H94.3926V19.952H94.7791L95.3618 20.9153H95.7424L95.1121 19.9342C95.4391 19.8985 95.6889 19.7201 95.6889 19.3217ZM94.3985 19.6666V18.9768H94.8564C95.0942 18.9768 95.338 19.0303 95.338 19.3039C95.338 19.6428 95.0823 19.6666 94.7969 19.6666H94.3985Z"
        fill={fillPath}
      />
      <path
        d="M94.803 17.877C93.7446 17.877 92.8467 18.6916 92.8467 19.7976C92.8467 20.9155 93.7446 21.7302 94.803 21.7302C95.8555 21.7302 96.7475 20.9155 96.7475 19.7976C96.7475 18.6916 95.8555 17.877 94.803 17.877ZM94.803 21.4091C93.923 21.4091 93.2332 20.7253 93.2332 19.7976C93.2332 18.8878 93.923 18.1981 94.803 18.1981C95.6712 18.1981 96.361 18.8878 96.361 19.7976C96.3669 20.7253 95.6771 21.4091 94.803 21.4091Z"
        fill={fillPath}
      />
      <rect x="141.5" y="5.5" width="38" height="13" stroke={betaTextFillPath} />
      <path
        d="M150.688 9.04255H148V15H150.774C152.109 15 152.991 14.4298 152.991 13.3574C152.991 12.5404 152.503 12.1404 151.801 11.9021C152.246 11.6723 152.657 11.2979 152.657 10.566C152.657 9.62979 151.895 9.04255 150.688 9.04255ZM150.474 11.5532H149.036V9.97021H150.551C151.228 9.97021 151.604 10.2511 151.604 10.7362C151.604 11.2894 151.15 11.5532 150.474 11.5532ZM150.782 14.0723H149.036V12.4383H150.705C151.536 12.4383 151.938 12.7362 151.938 13.2468C151.938 13.7915 151.501 14.0723 150.782 14.0723Z"
        fill={betaTextFillPath}
      />
      <path
        d="M159.478 9.04255H155.034V15H159.52V14.0638H156.087V12.4638H159.092V11.5277H156.087V9.97872H159.478V9.04255Z"
        fill={betaTextFillPath}
      />
      <path d="M164.16 10.0128H166.06V9.04255H161.198V10.0128H163.098V15H164.16V10.0128Z" fill={betaTextFillPath} />
      <path
        d="M170.363 9H169.387L166.75 15H167.829L168.446 13.5617H171.279L171.887 15H173L170.363 9ZM170.894 12.634H168.831L169.858 10.2511L170.894 12.634Z"
        fill={betaTextFillPath}
      />
    </svg>
  );
};

export const GartnerXOriginalLogo = ({
  fillSvg = "none",
  height = "16",
  title = "",
  viewBox = "0 0 92 16",
  width = "92",
}: LogoProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width}>
      {!!title && <title>{title}</title>}
      <path
        d="M91.1916 12.4162L85.3029 6.53906C85.5326 7.02774 85.9139 7.44723 85.9139 8.00078C85.9139 8.56298 85.6929 9.08626 85.2986 9.48412C85.2986 9.48412 85.2986 9.48412 85.2943 9.48845C85.2943 9.48845 85.2943 9.48845 85.2899 9.49277L83.8037 10.9761L88.2191 15.3829C89.038 16.2002 90.3726 16.2002 91.1916 15.3829C91.5902 14.985 91.8068 14.4618 91.8068 13.8996C91.8068 13.3417 91.5902 12.8141 91.1916 12.4162Z"
        fill="#CC4308"
      />
      <path d="M84.0502 5.28598L83.6826 4.91406L84.0502 5.28598Z" fill="#993206" />
      <path
        d="M87.2359 8.46784L85.3034 6.53906C85.5244 6.9153 85.9143 7.44723 85.9143 8.00511C85.9143 8.56731 85.6933 9.09058 85.299 9.48845C85.299 9.48845 85.299 9.48845 85.2947 9.49277C85.2947 9.49277 85.2947 9.49277 85.2904 9.4971L83.7998 10.9848L84.9091 12.0919C84.9264 12.1092 84.9611 12.1092 84.9784 12.0919L86.4473 10.6258C87.0063 10.0593 87.2966 9.26789 87.2359 8.46784Z"
        fill="#993206"
      />
      <path
        d="M91.1919 0.668782C90.7932 0.270918 90.2689 0.0546875 89.7056 0.0546875C89.1423 0.0546875 88.618 0.275243 88.2194 0.668782L85.5936 3.2895C85.5762 3.3068 85.5762 3.34139 85.5936 3.35869L88.4967 6.25618C88.514 6.27348 88.5487 6.27348 88.566 6.25618L91.1919 3.63547C91.5905 3.2376 91.8072 2.71432 91.8072 2.15212C91.8072 1.58992 91.5862 1.06232 91.1919 0.668782Z"
        fill="#FF540A"
      />
      <path
        d="M79.4272 0.672444C79.0156 0.261606 78.4783 0.0583496 77.941 0.0583496C77.4037 0.0583496 76.8664 0.261606 76.4548 0.672444C76.0561 1.07031 75.8395 1.59359 75.8395 2.15579C75.8395 2.71799 76.0605 3.24126 76.4548 3.63913L80.8312 8.00699L76.4375 12.3921C75.6185 13.2095 75.6185 14.5415 76.4375 15.3588C77.2564 16.1762 78.591 16.1762 79.4099 15.3588L85.2855 9.49465C85.2855 9.49465 85.2855 9.49465 85.2899 9.49033C85.2899 9.49033 85.2899 9.49033 85.2942 9.486C85.6928 9.08814 85.9095 8.56486 85.9095 8.00266C85.9095 7.44046 85.6885 6.92583 85.2899 6.52797"
        fill="#FF540A"
      />
      <path
        d="M69.9375 7.62574L70.1412 5.19531H69.1879C67.979 5.19531 66.9997 5.60183 66.4408 6.49702L66.3801 5.19531H63.7109V15.609H66.5274V10.1167C66.5274 8.89718 67.355 7.62574 68.9409 7.62574H69.9375Z"
        fill="white"
      />
      <path
        d="M46.8774 4.94141C45.3392 4.94141 44.2949 5.63767 43.736 6.52854L43.6753 5.19656H41.0625V15.6102H43.879V9.94066C43.879 8.6303 44.5029 7.50158 46.0672 7.50158C47.692 7.50158 47.7917 8.95465 47.7917 10.105V15.6102H50.6125V9.07141C50.6125 6.96532 49.7156 4.94141 46.8774 4.94141Z"
        fill="white"
      />
      <path
        d="M37.0313 11.9233V7.62898H39.6398L39.8348 5.19855H37.0313V2.25781H34.2148V12.1352C34.2148 14.5786 34.9861 15.7938 37.6423 15.7938C38.3702 15.7938 38.9682 15.729 39.6441 15.5473L39.8391 13.1775C39.2325 13.3807 38.7472 13.4326 38.3096 13.4326C37.3 13.4326 37.0313 12.9007 37.0313 11.9233Z"
        fill="white"
      />
      <path
        d="M29.8349 6.49702L29.7785 5.19531H27.1094V15.609H29.9259V10.1167C29.9259 8.89718 30.7145 7.62574 32.3004 7.62574H33.0673L33.284 5.19531H32.582C31.3774 5.19531 30.3982 5.60615 29.8349 6.49702Z"
        fill="white"
      />
      <path
        d="M8.25878 9.52421H11.4132V12.7547C10.5293 13.1526 9.5067 13.3428 8.07679 13.3082C5.04366 13.2304 3.10246 11.0508 3.14146 7.89383C3.18045 4.97905 4.93967 2.85999 7.86447 2.7216C9.65835 2.63943 11.1576 3.14541 12.1758 4.01466L14.1604 2.01236C12.5138 0.563617 10.408 -0.0331796 8.05079 0.00141729C3.40577 0.0706111 0 3.18433 0 8.05817C0 12.9536 3.37111 15.8511 8.05079 15.9938C10.5726 16.0674 12.4835 15.4792 14.3944 14.4586V6.87755H8.4711L8.25878 9.52421Z"
        fill="white"
      />
      <path
        d="M57.2674 4.9375C54.23 4.9375 51.8901 7.1344 51.8901 10.3995C51.8901 13.6689 54.243 15.8615 57.3844 15.8615C59.1696 15.8615 60.7382 15.2776 62.0468 13.6689L60.2096 12.2677C59.2823 13.2062 58.5717 13.5867 57.3021 13.5521C55.9979 13.5175 54.9493 12.7175 54.7283 11.3855H62.4541V10.5508C62.4498 6.96142 60.6125 4.9375 57.2674 4.9375ZM54.7239 9.21022C54.9146 8.05554 55.8245 7.169 57.1938 7.1171C58.5544 7.06521 59.5423 7.89553 59.6289 9.21022H54.7239Z"
        fill="white"
      />
      <path
        d="M20.6776 4.94141C19.0397 4.94141 17.5058 5.38684 16.0282 6.58043L17.6531 8.22811C18.4027 7.52752 19.4123 7.11669 20.4349 7.11669C21.8605 7.11669 22.6188 7.73511 22.6188 8.8768V9.01519H22.1205C19.6376 9.01519 15.6816 9.26169 15.7379 12.7171C15.7769 14.9183 17.5881 15.8611 19.5076 15.8611C20.7816 15.8611 22.0208 15.5237 22.7834 14.581L22.8441 15.6059H25.3269V8.79031C25.3356 6.4334 23.91 4.94141 20.6776 4.94141ZM22.6231 11.5018C22.6231 13.8674 18.576 14.4901 18.563 12.5311C18.5544 11.1386 20.8986 11.0737 22.2331 11.0694H22.6231V11.5018Z"
        fill="white"
      />
      <path
        d="M69.7268 14.0522C69.7268 13.7321 69.5362 13.5938 69.1548 13.5938H68.5396V15.2112H68.7822V14.5106H69.0638L69.4885 15.2112H69.7658L69.3065 14.4976C69.5448 14.4716 69.7268 14.3419 69.7268 14.0522ZM68.7865 14.303V13.8013H69.1202C69.2935 13.8013 69.4712 13.8403 69.4712 14.0392C69.4712 14.2857 69.2848 14.303 69.0768 14.303H68.7865Z"
        fill="white"
      />
      <path
        d="M69.0857 13C68.3144 13 67.6602 13.5925 67.6602 14.3968C67.6602 15.2099 68.3144 15.8023 69.0857 15.8023C69.8527 15.8023 70.5026 15.2099 70.5026 14.3968C70.5026 13.5925 69.8527 13 69.0857 13ZM69.0857 15.5688C68.4444 15.5688 67.9418 15.0715 67.9418 14.3968C67.9418 13.7352 68.4444 13.2335 69.0857 13.2335C69.7184 13.2335 70.221 13.7352 70.221 14.3968C70.2253 15.0715 69.7227 15.5688 69.0857 15.5688Z"
        fill="white"
      />
    </svg>
  );
};

export const GartnerFavoriteIcon = () => {
  return (
    <svg height="16" viewBox="0 0 16 16" width="16">
      <title>Gartner Favorite Icon</title>
      <path
        d="M8.45876 9.52596H11.3076V12.7556C10.5093 13.1533 9.58579 13.3436 8.2944 13.309C5.55509 13.2312 3.80193 11.0521 3.83715 7.89602C3.87237 4.98202 5.46117 2.86354 8.10265 2.72519C9.72276 2.64304 11.0768 3.14888 11.9964 4.0179L13.7887 2.01614C12.3016 0.563464 10.3958 -0.0331707 8.27092 0.00141682C4.07586 0.0749154 1 3.18779 1 8.06032C1 12.9545 4.04455 15.8512 8.27092 15.9938C10.5485 16.0673 12.2742 15.4793 14 14.459V6.88002H8.65051L8.45876 9.52596Z"
        fill={defaultGartnerBlueColor}
      />
    </svg>
  );
};

export const BuySmartLogo = ({ height = "44", variant = logoColorVariants.blue, width = "223" }: LogoProps) => {
  const fillPath = validTypes[variant] || validTypes[logoColorVariants.blue];

  return (
    <svg height={height} viewBox="0 0 223 44" width={width}>
      <title>Buy Smart Logo Regular</title>
      <g clipPath="url(#clip0_11190_43181)">
        <path
          d="M88.22 10.98C88.22 13.27 89.28 14.35 94.22 15.06C101.98 16.05 105.98 18.13 105.97 24.8C105.97 30.5 101.3 35.03 93.71 35.03C84.92 35.03 80.88 30.91 80.47 24.49H87.47C87.75 27.36 88.99 29.74 93.75 29.74C96.94 29.74 99.05 28.02 99.05 25.47C99.05 22.92 97.7 22.02 92.99 21.3C84.78 20.23 81.41 17.76 81.41 11.56C81.41 6.13 85.86 2 93.05 2C100.24 2 104.46 5.24 105.05 11.61H98.36C97.91 8.69 96.21 7.34001 93.02 7.34001C89.83 7.34001 88.22 8.69001 88.22 10.98Z"
          fill={fillPath}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9183 15.3825C20.9636 16.6438 19.587 17.5196 18.04 17.85C22.21 18.7 24.46 21.13 24.46 25.36V25.54C24.46 31.44 20.6 34.54 13.19 34.54H0V2.45001H12.61C19.61 2.45001 23.24 5.33 23.24 10.8V10.98C23.3421 12.5586 22.873 14.1212 21.9183 15.3825ZM11.41 7.43001H7.09L7.10001 15.65H11.45C14.95 15.65 16.34 14.39 16.34 11.52V11.34C16.34 8.65001 14.82 7.43001 11.41 7.43001ZM12.07 29.51C15.66 29.51 17.28 27.9 17.28 24.98L17.26 24.8C17.26 21.84 15.64 20.32 11.69 20.32H7.07001V29.51H12.07Z"
          fill={fillPath}
        />
        <path
          d="M39.52 30.01C42.25 30.01 44.41 28.3 44.41 24.85V11.07H50.88V34.54H44.42V30.86C43.21 33.24 40.83 35.03 36.97 35.03C32.38 35.03 28.97 32.34 28.97 26.19V11.07H35.43V25.3C35.43 28.48 36.79 30.01 39.52 30.01Z"
          fill={fillPath}
        />
        <path
          d="M66.81 25.16L61.11 11.07H54.11L63.44 32.16L59.31 42.53H65.6L78.3 11.07H72.06L66.81 25.16Z"
          fill={fillPath}
        />
        <path
          d="M138.91 10.53C137.358 10.4999 135.823 10.8573 134.443 11.5696C133.064 12.282 131.884 13.327 131.01 14.61C129.89 11.83 127.55 10.53 124.46 10.53C123 10.4976 121.56 10.8654 120.294 11.5934C119.028 12.3214 117.986 13.3819 117.28 14.66V11.07H110.77V34.54H117.28V20.67C117.28 17.49 119.28 15.87 121.81 15.87C124.1 15.87 125.49 17.22 125.49 20.23V34.54H131.95V20.67C131.95 17.49 133.95 15.87 136.48 15.87C138.82 15.87 140.16 17.22 140.16 20.23V34.54H146.63V19.54C146.63 13.27 143.26 10.53 138.91 10.53Z"
          fill={fillPath}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M151.7 18.53C152.1 13 156.5 10.53 162.24 10.53C167.99 10.53 172.07 12.86 172.07 19.21V34.51H165.74V31.68C164.4 33.57 162.33 35 158.65 35C154.39 35 150.8 32.94 150.8 28C150.8 22.53 155.83 20.46 163.01 20.46H165.66V19.52C165.66 16.74 164.85 15.21 161.85 15.21C159.28 15.21 158.12 16.53 157.85 18.53H151.7ZM160.58 30.5C163.46 30.5 165.65 28.93 165.65 26.33L165.68 24.35H163.17C159.37 24.35 157.17 25.16 157.17 27.67C157.17 29.38 158.21 30.5 160.58 30.5Z"
          fill={fillPath}
        />
        <path
          d="M184.07 15.56V11.07H177.56V34.54H184.07V22.78C184.07 18.29 186.85 16.72 191.65 16.78V10.72C190.039 10.6337 188.442 11.0542 187.082 11.9223C185.723 12.7904 184.669 14.0625 184.07 15.56Z"
          fill={fillPath}
        />
        <path
          d="M203.67 15.69V27C203.67 28.89 204.61 29.78 206.27 29.8C207.115 29.8255 207.956 29.6757 208.74 29.36V34.36C207.469 34.7956 206.133 35.0087 204.79 34.99C199.85 34.99 197.21 32.48 197.21 27.59V15.69H194.21V11.07H197.21V6H203.67V11.07H208.6V15.69H203.67Z"
          fill={fillPath}
        />
        <path d="M211.38 6.94H212.82V11.34H214.04V6.94H215.48V5.99001H211.38V6.94Z" fill={fillPath} />
        <path
          d="M219.21 9.32L220.44 5.99001H222.01V11.34H220.81V7.59L219.33 11.34H218.93L217.44 7.59V11.34H216.36V5.99001H217.96L219.21 9.32Z"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_11190_43181">
          <rect width="223" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BuySmartLogoOrangeStacked = ({
  height = "34",
  width = "80",
  filltop = "#ED6926",
  fillbottom = "#002856",
}: LogoProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 80 34" xmlns="http://www.w3.org/2000/svg">
      <title>Buy Smart Logo Regular</title>
      <g clipPath="url(#clip0_1011_564)">
        <g clipPath="url(#clip1_1011_564)">
          <path
            d="M31.9649 21.9198C31.9649 22.7471 32.349 23.1372 34.1389 23.3937C36.9506 23.7514 38.4 24.5028 38.3963 26.9123C38.3963 28.9715 36.7043 30.6079 33.9542 30.6079C30.7693 30.6079 29.3054 29.1196 29.1569 26.8003H31.6932C31.7947 27.8371 32.2439 28.6969 33.9686 28.6969C35.1245 28.6969 35.889 28.0756 35.889 27.1544C35.889 26.2332 35.3999 25.9081 33.6933 25.648C30.7185 25.2614 29.4975 24.3691 29.4975 22.1294C29.4975 20.1678 31.1099 18.6758 33.715 18.6758C36.3202 18.6758 37.8492 19.8462 38.063 22.1474H35.639C35.476 21.0926 34.86 20.6049 33.7041 20.6049C32.5483 20.6049 31.9649 21.0926 31.9649 21.9198Z"
            fill={filltop}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.94169 23.5102C7.59577 23.9659 7.097 24.2823 6.53648 24.4016C8.0474 24.7087 8.86265 25.5865 8.86265 27.1146V27.1797C8.86265 29.3111 7.46404 30.4309 4.77916 30.4309H0V18.8383H4.56901C7.10534 18.8383 8.4206 19.8788 8.4206 21.8548V21.9198C8.45759 22.4901 8.28761 23.0546 7.94169 23.5102ZM4.13421 20.6374H2.56893L2.57256 23.6069H4.1487C5.41687 23.6069 5.92051 23.1517 5.92051 22.1149V22.0499C5.92051 21.0781 5.36976 20.6374 4.13421 20.6374ZM4.37335 28.6138C5.67413 28.6138 6.2611 28.0322 6.2611 26.9774L6.25386 26.9123C6.25386 25.843 5.66688 25.2939 4.23566 25.2939H2.56169V28.6138H4.37335Z"
            fill={filltop}
          />
          <path
            d="M14.3194 28.7945C15.3085 28.7945 16.0912 28.1767 16.0912 26.9304V21.9523H18.4355V30.4309H16.0948V29.1015C15.6564 29.9613 14.794 30.6079 13.3954 30.6079C11.7323 30.6079 10.4968 29.6362 10.4968 27.4145V21.9523H12.8374V27.093C12.8374 28.2417 13.3302 28.7945 14.3194 28.7945Z"
            fill={filltop}
          />
          <path
            d="M24.2074 27.0424L22.1421 21.9523H19.6058L22.9864 29.5712L21.4899 33.3173H23.769L28.3706 21.9523H26.1097L24.2074 27.0424Z"
            fill={filltop}
          />
          <path
            d="M50.3316 21.7573C49.7692 21.7464 49.2129 21.8755 48.7132 22.1328C48.2134 22.3902 47.7858 22.7677 47.4691 23.2312C47.0633 22.2269 46.2155 21.7573 45.0959 21.7573C44.567 21.7456 44.0449 21.8784 43.5863 22.1414C43.1278 22.4044 42.7501 22.7875 42.4943 23.2492V21.9523H40.1355V30.4309H42.4943V25.4204C42.4943 24.2716 43.219 23.6864 44.1357 23.6864C44.9654 23.6864 45.4691 24.174 45.4691 25.2614V30.4309H47.8097V25.4204C47.8097 24.2716 48.5344 23.6864 49.4511 23.6864C50.299 23.6864 50.7845 24.174 50.7845 25.2614V30.4309H53.1288V25.0121C53.1288 22.7471 51.9077 21.7573 50.3316 21.7573Z"
            fill={filltop}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.9658 24.6473C55.1107 22.6496 56.705 21.7573 58.7848 21.7573C60.8682 21.7573 62.3465 22.599 62.3465 24.8929V30.4201H60.0529V29.3978C59.5674 30.0805 58.8174 30.5971 57.484 30.5971C55.9405 30.5971 54.6397 29.8529 54.6397 28.0683C54.6397 26.0923 56.4622 25.3445 59.0638 25.3445H60.024V25.0049C60.024 24.0006 59.7305 23.4479 58.6435 23.4479C57.7123 23.4479 57.292 23.9248 57.1941 24.6473H54.9658ZM58.1833 28.9715C59.2268 28.9715 60.0203 28.4043 60.0203 27.4651L60.0312 26.7498H59.1218C57.7449 26.7498 56.9478 27.0424 56.9478 27.9491C56.9478 28.5669 57.3246 28.9715 58.1833 28.9715Z"
            fill={filltop}
          />
          <path
            d="M66.6945 23.5744V21.9523H64.3357V30.4309H66.6945V26.1826C66.6945 24.5606 67.7018 23.9934 69.441 24.0151V21.8259C68.8573 21.7947 68.2786 21.9466 67.786 22.2602C67.2934 22.5738 66.9117 23.0334 66.6945 23.5744Z"
            fill={filltop}
          />
          <path
            d="M73.7962 23.6213V27.7071C73.7962 28.3899 74.1368 28.7114 74.7383 28.7186C75.0444 28.7278 75.3491 28.6737 75.6332 28.5596V30.3659C75.1728 30.5233 74.6887 30.6002 74.202 30.5935C72.4121 30.5935 71.4555 29.6868 71.4555 27.9202V23.6213H70.3685V21.9523H71.4555V20.1208H73.7962V21.9523H75.5825V23.6213H73.7962Z"
            fill={filltop}
          />
          <path d="M76.5898 20.4604H77.1115V22.0499H77.5536V20.4604H78.0753V20.1172H76.5898V20.4604Z" fill={filltop} />
          <path
            d="M79.4268 21.3201L79.8725 20.1172H80.4414V22.0499H80.0066V20.6952L79.4703 22.0499H79.3254L78.7855 20.6952V22.0499H78.3942V20.1172H78.9739L79.4268 21.3201Z"
            fill={filltop}
          />
        </g>
        <g clipPath="url(#clip2_1011_564)">
          <path
            d="M47.7527 3.54713L47.6131 5.20532H46.9353C45.8531 5.20532 45.2916 6.07223 45.2916 6.90423V10.6511H43.3745V3.54713H45.1898L45.2305 4.4344C45.6116 3.8264 46.2778 3.54713 47.1011 3.54713H47.7527Z"
            fill={fillbottom}
          />
          <path
            d="M34.4611 10.6511H32.5411V6.8955C32.5411 6.11004 32.4742 5.11804 31.3658 5.11804C30.3011 5.11804 29.8764 5.88604 29.8764 6.78204V10.6511H27.9593V3.54713H29.7367L29.7774 4.45477C30.1585 3.84677 30.8684 3.37259 31.9156 3.37259C33.8502 3.37259 34.4582 4.7544 34.4582 6.18858V10.6511H34.4611Z"
            fill={fillbottom}
          />
          <path
            d="M27.1214 8.99295L26.9876 10.6104C26.528 10.7326 26.1178 10.7791 25.6233 10.7791C23.8138 10.7791 23.2902 9.95004 23.2902 8.28313V1.54568H25.2102V3.55004H27.1185L26.9847 5.20822H25.2073V8.13768C25.2073 8.80677 25.3905 9.1675 26.0771 9.1675C26.3796 9.1675 26.7113 9.13259 27.1214 8.99295Z"
            fill={fillbottom}
          />
          <path
            d="M22.6618 3.54713L22.5134 5.20532H21.9927C20.9135 5.20532 20.3753 6.07223 20.3753 6.90423V10.6511H18.4553V3.54713H20.2735L20.3113 4.4344C20.6953 3.8264 21.3614 3.54713 22.1847 3.54713H22.6618Z"
            fill={fillbottom}
          />
          <path
            d="M9.79782 9.86567C8.49745 10.5609 7.19709 10.9624 5.48073 10.9129C2.29818 10.8169 0 8.83876 0 5.49913C0 2.17404 2.31854 0.0504013 5.48073 0.000946707C7.08364 -0.022326 8.51781 0.382038 9.64072 1.37404L8.29091 2.74131C7.59564 2.15077 6.57746 1.80459 5.35564 1.85986C3.36582 1.95295 2.16727 3.39876 2.13818 5.38858C2.112 7.54422 3.43273 9.02786 5.49818 9.08313C6.46982 9.1064 7.16509 8.9755 7.77018 8.70495V6.50277H5.62327L5.76872 4.69622H9.79782V9.86567Z"
            fill={fillbottom}
          />
          <path
            d="M42.5193 7.77404H37.2596C37.4109 8.68168 38.1236 9.22859 39.0109 9.25186C39.8749 9.27514 40.3578 9.01331 40.9891 8.37331L42.24 9.32749C41.3498 10.4242 40.2822 10.8228 39.0662 10.8228C36.928 10.8228 35.3251 9.3275 35.3251 7.09623C35.3251 4.86786 36.9193 3.36968 38.9876 3.36968C41.2654 3.36968 42.5193 4.7515 42.5193 7.20095V7.77404ZM40.5964 6.2904C40.5382 5.39149 39.8662 4.82422 38.9382 4.86204C38.0073 4.89695 37.3847 5.50495 37.2567 6.2904H40.5964Z"
            fill={fillbottom}
          />
          <path
            d="M17.248 5.99949V10.6511H15.5549L15.5142 9.95004C14.9964 10.593 14.1527 10.8257 13.2858 10.8257C11.9796 10.8257 10.7462 10.1828 10.72 8.68168C10.6822 6.3224 13.3731 6.15659 15.0662 6.15659H15.4036V6.06349C15.4036 5.28386 14.8887 4.86204 13.9171 4.86204C13.2189 4.86204 12.5324 5.14131 12.0233 5.6184L10.9149 4.49259C11.9215 3.67804 12.9658 3.37259 14.08 3.37259C16.2793 3.37549 17.248 4.39077 17.248 5.99949ZM15.4036 7.55295H15.136C14.2284 7.55586 12.6313 7.5995 12.6371 8.55077C12.6458 9.88895 15.4007 9.46423 15.4007 7.84968V7.55295H15.4036Z"
            fill={fillbottom}
          />
          <path
            d="M46.2545 9.82495C46.2545 9.37404 46.5949 9.03077 47.0342 9.03077C47.4676 9.03077 47.808 9.37113 47.808 9.82495C47.808 10.2846 47.4647 10.6249 47.0342 10.6249C46.5978 10.6249 46.2545 10.2846 46.2545 9.82495ZM47.0342 10.785C47.5549 10.785 48 10.3806 48 9.82495C48 9.27513 47.5549 8.87368 47.0342 8.87368C46.5076 8.87368 46.0625 9.27804 46.0625 9.82495C46.0625 10.3806 46.5076 10.785 47.0342 10.785ZM47.0225 9.9035L47.3134 10.3806H47.4996L47.1854 9.89477C47.3484 9.87441 47.4705 9.78713 47.4705 9.59222C47.4705 9.37404 47.3425 9.27804 47.0807 9.27804H46.6618V10.3806H46.8276V9.9035H47.0225ZM46.8305 9.42059H47.0574C47.1738 9.42059 47.2989 9.44677 47.2989 9.5835C47.2989 9.75222 47.1738 9.76386 47.0313 9.76386H46.8305V9.42059Z"
            fill={fillbottom}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1011_564">
          <rect width="80" height="34" fill="white" />
        </clipPath>
        <clipPath id="clip1_1011_564">
          <rect width="80" height="16" fill="white" transform="translate(0 18)" />
        </clipPath>
        <clipPath id="clip2_1011_564">
          <rect width="48" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BuySmartLogoStacked = ({ height = "80", variant = logoColorVariants.blue, width = "142" }: LogoProps) => {
  const fillPath = validTypes[variant] || validTypes[logoColorVariants.blue];

  return (
    <svg height={height} viewBox="0 0 142 80" width={width}>
      <title>Buy Smart Logo Stacked</title>
      <g clipPath="url(#clip0_11190_43187)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.46 24.93V25.11C24.46 30.99 20.61 34.09 13.18 34.09H-0.0200348V2.03998H12.62C19.62 2.03998 23.25 4.90998 23.25 10.39V10.53C23.35 12.1081 22.88 13.6697 21.9257 14.9306C20.9713 16.1914 19.596 17.0677 18.05 17.4C22.22 18.25 24.46 20.63 24.46 24.93ZM11.4 6.97998H7.09996V15.2H11.45C14.95 15.2 16.34 13.94 16.34 11.07V10.89C16.34 8.18998 14.82 6.97998 11.4 6.97998ZM7.09996 19.86V29.06H12.08C15.67 29.06 17.28 27.45 17.28 24.53V24.35C17.28 21.39 15.67 19.86 11.72 19.86H7.09996Z"
          fill={fillPath}
        />
        <path
          d="M44.43 30.41C43.21 32.79 40.84 34.58 36.98 34.58C32.42 34.58 28.98 31.89 28.98 25.74V10.62H35.45V24.84C35.45 28.03 36.79 29.56 39.53 29.56C42.27 29.56 44.43 27.85 44.43 24.39V10.62H50.89V34.09H44.43V30.41Z"
          fill={fillPath}
        />
        <path
          d="M59.31 42.08H65.6L78.3 10.62H72.06L66.81 24.71L61.11 10.62H54.11L63.44 31.71L59.31 42.08Z"
          fill={fillPath}
        />
        <path
          d="M7.73996 54.31C7.73996 56.6 8.79996 57.67 13.74 58.39C21.5 59.39 25.5 61.47 25.46 68.13C25.46 73.83 20.79 78.36 13.2 78.36C4.40997 78.36 0.369961 74.23 -0.0400391 67.82H6.95996C7.23996 70.69 8.48996 73.07 13.24 73.07C16.43 73.07 18.54 71.36 18.54 68.8C18.54 66.24 17.19 65.35 12.48 64.63C4.26997 63.55 0.899963 61.08 0.899963 54.89C0.899963 49.41 5.36996 45.33 12.57 45.33C19.77 45.33 23.99 48.56 24.57 54.94H17.88C17.43 52.02 15.72 50.67 12.54 50.67C9.35996 50.67 7.73996 52.02 7.73996 54.31Z"
          fill={fillPath}
        />
        <path
          d="M58.43 53.84C56.878 53.8109 55.3431 54.1685 53.9639 54.8808C52.5848 55.5931 51.4046 56.6376 50.53 57.92C49.41 55.14 47.08 53.84 43.98 53.84C42.5202 53.8077 41.0795 54.1754 39.8138 54.9034C38.5482 55.6314 37.5059 56.6919 36.8 57.97V54.39H30.29V77.86H36.8V63.98C36.8 60.79 38.8 59.18 41.33 59.18C43.62 59.18 45.01 60.53 45.01 63.53V77.85H51.47V63.98C51.47 60.79 53.47 59.18 56.01 59.18C58.34 59.18 59.69 60.53 59.69 63.53V77.85H66.15V62.85C66.15 56.58 62.78 53.84 58.43 53.84Z"
          fill={fillPath}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.22 61.84C71.62 56.31 76.02 53.84 81.77 53.84C87.51 53.84 91.59 56.17 91.6 62.54V77.82H85.28V74.99C83.93 76.88 81.86 78.31 78.18 78.31C73.92 78.31 70.33 76.25 70.33 71.31C70.33 65.84 75.36 63.77 82.54 63.77H85.19V62.83C85.19 60.05 84.37 58.52 81.37 58.52C78.8 58.52 77.64 59.84 77.37 61.84H71.22ZM80.11 73.81C82.98 73.81 85.18 72.24 85.18 69.64L85.21 67.66H82.69C78.89 67.66 76.69 68.47 76.69 70.98C76.69 72.69 77.73 73.81 80.11 73.81Z"
          fill={fillPath}
        />
        <path
          d="M103.59 58.86V54.39H97.08V77.86H103.59V66.09C103.59 61.6 106.37 60.03 111.18 60.09V54.03C109.568 53.9378 107.968 54.3545 106.606 55.2212C105.244 56.088 104.189 57.3609 103.59 58.86Z"
          fill={fillPath}
        />
        <path
          d="M123.19 59V70.31C123.19 72.19 124.13 73.09 125.79 73.12C126.636 73.1459 127.478 72.9925 128.26 72.67V77.67C126.988 78.1006 125.653 78.3136 124.31 78.3C119.38 78.3 116.73 75.78 116.73 70.89V59.01H113.73V54.39H116.73V49.32H123.19V54.39H128.13V59H123.19Z"
          fill={fillPath}
        />
        <path d="M130.89 50.25H132.33V54.65H133.55V50.25H135V49.3H130.89V50.25Z" fill={fillPath} />
        <path
          d="M138.72 52.63L139.95 49.3H141.53V54.65H140.32V50.9L138.84 54.65H138.44L136.96 50.9V54.65H135.87V49.3H137.47L138.72 52.63Z"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_11190_43187">
          <rect width="142" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GartnerLogo = ({ height = "24", variant = logoColorVariants.white, width = "108" }: LogoProps) => {
  const fillPath = validTypes[variant] || validTypes[logoColorVariants.white];
  return (
    <svg height={height} viewBox="0 0 108 24" width={width} xmlns={xmlnsUrl}>
      <title>Gartner Logo</title>
      <path
        d="M12.3934 24.4909C16.2769 24.6005 19.217 23.7174 22.1605 22.1867V10.819H13.0455L12.7178 14.7906H17.5728V19.6329C16.2103 20.2276 14.6357 20.5146 12.4377 20.4612C7.76644 20.342 4.77822 17.0772 4.83706 12.3398C4.89687 7.96742 7.60937 4.78726 12.1109 4.58351C14.8701 4.46201 17.179 5.22218 18.7482 6.52271L21.803 3.51841C19.2671 1.34283 16.0226 0.448397 12.3968 0.502289C5.24409 0.611967 0 5.28175 0 12.5904C0 19.9312 5.19399 24.2772 12.3934 24.4909Z"
        fill={fillPath}
      />
      <path
        d="M61.3416 20.2684L61.0382 23.8225C59.9975 24.0938 59.0736 24.1912 57.9556 24.1912C53.8665 24.1912 52.6804 22.3683 52.6804 18.7045V3.89245H57.02V8.2975H61.3382L61.0382 11.9447H57.02V18.3873C57.02 19.8576 57.4348 20.6518 58.986 20.6518C59.6605 20.6518 60.4108 20.5728 61.3416 20.2684Z"
        fill={fillPath}
      />
      <path
        d="M77.9378 23.9172H73.5977V15.6597C73.5977 13.9304 73.444 11.7539 70.9421 11.7539C68.5326 11.7539 67.5726 13.4444 67.5726 15.4129V23.9172H63.234V8.29717H67.2566L67.3485 10.294C68.2106 8.95712 69.8164 7.91613 72.1851 7.91613C76.5577 7.91613 77.9378 10.9512 77.9378 14.1096V23.9172Z"
        fill={fillPath}
      />
      <path
        d="M107.687 11.945L108 8.29731H106.53C104.671 8.29731 103.163 8.90999 102.301 10.2469L102.208 8.29731H98.1007V23.9173H102.437V15.6788C102.437 13.8516 103.709 11.945 106.155 11.945H107.687Z"
        fill={fillPath}
      />
      <path
        d="M51.2555 8.29731L50.9195 11.945H49.7402C47.2986 11.945 46.0843 13.8492 46.0843 15.6788V23.9173H41.7443V8.29731H45.8534L45.9389 10.2469C46.8055 8.90999 48.3135 8.29731 50.1754 8.29731H51.2555Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.2648 17.5917H96.1627V16.3384C96.1627 10.951 93.3296 7.91599 88.1765 7.91599C83.5009 7.91599 79.8941 11.2087 79.8941 16.1068C79.8941 21.0101 83.5189 24.299 88.3574 24.299C91.1083 24.299 93.5232 23.4221 95.5354 21.0125L92.7038 18.9135C91.2746 20.3204 90.1814 20.8943 88.2276 20.8428C86.2178 20.7884 84.6038 19.5876 84.2648 17.5917ZM88.0671 11.1846C90.1615 11.1051 91.6821 12.3527 91.8154 14.326H84.2648C84.5556 12.5966 85.959 11.2621 88.0671 11.1846Z"
        fill={fillPath}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0122 23.9171V13.6888C39.0122 10.1522 36.8181 7.91609 31.841 7.91609C29.3211 7.91609 26.9558 8.58454 24.6824 10.3763L27.1863 12.8492C28.3378 11.7997 29.8949 11.1847 31.4724 11.1847C33.6699 11.1847 34.837 12.1108 34.837 13.8254V14.0306H34.0726C30.2474 14.0306 24.1567 14.3989 24.2448 19.5839C24.3031 22.8828 27.0949 24.2991 30.0495 24.2991C32.0117 24.2991 33.9174 23.7904 35.0913 22.3769L35.1861 23.9171H39.0122ZM34.2335 17.1053H34.837V17.7554C34.837 21.3038 28.6033 22.2389 28.5839 19.2965C28.5703 17.2079 32.179 17.1096 34.2335 17.1053Z"
        fill={fillPath}
      />
    </svg>
  );
};
