import { createGenerateClassName, StylesProvider } from "@material-ui/core";
import { messengerFlagLoader } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  FeatureFlagProvider,
} from "gx-npm-ui";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarContextProvider } from "./context/snack-bar-banner/snack-bar.context";
import { WorkspaceHeaderContextProvider } from "./context/workspace-header.context";
import { GCOM_3787__fileHub } from "./lib/feature-flags";
import HandleRedirects from "./handle-redirects";
import HandleRedirectsV2Component from "./handle-redirects-v2.component";
import AppV2Component from "./app-v2.component";

const generateClassName = createGenerateClassName({ seed: "workspace-header-class" });
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <WorkspaceHeaderContextProvider>
          <SnackbarContextProvider>
            <FeatureFlagBooleanContainer strictMode={true} flagName={GCOM_3787__fileHub}>
              <FeatureFlagBooleanOff>
                <BrowserRouter>
                  <Routes>
                    <Route element={<AppV2Component />} path="/s/evaluation/:initId/product/:initProdId/:tab" />
                    <Route element={<HandleRedirects />} path="*" />
                  </Routes>
                </BrowserRouter>
              </FeatureFlagBooleanOff>
              <FeatureFlagBooleanOn>
                <BrowserRouter>
                  <Routes>
                    <Route
                      element={<HandleRedirectsV2Component />}
                      path="/s/evaluation/:initId/product/:initProdId/documents"
                    />
                    <Route element={<AppV2Component />} path="/s/evaluation/:initId/product/:initProdId/:tab" />
                    <Route element={<HandleRedirectsV2Component />} path="*" />
                  </Routes>
                </BrowserRouter>
              </FeatureFlagBooleanOn>
            </FeatureFlagBooleanContainer>
          </SnackbarContextProvider>
        </WorkspaceHeaderContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
