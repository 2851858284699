// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BwxT8cJxpeL437z0tfUw .TIYs4XLymop6Jje1LPWS{border-bottom:1px solid var(--color-neutrals-silver);position:fixed;top:0;width:100%;z-index:1106}.BwxT8cJxpeL437z0tfUw .TIYs4XLymop6Jje1LPWS.ethWGOnjzRUEds6f_cDe{border-bottom:none;box-shadow:0 4px 12px rgba(20,19,18,.16)}.BwxT8cJxpeL437z0tfUw .TIYs4XLymop6Jje1LPWS .vlP4aCR6v2BSeBtsU5zi{background-color:var(--color-white);padding:0 24px;transition:padding .5s ease-in-out;width:100%}@media(min-width: 1240px){.BwxT8cJxpeL437z0tfUw .TIYs4XLymop6Jje1LPWS .vlP4aCR6v2BSeBtsU5zi{padding:0 56px}}.BwxT8cJxpeL437z0tfUw .TIYs4XLymop6Jje1LPWS .vlP4aCR6v2BSeBtsU5zi .A1ZI5j09H_GL6FxMma1A{display:flex;flex-direction:column;justify-content:center;margin:0 auto;max-width:1396px}`, "",{"version":3,"sources":["webpack://./src/app-v2.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,oDAAA,CACA,cAAA,CACA,KAAA,CACA,UAAA,CACA,YAAA,CAEA,iEACE,kBAAA,CACA,wCAAA,CAGF,kEACE,mCAAA,CACA,cAAA,CACA,kCAAA,CACA,UAAA,CAEA,0BANF,kEAOI,cAAA,CAAA,CAGF,wFACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".appRoot {\n  & .appContent {\n    border-bottom: 1px solid var(--color-neutrals-silver);\n    position: fixed;\n    top: 0;\n    width: 100%;\n    z-index: 1106;\n\n    &.contentScrolling {\n      border-bottom: none;\n      box-shadow: 0 4px 12px rgba(20, 19, 18, 0.16);\n    }\n\n    & .tabsHeaderOutsideContainer {\n      background-color: var(--color-white);\n      padding: 0 24px;\n      transition: padding 0.5s ease-in-out;\n      width: 100%;\n\n      @media (min-width: 1240px) {\n        padding: 0 56px;\n      }\n\n      & .tabsHeaderInsiderContainer {\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        margin: 0 auto;\n        max-width: 1396px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appRoot": `BwxT8cJxpeL437z0tfUw`,
	"appContent": `TIYs4XLymop6Jje1LPWS`,
	"contentScrolling": `ethWGOnjzRUEds6f_cDe`,
	"tabsHeaderOutsideContainer": `vlP4aCR6v2BSeBtsU5zi`,
	"tabsHeaderInsiderContainer": `A1ZI5j09H_GL6FxMma1A`
};
export default ___CSS_LOADER_EXPORT___;
