import classNames from "classnames";
import { FreeTrialRole } from "gx-npm-lib";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { MiniButton } from "../../miniButton";
import { TypographyComponent } from "../../typography/typography.component";
import { FreeTrialInfoModalComponent } from "../free-trial-info-modal/free-trial-info-modal.component";
import FreeTrialTimerIcon from "./free-trial-timer.icon";
import styles from "./free-trial-banner.styles.module.scss";

type FreeTrialBannerProps = { daysRemaining?: number; freeTrialRole?: FreeTrialRole | "" };
const FreeTrialBannerComponent = ({ daysRemaining = 0, freeTrialRole = "" }: FreeTrialBannerProps) => {
  const { t } = useTranslation();
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  const handleCloseModal = () => {
    setShowMoreInfoModal(false);
  };

  const handleOpenModal = () => {
    setShowMoreInfoModal(true);
  };

  return (
    <Fragment>
      {freeTrialRole && (
        <Fragment>
          <div className={styles.bannerWrapper} role="banner">
            <div className={styles.iconContainer}>
              <FreeTrialTimerIcon />
            </div>
            {freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT && (
              <p>
                <TypographyComponent boldness="semi" color="darkLemon" element="span" styling="p3">
                  {daysRemaining}
                  {` `}
                  {daysRemaining !== 1 ? t("days remaining") : t("day remaining")}
                </TypographyComponent>
                {` `}
                <TypographyComponent boldness="medium" color="darkLemon" element="span" styling="p3">
                  {t("in your Gartner BuySmart free trial.")}
                </TypographyComponent>
              </p>
            )}
            {freeTrialRole === FreeTrialRole.FREE_TRIAL_GUEST && (
              <p>
                <TypographyComponent boldness="medium" color="darkLemon" element="span" styling="p3">
                  {t("You are using a free trial version of Gartner BuySmart that has")}
                </TypographyComponent>
                {` `}
                <TypographyComponent boldness="semi" color="darkLemon" element="span" styling="p3">
                  {daysRemaining}
                  {` `}
                  {daysRemaining !== 1 ? t("days remaining") : t("day remaining")}
                </TypographyComponent>
                {`.`}
              </p>
            )}
            <MiniButton
              onClick={handleOpenModal}
              rootClassName={classNames(styles.moreInfoButton, styles.additionalSpecificity)}
            >
              {t("More information")}
            </MiniButton>
          </div>

          <FreeTrialInfoModalComponent
            daysRemaining={daysRemaining}
            freeTrialRole={freeTrialRole}
            isOpen={showMoreInfoModal}
            onClickClose={handleCloseModal}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export { FreeTrialBannerComponent };
