// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n0KTiiPG4Gbxf2MNnrzZ{margin-bottom:20px}.DBA7VjTNITvZeMtl4bLS{display:flex;float:right}.DBA7VjTNITvZeMtl4bLS .PhW88ccqYknuG0g3JX66{margin-left:24px}`, "",{"version":3,"sources":["webpack://./src/sections/navigation-dialog-v2/navigation-dialog-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,WAAA,CAEA,4CACE,gBAAA","sourcesContent":[".navBodyWrapper {\n  margin-bottom: 20px;\n}\n\n.navFooterWrapper {\n  display: flex;\n  float: right;\n\n  & .btnWrapper {\n    margin-left: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBodyWrapper": `n0KTiiPG4Gbxf2MNnrzZ`,
	"navFooterWrapper": `DBA7VjTNITvZeMtl4bLS`,
	"btnWrapper": `PhW88ccqYknuG0g3JX66`
};
export default ___CSS_LOADER_EXPORT___;
