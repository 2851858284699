import { useState, useEffect } from "react";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { UserState } from "./use-user-state.types";
import { DEFAULT_USER_STATE, STATE_SUBJECT } from "./use-user-state.constants";

export function useUserState() {
  const [userState, setUserState] = useState<UserState>(DEFAULT_USER_STATE);

  useEffect(() => {
    const userStateSubscription = createStateSubscription(STATE_SUBJECT, handleUserStateCallback);
    return () => {
      destroyStateSubscription(userStateSubscription);
    };
  }, []);

  const handleUserStateCallback = (newUserState: UserState) => {
    setUserState({ ...DEFAULT_USER_STATE, ...newUserState });
  };

  return userState;
}
