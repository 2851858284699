import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlns = "http://www.w3.org/2000/svg";

export const DeselectIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M2 4C2 2.89 2.9 2 4 2H7V4H4V7H2V4ZM22 4V7H20V4H17V2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4ZM20 20V17H22V20C22 21.11 21.1 22 20 22H17V20H20ZM2 20V17H4V20H7V22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20ZM10 2H14V4H10V2ZM10 20H14V22H10V20ZM20 10H22V14H20V10ZM2 10H4V14H2V10Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const DuplicateIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M18.2105 5.63636H8.36842C7.89382 5.63636 7.43866 5.82792 7.10307 6.1689C6.76748 6.50987 6.57895 6.97233 6.57895 7.45455V20.1818C6.57895 20.664 6.76748 21.1265 7.10307 21.4675C7.43866 21.8084 7.89382 22 8.36842 22H18.2105C18.6851 22 19.1403 21.8084 19.4759 21.4675C19.8115 21.1265 20 20.664 20 20.1818V7.45455C20 6.97233 19.8115 6.50987 19.4759 6.1689C19.1403 5.82792 18.6851 5.63636 18.2105 5.63636ZM16 3C16 2.44772 15.5523 2 15 2H4.78947C4.31488 2 3.85972 2.19156 3.52412 2.53253C3.18853 2.87351 3 3.33597 3 3.81818V16C3 16.5523 3.44772 17 4 17C4.55228 17 5 16.5523 5 16V4H15C15.5523 4 16 3.55228 16 3Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const MoveToIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12ZM2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM12 11L9 11C8.45 11 8 11.45 8 12C8 12.55 8.45 13 9 13L12 13L12 14.79C12 15.24 12.54 15.46 12.85 15.14L15.64 12.35C15.84 12.15 15.84 11.84 15.64 11.64L12.85 8.85C12.54 8.54 12 8.76 12 9.21L12 11Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
