import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";
const xmlns = "http://www.w3.org/2000/svg";
const defaultColor = colorPalette.interactions.blueBerry.hex;
export const FolderSmallIcon = ({ title = "" }: IconProps) => {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns={xmlns}>
      {!!title && <title>{title}</title>}
      <g clipPath="url(#clip0_1464_1562)">
        <path
          d="M7.06004 3.06008C6.80671 2.80675 6.46671 2.66675 6.11337 2.66675H2.66671C1.93337 2.66675 1.34004 3.26675 1.34004 4.00008L1.33337 12.0001C1.33337 12.7334 1.93337 13.3334 2.66671 13.3334H13.3334C14.0667 13.3334 14.6667 12.7334 14.6667 12.0001V5.33341C14.6667 4.60008 14.0667 4.00008 13.3334 4.00008H8.00004L7.06004 3.06008Z"
          fill={colorPalette.neutrals.iron.hex}
        />
      </g>
      <defs>
        <clipPath id="clip0_1464_1562">
          <rect fill={colorPalette.basic.white.hex} height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RestoreArchivedFolderIcon = ({ title = "", fillPath = defaultColor }: IconProps) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns={xmlns}>
      {title && <title>{title}</title>}
      <path
        clipRule="evenodd"
        d="M9.17 4C9.7 4 10.21 4.21 10.59 4.59L12 6H20C21.1 6 22 6.9 22 8V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18L2.01 6C2.01 4.9 2.9 4 4 4H9.17ZM12.2896 16.1727L11.4699 14.1419C14.0479 13.1014 16.063 13.2047 18.0519 14.4185C16.5332 12.1522 14.1003 10.2657 10.291 11.2209L9.49373 9.2457L7.40371 13.7126C7.28383 13.9767 7.40735 14.2827 7.67694 14.3896L12.2896 16.1727Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
