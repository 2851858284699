import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { colorPalette } from "gx-npm-common-styles";
import { makeStyles } from "@material-ui/core/styles";
import { selectedOptionStyles as styles } from "./styles";
import { FeatureFlagBooleanContainer, FeatureFlagBooleanOff, FeatureFlagBooleanOn } from "../featureFlags";
import { GCOM_3606__fontUpdate } from "../feature-flags";
import { TypographyComponent } from "../typography/typography.component";

const defaultNoCurrentSelection = "- -";
const propTypes = {
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      icon: PropTypes.node,
      index: PropTypes.number,
      name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      selectedClassName: PropTypes.string,
      showSelectedIndex: PropTypes.bool,
    })
  ),
  noCurrentSelectionText: PropTypes.string,
  selectedIndex: PropTypes.number,
  variant: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const MenuSelectedOption = ({ disabled, menuItems = [], noCurrentSelectionText = "", selectedIndex = -1, variant }) => {
  const classes = useStyles();
  const currIdx =
    Array.isArray(menuItems) &&
    menuItems.findIndex((item) => {
      return selectedIndex !== -1 && !!item && item?.index === selectedIndex;
    });

  const menuItem = menuItems?.[currIdx] || {};
  const { color, icon, index, name, selectedClassName, showSelectedIndex } = menuItem;

  const DisabledIcon = () => {
    const disabledIconColor = colorPalette.neutrals.stone.hex;
    return React.isValidElement(icon) ? React.cloneElement(icon, { fillPath: disabledIconColor }) : icon;
  };

  const currIndexDisplay = showSelectedIndex ? index : name;
  const iconDisplay = disabled ? <DisabledIcon /> : icon;
  return (
    <div
      className={classNames(
        "gx-popover-menu-selected",
        classes.currentMenuItem,
        disabled && "disabled",
        selectedClassName,
        variant
      )}
    >
      {currIdx > -1 ? (
        <React.Fragment>
          {icon && <div className={classes.currentIcon}>{iconDisplay}</div>}
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                rootClassName={classNames("gx-popover-menu-current-selection", classes.currentSelection, color)}
                element={"p"}
                boldness={"medium"}
              >
                {currIndexDisplay}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <p
                className={classNames(
                  "gx-popover-menu-current-selection",
                  classes.currentSelection,
                  classes.semiBold,
                  color
                )}
              >
                {currIndexDisplay}
              </p>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </React.Fragment>
      ) : (
        <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
          <FeatureFlagBooleanOn>
            <TypographyComponent rootClassName={"no-current-selection"} boldness={"medium"} element={"p"}>
              {noCurrentSelectionText || defaultNoCurrentSelection}
            </TypographyComponent>
          </FeatureFlagBooleanOn>
          <FeatureFlagBooleanOff>
            <p className="no-current-selection">{noCurrentSelectionText || defaultNoCurrentSelection}</p>
          </FeatureFlagBooleanOff>
        </FeatureFlagBooleanContainer>
      )}
    </div>
  );
};

MenuSelectedOption.propTypes = propTypes;
export default MenuSelectedOption;
