import { FC, Fragment } from "react";
import classNames from "classnames";
import { CircularProgress as MuiCircularProgress, makeStyles } from "@material-ui/core";
import { loaderStyles as styles } from "./styles";
import { LoaderProps } from "./loader.types";

const useStyles = makeStyles(() => styles);
const Loader: FC<LoaderProps> = ({
  btnClass = "",
  rootClassName = "",
  size = 24,
  source = "",
  variant = "indeterminate",
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <MuiCircularProgress
        className={classNames(btnClass, rootClassName, classes.top, source === "button" && classes.btnLoader)}
        size={size}
        variant={variant}
      />
    </Fragment>
  );
};

export default Loader;
