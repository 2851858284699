import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ColumnBuildingIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "17",
  title = "",
  viewBox = "0 0 15 17",
  width = "15",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M1.57895 8.39448V11.6728C1.57895 12.353 2.10789 12.9021 2.76316 12.9021C3.41842 12.9021 3.94737 12.353 3.94737 11.6728V8.39448C3.94737 7.71423 3.41842 7.16512 2.76316 7.16512C2.10789 7.16512 1.57895 7.71423 1.57895 8.39448ZM6.31579 8.39448V11.6728C6.31579 12.353 6.84474 12.9021 7.5 12.9021C8.15526 12.9021 8.68421 12.353 8.68421 11.6728V8.39448C8.68421 7.71423 8.15526 7.16512 7.5 7.16512C6.84474 7.16512 6.31579 7.71423 6.31579 8.39448ZM1.18421 17H13.8158C14.4711 17 15 16.4509 15 15.7706C15 15.0904 14.4711 14.5413 13.8158 14.5413H1.18421C0.528947 14.5413 0 15.0904 0 15.7706C0 16.4509 0.528947 17 1.18421 17ZM11.0526 8.39448V11.6728C11.0526 12.353 11.5816 12.9021 12.2368 12.9021C12.8921 12.9021 13.4211 12.353 13.4211 11.6728V8.39448C13.4211 7.71423 12.8921 7.16512 12.2368 7.16512C11.5816 7.16512 11.0526 7.71423 11.0526 8.39448ZM6.76579 0.190551L0.528947 3.59998C0.205263 3.77209 0 4.1245 0 4.50151C0 5.06701 0.442105 5.52597 0.986842 5.52597H14.0211C14.5579 5.52597 15 5.06701 15 4.50151C15 4.1245 14.7947 3.77209 14.4711 3.59998L8.23421 0.190551C7.77632 -0.0635169 7.22368 -0.0635169 6.76579 0.190551Z"
        fill={fillPath}
      />
    </svg>
  );
};
