import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const PersonGearIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "21",
  title = "",
  viewBox = "0 0 22 21",
  width = "22",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M8.14 15.14L9 16H0V14C0 11.34 5.33 10 8 10C8.32 10 8.61 10.02 9 10.06L8.16 10.88C6.97 12.04 6.96 13.96 8.14 15.14Z"
        fill={fillPath}
      />
      <path d="M12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4Z" fill={fillPath} />
      <path
        clipRule="evenodd"
        d="M20.6276 15C20.6276 15.204 20.6091 15.396 20.5844 15.588L21.8859 16.578C22.0031 16.668 22.034 16.83 21.96 16.962L20.7263 19.038C20.6523 19.17 20.4919 19.224 20.35 19.17L18.8141 18.57C18.4934 18.804 18.1479 19.008 17.7717 19.158L17.5373 20.748C17.5188 20.892 17.3892 21 17.235 21H14.7677C14.6135 21 14.484 20.892 14.4655 20.748L14.2311 19.158C13.8548 19.008 13.5094 18.81 13.1886 18.57L11.6527 19.17C11.517 19.218 11.3504 19.17 11.2764 19.038L10.0428 16.962C9.96875 16.83 9.99959 16.668 10.1168 16.578L11.4183 15.588C11.3936 15.396 11.3751 15.198 11.3751 15C11.3751 14.802 11.3936 14.604 11.4183 14.412L10.1168 13.422C9.99959 13.332 9.96258 13.17 10.0428 13.038L11.2764 10.962C11.3504 10.83 11.5108 10.776 11.6527 10.83L13.1886 11.43C13.5094 11.196 13.8548 10.992 14.2311 10.842L14.4655 9.252C14.484 9.108 14.6135 9 14.7677 9H17.235C17.3892 9 17.5188 9.108 17.5373 9.252L17.7717 10.842C18.1479 10.992 18.4934 11.19 18.8141 11.43L20.35 10.83C20.4857 10.782 20.6523 10.83 20.7263 10.962L21.96 13.038C22.034 13.17 22.0031 13.332 21.8859 13.422L20.5844 14.412C20.6091 14.604 20.6276 14.796 20.6276 15ZM13.8425 15C13.8425 16.158 14.8109 17.1 16.0014 17.1C17.1918 17.1 18.1603 16.158 18.1603 15C18.1603 13.842 17.1918 12.9 16.0014 12.9C14.8109 12.9 13.8425 13.842 13.8425 15Z"
        fill={fillPath}
        fillRule="evenodd"
      />
    </svg>
  );
};
