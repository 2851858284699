import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const ToolsIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "15",
  title = "",
  viewBox = "0 0 15 15",
  width = "15",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M13.8283 11.8645L10.5104 8.5466C10.1729 8.20918 9.81443 8.13186 9.39969 8.13186L7.6142 9.91735C7.6142 10.3321 7.69153 10.6976 8.02894 11.028L11.3469 14.3459C11.621 14.6201 12.0639 14.6201 12.338 14.3459L13.8283 12.8557C14.1024 12.5886 14.1024 12.1387 13.8283 11.8645Z"
        fill={fillPath}
      />
      <path
        d="M10.2859 5.76309C10.56 6.03724 11.0029 6.03724 11.2771 5.76309L11.7762 5.26399L13.2664 6.75424C14.0889 5.93179 14.0889 4.59619 13.2664 3.77374L11.2771 1.78439C11.0029 1.51024 10.56 1.51024 10.2859 1.78439L9.78681 2.28349V0.497998C9.78681 0.0621698 9.25257 -0.169803 8.93624 0.146524L7.15075 1.93201C6.83442 2.24834 7.06639 2.78258 7.50222 2.78258H9.28771L8.78862 3.28168C8.51447 3.55583 8.51447 3.99868 8.78862 4.27283L9.03465 4.51887L7.00313 6.55039L4.11401 3.64721V2.94426C4.11401 2.75446 4.03668 2.57873 3.91015 2.44517L2.4902 1.01818C2.21605 0.74403 1.77319 0.74403 1.49904 1.01818L0.500851 2.01637C0.226701 2.29052 0.226701 2.73338 0.500851 3.00753L1.92081 4.43451C2.05437 4.56807 2.23011 4.63837 2.4199 4.63837H3.12285L6.02603 7.54155L5.42852 8.13905H4.51469C4.14213 8.13905 3.78362 8.28667 3.52353 8.55379L0.205613 11.8717C-0.0685375 12.1459 -0.0685375 12.5887 0.205613 12.8629L1.69586 14.3531C1.97001 14.6273 2.41287 14.6273 2.68702 14.3531L6.00494 11.0352C6.27206 10.7681 6.41968 10.4166 6.41968 10.044V9.13724L10.0399 5.51705L10.2859 5.76309Z"
        fill={fillPath}
      />
    </svg>
  );
};
