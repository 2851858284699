import { createContext, ReactNode, useEffect, useState } from "react";

type FeatureFlagProviderProps = {
  children: ReactNode | Array<ReactNode>;
  loader: (...args: unknown[]) => void;
};

type FeatureFlagContextValue = {
  flags: Record<string, boolean>;
};

/**
 * store for feature flags within app
 */
export const FeatureFlagContext = createContext<FeatureFlagContextValue>({ flags: {} });

/**
 * wrapper component for access flags being set in app context
 * @param children - any components within provider that will need access to context
 * @param loader - function module for loading flags into context
 */
export const FeatureFlagProvider = ({ children, loader }: FeatureFlagProviderProps) => {
  const [flags, setFlags] = useState<Record<string, boolean>>({});
  useEffect(() => {
    if (!loader) {
      return;
    }
    loader(setFlags);
  }, [loader]);

  return <FeatureFlagContext.Provider value={{ flags }}>{children}</FeatureFlagContext.Provider>;
};
