import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const defaultColor = colorPalette.brand.surf.hex;
export const MarketGuideIcon = ({ fillPath = defaultColor }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7143 4H6.28571C5.01714 4 4 5.02857 4 6.28571V17.7143C4 18.9714 5.01714 20 6.28571 20H17.7143C18.9714 20 20 18.9714 20 17.7143V6.28571C20 5.02857 18.9714 4 17.7143 4ZM10.8571 7.42857H13.1429V16.5714H10.8571V7.42857ZM7.42857 10.8571H9.71429V16.5714H7.42857V10.8571ZM14.2857 13.1429H16.5714V16.5714H14.2857V13.1429Z"
        fill={fillPath}
      />
    </svg>
  );
};
