import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const GroupPeople = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "24",
  title = "",
  viewBox = "0 0 24 24",
  width = "24",
}: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fillSvg} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <g clipPath="url(#clip0_2659_87546)">
        <circle cx="12" cy="12" r="12" fill="#DDDDDD" />
        <path
          d="M12 7C12.6188 7 13.2123 7.24583 13.6499 7.68342C14.0875 8.121 14.3333 8.71449 14.3333 9.33333C14.3333 9.95217 14.0875 10.5457 13.6499 10.9832C13.2123 11.4208 12.6188 11.6667 12 11.6667C11.3812 11.6667 10.7877 11.4208 10.3501 10.9832C9.9125 10.5457 9.66667 9.95217 9.66667 9.33333C9.66667 8.71449 9.9125 8.121 10.3501 7.68342C10.7877 7.24583 11.3812 7 12 7ZM7.33333 8.66667C7.70667 8.66667 8.05333 8.76667 8.35333 8.94667C8.25333 9.9 8.53333 10.8467 9.10667 11.5867C8.77333 12.2267 8.10667 12.6667 7.33333 12.6667C6.8029 12.6667 6.29419 12.456 5.91912 12.0809C5.54405 11.7058 5.33333 11.1971 5.33333 10.6667C5.33333 10.1362 5.54405 9.62753 5.91912 9.25245C6.29419 8.87738 6.8029 8.66667 7.33333 8.66667ZM16.6667 8.66667C17.1971 8.66667 17.7058 8.87738 18.0809 9.25245C18.456 9.62753 18.6667 10.1362 18.6667 10.6667C18.6667 11.1971 18.456 11.7058 18.0809 12.0809C17.7058 12.456 17.1971 12.6667 16.6667 12.6667C15.8933 12.6667 15.2267 12.2267 14.8933 11.5867C15.4667 10.8467 15.7467 9.9 15.6467 8.94667C15.9467 8.76667 16.2933 8.66667 16.6667 8.66667ZM7.66667 15.5C7.66667 14.12 9.60667 13 12 13C14.3933 13 16.3333 14.12 16.3333 15.5V16.6667H7.66667V15.5ZM4 16.6667V15.6667C4 14.74 5.26 13.96 6.96667 13.7333C6.57333 14.1867 6.33333 14.8133 6.33333 15.5V16.6667H4ZM20 16.6667H17.6667V15.5C17.6667 14.8133 17.4267 14.1867 17.0333 13.7333C18.74 13.96 20 14.74 20 15.6667V16.6667Z"
          fill={fillPath}
        />
      </g>
      <defs>
        <clipPath id="clip0_2659_87546">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
