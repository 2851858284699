import { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { BuildingIcon } from "gx-npm-icons";

const propTypes = {
  hasAltText: PropTypes.bool,
  imageHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  logo: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  svgStyle: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
const ProductLogo = ({
  hasAltText = true,
  imageHeight = "auto",
  imageWidth = "auto",
  logo = "",
  name = "",
  rootClassName = "",
  svgStyle,
}) => {
  const [isImgError, setIsImgError] = useState(false);
  const altText = name ? `logo, ${name}` : "logo";
  return logo && !isImgError ? (
    <img
      alt={hasAltText ? altText : ""}
      className={classNames(rootClassName)}
      onError={() => setIsImgError(true)}
      src={logo}
      style={{ maxHeight: imageHeight }}
      width={imageWidth}
    />
  ) : (
    <BuildingIcon
      ariaLabel={hasAltText ? "no logo available" : undefined}
      height={svgStyle?.height || imageHeight}
      role="img"
      rootClassName={classNames(rootClassName)}
      viewBox={svgStyle?.viewBox}
      width={svgStyle?.width || imageWidth}
    />
  );
};

ProductLogo.propTypes = propTypes;
export { ProductLogo };
