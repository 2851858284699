import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;

export const ChevronRightIcon = ({ fillPath = defaultColor, title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M9.29006 6.71002C8.90006 7.10002 8.90006 7.73002 9.29006 8.12002L13.1701 12L9.29006 15.88C8.90006 16.27 8.90006 16.9 9.29006 17.29C9.68006 17.68 10.3101 17.68 10.7001 17.29L15.2901 12.7C15.6801 12.31 15.6801 11.68 15.2901 11.29L10.7001 6.70002C10.3201 6.32002 9.68006 6.32002 9.29006 6.71002Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ChevronLeftIcon = ({ fillPath = defaultColor, title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M14.71 6.70998C14.32 6.31998 13.69 6.31998 13.3 6.70998L8.70998 11.3C8.31998 11.69 8.31998 12.32 8.70998 12.71L13.3 17.3C13.69 17.69 14.32 17.69 14.71 17.3C15.1 16.91 15.1 16.28 14.71 15.89L10.83 12L14.71 8.11998C15.1 7.72998 15.09 7.08998 14.71 6.70998Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ChevronRightLargeIcon = ({ fillPath = defaultColor, title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M7.37994 21.01C7.86994 21.5 8.65994 21.5 9.14994 21.01L17.4599 12.7C17.8499 12.31 17.8499 11.68 17.4599 11.29L9.14994 2.98C8.65994 2.49 7.86994 2.49 7.37994 2.98C6.88994 3.47 6.88994 4.26 7.37994 4.75L14.6199 12L7.36994 19.25C6.88994 19.73 6.88994 20.53 7.37994 21.01Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ChevronLeftLargeIcon = ({ fillPath = defaultColor, title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M16.6201 2.99C16.1301 2.5 15.3401 2.5 14.8501 2.99L6.54006 11.3C6.15006 11.69 6.15006 12.32 6.54006 12.71L14.8501 21.02C15.3401 21.51 16.1301 21.51 16.6201 21.02C17.1101 20.53 17.1101 19.74 16.6201 19.25L9.38006 12L16.6301 4.75C17.1101 4.27 17.1101 3.47 16.6201 2.99Z"
        fill={fillPath}
      />
    </svg>
  );
};

export const ChevronDownIcon = ({ title = "" }: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M15.88 9.29055L12 13.1705L8.11998 9.29055C7.72998 8.90055 7.09998 8.90055 6.70998 9.29055C6.31998 9.68055 6.31998 10.3105 6.70998 10.7005L11.3 15.2905C11.69 15.6805 12.32 15.6805 12.71 15.2905L17.3 10.7005C17.69 10.3105 17.69 9.68055 17.3 9.29055C16.91 8.91055 16.27 8.90055 15.88 9.29055Z"
        fill="#0052D6"
      />
    </svg>
  );
};
