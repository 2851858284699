import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

export const PendingPersonIcon = ({ title = "", showSmallerIcon = false }: IconProps) => {
  return showSmallerIcon ? (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        fill={colorPalette.neutrals.iron.hex}
        d="M8.00002 8.00008C9.47335 8.00008 10.6667 6.80675 10.6667 5.33341C10.6667 3.86008 9.47335 2.66675 8.00002 2.66675C6.52669 2.66675 5.33335 3.86008 5.33335 5.33341C5.33335 6.80675 6.52669 8.00008 8.00002 8.00008ZM8.00002 9.33341C6.22002 9.33341 2.66669 10.2267 2.66669 12.0001V12.6667C2.66669 13.0334 2.96669 13.3334 3.33335 13.3334H12.6667C13.0334 13.3334 13.3334 13.0334 13.3334 12.6667V12.0001C13.3334 10.2267 9.78002 9.33341 8.00002 9.33341Z"
      />
      <defs>
        <clipPath id="clip0_2851_5295">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!!title && <title>{title}</title>}
      <path
        d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
        fill={colorPalette.neutrals.iron.hex}
      />
    </svg>
  );
};
