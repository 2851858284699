import { colorPalette, weightPalette } from "gx-npm-common-styles";

const paragraphStyles = {
  root: {
    color: colorPalette.neutrals.carbon.hex,
    margin: "0",
  },
  // paragraph type styles
  p1: {
    fontSize: "18px",
    letterSpacing: "0.25px",
    lineHeight: "25.2px",
  },
  p2: {
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
  },
  p3: {
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
  },
  p4: {
    fontSize: "12px",
    letterSpacing: "0.35px",
    lineHeight: "18px",
  },
  p5: {
    fontSize: "10px",
    letterSpacing: "0.35px",
    lineHeight: "16px",
  },
  // bold type styles
  regular: {
    fontWeight: weightPalette.regular.amount,
    fontVariationSettings: weightPalette.regular.variation,
  },
  medium: {
    fontWeight: weightPalette.medium.amount,
    fontVariationSettings: weightPalette.medium.variation,
  },
  semi: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  bold: {
    fontWeight: weightPalette.bold.amount,
    fontVariationSettings: weightPalette.bold.variation,
  },
};

export default paragraphStyles;
