const defaultMax = 100;
const defaultMin = 1;
const defaultRange = 10;
const getRnd = (value, range = defaultRange) => {
  const randomBuffer = new Uint32Array(1);
  if (window?.crypto?.getRandomValues) {
    window.crypto.getRandomValues(randomBuffer);
  }
  const randomNumber = randomBuffer[0] / (0xffffffff + 1);
  const calcMax = (value || 0) * 1 + (range || defaultRange);
  const max = Math.floor(calcMax > defaultMax ? defaultMax : calcMax);
  const calcMin = (value || 0) * 1 - (range || defaultRange);
  const min = Math.ceil(calcMin < defaultMin ? defaultMin : calcMin);
  return Math.floor(randomNumber * (max - min + 1)) + min;
};

export default getRnd;
