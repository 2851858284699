import { colorPalette, weightPalette } from "gx-npm-common-styles";

const heightMargin = 81;
const heightContent = 476;
const heightOverall = heightMargin + heightContent;

const errorOverlayStyles = {
  root: {
    zIndex: "1600 !important",
  },
  container: {
    background: `linear-gradient(180deg, ${colorPalette.basic.white.hex} ${heightOverall}px, ${colorPalette.neutrals.quartz.hex} ${heightOverall}px)`,
    height: "100%",
    width: "100%",
  },
  wrapper: {
    borderBottom: `1px solid ${colorPalette.neutrals.stone.hex}`,
    width: "100%",
  },
  contentWrapper: {
    fontVariationSettings: weightPalette.semiBold.variation,
    fontWeight: weightPalette.semiBold.amount,
    height: `${heightContent}px`,
    letterSpacing: "0.15px",
    margin: `${heightMargin}px auto 0 auto`,
    position: "relative",
    width: "898px",
    "& h3": {
      color: colorPalette.neutrals.coal.hex,
      fontSize: "48px",
      lineHeight: "62.4px",
    },
    "& h5": {
      color: colorPalette.neutrals.iron.hex,
      fontSize: "24px",
      lineHeight: "31.2px",
    },
    "& a": {
      color: colorPalette.interactions.blueBerry.hex,
      "&:hover": {
        color: colorPalette.interactions.blackBerry.hex,
        cursor: "pointer",
      },
    },
  },
  linkWrapper: {
    marginTop: "28px",
  },
  imageWrapper: {
    left: 0,
    position: "absolute",
    top: 0,
  },
  textWrapper: {
    bottom: 0,
    marginRight: "50px",
    paddingBottom: "50px",
    position: "absolute",
    right: 0,
    textAlign: "center",
    width: "500px",
  },
};

export { errorOverlayStyles };
