const commonTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 568,
      md: 1024,
      lg: 1240,
      xl: 1440,
      xxl: 1920,
    },
  },
};

export { commonTheme };
