import { colorPalette, weightPalette } from "gx-npm-common-styles";

const headerStyles = {
  root: {
    color: colorPalette.neutrals.carbon.hex,
    letterSpacing: "0.15px",
    lineHeight: "130%",
    margin: 0,
  },
  // header type styles
  h1: {
    fontSize: "48px",
    "&$root": { letterSpacing: 0 },
  },
  h2: {
    fontSize: "36px",
    "&$root": { lineHeight: "130%" },
  },
  h3: { fontSize: "28px" },
  h4: { fontSize: "24px" },
  h5: { fontSize: "20px" },
  // bold type styles
  regular: {
    fontWeight: weightPalette.regular.amount,
    fontVariationSettings: weightPalette.regular.variation,
  },
  medium: {
    fontWeight: weightPalette.medium.amount,
    fontVariationSettings: weightPalette.medium.variation,
  },
  semi: {
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  bold: {
    fontWeight: weightPalette.bold.amount,
    fontVariationSettings: weightPalette.bold.variation,
  },
};

export default headerStyles;
