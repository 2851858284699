/**
 * @param {*} value var to be checked for being a number
 * @returns true if number and not NaN, false otherwise
 */
const isValidNumber = (value) => {
  let isValid = false;
  // eslint-disable-next-line no-self-compare
  if (typeof value === "number" && value === value) {
    isValid = true;
  }
  return isValid;
};

export default isValidNumber;
