const iconTypes = {
  arrow: "arrow",
  arrowDynamic: "arrowDynamic",
  kebab: "kebab",
};
const alignmentTypes = {
  leftBottom: "leftBottom",
  left: "left",
  rightBottom: "rightBottom",
  default: "default",
};

const variantTypes = {
  dropdown: "dropdown",
  select: "select",
};

export { iconTypes, variantTypes, alignmentTypes };
