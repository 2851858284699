import React from "react";
import { colorPalette } from "gx-npm-common-styles";
import { IconProps } from "../types";

const defaultColor = colorPalette.neutrals.iron.hex;
const xmlnsUrl = "http://www.w3.org/2000/svg";

export const BoxOutlineIcon = ({
  fillPath = defaultColor,
  fillSvg = "none",
  height = "17",
  title = "",
  viewBox = "0 0 17 17",
  width = "17",
}: IconProps) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M0 1.83333V4.58333C0 5.0875 0.4125 5.5 0.916667 5.5C1.42083 5.5 1.83333 5.0875 1.83333 4.58333V2.75C1.83333 2.24583 2.24583 1.83333 2.75 1.83333H4.58333C5.0875 1.83333 5.5 1.42083 5.5 0.916667C5.5 0.4125 5.0875 0 4.58333 0H1.83333C0.825 0 0 0.825 0 1.83333ZM0.916667 11C0.4125 11 0 11.4125 0 11.9167V14.6667C0 15.675 0.825 16.5 1.83333 16.5H4.58333C5.0875 16.5 5.5 16.0875 5.5 15.5833C5.5 15.0792 5.0875 14.6667 4.58333 14.6667H2.75C2.24583 14.6667 1.83333 14.2542 1.83333 13.75V11.9167C1.83333 11.4125 1.42083 11 0.916667 11ZM14.6667 13.75C14.6667 14.2542 14.2542 14.6667 13.75 14.6667H11.9167C11.4125 14.6667 11 15.0792 11 15.5833C11 16.0875 11.4125 16.5 11.9167 16.5H14.6667C15.675 16.5 16.5 15.675 16.5 14.6667V11.9167C16.5 11.4125 16.0875 11 15.5833 11C15.0792 11 14.6667 11.4125 14.6667 11.9167V13.75ZM14.6667 0H11.9167C11.4125 0 11 0.4125 11 0.916667C11 1.42083 11.4125 1.83333 11.9167 1.83333H13.75C14.2542 1.83333 14.6667 2.24583 14.6667 2.75V4.58333C14.6667 5.0875 15.0792 5.5 15.5833 5.5C16.0875 5.5 16.5 5.0875 16.5 4.58333V1.83333C16.5 0.825 15.675 0 14.6667 0Z"
        fill={fillPath}
      />
    </svg>
  );
};
