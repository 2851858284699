import { useWebSocket } from "gx-npm-lib";
import { Button, Dialog, TypographyComponent, variantType } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import { WebSocketMessageEvents } from "../../app.constants";
import { WorkspaceHeaderLocationParams, WsInMsgNavigationEvent } from "../../app.types";
import styles from "./navigation-dialog-v2.styles.module.scss";

const NavigationDialogV2Component = () => {
  const [bodyText, setBodyText] = useState<string | null>("");
  const [confirmBtnText, setConfirmBtnText] = useState<string | null>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [title, setTitle] = useState<string | null>("");
  const [userName, setUserName] = useState<string>("");
  const { initId = "" } = useParams<WorkspaceHeaderLocationParams>();
  const [wsMessage] = useWebSocket<WsInMsgNavigationEvent, unknown>();

  useEffect(() => {
    if (!wsMessage || !initId || initId !== wsMessage.initiativeId) {
      return;
    }
    const navigationEvents = [
      WebSocketMessageEvents.INITIATIVE_ACCESS_REVOKED,
      WebSocketMessageEvents.INITIATIVE_DELETED,
      WebSocketMessageEvents.PRODUCT_REMOVED_FROM_EVAL,
    ];
    if (!navigationEvents.includes(wsMessage.event)) {
      return;
    }
    if (wsMessage.event === WebSocketMessageEvents.INITIATIVE_ACCESS_REVOKED) {
      setBodyText("has revoked your access to edit and view this evaluation.");
      setConfirmBtnText("back to my evaluations");
      setRedirectUrl("/s/evaluations");
      setTitle("You no longer have access to this evaluation");
    } else if (wsMessage.event === WebSocketMessageEvents.INITIATIVE_DELETED) {
      setBodyText("has deleted this evaluation and you no longer can view it.");
      setConfirmBtnText("back to my evaluations");
      setRedirectUrl("/s/evaluations");
      setTitle("This evaluation has been deleted");
    } else if (wsMessage.event === WebSocketMessageEvents.PRODUCT_REMOVED_FROM_EVAL) {
      setBodyText("has removed this vendor from the active evaluation.");
      setConfirmBtnText("back to eval overview");
      setRedirectUrl(`/s/evaluation/${initId}/overview`);
      setTitle("Vendor removed from evaluation");
    }
    setIsOpen(true);
    setUserName(wsMessage.name);
  }, [initId, wsMessage]);

  const handleClickConfirm = () => {
    navigateToUrl(redirectUrl);
  };

  return (
    <Dialog
      body={
        <TypographyComponent boldness="regular" color="coal" rootClassName={styles.navBodyWrapper} styling="p3">
          {`${userName} ${bodyText}`}
        </TypographyComponent>
      }
      footer={
        <div className={styles.navFooterWrapper}>
          <div className={styles.btnWrapper}>
            <Button onClick={handleClickConfirm} rootClassName="btn-primary">
              {confirmBtnText}
            </Button>
          </div>
        </div>
      }
      open={isOpen}
      title={title}
      variant={variantType.dialog}
    />
  );
};

export default NavigationDialogV2Component;
