import { useWebSocket } from "gx-npm-lib";
import { TooltipV2, TypographyComponent, UsernameDisplay } from "gx-npm-ui";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebSocketMessageEvents } from "../../../app.constants";
import { WorkspaceHeaderLocationParams, WsInMsgViewingInitiative } from "../../../app.types";
import styles from "./active-viewer-avatars.styles.module.scss";

const MAX_VIEWERS_DISPLAYED = 3;
const ActiveViewerAvatarsComponent = () => {
  const [activeUsers, setActiveUsers] = useState<string[]>([]);
  const [wsMessage] = useWebSocket<WsInMsgViewingInitiative, unknown>();
  const { initId = "" } = useParams<WorkspaceHeaderLocationParams>();

  useEffect(() => {
    if (!wsMessage?.initiativeId || wsMessage.initiativeId !== initId) {
      return;
    }
    if (!Array.isArray(wsMessage.userList) || wsMessage.event !== WebSocketMessageEvents.VIEWING_INITIATIVE) {
      return;
    }
    setActiveUsers(wsMessage.userList);
  }, [initId, wsMessage]);

  return (
    <div className={styles.activeViewRoot}>
      {activeUsers.slice(0, MAX_VIEWERS_DISPLAYED).map((viewer) => {
        return (
          <div key={viewer}>
            <TooltipV2
              placement="bottom"
              PopperProps={{ modifiers: { offset: { offset: "4px, 2px" } } }}
              title={viewer}
            >
              <div>
                <UsernameDisplay invertColor={true} isSmallerIcon={true} name={viewer} showName={false} />
              </div>
            </TooltipV2>
          </div>
        );
      })}
      {activeUsers.length - MAX_VIEWERS_DISPLAYED > 0 && (
        <TooltipV2
          placement="top"
          PopperProps={{ modifiers: { offset: { offset: "4px, 2px" } } }}
          title={activeUsers.slice(MAX_VIEWERS_DISPLAYED).map((viewer) => (
            <span key={viewer}>
              {`${viewer} `}
              <br />
            </span>
          ))}
        >
          <div>
            <TypographyComponent
              boldness="medium"
              color="coal"
              rootClassName={styles.activeViewerAdditionalTooltipText}
              styling="p3"
            >
              {`+${activeUsers.length - MAX_VIEWERS_DISPLAYED}`}
            </TypographyComponent>
          </div>
        </TooltipV2>
      )}
    </div>
  );
};

export default ActiveViewerAvatarsComponent;
