import { UserInitAccess } from "./use-user-init-access.types";

export const DEFAULT_USER_INIT_ACCESS: UserInitAccess = Object.freeze({
  hasLoadedAccess: false,
  id: "",
  role: "",
});
export const STATE_SUBJECT = "USER_INIT_ACCESS";
export const SESSION_STORAGE_KEY_PREFIX = "userInitAccess";
export const SESSION_STORAGE_LOCK_KEY_PREFIX = "userInitAccess.lock";
