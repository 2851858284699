import isString from "../isUtils/isString";

/**
 * @param {*} str string to convert to acroynm
 * @returns take a string and split by spaces to create acronym of first to words of string
 */
const getAcronym = (str) => {
  let acro = "";
  if (isString(str)) {
    const arr = str.split(/\s/);
    const val = arr.reduce((prev, curr) => {
      return `${prev}${isString(str) ? curr.slice(0, 1) : ""}`;
    }, "");
    acro = val.substring(0, 2).toUpperCase();
  }
  return acro;
};

export default getAcronym;
