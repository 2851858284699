import isValidNumber from "./isValidNumber";

const isValidDate = (date) => {
  let isValid = false;
  if (date) {
    const castedDate = new Date(date);
    isValid = isValidNumber(castedDate.getDate());
  }
  return isValid;
};

export default isValidDate;
