import { Collapse } from "@material-ui/core";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { InfoIcon } from "gx-npm-icons";
import { postAsyncRequest } from "gx-npm-lib";
import { MiniButton, TypographyComponent } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { WorkspaceHeaderLocationParams } from "../../app.types";
import { SnackbarContext } from "../../context/snack-bar-banner/snack-bar.context";
import { WorkspaceHeaderContext } from "../../context/workspace-header.context";
import { TabRoute } from "../../app.constants";
import styles from "./legal-inline-banner-v2.styles.module.scss";

const LegalInlineBannerV2Component = () => {
  const { t } = useTranslation();
  const [isLegalAlertShown, setIsLegalAlertShown] = useState(false);
  const { initId = "", tab = "" } = useParams<WorkspaceHeaderLocationParams>();
  const { setShowSnackBar, setSnackbarIsError } = useContext(SnackbarContext);
  const { hasRequestedDocuments, isSurveyLegalDismissed, isSurveySubmitted, setIsSurveyLegalDismissed } =
    useContext(WorkspaceHeaderContext);

  useEffect(() => {
    if (!tab || !isSurveySubmitted || isSurveyLegalDismissed) {
      setIsLegalAlertShown(false);
    } else if (tab === TabRoute.RESPONSES) {
      setIsLegalAlertShown(true);
    } else if (tab === TabRoute.DOCUMENTS && hasRequestedDocuments) {
      setIsLegalAlertShown(true);
    }
  }, [hasRequestedDocuments, isSurveyLegalDismissed, isSurveySubmitted, tab]);

  const handleClickDismiss = async () => {
    const url = `/api/v2/initiatives/${initId}/workspace/dismiss-survey-legal`;
    const response = await postAsyncRequest(url);
    if (response?.status === 201) {
      setIsSurveyLegalDismissed(true);
    } else {
      setSnackbarIsError(true);
      setShowSnackBar(true);
    }
  };
  return (
    <div className={styles.legalBannerRoot}>
      <div className={styles.legalBannerWrapper}>
        <Collapse in={isLegalAlertShown}>
          <div role="banner" className={styles.contentContainer}>
            <InfoIcon fillPath={colorPalette.neutrals.coal.hex} />
            <TypographyComponent boldness="medium" color="coal" rootClassName={styles.contentMessage} styling="p3">
              {t(
                "Vendor responses in BuySmart are not reviewed by Gartner Experts and do not necessarily represent the views or opinions of Gartner."
              )}
            </TypographyComponent>
            <div className={styles.contentBtnWrapper}>
              <div>
                <MiniButton onClick={handleClickDismiss} rootClassName={classNames("btn-tertiary", styles.dismissBtn)}>
                  {t("DISMISS")}
                </MiniButton>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default LegalInlineBannerV2Component;
