import { colorPalette, weightPalette } from "gx-npm-common-styles";
import { currentlySelectedBannerHeight } from "../../lib/header-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const weightAmtMedium = weightPalette.medium.amount;
const weightVarMedium = weightPalette.medium.variation;

const selectedProductRootDefaultStyles = {
  collapseRoot: {
    width: "100%",
  },
  container: {
    alignItems: "center",
    backgroundColor: colorPalette.basic.white.hex,
    boxShadow: "0px -1px 0px 0px #DDD inset",
    color: colorCoal,
    display: "flex",
    height: `${currentlySelectedBannerHeight}px`,
    justifyContent: "center",
    position: "relative" as string as "relative",
    width: "100%",
  },
  title: {
    "&.p3": {
      color: colorCoal,
      fontVariationSettings: weightVarMedium,
      fontWeight: weightAmtMedium,
      paddingRight: "12px",
    },
  },
  productLogo: {
    paddingRight: "12px",
  },
};
export { selectedProductRootDefaultStyles };
