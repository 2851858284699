import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const defaultColor = colorPalette.basic.white.hex;
const defaultBetaColor = colorPalette.neutrals.silver.hex;

const xmlnsUrl = "http://www.w3.org/2000/svg";
/**
 * @deprecated - use /logo lib instead
 */
export const GartnerXLogo = ({
  fillPath = defaultColor,
  betaTextFillPath = defaultBetaColor,
  fillSvg = "none",
  height = "22",
  title = "",
  viewBox = "0 0 180 22",
  width = "180",
}) => {
  return (
    <svg fill={fillSvg} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      {!!title && <title>{title}</title>}
      <path
        d="M125.146 17.0752L117.065 8.99414C117.38 9.66608 117.904 10.2429 117.904 11.004C117.904 11.777 117.6 12.4965 117.059 13.0436C117.059 13.0436 117.059 13.0436 117.053 13.0495C117.053 13.0495 117.053 13.0495 117.047 13.0555L115.008 15.0951L121.067 21.1544C122.191 22.2783 124.022 22.2783 125.146 21.1544C125.693 20.6073 125.991 19.8878 125.991 19.1148C125.991 18.3477 125.693 17.6223 125.146 17.0752Z"
        fill="#CC4308"
      />
      <path d="M115.347 7.2692L114.842 6.75781L115.347 7.2692Z" fill="#993206" />
      <path
        d="M119.718 11.6462L117.066 8.99414C117.37 9.51147 117.905 10.2429 117.905 11.01C117.905 11.783 117.601 12.5025 117.06 13.0495C117.06 13.0495 117.06 13.0495 117.054 13.0555C117.054 13.0555 117.054 13.0555 117.048 13.0614L115.003 15.107L116.525 16.6292C116.549 16.653 116.597 16.653 116.62 16.6292L118.636 14.6134C119.403 13.8345 119.802 12.7463 119.718 11.6462Z"
        fill="#993206"
      />
      <path
        d="M125.147 0.918599C124.6 0.371536 123.88 0.0742188 123.107 0.0742188C122.334 0.0742188 121.615 0.377482 121.068 0.918599L117.464 4.52208C117.44 4.54587 117.44 4.59344 117.464 4.61722L121.448 8.60127C121.472 8.62506 121.52 8.62506 121.543 8.60127L125.147 4.99779C125.694 4.45073 125.991 3.73122 125.991 2.95819C125.991 2.18517 125.688 1.45972 125.147 0.918599Z"
        fill="#FF540A"
      />
      <path
        d="M109.003 0.924459C108.438 0.359556 107.7 0.0800781 106.963 0.0800781C106.226 0.0800781 105.488 0.359556 104.923 0.924459C104.376 1.47152 104.079 2.19103 104.079 2.96405C104.079 3.73708 104.382 4.45658 104.923 5.00365L110.929 11.0095L104.9 17.039C103.776 18.1629 103.776 19.9944 104.9 21.1182C106.023 22.2421 107.855 22.2421 108.979 21.1182L117.042 13.055C117.042 13.055 117.042 13.055 117.048 13.049C117.048 13.049 117.048 13.049 117.054 13.0431C117.601 12.496 117.898 11.7765 117.898 11.0035C117.898 10.2305 117.595 9.52287 117.048 8.9758"
        fill="#FF540A"
      />
      <path
        d="M95.9736 10.4844L96.2531 7.14258H94.9449C93.2859 7.14258 91.942 7.70153 91.1749 8.93243L91.0917 7.14258H87.4287V21.4614H91.2938V13.9095C91.2938 12.2326 92.4296 10.4844 94.6059 10.4844H95.9736Z"
        fill={fillPath}
      />
      <path
        d="M64.3335 6.79297C62.2226 6.79297 60.7895 7.75033 60.0224 8.97528L59.9392 7.1438H56.3535V21.4626H60.2186V13.6669C60.2186 11.8652 61.0749 10.3132 63.2215 10.3132C65.4514 10.3132 65.5882 12.3112 65.5882 13.8929V21.4626H69.4593V12.4717C69.4593 9.57586 68.2284 6.79297 64.3335 6.79297Z"
        fill={fillPath}
      />
      <path
        d="M50.8173 16.3955V10.4908H54.397L54.6646 7.14898H50.8173V3.10547H46.9521V16.6869C46.9521 20.0466 48.0106 21.7175 51.6557 21.7175C52.6547 21.7175 53.4753 21.6283 54.4029 21.3786L54.6705 18.12C53.838 18.3995 53.172 18.4708 52.5714 18.4708C51.1859 18.4708 50.8173 17.7394 50.8173 16.3955Z"
        fill={fillPath}
      />
      <path
        d="M40.9404 8.93243L40.8631 7.14258H37.2002V21.4614H41.0653V13.9095C41.0653 12.2326 42.1476 10.4844 44.3239 10.4844H45.3764L45.6737 7.14258H44.7104C43.0573 7.14258 41.7135 7.70748 40.9404 8.93243Z"
        fill={fillPath}
      />
      <path
        d="M11.3337 13.0958H15.6627V17.5377C14.4496 18.0848 13.0463 18.3464 11.084 18.2988C6.92154 18.1918 4.25758 15.1948 4.3111 10.854C4.36461 6.84619 6.77883 3.93248 10.7926 3.7422C13.2544 3.62922 15.3118 4.32494 16.7092 5.52015L19.4326 2.767C17.173 0.774973 14.2831 -0.045622 11.0483 0.00194877C4.67382 0.0970902 0 4.37845 0 11.08C0 17.8112 4.62625 21.7953 11.0483 21.9915C14.5091 22.0926 17.1314 21.2839 19.7537 19.8806V9.45663H11.6251L11.3337 13.0958Z"
        fill={fillPath}
      />
      <path
        d="M78.5864 6.79297C74.4181 6.79297 71.207 9.81371 71.207 14.3032C71.207 18.7986 74.4359 21.8134 78.747 21.8134C81.1969 21.8134 83.3495 21.0107 85.1453 18.7986L82.624 16.872C81.3515 18.1624 80.3763 18.6857 78.634 18.6381C76.8442 18.5905 75.4051 17.4904 75.1019 15.659H85.7042V14.5113C85.6983 9.57586 83.177 6.79297 78.5864 6.79297ZM75.0959 12.668C75.3576 11.0803 76.6063 9.86128 78.4854 9.78992C80.3525 9.71857 81.7083 10.8603 81.8272 12.668H75.0959Z"
        fill={fillPath}
      />
      <path
        d="M28.3763 6.79297C26.1286 6.79297 24.0236 7.40544 21.9959 9.04663L24.2258 11.3122C25.2545 10.3489 26.64 9.78398 28.0433 9.78398C29.9997 9.78398 31.0403 10.6343 31.0403 12.2041V12.3944H30.3565C26.9492 12.3944 21.5202 12.7334 21.5975 17.4845C21.651 20.5112 24.1366 21.8075 26.7708 21.8075C28.519 21.8075 30.2197 21.3437 31.2662 20.0474L31.3495 21.4566H34.7567V12.0852C34.7686 8.84446 32.8123 6.79297 28.3763 6.79297ZM31.0462 15.8136C31.0462 19.0662 25.4924 19.9225 25.4745 17.2288C25.4626 15.3141 28.6796 15.2249 30.5111 15.2189H31.0462V15.8136Z"
        fill={fillPath}
      />
      <path
        d="M95.6889 19.3217C95.6889 18.8817 95.4272 18.6914 94.9039 18.6914H94.0596V20.9153H94.3926V19.952H94.7791L95.3618 20.9153H95.7424L95.1121 19.9342C95.4391 19.8985 95.6889 19.7201 95.6889 19.3217ZM94.3985 19.6666V18.9768H94.8564C95.0942 18.9768 95.338 19.0303 95.338 19.3039C95.338 19.6428 95.0823 19.6666 94.7969 19.6666H94.3985Z"
        fill={fillPath}
      />
      <path
        d="M94.803 17.877C93.7446 17.877 92.8467 18.6916 92.8467 19.7976C92.8467 20.9155 93.7446 21.7302 94.803 21.7302C95.8555 21.7302 96.7475 20.9155 96.7475 19.7976C96.7475 18.6916 95.8555 17.877 94.803 17.877ZM94.803 21.4091C93.923 21.4091 93.2332 20.7253 93.2332 19.7976C93.2332 18.8878 93.923 18.1981 94.803 18.1981C95.6712 18.1981 96.361 18.8878 96.361 19.7976C96.3669 20.7253 95.6771 21.4091 94.803 21.4091Z"
        fill={fillPath}
      />
      <rect x="141.5" y="5.5" width="38" height="13" stroke={betaTextFillPath} />
      <path
        d="M150.688 9.04255H148V15H150.774C152.109 15 152.991 14.4298 152.991 13.3574C152.991 12.5404 152.503 12.1404 151.801 11.9021C152.246 11.6723 152.657 11.2979 152.657 10.566C152.657 9.62979 151.895 9.04255 150.688 9.04255ZM150.474 11.5532H149.036V9.97021H150.551C151.228 9.97021 151.604 10.2511 151.604 10.7362C151.604 11.2894 151.15 11.5532 150.474 11.5532ZM150.782 14.0723H149.036V12.4383H150.705C151.536 12.4383 151.938 12.7362 151.938 13.2468C151.938 13.7915 151.501 14.0723 150.782 14.0723Z"
        fill={betaTextFillPath}
      />
      <path
        d="M159.478 9.04255H155.034V15H159.52V14.0638H156.087V12.4638H159.092V11.5277H156.087V9.97872H159.478V9.04255Z"
        fill={betaTextFillPath}
      />
      <path d="M164.16 10.0128H166.06V9.04255H161.198V10.0128H163.098V15H164.16V10.0128Z" fill={betaTextFillPath} />
      <path
        d="M170.363 9H169.387L166.75 15H167.829L168.446 13.5617H171.279L171.887 15H173L170.363 9ZM170.894 12.634H168.831L169.858 10.2511L170.894 12.634Z"
        fill={betaTextFillPath}
      />
    </svg>
  );
};
