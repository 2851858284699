import { UUID } from "../types";
import { InitUserRole } from "../constants/enums";

export type UserInitAccess = {
  hasLoadedAccess: boolean;
  id: UUID;
  role: InitUserRole | "";
};

export enum WebSocketMessageEvents {
  INITIATIVE_ACCESS_CHANGED = "INITIATIVE_ACCESS_CHANGED",
  INITIATIVE_ACCESS_REVOKED = "INITIATIVE_ACCESS_REVOKED",
}

export type WsInMsg = {
  event: WebSocketMessageEvents;
  initiativeId: UUID;
};
