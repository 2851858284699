import { colorPalette } from "gx-npm-common-styles";
import { ArrowBackIcon, BuySmartLogoOrangeStacked } from "gx-npm-icons";
import { createStateSubscription, destroyStateSubscription } from "gx-npm-messenger-util";
import { Button, Loader, TypographyComponent } from "gx-npm-ui";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as singleSpa from "single-spa";
import { WorkspaceHeaderContext } from "../../context/workspace-header.context";
import { WorkspaceHeaderLocationParams } from "../../app.types";
import ActiveViewerAvatarsComponent from "./active-viewer-avatars/active-viewer-avatars.component";
import styles from "./branding-header-v2.styles.module.scss";

const BrandingHeaderV2Component = ({ isLoading }: { isLoading: boolean }) => {
  const { t } = useTranslation();
  const { initId = "", initProdId = "" } = useParams<WorkspaceHeaderLocationParams>();
  const [backBtnText, setBackBtnText] = useState<string>("");
  const [backBtnRoute, setBackBtnRoute] = useState<string>("");
  const [sourcePage, setSourcePage] = useState<string>("overview");
  const { initName } = useContext(WorkspaceHeaderContext);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    const subscription = createStateSubscription("WORKSPACE_HEADER_SOURCE_PAGE", (data: { sourcePage: string }) => {
      if (!data?.sourcePage) {
        return;
      }
      setSourcePage(data.sourcePage);
      sessionStorage.setItem(`workspace-source-page-${initId}-${initProdId}`, data.sourcePage);
    });
    return () => {
      destroyStateSubscription(subscription);
    };
  }, [initId, initProdId]);

  useEffect(() => {
    if (!initId || !initProdId) {
      return;
    }
    const storageKey = `workspace-source-page-${initId}-${initProdId}`;
    const storageValue = sessionStorage.getItem(storageKey);
    if (!storageValue) {
      return;
    }
    setSourcePage(storageValue);
  }, [initId, initProdId]);

  useEffect(() => {
    if (!initId || !initProdId || !sourcePage) {
      return;
    }
    sessionStorage.setItem(`workspace-source-page-${initId}-${initProdId}`, sourcePage);
  }, [initId, initProdId, sourcePage]);

  useEffect(() => {
    if (!sourcePage) {
      return;
    } else if (sourcePage === "overview") {
      setBackBtnRoute("overview");
      setBackBtnText(t("OVERVIEW") as string);
    } else if (sourcePage === "vendor-list") {
      setBackBtnRoute("products");
      setBackBtnText(t("VENDOR LIST") as string);
    } else if (sourcePage === "questionnaire") {
      setBackBtnRoute("questionnaire");
      setBackBtnText(t("QUESTIONNAIRE") as string);
    } else if (sourcePage === "scorecard") {
      setBackBtnRoute("scorecard");
      setBackBtnText(t("SCORECARD") as string);
    } else if (sourcePage === "selection") {
      setBackBtnRoute("selection");
      setBackBtnText(t("SELECTION") as string);
    } else {
      setBackBtnRoute("overview");
      setBackBtnText(t("OVERVIEW") as string);
    }
  }, [sourcePage, t]);

  const handleClick = () => {
    singleSpa.navigateToUrl(`/s/evaluation/${initId}/${backBtnRoute}`);
  };
  return (
    <div className={styles.brandingHeaderRoot}>
      <div className={styles.brandingHeaderContent}>
        <Button rootClassName="btn-tertiary" onClick={handleClick}>
          <ArrowBackIcon fillPath={colorPalette.interactions.defaultCta.hex} />
          {`${t("BACK TO")} ${backBtnText}`}
        </Button>
        <div className={styles.brandingHeaderDivider} />
        {isLoading && <Loader rootClassName={styles.brandingHeaderLoader} />}
        {!isLoading && (
          <TypographyComponent
            boldness="regular"
            color="iron"
            rootClassName={styles.brandingHeadingInitName}
            styling="p1"
          >
            {initName || t("Untitled evaluation")}
          </TypographyComponent>
        )}
        <ActiveViewerAvatarsComponent />
      </div>
      <div>
        <BuySmartLogoOrangeStacked />
      </div>
    </div>
  );
};

export default BrandingHeaderV2Component;
